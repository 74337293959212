import { FlexibleTwoColTemplateComponent } from "libs/templates/flexible-two-col/flexible-two-col.component";
import { FlexibleTemplateComponent } from "libs/templates/flexible/flexible.component";

export const TMobileRoutes = [
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home-tmobile', contentGroup: 'member-home-tmobile' } },

  // BENEFITS AND COVERAGE
  { path: 'welcome', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'welcome-tmobile', contentGroup: 'member-welcome-tmobile' } },
  { path: 'preapproval', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'preapproval-tmobile', contentGroup: 'member-preapproval-tmobile' } },
  { path: 'personal-funding-accounts', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'personal-funding-accounts-tmobile', contentGroup: 'member-personal-funding-accounts-tmobile' } },
  ​{ path: 'personal-health-support', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'personal-health-support-tmobile', contentGroup: 'member-personal-health-support-tmobile' } },
  
  // PRESCRIPTIONS
  { path: 'drugs-pharmacy', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'drugs-pharmacy-tmobile', contentGroup: 'member-drugs-pharmacy-tmobile' } },

  // FIND CARE
  { path: 'find-doctor', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'find-doctor-tmobile', contentGroup: 'member-find-doctor-tmobile' } },
  { path: 'virtual-care', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'virtual-care-tmobile', contentGroup: 'member-virtual-care-tmobile' } },

  // EXPLORE RESOURCES
  { path: 'help-forms', component: FlexibleTwoColTemplateComponent, data: { page: 'help-forms-tmobile', contentGroup: 'member-help-forms-tmobile' } },
  { path: 'help-forms/account', component: FlexibleTwoColTemplateComponent, data: { page: 'account-tmobile', contentGroup: 'member-account-tmobile' } },
  { path: 'help-forms/benefits', component: FlexibleTwoColTemplateComponent, data: { page: 'benefits-tmobile', contentGroup: 'member-benefits-tmobile' } },
  { path: 'help-forms/care-costs', component: FlexibleTwoColTemplateComponent, data: { page: 'care-costs-tmobile', contentGroup: 'member-care-costs-tmobile' } },
  { path: 'help-forms/claims', component: FlexibleTwoColTemplateComponent, data: { page: 'claims-tmobile', contentGroup: 'member-claims-tmobile' } },
  { path: 'help-forms/forms', component: FlexibleTwoColTemplateComponent, data: { page: 'forms-tmobile', contentGroup: 'member-forms-tmobile' } },
  { path: 'care-essentials', component: FlexibleTwoColTemplateComponent, data: { page: 'care-essentials-tmobile', contentGroup: 'member-care-essentials-tmobile' } },
  { path: 'care-essentials/mental-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mental-health-tmobile', contentGroup: 'member-mental-health-tmobile' } },
  { path: 'care-essentials/preventive', component: FlexibleTwoColTemplateComponent, data: { page: 'preventive-tmobile', contentGroup: 'member-preventive-tmobile' } },
  { path: 'care-essentials/cancer', component: FlexibleTwoColTemplateComponent, data: { page: 'cancer-tmobile', contentGroup: 'member-cancer-tmobile' } },
  { path: 'care-essentials/diabetes', component: FlexibleTwoColTemplateComponent, data: { page: 'diabetes-tmobile', contentGroup: 'member-diabetes-tmobile' } },
  { path: 'care-essentials/pregnancy-baby', component: FlexibleTwoColTemplateComponent, data: { page: 'pregnancy-baby-tmobile', contentGroup: 'member-pregnancy-baby-tmobile' } },
  { path: 'care-essentials/family-planning-sexual-health', component: FlexibleTwoColTemplateComponent, data: { page: 'family-planning-sexual-health-tmobile', contentGroup: 'member-family-planning-sexual-health-tmobile' } },
  { path: 'care-essentials/lgbtq', component: FlexibleTwoColTemplateComponent, data: { page: 'lgbtq-tmobile', contentGroup: 'member-lgbtq-tmobile' } },
  { path: 'glossary', component: FlexibleTemplateComponent, data: { page: 'glossary-tmobile', contentGroup: 'member-glossary-tmobile' } },
  { path: 'how-health-plans-work', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'how-health-plans-work-tmobile', contentGroup: 'member-how-health-plans-work-tmobile' } },
  { path: 'mobile', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'mobile-tmobile', contentGroup: 'member-mobile-tmobile' } },

  // ORPHAN PAGES
  { path: 'outbreaks', component: FlexibleTemplateComponent, data: { page: 'outbreaks-tmobile', contentGroup: 'member-outbreaks-tmobile' } },
  { path: 'care-while-traveling', component: FlexibleTemplateComponent, data: { page: 'care-while-traveling-tmobile', contentGroup: 'member-care-while-traveling-tmobile' } },

  // FOOTER
  { path: 'contact', component: FlexibleTemplateComponent, data: { page: 'contact-tmobile', contentGroup: 'member-contact-tmobile' } },
  { path: 'about-premera', component: FlexibleTemplateComponent, data: { page: 'about-premera-tmobile', contentGroup: 'member-about-premera-tmobile' } },
  { path: 'tmobile', component: FlexibleTemplateComponent, data: { page: 'tmobile-tmobile', contentGroup: 'member-tmobile-tmobile' } },
  { path: 'data-collection', component: FlexibleTemplateComponent, data: { page: 'data-collection-tmobile', contentGroup: 'member-data-collection-tmobile' } },
  { path: 'privacy', component: FlexibleTemplateComponent, data: { page: 'privacy-tmobile', contentGroup: 'member-privacy-tmobile' } },
  { path: 'privacidad', component: FlexibleTemplateComponent, data: { page: 'privacidad-tmobile', contentGroup: 'member-privacidad-tmobile' } },
  { path: 'terms', component: FlexibleTemplateComponent, data: { page: 'terms-tmobile', contentGroup: 'member-terms-tmobile' } },
  { path: 'medical-policies', component: FlexibleTemplateComponent, data: { page: 'medical-policies-tmobile', contentGroup: 'member-medical-policies-tmobile' } },
]