import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, FormGroup } from '@angular/forms';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SelectComponent),
  multi: true
};

@Component({
  selector: 'pbc-select',
  templateUrl: './select.component.html',
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input()
  id: string;
  @Input()
  label: string;
  @Input()
  options: any;
  @Input()
  formControlName: string;
  @Input()
  formGroup: FormGroup;
  @Input()
  compareValue: string;
  @Input()
  optionLabelFunction: (option) => string;
  @Input()
  emptyDefaultValue: boolean;
  @Input()
  disabled: boolean;
  @Output()
  focus = new EventEmitter();
  @Output()
  blur = new EventEmitter();

  control: FormControl = new FormControl();

  constructor() {}

  ngOnInit() {
    if (this.formGroup) {
      this.control = null;
      this.control = this.formGroup.get(this.formControlName) as FormControl;
    }
  }
  // get accessor
  get value(): any {
    return this.control.value;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.control.value) {
      this.control.patchValue(v);
      this.propogateChange(v);
    }
  }

  compareFunction(o1: any, o2: any): boolean {
    return o1 && o2 && JSON.stringify(o1) === JSON.stringify(o2);
  }

  // propagate changes into the custom form control
  propogateChange = (_: any) => { };
  propogateTouched = () => {};

  writeValue(obj: any): void {
    if (obj && obj !== this.control.value) {
      this.control.patchValue(obj);
      this.propogateChange(obj);
    }
  }
  registerOnChange(fn: any): void {
    this.propogateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propogateTouched = fn;
  }
}
