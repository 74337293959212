import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { HelperService } from 'libs/services/helper.service';
import { ComponentState } from 'libs/models/pbc-models';
import { NgbActiveModal, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'pbc-carousel',
  templateUrl: './carousel.component.html',
  styles: [
  ]
})
export class CarouselComponent  {
  @Input() data: ComponentState;
  sections: any;
  previousArrowElement : any;
  nextArrowElement : any;
  numberOfSlides : any;
  changeClassName : any;
  @ViewChild('divCarousel') divCarousel: ElementRef;
  @ViewChild('btnCarouselClose') btnCarouselClose: ElementRef;  

  ngAfterViewInit() {
    this.numberOfSlides = document.getElementsByClassName('carousel-item').length;
    this.previousArrowElement = document.getElementsByClassName('carousel-control-prev');
    this.nextArrowElement = document.getElementsByClassName('carousel-control-next');
    if(this.previousArrowElement.length > 0){
      this.renderer.setStyle(this.previousArrowElement[0], 'display', 'none');
    }
    this.changeClassName = document.getElementsByClassName('modal-content');
    if(this.changeClassName.length > 0){
      this.renderer.addClass(this.changeClassName[0],'carousel-modal-content');
    }
  }
  
  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    if (!this.sections) {
      this.sections =JSON.parse(this.data.content.sections)
    }
    return this.sections;
  }

  constructor(private helperService: HelperService, public activeModal: NgbActiveModal,private elementRef: ElementRef, private renderer: Renderer2) {  }

  createHeading(cardHeaderLevel: string, cardStyle: string, cardHeader): string {
    return this.helperService.createHeading(cardHeaderLevel, cardStyle, cardHeader);
  }

  onSlide(event: NgbSlideEvent){   
    
    if(event.current == 'ngb-slide-'+ (this.numberOfSlides - 1) && this.nextArrowElement.length > 0){
      this.renderer.setStyle(this.nextArrowElement[0], 'display', 'none');
    }

    if(event.prev == 'ngb-slide-' + (this.numberOfSlides - 1) && event.current =='ngb-slide-' + (this.numberOfSlides - 2) && this.nextArrowElement.length > 0){
      this.renderer.removeStyle(this.nextArrowElement[0], 'display');
    }

    if(event.prev == 'ngb-slide-0' && event.current == 'ngb-slide-1' && this.previousArrowElement.length > 0){
      this.renderer.removeStyle(this.previousArrowElement[0],'display');
    }

    if(event.current == 'ngb-slide-0' && event.prev == 'ngb-slide-1' && this.previousArrowElement.length > 0){
      this.renderer.setStyle(this.previousArrowElement[0],'display','none');
    }

    this.renderer.setAttribute(this.divCarousel.nativeElement, 'data-analytics-tag', `${this.data?.analyticsView}-${event.current}`);
    this.renderer.setAttribute(this.btnCarouselClose.nativeElement, 'data-analytics-tag', `${this.data?.analyticsView}-${event.current}-close`);
    
  }
}
