<div class="carousel-modal-header">
  <h4 class="modal-title" style="visibility: hidden;">invisible heading</h4>
  <button type="button" class="btn-close border-0" #btnCarouselClose pbcAnalyticsTag aria-label="Close" (click)="activeModal.dismiss()"><img
      src="\assets\image\CloseCTA.svg" alt="" aria-hidden="true"></button>
</div>
<div *ngIf="data?.isVisible" [ngClass]="data?.content?.parentClasses" data-id="carousel" class="carousel-content">
  <div class="row no-gutters">
    <div class="col col-sm-10 col-md-8 col-lg-6 mx-auto">
      <!-- <h2 *ngIf="!!data?.content?.heading" [innerHTML]="data?.content?.heading" [ngClass]="data?.content?.headingClass"></h2> -->
      <div [innerHTML]="data?.content?.introduction"></div>
    </div>
  </div>
  <div class="row no-gutters">
    <div class="col col-sm-12 col-md-12 col-lg-12 mx-auto">
      <div pbcAnalyticsTag #divCarousel></div>
      <ngb-carousel (slide)="onSlide($event)" [interval]="false" [wrap]="false"
        [showNavigationArrows]="data?.content?.showNavigationArrows === 'true'"
        [showNavigationIndicators]="data?.content?.showNavigationIndicators === 'true'">
        <ng-container *ngFor="let section of getSections()">
          <ng-template ngbSlide>
            <div class="carousel-caption p-0" *ngIf="!!section.sectionHeader || !!section.sectionBody">
              <div *ngIf="!!section.sectionHeader"
                [innerHTML]="((createHeading(section.sectionHeaderLevel, section.sectionHeaderStyle, section.sectionHeader)) | decodeHtml) | safehtml">
              </div>
            </div>
            <div *ngIf="!!section.sectionImage" [innerHTML]="section.sectionImage"></div>
            <div *ngIf="!!section.sectionImagePath" class="text-center" [innerHTML]="section.sectionImagePath | decodeHtml"></div>
            <div class="text-center" [innerHTML]="section.sectionSubHeader | decodeHtml"></div>
            <div class="section-body" [innerHTML]="section.sectionBody | decodeHtml"></div>
            <div class="section-link" [innerHTML]="section.sectionLink | decodeHtml"></div>
          </ng-template>
        </ng-container>
      </ngb-carousel>
    </div>
  </div>
</div>