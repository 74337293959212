import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { mergeMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { addMonths } from 'date-fns';
import { Router } from '@angular/router';

import { ComponentState, ContentLabels, Group, PageContent, SuccessResponse } from 'libs/models/pbc-models';
import { GroupService } from 'libs/services/group.service';
import { DataService } from './data.service';
import { MemberSharedService } from './member-shared.service';
import { PbcCookieService } from './pbc-cookie.service';
import { UrlBuilderService } from './url-builder.service';
import { AnalyticsReportingService } from './analytics-reporting.service';
import { AuthenticationService } from './authentication.service';
import { RegistrationPlan } from 'libs/models/member.models';
import { ChildSite, ChildSiteScopes } from 'libs/models/site-config';
import { ContentHelperMethods } from 'libs/components/content-component';
import { EnvironmentService } from './environment-variables.service';
import { LoggingService, SeverityLevel } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService implements OnDestroy {
  private readonly locale: string;
  private readonly waRegion = 'pbcwa';
  private readonly akRegion = 'pbcak';
  private readonly lwwaRegion = 'lwwa';
  private readonly defaultLocale = 'en-us';
  private readonly sixMonthExpiration = 6;
  private readonly excludeWithCredentials = false;
  private readonly waLogoImageUrl = 'assets/image/logos/pbc_logo.svg';
  private readonly akLogoImageUrl = 'assets/image/logos/pbc_logo_AK.svg';
  private readonly lwwaWhiteLogoImageUrl='assets/image/logos/lw_logo_white.svg';
  private readonly lwwaColorLogoImageUrl='assets/image/logos/lw_logo_color.svg';
  componentDestroyed$: Subject<boolean> = new Subject();
  doneLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  region$ = new BehaviorSubject<string>(this.waRegion);
  childSite$ = new BehaviorSubject<ChildSite>(ChildSite.none);
  group: Group;

  constructor(private readonly dataService: DataService,
    private readonly urlBuilder: UrlBuilderService,
    private readonly pbcCookieService: PbcCookieService,
    private readonly memberSharedService: MemberSharedService,
    private readonly groupService: GroupService,
    private readonly analyticsService: AnalyticsReportingService,
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly logger: LoggingService) {

    this.refreshChildSite(window.location.pathname, EnvironmentService.variables.site);
    this.locale = this.setLocale();
   
    // This will set only if cookie is available 
    const regionCookieValue = this.pbcCookieService.get(EnvironmentService.variables.site === this.lwwaRegion ? this.pbcCookieService.lwwaRegion : this.pbcCookieService.regionCookie);
    if (!!regionCookieValue && (regionCookieValue.toLowerCase() === this.waRegion || 
        regionCookieValue.toLowerCase() === this.akRegion || 
        regionCookieValue.toLowerCase() === this.lwwaRegion)) {
      this.region$.next(regionCookieValue);
    }else if(EnvironmentService.variables.site === this.lwwaRegion ) {
      // This block will get executed when we dont have cookie and site belongs to lifewise; Override region behaviour value with lifewise
      this.region$.next (this.lwwaRegion);
    }

    if(window.location.pathname === '/callback'){
      this.pbcCookieService.delete(this.pbcCookieService.memberAuthCookie);
      this.pbcCookieService.delete(this.pbcCookieService.sessionGuidCookie);
    }

    this.authService.getIsAuthenticated().pipe(takeUntil(this.componentDestroyed$))
      .subscribe(isAuthenticated => {
        this.doneLoading$.next(false);
        if (isAuthenticated) {
          // listen for changes to logged in member
          this.memberSharedService.loggedInMemberKey.pipe(takeUntil(this.componentDestroyed$)).subscribe(memberKey => {
            if (!memberKey) {
              this.doneLoading$.next(true);
            } else {
              this.doneLoading$.next(false);
              this.setRegionFromSelectedRegistration();
              const groupKey = this.getSelectedGroupKey();
              if (!!groupKey) {
                this.groupService.getGroup(null, groupKey,memberKey).pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(groupDetails => {
                    this.setGroup(groupDetails);
                    this.doneLoading$.next(true);
                  }, err => {
                    this.doneLoading$.next(true);
                  });
              } else {
                this.groupService.getLoggedInMemberGroup().pipe(takeUntil(this.componentDestroyed$))
                  .subscribe(groupDetails => {
                    this.setGroup(groupDetails);
                    this.doneLoading$.next(true);
                  }, err => {
                    this.doneLoading$.next(true);
                  });
              }
            }
          });
        } else {
          this.doneLoading$.next(true);
        }
      }, err => { this.doneLoading$.next(true); });
  }

  refreshChildSite(path: string, site: string): ChildSite {
    const siteScope = ChildSiteScopes.find(scope => scope.site === site);
    if (!siteScope) {
      if (this.childSite$.value !== ChildSite.none) {
        this.childSite$.next(ChildSite.none);
      }
      return this.childSite$.value;
    }
    const childSiteConfig = siteScope.childSiteConfigs.find(config => path.startsWith(config.parentPath + config.childSite));
    const result = !!childSiteConfig ? childSiteConfig.childSite : ChildSite.none;
    if (this.childSite$.value !== result) {
      this.childSite$.next(result);
    }
    return this.childSite$.value;
  }

  getChildSiteHomeUrl(childSite: ChildSite, site: string): string {
    const siteScope = ChildSiteScopes.find(scope => scope.site === site);
    if (!siteScope) {
      return '/';
    }
    const childSiteConfig = siteScope.childSiteConfigs.find(config => config.childSite === childSite);
    return !!childSiteConfig ? `${childSiteConfig.parentPath}${childSiteConfig.childSite}` : '/';
  }

  isChildSite(): boolean {
    return (this.childSite$.value !== ChildSite.none);
  }

  getComponentState(instanceName: string, type: string): Observable<ComponentState> {
    return this.doneLoading$.pipe(mergeMap((done) => {
      if (done && !!instanceName) {
        return this.getComponentStateData(instanceName, type, !!this.group ? this.group.parentGroupId : '', !!this.group ? this.group.groupId : '');
      } else {
        return new Observable<ComponentState>();
      }
    }));
  }

  /*
  Usage:
    this.contentLabels = this.contentService.getContentLabels(...);
    in HTML:
      <div [innerHtml]="contentLabels?.someKey?.value" />
      <div>{{contentLabels?.someKey?.value}}</div>
      - if you need to know if the value contains encoded html -
      <div *ngIf="contentLabels?.someKey?.isHtml" [innerHtml]="contentLabels?.someKey?.value" />
      <span *ngIf="!contentLabels?.someKey?.isHtml">{{contentLabels?.someKey?.value}}</span>
  */
  getContentLabels(instanceName: string, type: string): Observable<ContentLabels> {
    return this.getComponentState(instanceName, type).pipe(map(response => {
      if (!response || !response.content) {
        return {};
      }
      return ContentHelperMethods.getContentArray(response.content.contents);
    }));
  }

  getPageContent(pageName: string, type: string): Observable<PageContent> {
    return this.doneLoading$.pipe(mergeMap((done) => {
      if (done && !!pageName) {
        return this.parsePageContent(pageName, type, !!this.group ? this.group.parentGroupId : '', !!this.group ? this.group.groupId : '');
      } else {
        return new Observable<PageContent>();
      }
    }));
  }

  getGlobalAlert(type: string): Observable<ComponentState> {
    return this.doneLoading$.pipe(mergeMap((done) => {
      if (done) {
        return this.getGlobalAlertData(type, !!this.group ? this.group.parentGroupId : '', !!this.group ? this.group.groupId : '');
      } else {
        return new Observable<ComponentState>();
      }
    }));
  }

  switchRegion() {
    const newRegionValue = this.isAkRegion() ? this.waRegion : this.akRegion;
    this.setCookie(this.pbcCookieService.regionCookie, newRegionValue, this.waRegion);
    this.region$.next(newRegionValue);
  }

  setRegion(region?: string) {
    const newRegion = this.validateRegion(region);
    const regionValue = this.setCookie(this.pbcCookieService.regionCookie, newRegion, this.waRegion);
    this.region$.next(regionValue);
  }

  isAkRegion(): boolean {
    return this.region$.value === this.akRegion;
  }

  islwwaRegion():boolean{
    return this.region$.value===this.lwwaRegion;
  }

  // TODO: delete when no longer used by footer
  getLogoImageUrl(): string {
    return (this.isAkRegion() && !this.isChildSite()) 
    ? this.akLogoImageUrl 
    : this.islwwaRegion()
      ? this.lwwaWhiteLogoImageUrl
    :this.waLogoImageUrl;
  }

  getLocale(): string {
    return this.locale;
  }

  setLocale(locale?: string): string {
    return this.setCookie(this.pbcCookieService.localeCookie, locale, this.defaultLocale);
  }

  getContentFile(path: string, domain: string): Observable<string> {
    if (!path.includes('mocks/')) {
      path = `${domain}/${path}`;
    }
    return this.dataService.get<string>(path, new HttpHeaders(), null, 'text');
  }

  private validateRegion(region?: string): string {
    if (EnvironmentService.variables.site === this.lwwaRegion) return this.lwwaRegion; 
    
    if (!region || region !== this.akRegion) {
      return this.waRegion;
    }
    return this.akRegion;
  }

  private setGroup(groupDetails: Group) {
    this.group = groupDetails;
    if (!!this.group && !!this.group.groupId) {
      this.analyticsService.setGroupId(this.group.groupId);
      this.analyticsService.setGroupName(this.group.groupName);
    }
  }

  private getSelectedGroupKey(): string {
    let groupKey = '';
    const selected = this.getSelectedRegistration();
    if (!!selected) {
      groupKey = selected.groupKey;
    }
    return groupKey;
  }

  private setRegionFromSelectedRegistration() {
    const selected = this.getSelectedRegistration();
    if (!!selected && selected.lineOfBusiness.toLocaleLowerCase() !== this.region$.value) {
      this.setRegion(selected.lineOfBusiness.toLocaleLowerCase());
    }
  }

  private getSelectedRegistration(): RegistrationPlan {
    let selectedRegistration: RegistrationPlan = null;
    const account = this.memberSharedService.getRegistrationsFromCookie();
    const selectedMemberKey = this.memberSharedService.getSelectedMemberKey();
    if (!!account && !!selectedMemberKey && !!account.registrations) {
      selectedRegistration = account.registrations.find(r => r.memberKey === selectedMemberKey);
    }
    return selectedRegistration;
  }

  private getComponentStateData(instanceName: string, type: string, parentGroupId = '', groupId = '', locale = ''): Observable<ComponentState> {
    const data = { instanceName, type: this.contentCrossWalk(type), locale: locale || this.locale, lineOfBusiness: this.region$.value, parentGroupId, groupId };
    return this.dataService
      .post<SuccessResponse<ComponentState>>(this.urlBuilder.buildContentUrl(instanceName, this.region$.value), data, {}, this.excludeWithCredentials)
      .pipe(map(response => !!response ? response.result : null));
  }

  private parsePageContent(pageName: string, type: string, parentGroupId = '', groupId = '', locale = ''): Observable<PageContent> {
    const returnVal = new PageContent();
    returnVal.pageContent = {};
    return this.getPageStateData(pageName, type, parentGroupId, groupId, locale)
      .pipe(switchMap(response => {
        if (!response) {
          return of(returnVal);
        }
        returnVal.pageInfo = response[pageName];
        if (!returnVal.pageInfo.isVisible) {
          const err = 'Content Service: Page is not visible.';
          console.log(err);
          this.logger.track(err, this.router.url, SeverityLevel.Warning);
          this.router.navigate(['/page-not-found'], { skipLocationChange: true });
        }
        if (response[pageName].instanceNames) {
          const pageInstances = JSON.parse(response[pageName].instanceNames);
          for (const pageInstanceName in pageInstances) {
            if (pageInstances.hasOwnProperty(pageInstanceName) && response[pageInstanceName]) {
              returnVal.pageContent[pageInstanceName] = response[pageInstanceName];
            }
          }
        }
        if (returnVal.pageInfo.content.metaTag) {
          returnVal.pageInfo.content.metaTag = JSON.parse(returnVal.pageInfo.content.metaTag);
        }
        return of(returnVal);
      }));
  }

  private getPageStateData(pageName: string, type: string, parentGroupId = '', groupId = '', locale = ''): Observable<ComponentState[]> {
    const data = { pageName, type: this.contentCrossWalk(type), locale: locale || this.locale, lineOfBusiness: this.region$.value, parentGroupId, groupId };
    return this.dataService
      .post<SuccessResponse<ComponentState[]>>(this.urlBuilder.buildPageStateUrl(pageName), data, {}, this.excludeWithCredentials)
      .pipe(map(
        response => !!response ? response.result : null
      ));
  }

  private getGlobalAlertData(type: string, parentGroupId = '', groupId = '', locale = ''): Observable<ComponentState> {
    const data = { type: this.contentCrossWalk(type), locale: locale || this.locale, lineOfBusiness: this.region$.value, parentGroupId, groupId };
    return this.dataService
      .post<SuccessResponse<ComponentState[]>>(this.urlBuilder.buildAlertUrl(type), data, {}, this.excludeWithCredentials)
      .pipe(map(
        response => !!response && !!response.result && response.result.length > 0 ? response.result[0] : null
      ));
  }

  private setCookie(cookieName: string, cookieValue: string, defaultCookieValue: string) {
    const sixMonthExpirationDate = addMonths(new Date(), this.sixMonthExpiration);

    if (cookieValue) {
      this.pbcCookieService.setExpiringCookie(cookieName, cookieValue, sixMonthExpirationDate);
    } else {
      cookieValue = this.pbcCookieService.get(cookieName);
      if (!cookieValue) {
        this.pbcCookieService.setExpiringCookie(cookieName, defaultCookieValue, sixMonthExpirationDate);
        cookieValue = defaultCookieValue;
      }
    }
    return cookieValue;
  }

  // This crosswalk need to be removed when we migrate these content server to single source 
  // This is a temp function to crosswalk between Premera and Lifewise content servers 
  private contentCrossWalk(type: string): string {
    return EnvironmentService.variables.site === this.lwwaRegion ? type.replace("member", "lwwa") : type;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
