import { BaseMemberRequest } from 'libs/models/member.models';

export class PaperlessPreferenceResponse {
  memberKey: string;
  optIn: { state: boolean };
  email: string;
  constructor() {
    this.optIn = { state: false };
  }
}

export enum PaperlessType {
  Form1095 = '1095',
  Eob = 'eob'
}

export class PaperlessPreferenceRequest extends BaseMemberRequest {
  targetMemberKey: string;
  constructor(requestorMemberKey: string, targetMemberKey: string) {
    super(requestorMemberKey);
    this.targetMemberKey = targetMemberKey;
  }
}

export class SetPaperlessPreferenceRequest extends BaseMemberRequest {
  targetMemberKey: string;
  optInStatus: boolean;
  email: string;
  consentSource: string;
  constructor(memberKey: string, targetMemberKey: string, optInStatus: boolean, email: string, contentSource: string = 'PremeraMemberWeb') {
    super(memberKey);
    this.targetMemberKey = targetMemberKey;
    this.optInStatus = optInStatus;
    this.email = email;
    this.consentSource = contentSource;
  }
}

export class PaperlessResult {
  memberKey: string;
  formId: string;
  taxYear: string;
  firstName: string;
  lastName: string;
}

export class PaperlessEligibilityRequest extends BaseMemberRequest {
  memberKey: string;
  targetMemberKey: string;

  constructor(requestorMemberKey: string, targetMemberKey: string) {
    super(requestorMemberKey);
    this.memberKey = requestorMemberKey;
    this.targetMemberKey = targetMemberKey;
  }
}
