import { Injectable, isDevMode } from '@angular/core';

export class EnvironmentVariables {
  site: string;
  name: string;
  appId: string;
  production = !isDevMode();
  mockDemoSSO: boolean;
  version: string;
  contentDomain: string;
  egainDomain: string;
  medicareSignInDomain: string;
  appInsightsKey: string;
  configServiceUrl: string;
  baseUrlVisitor: string;
  baseUrlMember: string;
  baseUrlEmployer: string;
  baseUrlProducer: string;
  baseUrlProvider: string;
  oHPAvailityUrl: string;
  oHPEvolentUrl: string;
  notification: {
    kinwellURL: string;
  }
  bingMap: {
    bingMapKey: string,
    bingMapLocationUrl: string,
    bingMapControlUrl: string
  }
  hmo: Boolean;
  dataLocation: {
    bffBaseUrl: string,
    contentBaseUrl: string,
    healthBffUrl: string,
    healthContentUrl: string,
    loginUrl: string,
    validateUrl: string,
    signOutUrl: string,
    ssoOutboundUrl: string,
    encryptedTokenUrl: string,
    claimsSearchUrl: string,
    claimsProvidersSearchUrl: string,
    claimIdSearchUrl: string,
    claimsExportUrl: string,
    detailsUrl: string,
    claimsSubmissionUrl: string,
    contactDetailsGetUrl: string;
    contactDetailsUpdateUrl: string;
    eobUrl: string,
    eobVisibilityUrl: string,
    memberRegistrationsUrl: string,
    memberRegistrationDetailsUrl: string,
    memberRegistrationDetailsUrlV2: string,
    memberDetails: string,
    familyMembersUrl: string,
    familyMembersEligibilityUrl: string,
    eligibleKeysUrl: string,
    memberNameAndDobUrl: string,
    memberVisibilityPermissions: string,
    memberVisibilityPermissionsHmo: string,
    evaluateAllRules: string,
    evaluateAnyRules: string,
    memberImpersonationRequestUrl: string,
    memberSsoRequestUrl: string,
    memberAccountEmailRequestUrl: string,
    memberGroup: string,
    memberPhysicalCardRequestUrl: string,
    memberTemporaryCardRequestUrl: string,
    memberTemporaryCardRequestHMOUrl: string,
    memberFallbackCardRequestUrl: string,
    memberSurrogateIdRequestUrl: string,
    memberSetSelectedPlanUrl: string,
    memberGetSelectedPlanUrl: string,
    memberExperianBenefitUrl: string,
    memberPaperlessEobPreferencesUrl: string,
    memberSetPaperlessEobPreferencesUrl: string,
    memberPaperless1095PreferencesUrl: string,
    memberSetPaperless1095PreferencesUrl: string,
    memberLoggingAddEntry: string,
    memberDataGetUrl: string,
    memberDataSetUrl: string,
    notificationGetUrl: string,
    notificationsUpdateUrl: string,
    benefitsAccumulatorsUrl: string,
    benefitsCategoriesUrl: string,
    benefitsServicesUrl: string,
    benefitBookletsUrl: string,
    benefitBookletUrl: string,
    benefitGroupBooklets: string,
    benefitIsCoInsuranceUrl: string,
    benefitIsVisionPartOfMedical: string,
    form1095ListUrl: string,
    form1095DetailsUrl: string,
    form1095EligibilityUrl: string,
    hraPlanYearsUrl: string,
    hraBalanceUrl: string,
    hraClaimsUrl: string,
    contentComponentUrl: string,
    componentStateUrl: string,
    pageStateUrl: string,
    alertUrl: string,
    privacyAuthorizationsForMemberUrl: string,
    privacyAuthorizationHistoryForMemberUrl: string,
    privacyAuthorizationsToMemberUrl: string,
    authorizationDateRestrictionsUrl: string,
    removePrivacyAuthorization: string,
    authSubmissionUrl: string,
    pcpSelectedProviderUrl: string,
    pcpProviderInfoUrl: string,
    pcpSpecialtiesUrl: string,
    pcpLocationHintsUrl: string,
    pcpNameHintsUrl: string,
    pcpSearchByNameUrl: string,
    pcpEMSSearchByNameUrl: string,
    pcpSearchBySpecialtyUrl: string,
    pcpSetUrl: string,
    virtualAssistantUrl: string,
    referralDetailsUrl: string,
    referralsSummaryUrl: string,
    pcpDetailUrl: string,
    memberSetDefaultPaperlessEobPreferencesUrl: string,
    preapprovalsListUrl: string,
    newpcpSpecialtiesUrl: string,
    newpcpLocationHintsUrl: string,
    newpcpSearchByNameUrl: string,
    newpcpSearchBySpecialtyUrl: string,
    newpcpSetUrl: string,
    pcpGetCurrentPcpUrl: any,
    pcpGetFamilyUrl: any,
    visionClaimSubmissionUrl: string,
    visionClaimCreateDraftUrl: string,
    visionClaimAttachmentUploadUrl: string,
    visionClaimAttachmentPDFUploadUrl : string,
    visionClaimUpdateDraftUrl: string,
    visionClaimAttachmentDeleteUrl: string,
    medicalPoliciesRecentUpdatesUrl: string,
  };
  sso: {
    findCareLaunch: string,
    expressScriptLaunch: string,
    teladocLaunch: string,
    pcpLaunch: string,
    rxAdvanceLaunch: string,
  };
  authentication: {
    clientId: string,
    callbackUrl: string,
    responseType: string,
    scope: string,
    state: string,
    nonce: string,
    responseMode: string,
    authSuffix: string,
    authorization: string,
    restart: string,
    defaultReturnUrl: string
  };
  addPlan: {
    realmSuffix: string,
    url: string,
  };
  recoveryEmail: {
    realmSuffix: string,
    url: string,
  };
  recoveryPhone: {
    realmSuffix: string,
    url: string
  };
  resetPassword: {
    realmSuffix: string,
    url: string
  };
  fileCompression: {
    compressionThreshold: number,
    compressionRatio: number
  };
  featureFlags: {
    accountPrivacy: boolean,
    accountSettings: boolean,
    benefits: boolean,
    benefitBooklets: boolean,
    benefitsDashWidget: boolean,
    billPay: boolean,
    chat: boolean,
    claimsEob: boolean,
    claimsList: boolean,
    claimsSubmission: boolean,
    claimsSubmitDental: boolean,
    claimsSubmitMedical: boolean,
    claimsSubmitPrescription: boolean,
    claimsSubmitVision: boolean,
    configService: boolean,
    claimsSubmitVisionPartOfMedical: boolean,
    editContactDetails: boolean,
    eobPromoFlag: boolean,
    experian: boolean,
    findADoctor: boolean,
    form1095Advanced: boolean,
    fundingAccounts: boolean,
    hra: boolean,
    identificationCard: boolean,
    identificationCardOrdering: boolean,
    login: boolean,
    multiReg: boolean,
    multiRegAddPlan: boolean,
    multiRegPlanSwitch: boolean,
    paperlessEob: boolean,
    paperless1095: boolean,
    primaryCareProvider: boolean,
    resetPasswordFeatureFlag: boolean,
    resetRecoveryEmailFeatureFlag: boolean,
    resetRecoveryPhoneFeatureFlag: boolean,
    rxCareAlerts: boolean,
    costEstimate: boolean,
    costEstimateRequest: boolean,
    secureChat: boolean,
    ssoOutboundSapphire: boolean,
    ssoOutboundEsi: boolean,
    ssoOutboundRxAdvance: boolean,
    ssoOutboundTeladocFlag: boolean,
    tMobileFlag: boolean,
    wellness: boolean,
    planSwitchingFlag: boolean,
    kinwellAdFlag: boolean,
    futureHmoBannerFlag: boolean,
    hmoWelcomePageFlag: boolean,
    newVisionClaimFlag: boolean,
    visionPCPSearchFlag : boolean
  };
}


@Injectable()
export class EnvironmentService {
  static variables: EnvironmentVariables;

  constructor() {
    if (!EnvironmentService.variables) {
      EnvironmentService.variables = new EnvironmentVariables();
    }
  }

  inMemberContext(): boolean {
    return document.location.origin.includes(EnvironmentService.variables.baseUrlMember);
  }

  inVisitorContext(): boolean {
    return document.location.origin.includes(EnvironmentService.variables.baseUrlVisitor);
  }
}