import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subscription, BehaviorSubject } from 'rxjs';

import { DataService } from './data.service';
import { LoggingService } from './logging.service';
import { EnvironmentService } from './environment-variables.service';

@Injectable({
  providedIn: 'root'
})
export class AzureConfigService implements OnDestroy {
  private configSubscription: Subscription;
  doneLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private readonly dataService: DataService, private readonly logger: LoggingService) {

  }

  ngOnDestroy() {
  }

  initialize(): Promise<void> {
    return new Promise<void>((resolver) => {
      if (!EnvironmentService.variables.featureFlags.configService) {
        this.doneLoading.next(true);
        return resolver();
      }
      this.configSubscription = this.dataService.post<AzureConfigSettings>(EnvironmentService.variables.configServiceUrl, { appId: EnvironmentService.variables.appId }, {}, false).pipe(map(response => response)).subscribe(response => {
        if (!!response) {

          this.setAllUrls(response);
          this.setAllFeatureFlags(response);

          if (!!response.appInsightsKey) {
            EnvironmentService.variables.appInsightsKey = response.appInsightsKey;
            this.logger.updateAppInsightsKey(response.appInsightsKey);
          }

          if (!!response.findCareLaunch) {
            EnvironmentService.variables.sso.findCareLaunch = response.findCareLaunch;
          }

          if (!!response.fileCompressionThreshold && !isNaN(parseInt(response.fileCompressionThreshold, 10))) {
            EnvironmentService.variables.fileCompression.compressionThreshold = parseInt(response.fileCompressionThreshold, 10);
          }

          if (!!response.fileCompressionRatio && !isNaN(parseFloat(response.fileCompressionRatio))) {
            EnvironmentService.variables.fileCompression.compressionRatio = parseFloat(response.fileCompressionRatio);
          }

        }
        if (!!this.configSubscription) {
          this.configSubscription.unsubscribe();
        }
        this.doneLoading.next(true);
        resolver();
      }, (error) => {
        this.logger.trackException(error, '/');
        this.doneLoading.next(true);
        resolver();
      });
    });
  }

  private setAllUrls(response: AzureConfigSettings) {

    if (!!response.visitorUrl) {
      EnvironmentService.variables.baseUrlVisitor = response.visitorUrl;
    }
    if (!!response.memberUrl) {
      EnvironmentService.variables.baseUrlMember = response.memberUrl;
    }

    if (!!response.secureAuthUrl) {
      EnvironmentService.variables.authentication.authorization = response.secureAuthUrl + EnvironmentService.variables.authentication.authSuffix;
      EnvironmentService.variables.recoveryEmail.url = response.secureAuthUrl + EnvironmentService.variables.recoveryEmail.realmSuffix;
      EnvironmentService.variables.recoveryPhone.url = response.secureAuthUrl + EnvironmentService.variables.recoveryPhone.realmSuffix;
      EnvironmentService.variables.resetPassword.url = response.secureAuthUrl + EnvironmentService.variables.resetPassword.realmSuffix;
      EnvironmentService.variables.addPlan.url = response.secureAuthUrl + EnvironmentService.variables.addPlan.realmSuffix;
    }

    if (!!response.authCallbackUrl) {
      EnvironmentService.variables.authentication.callbackUrl = response.authCallbackUrl;
    }
    if (!!response.bffServiceUrl) {
      EnvironmentService.variables.dataLocation.bffBaseUrl = response.bffServiceUrl;
    }
    if (!!response.contentDomainUrl) {
      EnvironmentService.variables.contentDomain = response.contentDomainUrl;
    }
    if (!!response.contentServiceUrl) {
      EnvironmentService.variables.dataLocation.contentBaseUrl = response.contentServiceUrl;
    }
  }

  private setAllFeatureFlags(response: AzureConfigSettings) {
    this.checkAndSetFlag('accountPrivacy', response.accountPrivacyFlag);
    this.checkAndSetFlag('accountSettings', response.accountSettingsFlag);
    this.checkAndSetFlag('benefits', response.benefitsFlag);
    this.checkAndSetFlag('benefitBooklets', response.benefitBookletsFlag);
    this.checkAndSetFlag('benefitsDashWidget', response.benefitsDashWidgetFlag);
    this.checkAndSetFlag('billPay', response.billPayFlag);
    this.checkAndSetFlag('chat', response.chatFlag);
    this.checkAndSetFlag('claimsEob', response.claimsEobFlag);
    this.checkAndSetFlag('claimsList', response.claimsListFlag);
    this.checkAndSetFlag('claimsSubmission', response.claimsSubmissionFlag);
    this.checkAndSetFlag('claimsSubmitDental', response.claimsSubmitDentalFlag);
    this.checkAndSetFlag('claimsSubmitMedical', response.claimsSubmitMedicalFlag);
    this.checkAndSetFlag('claimsSubmitPrescription', response.claimsSubmitPrescriptionFlag);
    this.checkAndSetFlag('claimsSubmitVision', response.claimsSubmitVisionFlag);
    this.checkAndSetFlag('claimsSubmitVisionPartOfMedical', response.claimsSubmitVisionPartOfMedicalFlag)
    this.checkAndSetFlag('editContactDetails', response.editContactDetailsFlag);
    this.checkAndSetFlag('eobPromoFlag', response.eobPromoFlag);
    this.checkAndSetFlag('experian', response.experianFlag);
    this.checkAndSetFlag('findADoctor', response.findADoctorFlag);
    this.checkAndSetFlag('form1095Advanced', response.form1095AdvancedFlag);
    this.checkAndSetFlag('fundingAccounts', response.fundingAccountsFlag);
    this.checkAndSetFlag('hra', response.hraFlag);
    this.checkAndSetFlag('identificationCard', response.identificationCardFlag);
    this.checkAndSetFlag('identificationCardOrdering', response.identificationCardOrderingFlag);
    this.checkAndSetFlag('login', response.loginFlag);
    this.checkAndSetFlag('medicareSupplementFlag', response.medicareSupplementFlag);
    this.checkAndSetFlag('multiReg', response.multiRegFlag);
    this.checkAndSetFlag('multiRegAddPlan', response.multiRegAddPlanFlag);
    this.checkAndSetFlag('multiRegPlanSwitch', response.multiRegPlanSwitchFlag);
    this.checkAndSetFlag('paperlessEob', response.paperlessEobFlag);
    this.checkAndSetFlag('paperless1095', response.paperless1095Flag);
    this.checkAndSetFlag('primaryCareProvider', response.primaryCareProviderFlag);
    this.checkAndSetFlag('resetPassword', response.resetPasswordFeatureFlag);
    this.checkAndSetFlag('resetRecoveryEmail', response.resetRecoveryEmailFeatureFlag);
    this.checkAndSetFlag('resetRecoveryPhone', response.resetRecoveryPhoneFeatureFlag);
    this.checkAndSetFlag('rxCareAlerts', response.rxCareAlertsFlag);
    this.checkAndSetFlag('costEstimate', response.costEstimateFlag);
    this.checkAndSetFlag('costEstimateRequest', response.costEstimateRequestFlag);
    this.checkAndSetFlag('ssoOutboundCyc', response.ssoOutboundCycFlag);
    this.checkAndSetFlag('ssoOutboundEsi', response.ssoOutboundEsiFlag);
    this.checkAndSetFlag('ssoOutboundRxAdvance', response.ssoOutboundRxAdvanceFlag);
    this.checkAndSetFlag('ssoOutboundSapphire', response.ssoOutboundSapphireFlag);
    this.checkAndSetFlag('ssoOutboundSecureInbox', response.ssoOutboundSecureInboxFlag);
    this.checkAndSetFlag('ssoOutboundTeladocFlag', response.ssoOutboundTeladocFlag);
    this.checkAndSetFlag('tMobileFlag', response.tMobileFlag);
    this.checkAndSetFlag('wellness', response.wellnessFlag);
    this.checkAndSetFlag('defaultEobEligibility', response.defaultEobEligibility);   
    this.checkAndSetFlag('futureEligibilityFlag', response.futureEligibilityFlag);    
    this.checkAndSetFlag('pcpWelcomeFlag', response.pcpWelcomeFlag);
    this.checkAndSetFlag('planSwitchingFlag', response.planSwitchingFlag);
    this.checkAndSetFlag('notificationFEFlag', response.notificationFEFlag);
    this.checkAndSetFlag('kinwellAdFlag', response.kinwellAdFlag);
    this.checkAndSetFlag('futureHmoBannerFlag', response.futureHmoBannerFlag);
    this.checkAndSetFlag('hmoWelcomePageFlag', response.hmoWelcomePageFlag);
    this.checkAndSetFlag('newVisionClaimFlag', response.newVisionClaimFlag); 
    this.checkAndSetFlag('amazonBookletFlag', response.amazonBookletFlag);
    this.checkAndSetFlag('visionPCPSearchFlag', response.visionPCPSearchFlag); 
  }

  private checkAndSetFlag(featureFlagKey: string, responseFlag: string) {
    if (!!responseFlag && EnvironmentService.variables.featureFlags.hasOwnProperty(featureFlagKey)) {
      EnvironmentService.variables.featureFlags[featureFlagKey] = responseFlag.toLowerCase() === 'true';
    }
  }
}

export class AzureConfigSettings {
  appInsightsKey: string;
  authCallbackUrl: string;
  bffServiceUrl: string;
  contentServiceUrl: string;
  contentDomainUrl: string;
  visitorUrl: string;
  memberUrl: string;
  secureAuthUrl: string;
  findCareLaunch: string;
  fileCompressionThreshold: string;
  fileCompressionRatio: string;
  // feature flags
  accountPrivacyFlag: string;
  accountSettingsFlag: string;
  benefitsFlag: string;
  benefitBookletsFlag: string;
  benefitGroupBooklets: string;
  benefitsDashWidgetFlag: string;
  billPayFlag: string;
  chatFlag: string;
  claimsEobFlag: string;
  claimsListFlag: string;
  claimsSubmissionFlag: string;
  claimsSubmitDentalFlag: string;
  claimsSubmitMedicalFlag: string;
  claimsSubmitPrescriptionFlag: string;
  claimsSubmitVisionFlag: string;
  editContactDetailsFlag: string;
  claimsSubmitVisionPartOfMedicalFlag: string;
  eobPromoFlag: string;
  experianFlag: string;
  findADoctorFlag: string;
  form1095AdvancedFlag: string;
  fundingAccountsFlag: string;
  hraFlag: string;
  identificationCardFlag: string;
  identificationCardOrderingFlag: string;
  loginFlag: string;
  medicareSupplementFlag: string;
  multiRegFlag: string;
  multiRegAddPlanFlag: string;
  multiRegPlanSwitchFlag: string;
  paperlessEobFlag: string;
  paperless1095Flag: string;
  primaryCareProviderFlag: string;
  resetPasswordFeatureFlag: string;
  resetRecoveryEmailFeatureFlag: string;
  resetRecoveryPhoneFeatureFlag: string;
  rxCareAlertsFlag: string;
  costEstimateFlag: string;
  costEstimateRequestFlag: string;
  secureInboxFlag: string;
  ssoOutboundCycFlag: string;
  ssoOutboundEsiFlag: string;
  ssoOutboundRxAdvanceFlag: string;
  ssoOutboundSapphireFlag: string;
  ssoOutboundSecureInboxFlag: string;
  ssoOutboundTeladocFlag: string;
  tMobileFlag: string;
  wellnessFlag: string; 
  defaultEobEligibility: string; 
  futureEligibilityFlag: string;  
  pcpWelcomeFlag: string;  
  planSwitchingFlag :string;
  notificationFEFlag : string;
  kinwellAdFlag : string;
  futureHmoBannerFlag: string;
  hmoWelcomePageFlag : string;
  newVisionClaimFlag : string;
  visionClaimPartTwoEnhancement: string;
  amazonBookletFlag : string;
  newVisionServiceFlag : string;
  visionPCPSearchFlag : string;
  }
