import { Component, OnInit, Input, OnDestroy, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

import { ComponentState, CtaModel, DataComponentState } from 'libs/models/pbc-models';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { ContentService } from 'libs/services/content.service';
import { HelperService } from 'libs/services/helper.service';

export class SalesRep {
  zipCode: string;
  name: string;
  city: string;
}

export class RawParsedSalesRep {
  salespersons: {
    salesperson: {
      zipcode: string;
      salesperson: string;
      city: string;
    }[]
  };
}

export class SalesRepDetail {
  name: string;
  bio: string;
  photo: string;
}

export class RawParsedSalesRepDetail {
  salespersondetails: {
    salespersondetail: {
      name: string;
      bio: string;
      photo: string;
    }[]
  };
}

@Component({
  selector: 'pbc-find-medicare-rep',
  templateUrl: './find-medicare-rep.component.html'
})

@Injectable()
export class FindMedicareRepComponent implements OnInit, OnDestroy {
  @Input() data: ComponentState;
  marketoData: ComponentState = new ComponentState(true);
  componentDestroyed$: Subject<boolean> = new Subject();
  hasError = false;
  salesReps: SalesRep[];
  salesRepDetails: SalesRepDetail[];
  selectedSalesRep: SalesRep = new SalesRep();
  selectedSalesRepCity: string;
  selectedSalesRepZip: string;
  selectedSalesRepDetails: SalesRepDetail = new SalesRepDetail();
  ctaOne: CtaModel;
  ctaTwo: CtaModel;
  instanceName = 'medicare-representatives-instance';
  contentGroup = 'medicare-representatives';
  medicareRepsContent: DataComponentState;
  DEFAULT_MEDICARE_REP_NAME = 'Medicare Representative';

  constructor(
    private readonly helper: HelperService,
    private readonly contentService: ContentService,
    private readonly router: Router,
    private readonly logger: LoggingService) { }

  ngOnInit(): void {
    if (!!this.data && !!this.data.content && !!this.data.content.dataFilePath) {
      this.contentService.getContentFile(this.data.content.dataFilePath, EnvironmentService.variables.contentDomain)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.salesReps = this.parseXml(result);
        });

      this.contentService.getContentFile(this.data.content.detailDataFilePath, EnvironmentService.variables.contentDomain)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.salesRepDetails = this.parseXmlDetails(result);
        });

      this.marketoData.content = { formId: this.data.content.contactMeFormId, formTitle: this.data.content.contactMeText, redirectUrl: this.data.content.contactMeRedirectUrl };

      this.ctaTwo = new CtaModel(this.data.content.ctaTwoUrl, this.data.content.ctaTwoText, this.data.content.ctaTwoStyle,
        this.data.content.ctaTwoExternal, this.data.content.ctaTwoAnalyticsTracker, null, this.data.content.ctaTwoPreventDefault);
    }

    this.contentService.getComponentState(this.instanceName, this.contentGroup)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        this.medicareRepsContent = response as DataComponentState;
      }, err => {
        this.logger.trackException(err, this.router.url, SeverityLevel.Error);
        return of(null);
      });
  }

  search(zipCode: string) {
    const result = this.salesReps.filter(s => s.zipCode === zipCode);
    this.selectedSalesRep = this.getSelectedSalesRep(result);
    this.selectedSalesRepCity = !!this.selectedSalesRep && !!this.selectedSalesRep.city ? `${this.selectedSalesRep.city}, WA` : '';
    this.selectedSalesRepDetails = this.getSelectedSalesRepDetail(this.selectedSalesRep.name);
  }

  getSelectedSalesRep(salesReps: SalesRep[]): SalesRep {
    if (!salesReps || salesReps.length === 0) {
      const defaultMedicareRep = new SalesRep();
      defaultMedicareRep.name = this.DEFAULT_MEDICARE_REP_NAME;
      return defaultMedicareRep;
    }
    const salesRep = salesReps.find(x => x.name !== this.DEFAULT_MEDICARE_REP_NAME);
    if (!!salesRep) {
      return salesRep;
    }
    return salesReps[0];
  }

  getSelectedSalesRepDetail(name: string): SalesRepDetail {
    return this.salesRepDetails.find(d => d.name.toLowerCase() === name.toLowerCase());
  }

  parseXml(xml: string): SalesRep[] {
    const result = this.helper.parseXml(xml);
    if (!result || !result.salespersons) {
      this.handleError(new Error('Empty or invalid xml'));
      return;
    }
    return this.processRawObject(result);
  }

  parseXmlDetails(xml: string): SalesRepDetail[] {
    const result = this.helper.parseXml(xml);
    if (!result || !result.salespersondetails) {
      this.handleError(new Error('Empty or invalid xml'));
      return;
    }
    return this.processRawObject1(result);
  }

  private processRawObject(rawParsedContent: RawParsedSalesRep): SalesRep[] {
    return rawParsedContent.salespersons.salesperson.map(s => {
      return {
        zipCode: s.zipcode,
        name: s.salesperson,
        city: s.city,
      };
    });
  }

  private processRawObject1(rawParsedContent: RawParsedSalesRepDetail): SalesRepDetail[] {
    return rawParsedContent.salespersondetails.salespersondetail.map(s => {
      return {
        name: s.name,
        bio: s.bio,
        photo: s.photo,
      };
    });
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
