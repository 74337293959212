<div class="row">
  <div class="col-lg-8 mx-auto">
    <h1 tabindex="-1" [innerHtml]="pageTitle"></h1>
    <pbc-alert [data]="localAlert" [alert]="popUpAlert"></pbc-alert>
  </div>
</div>
<div *ngIf="!hasError" class="row">
  <div class="col-lg-8 mx-auto">
    <div class="row mb-3">
      <div class="col-12 col-sm-6">
        <div class="form-group">
          <label for="groupNumber" class="visually-hidden">{{groupBookletsContent?.content?.filterInputLabel}}</label>
          <input class="form-control" id="groupNumber" name="groupNumber" [(ngModel)]="groupNumber"
            [ngClass]="groupNumber ? 'hasLength' : 'hasNoLength'">
          <span class="zoom-label">{{groupBookletsContent?.content?.filterInputLabel}}</span>
        </div>
      </div>
      <div class="col-12 col-sm-6 my-auto">
        <button class="btn btn-primary" (click)="filter()">{{groupBookletsContent?.content?.filterBtnLabel}}</button>
        <button class="btn btn-secondary" (click)="reset()">{{groupBookletsContent?.content?.resetBtnLabel}}</button>
      </div>
    </div>
    <div *ngIf="groupsLoading" class="text-center pt-5 pb-5">
      <pbc-loader [alwaysShow]="true"></pbc-loader>
    </div>
    <table *ngIf="!groupsLoading" class="table table-striped text-left">
      <caption class="sr-only">{{groupBookletsContent?.content?.tableCaption}}</caption>
      <thead>
        <tr class="row d-flex" role="row">
          <th scope="col" class="col pl-3" role="columnheader" [innerHtml]="groupBookletsContent?.content?.groupNumColLabel"
            [attr.aria-label]="groupBookletsContent?.content?.groupNumColLabel"></th>
          <th scope="col" class="col-5 text-left" role="columnheader" [innerHtml]="groupBookletsContent?.content?.descriptionColLabel"
            [attr.aria-label]="groupBookletsContent?.content?.descriptionColLabel"></th>
          <th scope="col" class="col text-left" role="columnheader" [innerHtml]="groupBookletsContent?.content?.effectiveColLabel"
            [attr.aria-label]="groupBookletsContent?.content?.effectiveColLabel"></th>
          <th scope="col" class="col text-left" role="columnheader" [innerHtml]="groupBookletsContent?.content?.scbColLabel"
            [attr.aria-label]="groupBookletsContent?.content?.scbColLabel"></th>
          <th scope="col" class="col text-left" role="columnheader" [innerHtml]="groupBookletsContent?.content?.bookletColLabel"
            [attr.aria-label]="groupBookletsContent?.content?.bookletColLabel"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let group of groupsToDisplay" class="row d-flex">
          <td class="col pl-3 font-weight-normal">{{group.groupnumber}}</td>
          <td class="col-5 text-left font-weight-normal">{{group.plandescription}}</td>
          <td class="col text-left font-weight-normal">{{group.effectivedate | date:'M/d/yyyy'}}</td>
          <td class="col text-left font-weight-normal">
            <a *ngIf="group.sbcdocument" class="pdf" [href]="getPdfUrl(group.sbcdocument)" target="_blank" rel="noopener noreferrer">
              {{groupBookletsContent?.content?.sbcPdfLabel}}
            </a>
          </td>
          <td class="col text-left font-weight-normal">
            <a *ngIf="group.bookletdocument" class="pdf" [href]="getPdfUrl(group.bookletdocument)" target="_blank" rel="noopener noreferrer">
              {{groupBookletsContent?.content?.bookletPdfLabel}}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-right mb-5">
      <button *ngIf="pageNumber > 0" pbcAnalyticsTag type="button" id="previousBtn" (click)="getPage(pageNumber - 1)" class="btn btn-link-text mr-3">{{constants.data.value.PREVIOUS}}</button>
      <button *ngIf="showNextBtn()" pbcAnalyticsTag type="button" id="nextBtn" (click)="getPage(pageNumber + 1)" class="btn btn-link-text mr-3">{{constants.data.value.NEXT}}</button>
    </div>
  </div>
</div>
<div aria-live="assertive" *ngIf="hasError">
  <pbc-error-data-block id="errorBlock" [showSessionId]="true"></pbc-error-data-block>
</div>