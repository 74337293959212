import { Component, OnInit, Input } from '@angular/core';
import { ComponentState } from 'libs/models/pbc-models';
import { ContentComponent } from '../content-component';

@Component({
  selector: 'lw-overlay-image-secondary',
  templateUrl: './overlay-image-secondary.component.html'
})
export class OverlayImageSecondaryComponent implements ContentComponent {
  @Input() svgColor;
  @Input() svgWidth;
  @Input() svgOpacity;

  @Input() data: ComponentState;

}