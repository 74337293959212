import { Component, Input, OnChanges } from '@angular/core';

import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-pull-quote',
  templateUrl: './pull-quote.component.html'
})

export class PullQuoteComponent implements ContentComponent, OnChanges {
  @Input() data: ComponentState;
  quotes: any[];

  ngOnChanges(): void {
    if (!this.quotes && !!this.data && !!this.data.content && !!this.data.content.quotes) {
      this.quotes = JSON.parse(this.data.content.quotes);
    }
  }

}
