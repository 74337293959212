import { basemockenvironment } from 'libs/environments/environment-mockbase';

const hostnames = {
  secureAuth: 'https://premeratest.saas.secureauth.com',
  bffApi: 'mocks/',
  contentApi: 'https://app-content-service-digweb-prod-westus2-01.ase-ecp-prod-aseilbv3-02.appserviceenvironment.net/api/',
  visitor: 'https://app-premera-visitor-demo-digweb-prod-westus2-01.ase-ecp-prod-aseilbv3-02.appserviceenvironment.net',
  member: 'https://app-premera-member-demo-digweb-prod-westus2-01.ase-ecp-prod-aseilbv3-02.appserviceenvironment.net',
  employer: 'https://www.premera.com/{region}/employer/',
  producer: 'https://www.premera.com/{region}/producer/',
  provider: 'https://www.premera.com/{region}/provider/'
};

export let environment = basemockenvironment;

// Overwrites for this environment
environment.site = 'visitor';
environment.name = 'demo';
environment.appId = 'member-partial-mock';
environment.production = true;
environment.version = 'PLZ-Premera-Visitor-CI-MASTER-PROD-DEMO_2024.9.18.1';
environment.contentDomain = '//www.premera.com';
environment.egainDomain = 'premeradev';
environment.appInsightsKey = '';
environment.configServiceUrl = '';
environment.baseUrlVisitor = `${hostnames.visitor}`;
environment.baseUrlMember = `${hostnames.member}`;
environment.baseUrlEmployer = `${hostnames.employer}`;
environment.baseUrlProducer = `${hostnames.producer}`;
environment.baseUrlProvider = `${hostnames.provider}`;

environment.dataLocation.bffBaseUrl = `${hostnames.bffApi}`;
environment.dataLocation.contentBaseUrl = `${hostnames.contentApi}`;
environment.dataLocation.healthContentUrl = 'v1.0/environment/information';
environment.dataLocation.contentComponentUrl = 'v1.0/content/';
environment.dataLocation.componentStateUrl = 'v1.0/content/componentstate';
environment.dataLocation.pageStateUrl = 'v1.0/content/pagestate';
environment.dataLocation.alertUrl = 'v1.0/content/alerts';
environment.dataLocation.authSubmissionUrl = 'v1.0/memberauth/addmemberauth';

environment.authentication.clientId = 'b6370759b0a24b9f95f8b87b949c44bd';
environment.authentication.registerUrl = `${hostnames.secureAuth}/SecureAuth30/Register.aspx`;
environment.authentication.authorization = '/callback#access_token=mocktoken';
environment.authentication.restart = `${hostnames.secureAuth}/SecureAuth30/Restart.aspx?ReturnStr=${hostnames.visitor}`;

environment.recoveryEmail.url = `${hostnames.secureAuth}/SecureAuth40/Authorized/accountupdate.aspx`;
environment.recoveryPhone.url = `${hostnames.secureAuth}/SecureAuth41/Authorized/accountupdate.aspx`;
environment.resetPassword.url = `${hostnames.secureAuth}/SecureAuth42/SecureAuth.aspx`;
environment.addPlan.url = `${hostnames.secureAuth}/SecureAuth30/Customized/AddAPlan.aspx`;

// if a feature flag is different for this environment, put it here like this:
environment.featureFlags.chat = false;
environment.featureFlags.fundingAccounts = false;

