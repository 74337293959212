<div *ngIf="alert?.isVisible" class="alert alert-{{alert?.type}} d-print-none" role="alert"
  [attr.aria-live]="alert?.type ==='danger' ? 'assertive' : 'polite'" [attr.aria-label]="friendlyAlertAnnouncement">
  <div [class]="isFutureEligiblity ? 'container welcome-to-your-hmoplan':'container d-flex'">
    <div *ngIf="!isFutureEligiblity" class="align-self-stretch"><i class="material-icons pr-1" aria-hidden="true">{{
        icon }}</i></div>
    <div [innerHtml]="alert?.message"></div>
    <div class="ml-auto close" aria-hidden="true"><a pbcAnalyticsTag role="button" *ngIf="alert?.isDismissible"
        (click)="closeAlert()" aria-label="Close">
        <i aria-hidden="true" class="material-icons pr-1">clear</i></a>
    </div>
  </div>
</div>