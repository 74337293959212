<div class="col-lg-4 ml-n2">
  <pbc-select id="state" [options]="states" [optionLabelFunction]="stateCallback" [emptyDefaultValue]="false"
    [ngModel]="states" [label]="data?.content?.selectState" (ngModelChange)="onStateChange($event)">
  </pbc-select>
  <pbc-select *ngIf="!!counties" id="county" [options]="counties" [optionLabelFunction]="countyCallback"
    [emptyDefaultValue]="false" [ngModel]="counties" [label]="data?.content?.selectCounty"
    (ngModelChange)="onCountyChange($event)">
  </pbc-select>
  <div class="mb-3">
    <a *ngIf="!!selectedCi" [class]="primary | buttonType" [href]="'https://premera.sapphirecareselect.com/?ci=' + selectedCi" 
    [innerHtml]="data?.content?.findButtonText" data-analytics-override-route="header" pbcAnalyticsTag target="_blank" rel="noopener noreferrer"></a>
  </div>
</div>
