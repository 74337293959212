<div>
  <div [innerHtml]="data?.content?.introSection"></div>
  <div class="row">
    <div class="col-sm-5 col-12">
      <div class="form-group select">
        <label for="drugSelection" class="visually-hidden">{{data?.content?.drugSearchInputLabel}}</label>
        <select pbcAnalyticsTag class="form-control" id="drugSelection" [(ngModel)]="selectedDrug"
          [ngClass]="selectedDrug ? 'hasLength': 'hasNoLength'">
          <option *ngFor="let drug of dropdownOptions" [ngValue]="drug">{{drug.drugName}}</option>
        </select>
        <span class="zoom-label">{{data?.content?.drugSearchInputLabel}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="selectedDrug" class="mb-3">
    <div>{{selectedDrug.drugName}}</div>
    <div *ngIf="selectedDrug.clinicalCriteria"><strong>{{data.content?.clinicalCriteriaLabel}}:</strong>
      {{selectedDrug.clinicalCriteria}}</div>
    <div *ngIf="selectedDrug.quantityLimit"><strong>{{data.content?.quantityLimitLabel}}:</strong>
      {{selectedDrug.quantityLimit}}</div>
    <div *ngIf="selectedDrug.quantityPerFill"><strong>{{data?.content?.quantityPerPillLabel}}:</strong>
      {{selectedDrug.quantityPerFillLabel}}</div>
    <div><a target="_blank" rel="noopener noreferrer" [href]="getFormLink()">{{data?.content?.formLabel}}</a></div>
    <div *ngIf="selectedDrug.medicalPolicy1 && data?.content.medicalPolicyLink"><a target="_blank" rel="noopener noreferrer"
        href="{{data.content.medicalPolicyLink + selectedDrug.medicalPolicy1}}">{{data?.content?.medicalPolicyLabel}}</a>
    </div>
    <div *ngIf="selectedDrug.medicalPolicy2 && data?.content.medicalPolicyLink"><a target="_blank" rel="noopener noreferrer"
        href="{{data.content.medicalPolicyLink + selectedDrug.medicalPolicy2}}">{{data?.content?.additionalMedicalPolicyLabel}}</a>
    </div>
    <br>
    <div><a target="_blank" rel="noopener noreferrer" [href]="getHMOFormLink()">{{data?.content?.hmoFormLabel}}</a>
    </div>
    <div *ngIf="selectedDrug.medicalPolicy1 && data?.content.medicalPolicyLinkHMO"><a target="_blank" rel="noopener noreferrer"
        href="{{data.content.medicalPolicyLinkHMO + selectedDrug.medicalPolicy1}}">{{data?.content?.hmoMedicalPolicyLabel}}</a>
    </div>
    <div *ngIf="selectedDrug.medicalPolicy2 && data?.content.medicalPolicyLinkHMO"><a target="_blank" rel="noopener noreferrer"
        href="{{data.content.medicalPolicyLinkHMO + selectedDrug.medicalPolicy2}}">{{data?.content?.hmoAdditionalMedicalPolicyLabel}}</a>
    </div>
  </div>
  <p *ngIf="data?.content?.footnote" [innerHtml]="data?.content?.footnote"></p>
</div>