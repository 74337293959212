import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-accordion',
  templateUrl: './accordion.component.html',
  encapsulation: ViewEncapsulation.None
})

export class AccordionComponent {
  @Input() data: ComponentState;
  sections: any;

  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    if (!this.sections) {
      this.sections = JSON.parse(this.data.content.sections);
    }
    return this.sections;
  }

  shouldOpenFirstOnLoad(): boolean {
    if (this.data && this.data.content && this.data.content.openOnLoad) {
      return this.data.content.openOnLoad.toLowerCase() === 'true';
    }
    return false;
  }
}
