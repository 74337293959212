
<div class="mt-3 mt-sm-0">
  <pbc-footer-nav-horiz *ngIf="horizontalLinks" [menus]="menus"></pbc-footer-nav-horiz>
</div>

<h2 class="footer-heading">{{footerContent?.content?.legalLanguageSupport}}</h2>
<p id="language-support">
  <a pbcAnalyticsTag data-analytics-override-route="footer" [attr.data-analytics-title]="lang.name"
    *ngFor="let lang of languages" lang="{{ lang.languageCode }}"
    href="{{ footerContent?.content?.pdfUrl + '#' + lang.name}}" target="_blank" rel="noopener noreferrer"
    title="{{ lang.name }}">{{ lang.nativeName }}</a>
</p>

<p id="legal" class="legal" [innerHtml]="footerContent?.content?.legalDisclaimer"></p>
<p id="copyright" class="copyright" [innerHtml]="copyrightText"></p>

<div class="pt-4 small" [hidden]="environment.production">
  <div id="uiBuild">UI</div>
  <div id="uiVersion">Version: {{ environment.version }}</div>
  <div id="uiEnv">Environment: {{ environment.name }}</div>
  <div id="uiSessionId" class="mb-2">Session ID: {{ getSessionId() }}</div>
  <div id="bffBuild">BFF</div>
  <div id="bffVersion">Version: {{ bffHealth?.buildNumber }}</div>
  <div id="bffEnvironment">Environment: {{ bffHealth?.activeEnvironment }}</div>
  <div id="bffPspUrl">PSP URL: {{bffHealth?.pspBaseUrl }}</div>
  <div id="bffContentTable">Content Table: {{ contentHealth?.contentTableName }}</div>
  <div id="bffContentUpdate">Content Last Updated: {{ contentHealth?.contentLastUpdated }}</div>
</div>
