<div *ngIf="data?.isVisible" data-id="card-row">
  <div *ngIf="data?.content.introduction" [innerHTML]="data?.content.introduction"></div>

  <!-- (3) Standard (top image optional), Secondary (accent background, no shadow) -->
  <div *ngIf="cardIsStandard(data?.content.cardStyle)" class="row card-row">
    <ng-container *ngFor="let section of getSections(); last as isLast">
      <div class="col-12 col-sm pb-sm-0" [ngClass]="{'pb-3': !isLast}">
        <div class="card" [ngClass]="{'card-secondary border-0': isSecondary(data?.content.cardStyle),'card-lwwa':isLwwa}">
          <div class="pt-2" *ngIf="data?.content?.useFontIcon && section?.iconStyle">
            <i [class]="section?.iconStyle"
              aria-hidden="true"></i>
          </div>
          <img *ngIf="!data?.content?.useFontIcon && section?.cardImagePath" alt="" [src]="section?.cardImagePath | urlQualify" class="card-img-top img-fluid" aria-hidden="true">
          <ng-container
            *ngIf="!data?.content?.useFontIcon && !section?.cardImagePath || data?.content?.useFontIcon && !section?.iconStyle">
            <!-- Please check smartform inputs.  There is a setting that is preventing the icon from showing -->
          </ng-container>
          <div class="card-body" [ngClass]="{'background-accent':  isSecondary(data?.content.cardStyle)}">
            <div
              [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml">
            </div>
            <div [ngClass]="{'card-text-lwwa mt-3':isLwwa}" class="card-text" [innerHTML]="section?.cardBody  | decodeHtml"></div>
          </div>
          <div *ngIf="section?.cardFooter" class="card-footer"
            [ngClass]="cardStandardFooterStyle(data?.content.cardStyle, section?.cardFooterBkg)">
            <div [innerHTML]="section?.cardFooter | decodeHtml"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Horizontal card with image left or right -->
  <div class="row card-row" *ngIf="cardIsHorizontal(data?.content.cardStyle)">
    <ng-container *ngFor="let section of getSections(); last as isLast">
      <div class="col-sm-12 col-md pb-md-0" [ngClass]="{'pb-3': !isLast}">
        <div class="card">
          <div class="row no-gutters h-100">
            <div class="col-3 col-sm-4" *ngIf="!isRightAligned(data?.content.cardStyle) && section?.cardImagePath">
              <div class="image-background-centered h-100"
                [style.background-image]="'url('+ (section?.cardImagePath | urlQualify) +')'"></div>
            </div>
            <div class="col p-3">
              <div
                [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml">
              </div>
              <div class="card-text" [innerHTML]="section?.cardBody | decodeHtml">{{section?.cardBody}}</div>
            </div>
            <div class="col-3 col-sm-4" *ngIf="isRightAligned(data?.content.cardStyle) && section?.cardImagePath">
              <div class="image-background-centered h-100"
                [style.background-image]="'url('+ (section?.cardImagePath | urlQualify) +')'"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Icon card with accent background -->
  <div class="row card-row" *ngIf="data?.content.cardStyle === 'icon-background'">
    <ng-container *ngFor="let section of getSections(); last as isLast">
      <div class="col-12 col-sm pb-sm-0" [ngClass]="{'pb-3': !isLast}">
        <div class="row h-100 no-gutters shadow">
          <div class="col-3 background-accent d-flex justify-content-center align-items-center">
            <img class="absolutely-centered" alt="" [src]="section?.cardImagePath | urlQualify" aria-hidden="true"
              *ngIf="!data?.content?.useFontIcon && section?.cardImagePath">
            <i *ngIf="data?.content?.useFontIcon && section?.iconStyle"
              class="d-flex justify-content-center align-items-center" [ngClass]="section?.iconStyle"
              aria-hidden="true"></i>
            <ng-container
              *ngIf="!data?.content?.useFontIcon && !section?.cardImagePath || data?.content?.useFontIcon && !section?.iconStyle">
              <!-- Please check smartform inputs.  There is a setting that is preventing the icon from showing -->
            </ng-container>
          </div>
          <div class="col">
            <div class="card card-secondary border-0 background-white">
              <div class="card-body">
                <div
                  [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml">
                </div>
                <div class="card-text" [innerHTML]="section?.cardBody  | decodeHtml"></div>
              </div>
              <div *ngIf="section?.cardFooter" class="card-footer border-0 background-white">
                <div [innerHTML]="section?.cardFooter | decodeHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Icon card without accent background -->
  <div class="row card-row" *ngIf="data?.content.cardStyle === 'icon'">
    <ng-container *ngFor="let section of getSections(); index as index; last as isLast">
      <div class="col-12 col-sm pb-sm-0" [ngClass]="{'pb-3': !isLast}">
        <div class="card">
          <div class="card-body">
            <div
              [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml">
            </div>
            <div class="row no-gutters align-items-center">
              <div class="col-auto p-3">
                <img alt="" [src]="section?.cardImagePath | urlQualify" aria-hidden="true"
                  *ngIf="!data?.content?.useFontIcon && section?.cardImagePath">
                <i *ngIf="data?.content?.useFontIcon && section?.iconStyle" [ngClass]="section?.iconStyle"
                  aria-hidden="true"></i>
                <ng-container
                  *ngIf="!data?.content?.useFontIcon && !section?.cardImagePath || data?.content?.useFontIcon && !section?.iconStyle">
                  <!-- Please check smartform inputs.  There is a setting that is preventing the icon from showing -->
                </ng-container>
              </div>
              <div class="col px-3 pt-3">
                <div [innerHTML]="section?.cardBody | decodeHtml"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Form card -->
  <div class="row card-row" *ngIf="data?.content.cardStyle === 'form'">
    <ng-container *ngFor="let section of getSections(); index as index; last as isLast">
      <div class="col-12 col-sm pb-sm-0" [ngClass]="{'pb-3': !isLast}">
        <div class="card">
          <div class="card-body">
            <div class="row no-gutters mt-3">
              <div class="col-1">
                <a [href]="section?.docPath" class="no-icon"><i class="far fa-2x {{section?.docType}}"
                    aria-hidden="true"></i></a>
              </div>
              <div class="col pl-2">
                <div
                  [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml">
                </div>
                <div [innerHTML]="section?.cardBody | decodeHtml"></div>
              </div>
            </div>
          </div>
          <div class="card-footer border-0 background-white">
            <div class="row no-gutters mt-3">
              <div class="col-11 offset-1 pb-2 pl-2">
                <a [href]="section?.docPath" class="mr-3 mr-lg-5 no-icon" target="_blank" rel="noopener">
                  <span *ngIf="section?.docLabel" class="material-icons">get_app</span>{{section?.docLabel}} <span
                    class="sr-only">{{ constants?.data?.value?.OPENS_NEW_WINDOW}}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="data?.content.AdditionalContent" [innerHTML]="data?.content.AdditionalContent"></div>

</div>