<div class="mt-1 mb-1">
  <div class="mt-2 mb-2 font-weight-bolder" *ngIf="isHmoUser">{{providerName}}</div>
  <div #catchheight class="card icon-card {{borderStyle}}" [ngClass]="{'loading': isLoading }">
    <div class="card-body">
      <div id="cardAlert" *ngIf="error?.isVisible">
        <pbc-alert [alert]="error" [level]="'Component'"></pbc-alert>
      </div>
      <div *ngIf="!error?.isVisible">
        <ng-content select="[card-select]"></ng-content>
        <h3 class="h4" *ngIf="headerOverride || data?.content?.cardHeader">{{headerOverride || data?.content?.cardHeader}}</h3>
        <div class="notification">
          <ng-content select="[card-notification]"></ng-content>
        </div>
        <div class="media">
          <div *ngIf="data?.content?.imageUrl && !hideImg">
            <img src="{{data?.content?.imageUrl}}" alt="{{data?.content?.imageUrlAltText}}" aria-hidden="true">
          </div>
          <div class="circles-div">
            <ng-content select="[card-icon]"></ng-content>
          </div>
          <div class="row">
            <div [class]="isHmoUser ? 'media-body row float-right ml-2 cls-mobile' : 'media-body' ">
              <ng-content select="[card-content]"></ng-content>
              <div [class]="isHmoUser? 'bottom-style': ''">
                <div *ngIf="ctaOne && !hideCta" [class]="isHmoUser ? (isFromPcpCardComponent ? pcpStyle : styleAdjust) : 'mt-2'">
                  <pbc-cta [model]="ctaOne" [notificationRedirectPlan] ="notificationRedirectPlan"></pbc-cta>
                </div>
                <div *ngIf="ctaTwo && !hideDetailCta && isFromPcpCardComponent && isHmoUser" class="detailsCta-style">
                  <pbc-cta [model]="ctaTwo" [notificationRedirectPlan]="notificationRedirectPlan"></pbc-cta>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>