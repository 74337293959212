import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentState, KeyValue, DataComponentState } from 'libs/models/pbc-models';
import { ContentService } from 'libs/services/content.service';
import { Impersonation, MemberDetails } from 'libs/models/member.models';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { StringsService } from 'libs/services/strings.service';
import { AuthenticationService } from 'libs/services/authentication.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { FooterContentHelperService } from './footer-content-helper.service';
import { ChildSite, Site } from 'libs/models/site-config';

@Component({
  selector: 'pbc-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit, OnDestroy {
  static footerFallbackContent: ComponentState;
  @Input() hideMobileApps: boolean;
  @Input() hideRegionSwitch: boolean;
  @Input() hideSocialMedia: boolean;
  @Input() horizontalLinks: boolean;
  @Input() logoImgUrl: string;
  @Input() site: Site;
  footerContent: ComponentState;
  homePageUrl: string;

  languages: any;
  impersonation: Impersonation;
  userFullName: string;
  memberDetailsObservable: BehaviorSubject<MemberDetails> = new BehaviorSubject(null);
  componentDestroyed$: Subject<boolean> = new Subject();
  memberDetail: MemberDetails;
  impersonationMessage: string;
  menus: any[];
  instanceName = '';

  constructor(private readonly contentService: ContentService, private readonly memberSharedService: MemberSharedService, private readonly constants: StringsService,
    private readonly authService: AuthenticationService, private readonly contentHelper: FooterContentHelperService, private readonly logger: LoggingService) { }

  ngOnInit() {
    // listen for changes to authentication, member key, region, childsite, etc
    combineLatest([this.authService.getIsAuthenticated(), this.memberSharedService.loggedInMemberKey, this.authService.defaultHomePage, this.contentService.region$, this.contentService.childSite$])
    .pipe(takeUntil(this.componentDestroyed$)).subscribe(([isUserAuthenticated, loggedInMemberKey, defaultHomeUrl, region, childSite]) => {
      this.instanceName = 'footer-content';
      this.homePageUrl = defaultHomeUrl;
      if (!isUserAuthenticated) {
        if (!!childSite) {
          this.instanceName = `${this.instanceName}-${childSite}`;
          this.homePageUrl = this.contentService.getChildSiteHomeUrl(childSite, EnvironmentService.variables.site);
        }
      } else {
        this.memberSharedService.getImpersonation().pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.impersonation = result;
          if (!!this.impersonation && this.impersonation.isImpersonating) {
            this.getImpersonatingText();
          }
        });
      }
      this.contentService.getComponentState(this.instanceName, `${!this.site ? 'member' : this.site}-navigation`)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        this.footerContent = response || this.getFooterFallbackContent();
        this.menus = this.contentHelper.parseFooterContent(this.footerContent as DataComponentState, 'footerMenuGroupBox') || [];
      }, error => {
        this.footerContent = this.getFooterFallbackContent();
      });
    }, error => { this.logger.trackException(error, 'footer', SeverityLevel.Error); });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getIsImpersonating(): boolean {
    if (!!this.impersonation) {
      return this.impersonation.isImpersonating;
    }
    return false;
  }

  getImpersonatingText(): void {
    this.memberSharedService.loggedInMemberKey.pipe(takeUntil(this.componentDestroyed$))
      .subscribe(loggedInMemKey => {
        if (!!loggedInMemKey) {
          this.memberSharedService.getLoggedInMemberDetails().pipe(takeUntil(this.componentDestroyed$))
            .subscribe(details => {
              if (!!details) {
                this.userFullName = `${details.firstName} ${details.lastName}`;
              }
              this.userFullName = !!this.userFullName ? this.userFullName : '';
              const values = [
                new KeyValue('userId', !!this.impersonation ? this.impersonation.userId : 'unknown'),
                new KeyValue('userFullName', this.userFullName),
                new KeyValue('environment', EnvironmentService.variables.name)
              ];

              if (!!this.footerContent && !!this.footerContent.content) {
                this.impersonationMessage = this.constants.replaceContentTokens(this.footerContent.content.impersonationText, values);
              }
            });
        }
      });
  }

  private getFooterFallbackContent(): ComponentState {
    if (!FooterComponent.footerFallbackContent) {
      return undefined;
    }

    return (FooterComponent.footerFallbackContent as any).result as ComponentState;
  }
}
