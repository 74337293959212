import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { EnvironmentService } from 'libs/services/environment-variables.service';
import { ContentService } from 'libs/services/content.service';
import { StringsService } from 'libs/services/strings.service';
import { KeyValue } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-audience-nav',
  templateUrl: './audience-nav.component.html'
})

export class AudienceNavComponent implements OnInit, OnDestroy {
  @Input()
  content: any;

  employerLink: string;
  producerLink: string;
  providerLink: string;
  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private readonly contentService: ContentService, private readonly stringsService: StringsService) { }

  ngOnInit() {
    this.contentService.region$.pipe(takeUntil(this.componentDestroyed$)).subscribe(region => {
      this.setRegion(!region || region.length < 5 ? null : region.slice(3, 5));
    });
  }

  private setRegion(region: string) {
    if (!region || region.toLowerCase() !== 'ak') {
      region = 'wa';
    }
    region = region.toLowerCase();
    this.employerLink = this.stringsService.replaceContentTokens(`${EnvironmentService.variables.baseUrlEmployer}`, [new KeyValue('region', region)]);
    this.producerLink = this.stringsService.replaceContentTokens(`${EnvironmentService.variables.baseUrlProducer}`, [new KeyValue('region', region)]);
    this.providerLink = this.stringsService.replaceContentTokens(`${EnvironmentService.variables.baseUrlProvider}`, [new KeyValue('region', region)]);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
