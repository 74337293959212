import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'buttonType'
})
export class ButtonTypePipe implements PipeTransform {
  transform(value: string): string | undefined {
    const primary = 'btn btn-primary';
    if (!value) {
      return primary;
    }
    switch (value.toLowerCase()) {
     case 'secondary' : {
      return 'btn btn-secondary';
     }
     case 'tertiary' : {
      if (sessionStorage.length > 0) {
        let isHmoUser = JSON.parse(sessionStorage.getItem("isHmoUser"));
        if (!isHmoUser && !!sessionStorage.getItem("selectedpcpNotification")) {
          let showHMOpcpNotification: string = JSON.parse(sessionStorage.getItem("selectedpcpNotification"));
          if (showHMOpcpNotification === "HMO") {
            isHmoUser = true;
          }
          else if (showHMOpcpNotification === "PPO") {
            isHmoUser = false;
          }
        }
        return isHmoUser? 'btnhyperlink-tertiary' : 'btn-tertiary';
      }
     }
     case 'link' : {
      return 'btn-link';
     }
     case 'success' : {
      return 'btn btn-success text-black btn-block button-font-weight';
     }
     case 'btn-outline-success' : {
      return 'btn btn-outline-success text-color btn-mg btn-block';
     }
     case 'btn-nolocation-success' : {
      return 'btn btn-outline-success button-width';
     }
     case 'primary':
     default: {
       return primary;
     }
   }
  }
}
