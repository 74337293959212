import { Injectable, isDevMode } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';

const noop = () => undefined;

export enum SeverityLevel {
  Verbose = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
  Critical = 4
}

@Injectable()
export class LoggingService {
  static appInsightsKey: string;
  private readonly telemetryProperties: object = {};

  // Determine whether `console log/info/warn/debug` should be logged
  enableConsoleLogging: boolean = isDevMode();

  constructor() {
    if (!AppInsights.config) {
      const config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: !!LoggingService.appInsightsKey ? LoggingService.appInsightsKey : 'ce35d267-0f7c-4ab1-99c2-7e4343a6db79'
      };
      AppInsights.downloadAndSetup(config);
    }

    // For every telemetry created, we want to attach our custom properties to the log
    if (AppInsights.queue) {
      AppInsights.queue.push(() => {
        AppInsights.context.addTelemetryInitializer((envelope) => {
          const telemetryItem = envelope.data.baseData;

          telemetryItem.properties = {...telemetryItem.properties, ...this.telemetryProperties};
        });
      });
    }
  }

  updateAppInsightsKey(key: string): void {
    AppInsights.config.instrumentationKey = key;
  }

  /**
   * Log an application event
   *
   * @param name         - the event name.
   * @param properties   - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param measurements - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   *
   * Note: we have no use cases for using AppInsights.trackTrace or trackPageView;
   * the former is noise and the latter is covered by Adobe Analytics
   */
  trackEvent(name: string, properties?: any, measurements?: any) {
    AppInsights.trackEvent(name, properties, measurements);
  }

  /**
   * Log an application trace
   *
   * @param message       - the message to trace.
   * @param properties    - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param severityLevel - ApplicationInsights security level.
   */
  track(message: string, properties, severityLevel: SeverityLevel) {
    AppInsights.trackTrace(message, properties, severityLevel);
  }

  /**
   * Log a caught exception
   *
   * @param exception     - a caught error object
   * @param url           - url of page where error occurred
   * @param severityLevel - ApplicationInsights security level
   */
  trackException(exception: Error, url: string, severityLevel?: SeverityLevel) {
    const properties = { url };

    AppInsights.trackException(exception, null, properties, null, severityLevel);
  }

  /**
   * Log an application page view
   *
   * @param name         - the string used as the name. Defaults to the document title.
   * @param url          - a relative or absolute URL that identifies the page or other item. Defaults to the window location.
   * @param properties   - additional data used to filter pages and metrics in the portal. Defaults to empty.
   * @param measurements - metrics associated with this page, displayed in Metrics Explorer on the portal. Defaults to empty.
   * @param duration     - duration to track events (in ms).
   */
  trackPageView(
    name?: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number) {

    AppInsights.trackPageView(name, url, properties, measurements, duration);
  }

  /**
   * Override console.log and only log if console logging is enabled
   */
  get log() {
    if (this.enableConsoleLogging) {
      return console.log.bind(console);
    }

    return noop;
  }

  /**
   * Override console.info and only log if console logging is enabled
   */
  get info() {
    if (this.enableConsoleLogging) {
      return console.info.bind(console);
    }

    return noop;
  }

  /**
   * Override console.warn and only log if console logging is enabled
   */
  get warn() {
    if (this.enableConsoleLogging) {
      return console.warn.bind(console);
    }

    return noop;
  }

  /**
   * Override console.error
   */
  get error() {
    return console.error.bind(console);
  }
}
