import { Component, Input } from '@angular/core';

import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-statement-border',
  templateUrl: './statement-border.component.html'
})
export class StatementBorderComponent implements ContentComponent {
  @Input() data: ComponentState;
}
