import { Injectable } from '@angular/core';
import { NotificationRequest, NotificationResponseList, NotificationsResponse, UpdateNotificationRequest, UpdateNotificationResponse } from 'libs/models/notifications.model';
import { of } from 'rxjs';
import { SuccessResponse } from 'libs/models/pbc-models';
import { mergeMap, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { EnvironmentService } from './environment-variables.service';
import { MemberSharedService } from './member-shared.service';
import { UrlBuilderService } from './url-builder.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  updateNotificationRequest: UpdateNotificationRequest = new UpdateNotificationRequest('');
  constructor(private readonly dataService: DataService, private readonly memberSharedService: MemberSharedService, protected readonly urlBuilderService: UrlBuilderService) {
  }

  getNotification() {
    return this.memberSharedService.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new NotificationResponseList());
      }
      return this.dataService
        .post<SuccessResponse<NotificationResponseList[]>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.notificationGetUrl),
          new NotificationRequest(loggedInMemKey, loggedInMemKey), {})
        .pipe(map(response => {
          return response.result;
        }));
    }));
  }
  updateNotification(updatedNotificationDetails: NotificationsResponse[]) {
    return this.memberSharedService.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new NotificationResponseList());
      }
      this.updateNotificationRequest.MemberKey = loggedInMemKey;
      if (updatedNotificationDetails?.length > 0) {
        this.updateNotificationRequest.notifications = updatedNotificationDetails;
        return this.dataService
          .post<SuccessResponse<UpdateNotificationResponse>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.notificationsUpdateUrl),
            this.updateNotificationRequest, {})
          .pipe(map(response => {
            return response.result;
          }));
      }
    }));
  }
}
