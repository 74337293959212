import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { PbcCookieService } from './pbc-cookie.service';

@Injectable()
export class StateGuard implements CanActivate {

  constructor(private readonly pbcCookieService: PbcCookieService, private readonly router: Router) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const cookieRegion = this.pbcCookieService.get(this.pbcCookieService.regionCookie);
    if (!cookieRegion || !route.data.allowedRegions ||
      (!!route.data.allowedRegions && route.data.allowedRegions.indexOf(cookieRegion) < 0)) {
      this.router.navigate(['/']);
    }

    return true;
  }
}
