<div class="row">
  <div class="col-lg-8 mx-auto">
    <h1 tabindex="-1" [innerHtml]="pageTitle"></h1>
    <pbc-alert [data]="localAlert" [alert]="popUpAlert"></pbc-alert>
  </div>
</div>
<div *ngIf="!hasError" class="row">
  <div class="col-lg-8 mx-auto">
    <pbc-html-block [data]="contentCache['html-block-1']"></pbc-html-block>
    <div class="row no-gutters" aria-label="drug search" role="region">
      <iframe
        id="drugSearch"
        name="drugSearch"
        title="Drug Search"
        class="col mt-5 py-5 border-0 border-light-top"
        [height]="700"
        [src]="singleFormularyContent?.content?.iframeSrc | urlQualifySafeResource"
      ></iframe>
    </div>
  </div>
</div>
<div aria-live="assertive" *ngIf="hasError">
  <pbc-error-data-block id="errorBlock" [showSessionId]="true"></pbc-error-data-block>
</div>