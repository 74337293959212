export enum ProductCategory {
  Medical = 'M',
  Prescription = 'R',
  Dental = 'D',
  Vision = 'V'
}

export enum ProductCategoryLong {
  Medical = 'Medical',
  Prescription = 'Pharmacy',
  Dental = 'Dental',
  Vision = 'Vision'
}
