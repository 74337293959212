import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { MemberVisibilityPermissions, BaseMemberRequest, EvaluateRulesRequest } from 'libs/models/member.models';
import { DataService } from 'libs/services/data.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { SuccessResponse } from 'libs/models/pbc-models';
import { MemberSharedService } from './member-shared.service';
import { MemberPermission } from 'libs/models/pbc-enums';
import { AuthenticationService } from './authentication.service';
import { Form1095SharedService } from './form-1095-shared.service';
import { UrlBuilderService } from './url-builder.service';

@Injectable({
  providedIn: 'root'
})
export class VisibilityService {
  private cachedMemberPermissions: BehaviorSubject<MemberVisibilityPermissions>;
  private form1095ExclusionGroups: Array<string> = ['1000015', '1000016'];

  constructor(protected readonly dataService: DataService, protected readonly authenticationService: AuthenticationService,
    protected readonly memberSharedService: MemberSharedService, protected readonly router: Router,
    protected readonly form1095SharedService: Form1095SharedService, protected readonly urlBuilderService: UrlBuilderService) { }

  evaluateVisibilityRules(rules: string[], operation = 'and'): Observable<boolean> {
    return combineLatest([this.memberSharedService.loggedInMemberKey, this.authenticationService.getIsAuthenticated()])
      .pipe(mergeMap(([loggedInMemKey, isAuthenticated]) => {
        if (!!loggedInMemKey && isAuthenticated) {
          if (!!operation && operation.toLowerCase() === 'or') {
            return this.dataService
              .post<SuccessResponse<boolean>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.evaluateAnyRules),
                new EvaluateRulesRequest(loggedInMemKey, rules), {}).pipe(map(response => !!response ? response.result : false));
          } else {
            return this.dataService
              .post<SuccessResponse<boolean>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.evaluateAllRules),
                new EvaluateRulesRequest(loggedInMemKey, rules), {}).pipe(map(response => !!response ? response.result : false));
          }
        } else {
          return of(false);
        }
      }), catchError(() => of(false)));
  }

  getMemberVisibilityPermissions(): Observable<MemberVisibilityPermissions> {
    if (!!this.cachedMemberPermissions) {
      return this.cachedMemberPermissions.asObservable();
    }
    const newSubject = new BehaviorSubject<MemberVisibilityPermissions>(new MemberVisibilityPermissions());
    return this.refreshMemberPermissionsOnMemberKeyChange().pipe(map((perms) => {
      newSubject.next(perms);
      this.cachedMemberPermissions = newSubject;
      return this.cachedMemberPermissions.value;

    }),catchError(() => {
      this.cachedMemberPermissions = undefined;
      return of(undefined);
    }));
  }

  // has ANY of the permissions
  hasPermission(...permissions: MemberPermission[]): Observable<boolean> {
    return this.getMemberVisibilityPermissions().pipe(map(response => {
      return !!response && !!response.permissions && response.permissions.some(c => permissions.some(p => p === c));
    }));
  }

  // Call an internal method for checking navigation item visibility
  isRouteVisible(route: string): Observable<boolean> {
    if (!route) {
      return of(true);
    }

    const finalSegment = route.substring(route.lastIndexOf('/') + 1);
    const routeConfiguration = this.router.config.find(x => x.path === finalSegment);
    const visibilityFunction = !!routeConfiguration && !!routeConfiguration.data ? routeConfiguration.data.visibilityFunction : null;

    if (visibilityFunction) {
      return !!this[visibilityFunction] ? this[visibilityFunction]() : of(false);
    }

    return of(true);
  }

  // memberService.loggedInMemberKey is already a behavior subject. So we can pipe that to the service call
  // and automatically update whenever the visibility permissions change.
  private refreshMemberPermissionsOnMemberKeyChange(): Observable<MemberVisibilityPermissions> {
    let hmo : any;
    let url : string;
    if(JSON.parse(sessionStorage.getItem("demoHmoNonHmoValue")) != undefined) {
      hmo=JSON.parse(sessionStorage.getItem("demoHmoNonHmoValue"));
    } else {
      hmo=EnvironmentService.variables.hmo;
      sessionStorage.setItem('demoHmoNonHmoValue',hmo);
    }
    if(EnvironmentService.variables.name == 'mock' || EnvironmentService.variables.name == 'demo'){
      url = (hmo==false) ? EnvironmentService.variables.dataLocation.memberVisibilityPermissions : EnvironmentService.variables.dataLocation.memberVisibilityPermissionsHmo;
    } else {
      url =EnvironmentService.variables.dataLocation.memberVisibilityPermissionsHmo;
    }
    return combineLatest([this.memberSharedService.loggedInMemberKey, this.authenticationService.getIsAuthenticated()])
      .pipe(mergeMap(([loggedInMemKey, isAuthenticated]) => {
        if (!!loggedInMemKey && isAuthenticated) {
          return this.dataService
            .post<SuccessResponse<MemberVisibilityPermissions>>(this.urlBuilderService.buildBffUrl(url),
              new BaseMemberRequest(loggedInMemKey), {}).pipe(map(response => !!response ? response.result : null));
        } else {
          return of(new MemberVisibilityPermissions());
        }
      }));
  }

  canDisplayForm1095Page(): Observable<boolean> {
    // show if advanced features are turned OFF
    if (!EnvironmentService.variables.featureFlags.form1095Advanced) {
      return of(true);
    }

    // show if advanced features turned ON and meets this criteria
    return combineLatest([this.hasPermission(MemberPermission.IsSubscriber),
    this.hasPermission(MemberPermission.IsSelfFundedGroup, MemberPermission.IsMedSuppGroup, MemberPermission.IsIndividualGroup),
    this.form1095SharedService.getForm1095Eligibility(),
    this.memberSharedService.getLoggedInMemberDetails()])
      .pipe(mergeMap(([isSubscriber, form1095AlternateExperience, form1095Eligibility, memberDetails]) => {
        if (!isSubscriber || this.isIn1095ExclusionGroup(memberDetails.groupId)) {
          return of(false);
        }
        return of(form1095AlternateExperience || (!!form1095Eligibility && form1095Eligibility.is1095Eligible));
      }));
  }

  isIn1095ExclusionGroup(groupId: string): boolean {
    return !!this.form1095ExclusionGroups.find(exc => exc === groupId);
  }
}
