import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AnimateOnScrollDirective } from './animate-on-scroll.directive';
import { AnalyticsTagDirective } from './analytics-tag.directive';
import { LazyLoadImgDirective } from './lazy-load-img.directive';
import { LazyLoadBackgroundDirective } from './lazy-load-background.directive';
import { CloseDropdownDirective } from './close-dropdown.directive';
import { ApplyRulesDirective } from './apply-rules.directive';
import {PbcNgbPanelToggle, PbcNgbPanelHeader, PbcNgbPanelTitle, PbcNgbPanelContent, PbcNgbPanel } from '../components/pbc-ngb-accordion/pbc-accordion';
import { TrackScrollDirective } from './track-scroll.directive';

@NgModule({
  declarations: [
    AnimateOnScrollDirective,
    AnalyticsTagDirective,
    LazyLoadImgDirective,
    LazyLoadBackgroundDirective,
    CloseDropdownDirective,
    ApplyRulesDirective,
    TrackScrollDirective,

    // exports for ngb-accordion with fixes for JAWS
    PbcNgbPanelToggle,
    PbcNgbPanelHeader,
    PbcNgbPanelTitle,
    PbcNgbPanelContent,
    PbcNgbPanel
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AnimateOnScrollDirective,
    AnalyticsTagDirective,
    LazyLoadImgDirective,
    LazyLoadBackgroundDirective,
    CloseDropdownDirective,
    ApplyRulesDirective,
    TrackScrollDirective,

    // exports for ngb-accordion with fixes for JAWS
    PbcNgbPanelToggle,
    PbcNgbPanelHeader,
    PbcNgbPanelTitle,
    PbcNgbPanelContent,
    PbcNgbPanel
  ]
})
export class DirectivesModule { }
