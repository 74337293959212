import { Component, Input, OnChanges } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { CtaModel } from 'libs/models/pbc-models';
import { ExternalTargetPipe } from 'libs/pipes/external-target.pipe';
import { SafeExternalTargetPipe } from 'libs/pipes/safe-external-target.pipe';
import { StringsService } from 'libs/services/strings.service';

@Component({
  selector: 'pbc-cta',
  template: `
  <div id="impersonationMessage" class="alert alert-warning" *ngIf="ctaActionBlocked && !isHmoUser">
    <p class="body-smaller text-left mb-0"><i class="material-icons" aria-hidden="true">warning</i>
      {{blockedMessage}}</p>
  </div>
  <a *ngIf="!!href && !!title"
    [attr.href]="href" onclick="return false;"
    (click)="linkClick()"
    attr.aria-label="{{getAriaLabel()}}"
    [class]="style | buttonType"
    pbcAnalyticsTag data-analytics-component="cta"><span [innerHTML]="title"></span></a>
  `
})
export class CtaComponent implements OnChanges {
  @Input() model: CtaModel;

  @Input() href: string;
  @Input() title: string;
  @Input() customAriaLabelForScreenReader: string;
  @Input() style: string;
  @Input() isExternal: string;
  @Input() analytics: string;
  @Input() navExtras?: NavigationExtras | null;

  @Input() blockAction: boolean;
  @Input() preventDefault: boolean;
  @Input() blockedMessage: string;
  @Input() notificationRedirectPlan: string = "";
  ctaActionBlocked = false;
  isHmoUser : boolean = false;
  featureFlags: any;

  constructor(private readonly router: Router,
    private readonly externalTarget: ExternalTargetPipe,
    private readonly safeExternalTarget: SafeExternalTargetPipe,
    private readonly stringsService: StringsService) {
    this.featureFlags = EnvironmentService.variables.featureFlags;
  }

  ngOnChanges() {
    this.isHmoUser = JSON.parse(sessionStorage.getItem("isHmoUser"));
    if (this.featureFlags.notificationFEFlag && this.notificationRedirectPlan === "HMO") {
      this.isHmoUser = true;
    }
    else if (this.featureFlags.notificationFEFlag && this.notificationRedirectPlan === "PPO") {
      this.isHmoUser = false;
    }
    // when model is present, override individual input property
    this.href = !!this.href ? this.href.split('&amp;').join('&') : this.href;
    if (this.model) {
      this.href = (this.model.href) ? this.model.href : this.href;
      this.title = (this.model.title) ? this.model.title : this.title;
      this.style = (this.model.style) ? this.model.style : this.style;
      this.isExternal = (this.model.target) ? this.model.target : this.isExternal;
      this.analytics = (this.model.analytics) ? this.model.analytics : this.analytics;
      this.navExtras = (this.model.navExtras) ? this.model.navExtras : this.navExtras;
      this.preventDefault = this.model.preventDefault !== undefined ? this.model.preventDefault : this.preventDefault;
    }
  }

  linkClick() {
    if (this.blockAction) {
      this.ctaActionBlocked = true;
      return;
    }

    if (this.isExternal && this.isExternal.toString() === 'true') {
      window.open(this.href, this.externalTarget.transform(this.isExternal)).opener = null;
    } else if (!this.preventDefault) {
      this.router.navigateByUrl(this.href, this.navExtras);
    }
  }

  getAriaLabel(): string {
    let isExternalLink = this.isExternal && this.isExternal.toString() === 'true';
    if (this.href && this.href.indexOf('/sso-redirect') >= 0) {
      isExternalLink = true;
    }

    if (isExternalLink) {
      return `${this.customAriaLabelForScreenReader || this.title} ${this.stringsService.data.value.OPENS_NEW_WINDOW}`;
    } else {
      return `${this.customAriaLabelForScreenReader || this.title}`;
    }
  }
}
