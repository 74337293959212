import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { format, parse } from 'date-fns';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    return format(parse(value), 'MM/DD/YYYY');
  }
}
