<div class="modal-content" aria-modal="true" role="dialog">
    <div class="modal-header">
        <h4 class="modal-title" [innerHtml]="title"></h4>
        <button pbcAnalyticsTag aria-label="Close" class="close" type="button" (click)="closeModal()">
            <span aria-hidden="true">x</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="marketo-form-component p-3">
            <form [id]="marketoFormId"></form>
        </div>
    </div>
</div>