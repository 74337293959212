import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { Group, GroupRequest, SuccessResponse } from 'libs/models/pbc-models';
import { DataService } from 'libs/services/data.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { MemberSharedService } from './member-shared.service';
import { UrlBuilderService } from './url-builder.service';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(private readonly dataService: DataService, private readonly memberSharedService: MemberSharedService, protected readonly urlBuilderService: UrlBuilderService) { }

  getMemberGroup(groupId?: string): Observable<Group> {
    if (!!groupId) {
      return this.getGroup(groupId);
    }
    return this.getLoggedInMemberGroup();
  }

  public getGroup(groupId: string, groupKey?: string,memberKey? :string): Observable<Group> {
    return this.dataService
      .post<SuccessResponse<Group>>(
        this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberGroup),
        new GroupRequest(groupId, groupKey,memberKey), {}
      )
      .pipe(map(response => {
        return response.result;
      }));
  }

  public getLoggedInMemberGroup(): Observable<Group> {
    return this.memberSharedService.getLoggedInMemberDetails().pipe(mergeMap(memberDetails => {
      if (!!memberDetails && !!memberDetails.groupId) {
        return this.getGroup(memberDetails.groupId);
      } else {
        return null;
      }
    }));
  }
}
