import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from './loader.service';
import { AiSessionService } from './ai-session.service';
import { EnvironmentService } from './environment-variables.service';

@Injectable()
export class DataService {
  readonly jsonExtension: string = '.json';
  constructor(    
    private readonly http: HttpClient,
    private readonly loaderService: LoaderService,
    private readonly aiSession: AiSessionService
  ) { }

  get<T>(url: string, headers: HttpHeaders = new HttpHeaders(), withCredentials = true, responseType?: string): Observable<T> {
    const options = {};

    if (this.showMockData(url)) {
      return this.getMock(url, responseType);
    }

    headers = this.appendCorrelationIdHeader(headers);

    options['headers'] = headers;
    options['withCredentials'] = withCredentials;

    if (responseType) {
      options['responseType'] = responseType;
    }

    this.loaderService.show();
    return this.http.get<T>(url, options).pipe(finalize(() => {
      this.loaderService.hide();
    }));
  }

  post<T>(url: string, data: any, options: {} = {}, withCredentials = true): Observable<T> {

    if (this.showMockData(url)) {
      return ['mock'].includes(EnvironmentService.variables.name) || url.indexOf(this.jsonExtension) > 1  ?  this.getLocalMock(url, data,options) : this.postMock(url, data, options);
    }

    this.loaderService.show();
    let httpHeaders = options['headers'];

    httpHeaders = this.appendCorrelationIdHeader(httpHeaders);

    options['headers'] = httpHeaders;
    options['withCredentials'] = withCredentials;

    return this.http.post<T>(url, data, options).pipe(finalize(() => {
      this.loaderService.hide();
    }));
  }

  showMockData(url: string): boolean {   
    if (['demo', 'localdemo'].includes(EnvironmentService.variables.name)) {
      if (url.includes('contentservices') || url.includes('//member-edge')) {
        // Scenario: environment - local
        // If a real URL is set for content and we are in the partialMock for demo, don't mock the content data.
        return false;
      } else if (!url.includes('mocks/content/')) {
        // Scenario: environment - dev, test, preprod, prod
        // Content is from live URL
        // All other data is Mocked
        return true;
      }
    } else {
      // Scenario: environment - local
      // All data is mocked
      if (url.includes('mocks/')) {
        return true;
      }
    }
    return false;
  }

  private appendCorrelationIdHeader(headers: HttpHeaders = new HttpHeaders()) {
    const currentSession = this.aiSession.readSessionId();
    if (!!currentSession && !!headers) {
      headers = headers.set('CorrelationId', `${currentSession}`);
    }
    return headers;
  }

  private postMock<T>(url: string, data: any, options: {}): Observable<T> {
    // const rando = Math.floor(Math.random() * 2000);
    // console.log(`wait for ${rando}`);
    this.loaderService.show();
    return this.http.post<T>(url,data, options).pipe(
      // delay(rando), // For testing timeouts
      finalize(() => {
        this.loaderService.hide();
      }));
  }

  private getLocalMock<T>(url: string, data: any, options: {}): Observable<T> {
    // const rando = Math.floor(Math.random() * 2000);
    // console.log(`wait for ${rando}`);
    this.loaderService.show();
    return this.http.get<T>(url, options).pipe(
      // delay(rando), // For testing timeouts
      finalize(() => {
        this.loaderService.hide();
      }));
  }

  private getMock<T>(url: string, responseType): Observable<T> {
    const options = responseType ? { responseType } : {};
    this.loaderService.show();
    return this.http.get<T>(url, options).pipe(finalize(() => {
      this.loaderService.hide();
    }));
  }
}
