import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { BaseContentPage } from 'libs/classes/base-content-page';
import { ComponentState } from 'libs/models/pbc-models';
import { SeverityLevel } from 'libs/services/logging.service';

@Component({
  selector: 'pbc-single-formulary-template',
  templateUrl: './single-formulary-template.component.html'
})
export class SingleFormularyTemplateComponent extends BaseContentPage implements OnInit {
  singleFormularyContent: ComponentState;
  hasError = false;

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute
  ) {
    super(false, tabTitle, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();

    const pageData = this.activatedRoute.snapshot.data;
    if (pageData) {
      this.contentService
        .getComponentState('content-' + pageData['page'], pageData['contentGroup'])
        .pipe(takeUntil(this.componentDestroyed$), catchError(err => this.handleError(err)))
        .subscribe(res => {
          this.singleFormularyContent = res;
        });
    }
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
    return of(null);
  }
}
