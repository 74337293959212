import { BaseMemberRequest } from 'libs/models/member.models';

export class PaperlessEobPromoRequest extends BaseMemberRequest {
  targetMemberKey: string;
  type: string;
  constructor(requestorMemberKey: string, targetMemberKey: string, type: string) {
    super(requestorMemberKey);
    this.targetMemberKey = targetMemberKey;
    this.type = type;
  }
}

export class PaperlessEobPromoResponse {
  showEobPromo: boolean;
}

export class SetPaperlessEobPromoData extends BaseMemberRequest {
  targetMemberkey: string;
  type: string;
  actionType: string;
  dateOfAction: Date;
  constructor(requestorMemberKey: string, targetMemberKey: string, type: string, actionType: string, dateOfAction: Date) {
    super(requestorMemberKey);
    this.targetMemberkey = targetMemberKey;
    this.type = type;
    this.actionType = actionType;
    this.dateOfAction = dateOfAction;
  }
}
