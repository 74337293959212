import { Component } from '@angular/core';

import { FooterTopComponent } from '../footer-top.component';

@Component({
  selector: 'pbc-footer-top-mobile',
  templateUrl: './footer-top-mobile.component.html',
})
export class FooterTopMobileComponent extends FooterTopComponent {

}
