import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'libs/services/loader.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pbc-placeholder',
  template: `
  <div *ngIf="loadingData">
    <content-loader animate="false">
      <svg:rect x="10" y="10" rx="0" ry="0" width="150" height="100" />
      <svg:rect x="170" y="10" rx="3" ry="3" width="120" height="20" />
      <svg:rect x="170" y="40" rx="3" ry="3" width="140" height="10" />
      <svg:rect x="170" y="60" rx="3" ry="3" width="120" height="10" />
      <svg:rect x="170" y="80" rx="3" ry="3" width="150" height="10" />
    </content-loader>
  </div>
  `
})
export class PlaceholderComponent implements OnInit, OnDestroy {
  private loaderSubscription: Subscription;
  loadingData = true;

  constructor(readonly loader: LoaderService) {}

  ngOnInit() {
    this.loaderSubscription = this.loader.loaderState.pipe(debounceTime(50)).subscribe((state) => {
      this.loadingData = state;
    })
  }

  ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }
}
