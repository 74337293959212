import { Component } from '@angular/core';

import { StringsService } from 'libs/services/strings.service';
import { AnalyticsReportingService } from 'libs/services/analytics-reporting.service';
import { AuthenticationService } from 'libs/services/authentication.service';

@Component({
  selector: 'pbc-page-not-found',
  templateUrl: './page-not-found.component.html'
})

export class PageNotFoundComponent {
  constructor(readonly strings: StringsService,
    private readonly analyticsReporting: AnalyticsReportingService,
    private readonly authService: AuthenticationService
    ) {
      this.authService.logPageView('NotFoundPage');
      this.analyticsReporting.setAnalyticsPageError('404');
  }
}
