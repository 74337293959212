import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { LoaderService } from 'libs/services/loader.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pbc-loader',
  template: `
  <div *ngIf="alwaysShow || loadingData | debounce: 500" class="loader text-primary text-center">
    <img [src]="isLwwa?'/assets/image/oval-loader.png':'/assets/image/spinner-transparent.png'" alt="loading">
  </div>
  `,
  styles: [
    `
    .loader  {
      -webkit-animation:spin 1s linear infinite;
      -moz-animation:spin 1s linear infinite;
      animation:spin 1s linear infinite;
    }

    @keyframes spin { 100% { transform: rotate(360deg); } }
    @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
    @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
    `
  ]
})
export class LoaderComponent implements OnInit, OnDestroy {
  private loaderSubscription: Subscription;
  loadingData = true;
  isLwwa:boolean=false;
  @Input() alwaysShow = false;

  constructor(readonly loader: LoaderService) {}

  ngOnInit() {
    this.loaderSubscription = this.loader.loaderState.pipe(debounceTime(100)).subscribe((state) => {
      this.loadingData = state;
    });
    this.isLwwa = EnvironmentService.variables.site === "lwwa";
  }

  ngOnDestroy() {
    if (this.loaderSubscription) {
      this.loaderSubscription.unsubscribe();
    }
  }
}
