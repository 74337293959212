<a *ngIf ="!!footerContent?.content?.socialFacebookUrl" href="{{ footerContent?.content?.socialFacebookUrl }}" target="_blank" rel="noopener noreferrer" pbcAnalyticsTag
  data-analytics-override-route="footer" data-analytics-title="facebook">
  <i class="fab fa-facebook-f" aria-hidden="true"></i>
  <span class="sr-only">{{ footerContent?.content?.socialFacebookScreenReader }}</span>
</a>

<a *ngIf ="!!footerContent?.content?.socialTwitterUrl" href="{{ footerContent?.content?.socialTwitterUrl }}" target="_blank" rel="noopener noreferrer" pbcAnalyticsTag
  data-analytics-override-route="footer" data-analytics-title="twitter">
  <i class="fab fa-twitter rounded-circle" aria-hidden="true"></i>
  <span class="sr-only">{{ footerContent?.content?.socialTwitterScreenReader }}</span>
</a>

<a *ngIf ="!!footerContent?.content?.socialYoutubeUrl" href="{{ footerContent?.content?.socialYoutubeUrl }}" target="_blank" rel="noopener noreferrer" pbcAnalyticsTag
  data-analytics-override-route="footer" data-analytics-title="youtube">
  <i class="fab fa-youtube" aria-hidden="true"></i>
  <span class="sr-only">{{ footerContent?.content?.socialYoutubeScreenReader }}</span>
</a>

<a *ngIf ="!!footerContent?.content?.socialLinkedInUrl" href="{{ footerContent?.content?.socialLinkedInUrl }}" target="_blank" rel="noopener noreferrer" pbcAnalyticsTag
  data-analytics-override-route="footer" data-analytics-title="linkedin">
  <i class="fab fa-linkedin-in" aria-hidden="true"></i>
  <span class="sr-only">{{ footerContent?.content?.socialLinkedInScreenReader }}</span>
</a>