import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { BehaviorSubject } from 'rxjs';
import { KeyValue } from 'libs/models/pbc-models';
import { EnvironmentService } from './environment-variables.service';

@Injectable()
export class StringsService {

  static staticDefaultData: any;
  defaultData: any;
  data: BehaviorSubject<any> = new BehaviorSubject<any>({});
  domainTokens = [];
  memberToken = '{member}/';


  /// This will filter out all symbols, except 0-9 and .
  static returnFilteredCurrency(value: string) {
    return value.replace(/[^\d.-]/g, '');
  }

  constructor(private readonly http: HttpClient, private readonly logger: LoggingService, private readonly environment: EnvironmentService) {
    if (!!this.defaultData) {
      this.data.next(this.defaultData);
    } else if (!!StringsService.staticDefaultData) { // for unit tests
      this.data.next(StringsService.staticDefaultData);
    }
  }

  // Replaces a token surrounded by curly brackets with a value.
  // ex: 'this is my {description} string' --> 'this is my cool string'
  replaceContentTokens(contentString: string, tokensAndValues: KeyValue[]): string {
    if (!contentString || !tokensAndValues) {
      return '';
    }
    let result = contentString;
    tokensAndValues.forEach(pair => {
      const regExSearch = new RegExp(`{${pair.id}}`, 'g');
      result = result.replace(regExSearch, pair.value);
    });
    return result;
  }

  // This link is in another context so needs to be fully qualified
  needsDomain(url: string): boolean {
    if (!url) {
      return false;
    }
    if (url.startsWith('http')) {
      return true;
    }
    return (url.startsWith(this.memberToken) && this.environment.inVisitorContext()) ||
      (!url.startsWith(this.memberToken) && this.environment.inMemberContext());
  }

  addDomainToLink(url: string): string {
    if (!this.domainTokens.length) {
      this.domainTokens = [
        new KeyValue('member', EnvironmentService.variables.baseUrlMember),
        new KeyValue('visitor', EnvironmentService.variables.baseUrlVisitor)
      ];
    }
    if (!url) {
      return url;
    }
    url = this.replaceContentTokens(url, this.domainTokens);
    if (!url.startsWith('http')) {
      url = url.startsWith('/') ? `${EnvironmentService.variables.baseUrlVisitor}${url}` : `${EnvironmentService.variables.baseUrlVisitor}/${url}`;
    }
    return url;
  }

  dropRootToken(url: string): string {
    if (!url) {
      return url;
    }
    return url.replace(this.memberToken, '');
  }

  stringFormatter(templateString: string, replacementStrings: string[]): string {
    if (!!templateString && replacementStrings.length > 0) {
      replacementStrings.forEach((x, index) => {
        const expression = `\\{${index}\\}`;
        const regex = new RegExp(expression, 'g');
        templateString = templateString.replace(regex, x);
      });
    }
    return templateString;
  }

  use(lang?: string): Promise<{}> {
    if (!this.data.value.hasOwnProperty('SITE_TITLE')) {
      this.data.next(this.defaultData);
    }
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/l10n/${lang || 'en'}.json`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data.next(Object.assign({}, translation || {}));
          resolve(this.data);
        },
        error => {
          this.logger.trackException(error, langPath, SeverityLevel.Warning);
          this.data.next({});
          resolve(this.data);
        }
      );
    });
  }
}
