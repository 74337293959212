import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { StatusCode } from 'libs/models/pbc-enums';
import { LoggingService, SeverityLevel } from './logging.service';

@Injectable()
export class PbcErrorHandlerService implements ErrorHandler {
  private router: Router;
  constructor(private readonly logger: LoggingService, private readonly injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {
    this.router = this.injector.get(Router);
    if (error instanceof HttpErrorResponse) {
      const errorToTrack: Error = new Error(JSON.stringify(error));
      this.logger.trackException(errorToTrack, error.url);

      if (!navigator.onLine) {
        // Handle offline error
      } else {
        switch (error.status) {
          case StatusCode.Unauthorized:
            console.debug(`Http error 401, error url : ${error.url}, router url : ${this.router.url}`);
            break;

          case StatusCode.Forbidden:
            console.debug(`Http error 403, error url : ${error.url}, router url : ${this.router.url}`);
            this.logger.track(`Logging from Interceptor ::: Http error 403, error url : ${error.url}, router url : ${this.router.url}`,{ 'Service ': 'CallBackComponent' }, SeverityLevel.Information);
            this.router.navigate(['/unauthorized'], { skipLocationChange: true });
            break;

          case StatusCode.NotFound:
            console.error(`Http error 404, error url : ${error.url}, router url : ${this.router.url}`);
            break;

          case StatusCode.InternalServerError:
            console.error(`Http error 500, error url : ${error.url}, router url : ${this.router.url}`);
            break;

          default:
            console.debug(`Error status : ${error.status}, error url : ${error.url}, router url : ${this.router.url}`);
            break;
        }
      }
    } else {
      this.logger.trackException(error, this.router.url);
      console.debug(`A client side error occurred, ${error.message}, router url : ${this.router.url}... at ${error.stack}`);
      // generic error routing, temporaries
      this.router.navigate(['/internal-server-error'], { skipLocationChange: true });
    }
  }
}
