<pbc-placeholder></pbc-placeholder>
<div *ngIf="data?.isVisible" [ngClass]="data?.content?.parentClasses" data-id="img-block-banner" class="row align-items-center no-gutters">
  <div class="col pr-md-3 pr-xl-5" *ngIf="!isImageAlignedRight(data)" id="img-col-left">
    <img [src]="(data?.content?.imageMobileUrl | urlQualify) || (data?.content?.imageUrl  | urlQualify)" class="d-md-none" alt="" aria-hidden="true" data-id="img-left">
    <img [src]="data?.content?.imageUrl" alt="" aria-hidden="true" class="img-fluid d-none d-md-block">
  </div>
  <div class="col-md-8 order-2 mt-3 mt-md-0 mx-3 mx-lg-3 pd-align">
    <h1 class="mt-0" *ngIf="data?.content?.title" [innerHTML]="data?.content?.title"></h1>
    <div *ngIf="data?.content?.body" [innerHTML]="data?.content?.body"></div>
  </div>
  <div class="col pl-md-3 pl-xl-5 order-1 order-md-2 mx-3 mx-lg-3" *ngIf="isImageAlignedRight(data)" id="img-col-right">
    <img [src]="(data?.content?.imageMobileUrl | urlQualify) || (data?.content?.imageUrl  | urlQualify)" class="d-md-none" alt="" aria-hidden="true" data-id="img-right">
    <img [src]="data?.content?.imageUrl" alt="" aria-hidden="true" class="img-fluid d-none d-md-block">
  </div>
</div>
