<div [ngClass]="data?.content?.tableHeaderClass" [innerHtml]="tableHeaderText"></div>
<div class="table-responsive">
  <table class="table table-striped">
    <caption class="sr-only"></caption>
    <thead>
      <tr class="d-flex">
        <th scope="col"  *ngFor="let column of columns" [ngClass]="'col-'+ column.width">{{column?.name}}</th>
      </tr>
    </thead>
    <tbody>
      <tr class="d-flex" *ngFor="let row of rows">
        <td *ngFor="let cell of row" [ngClass]="'col-'+ cell.width">{{cell.value}}</td>
      </tr>
    </tbody>
  </table>
</div>
