import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { LayoutHelper } from 'libs/services/layout-helper';
import { BaseContentPage } from 'libs/classes/base-content-page';

@Component({
  selector: 'pbc-info-1',
  templateUrl: './info-1.component.html'
})
export class Info1TemplateComponent extends BaseContentPage {

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,
    public layoutHelper: LayoutHelper
    ) {
      super(
        true,
        tabTitle,
        activatedRoute);
      }
  }
