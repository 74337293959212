
export const basemockenvironment = {
  site: '',
  name: 'mock',
  appId: 'member-mock', // member-dev, if you want to use configService
  production: false,
  mockDemoSSO: true,
  version: '',
  contentDomain: '//www.premera.com',
  egainDomain: 'premeradev',
  medicareSignInDomain: '',
  appInsightsKey: '',
  configServiceUrl: 'https://localhost:4202/mocks/shared/config.json',
  baseUrlVisitor: '',
  baseUrlMember: '',
  baseUrlEmployer: '',
  baseUrlProducer: '',
  baseUrlProvider: '',
  oHPAvailityUrl: '',
  oHPEvolentUrl: '',
  bingMapKey: '',
  notification: {
   kinwellURL : 'https://kinwellhealth.com/appointments/'
  },
  bingMap: {
    bingMapKey: 'AiNsT5Ys0AQJMfi0h9SkzLkuO5bRbkdwOUelq7ctgLLGtMFEaCZotJb0RKRrQd20',
    bingMapLocationUrl: 'https://dev.virtualearth.net/REST/v1/Locations',
    bingMapControlUrl: 'https://www.bing.com/api/maps/mapcontrol'
  },
  hmo:false,
  dataLocation: {
    bffBaseUrl: '',
    contentBaseUrl: '',
    healthBffUrl: 'shared/health.json',
    healthContentUrl: 'shared/health.json',
    loginUrl: 'auth/login.json',
    validateUrl: 'auth/validate.json',
    signOutUrl: 'auth/signout.json',
    encryptedTokenUrl: 'auth/encryptedToken.json',
    ssoOutboundUrl: '{memberKey}/sso/outbound-sso-{target}.json',
    claimsSearchUrl: '{memberKey}/claims/claim-search.json',
    claimsProvidersSearchUrl: '{memberKey}/claims/claims-providers-search.json',
    claimIdSearchUrl: '{memberKey}/claims/claim-by-id.json',
    claimsExportUrl: '{memberKey}/claims/export-blob',
    detailsUrl: '{memberKey}/claims/claim-details.json',
    claimsSubmissionUrl: 'claims/claims-submission.json',
    contactDetailsGetUrl: 'account-settings/contact-details-get.json',
    contactDetailsUpdateUrl: 'account-settings/contact-details-update.json',
    eobUrl: 'claims/claim-eob.json',
    eobVisibilityUrl: 'claims/claim-eob-isvisible.json',
    searchBaseUrl: 'https://www.lifewisewa.test1',

    referralDetailsUrl: '{memberKey}/referrals/referral-details.json',
    referralsSummaryUrl: '{memberKey}/referrals/referral-list.json',
    preapprovalsListUrl: '{memberKey}/preapprovals/preapprovals.json',


    memberRegistrationsUrl: '{memberKey}/member/ordered-registrations.json',
    memberRegistrationDetailsUrl: '{memberKey}/member/registration-details.json',
    memberRegistrationDetailsUrlV2: '{memberKey}/member/registration-detailsV2.json',
    memberDetails: '{memberKey}/member/member-details.json',
    familyMembersUrl: '{memberKey}/member/family-members.json',
    familyMembersEligibilityUrl: '{memberKey}/member/eligible-family-members.json',
    eligibleKeysUrl: '{memberKey}/member/eligible-member-keys.json',
    memberNameAndDobUrl: '{memberKey}/member/member-name-and-dob.json',
    memberVisibilityPermissions: '{memberKey}/member/visibility.json',
    memberVisibilityPermissionsHmo: '{memberKey}/hmoMember/visibility.json',
    evaluateAllRules: 'member/evaluate-all-rules.json',
    evaluateAnyRules: 'member/evaluate-any-rules.json',
    memberImpersonationRequestUrl: 'member/impersonation.json',
    memberSsoRequestUrl: 'member/sso.json',
    memberAccountEmailRequestUrl: 'member/account-email.json',
    memberGroup: '{memberKey}/member/member-group.json',
    memberPhysicalCardRequestUrl: 'member/physical-card.json',
    memberTemporaryCardRequestUrl: 'member/id-card-front.json',
    memberTemporaryCardRequestHMOUrl: 'member/id-card-hmo.json',
    memberFallbackCardRequestUrl: '{memberKey}/member/fallback-card.json',
    memberSurrogateIdRequestUrl: 'member/surrogate-id.json',
    memberSetSelectedPlanUrl: 'member/selected-memberkey-set.json',
    memberGetSelectedPlanUrl: 'member/selected-memberkey-get.json',
    memberExperianBenefitUrl: '{memberKey}/member/has-credit-monitoring.json',
    memberDataGetUrl: '{memberKey}/member/get-promo-data.json',
    memberDataSetUrl: 'member/set-promo-data.json',
    notificationGetUrl: 'member/notification-center.json',
    notificationsUpdateUrl: 'member/notification-center.json',
    memberPaperlessEobPreferencesUrl: '{memberKey}/account-settings/paperless-eob-and-email.json',
    memberPaperless1095PreferencesUrl: '{memberKey}/account-settings/paperless-1095-and-email.json',
    memberSetPaperlessEobPreferencesUrl: 'account-settings/set-paperless-eob-and-email.json',
    memberSetDefaultPaperlessEobPreferencesUrl: 'account-settings/set-paperless-eob-and-email.json',
    memberSetPaperless1095PreferencesUrl: 'account-settings/set-paperless-1095-and-email.json',

    memberLoggingAddEntry: 'member/logentry.json',

    benefitsAccumulatorsUrl: '{memberKey}/benefits/medical-accumulators.json',
    benefitsCategoriesUrl: 'benefits/medical-categories.json',
    benefitsServicesUrl: 'benefits/medical-service-details.json',
    benefitBookletsUrl: '{memberKey}/benefits/booklets.json',
    benefitBookletUrl: 'benefits/booklet.json',
    benefitGroupBooklets: '{memberKey}/benefits/group-booklets.json',
    benefitIsCoInsuranceUrl: 'benefits/is-coinsurance.json',
    benefitIsVisionPartOfMedical: 'benefits/is-vision-part-of-medical.json',

    form1095ListUrl: '{memberKey}/account-settings/form-1095-list.json',
    form1095DetailsUrl: 'account-settings/form-1095-detail.json',
    form1095EligibilityUrl: '{memberKey}/account-settings/get-1095-eligibility.json',

    hraPlanYearsUrl: 'hra/hra-plan-years.json',
    hraBalanceUrl: 'hra/hra-balance.json',
    hraClaimsUrl: 'hra/hra-claims.json',

    contentComponentUrl: 'content/',
    componentStateUrl: 'content/{region}',
    pageStateUrl: 'content/',
    alertUrl: 'content/',

    privacyAuthorizationsForMemberUrl: '{memberKey}/account-settings/privacy-by-member.json',
    privacyAuthorizationHistoryForMemberUrl: '{memberKey}/account-settings/auth-history-response.json',
    privacyAuthorizationsToMemberUrl: '{memberKey}/account-settings/privacy-to-member.json',
    removePrivacyAuthorization: 'account-settings/remove-authorization.json',
    authSubmissionUrl: 'account-settings/auth-submission-response.json',
    authorizationDateRestrictionsUrl: 'account-settings/auth-date-restriction.json',

    pcpSelectedProviderUrl: '{memberKey}/dashboard/selected-pcp-info.json',
    pcpProviderInfoUrl: 'pcp/pcp-provider-info.json',
    pcpSpecialtiesUrl: 'pcp/specialty-list.json',
    pcpLocationHintsUrl: 'pcp/location-hints.json',
    pcpDetailUrl: 'pcp/guide-detail.json',
    pcpNameHintsUrl: 'pcp/hmo/PCPName-hints.json',
    pcpSearchByNameUrl: 'pcp/search-name.json',
    pcpSearchBySpecialtyUrl: 'pcp/search-specialty.json',
    pcpEMSSearchByNameUrl: 'v3.0/pcp/provider/search/name',
    pcpSetUrl: 'pcp/set-pcp.json',

    drugsRequiringApprovalXml: 'mocks/content/drugs-requiring-approval.xml',
    virtualAssistantUrl: 'ws://localhost:5002/chat',
    newpcpSpecialtiesUrl: 'pcp/hmo/specialty-list.json',
    newpcpLocationHintsUrl: 'pcp/hmo/location-hints.json',
    newpcpSearchByNameUrl: 'pcp/hmo/search-name.json',
    newpcpSearchBySpecialtyUrl: 'pcp/hmo/search-specialty.json',
    newpcpSetUrl: 'pcp/hmo/set-pcp.json',
    pcpGetCurrentPcpUrl: 'pcp/hmo/get-current-pcp.json',
    pcpGetFamilyUrl: 'pcp/hmo/get-family-pcp.json',
    visionClaimSubmissionUrl : 'claims/vision-claims-submission.json',    
    visionClaimCreateDraftUrl :'claims/vision-DraftCreation.json',
    visionClaimAttachmentUploadUrl : 'claims/vision-FileUpload.json',
    visionClaimAttachmentPDFUploadUrl : 'claims/vision-FileUpload.json',
    visionClaimUpdateDraftUrl : 'claims/vision-UpdateDraft.json',
    visionClaimAttachmentDeleteUrl:"claims/vision-FileDelete.json",
    medicalPoliciesRecentUpdatesUrl: 'https://localhost:4202/mocks/content/RecentUpdatesDataXML.xml',
  },
  sso: {
    findCareLaunch: 'sapphire_demo',
    cycLaunch: 'ConnectYourCare',
    expressScriptLaunch: 'ExpressScripts',
    secureInboxLaunch: 'eGain',
    teladocLaunch: 'Teladoc',
    pcpLaunch: 'Olympus',
    rxAdvanceLaunch: 'RXAdvance',
    billpayLaunch: 'Alacriti',
    medikeeperLaunch: 'MediKeeper'
  },
  authentication: {
    clientId: 'b6370759b0a24b9f95f8b87b949c44bd',
    callbackUrl: '',
    responseType: 'id_token+token',
    scope: 'openid+phone+email',
    state: '69b42ab13f27410fb490be8f80a61064',
    nonce: 'ebc75c3b6e6b46b5a7a0351b7c93fd06',
    responseMode: 'fragment',
    authSuffix: '/SecureAuth30/SecureAuth.aspx',
    registerUrl: '',
    authorization: '/callback#access_token=mocktoken',
    restart: '',
    defaultReturnUrl: '/dashboard'
  },
  relayStateRoutingTable: {
    claims: 'manage-claims',
    claimsearch: 'manage-claims',
    orderidcard: 'dashboard',
    providerdirectory: 'find-a-doctor/sso-launch',
    fundingaccount: 'connect-your-care/sso-launch',
    fundingaccountfsa: 'connect-your-care/sso-launch',
    fundingaccounthsa: 'connect-your-care/sso-launch',
    reimburse: 'connect-your-care/sso-launch',
    cdhaccount: 'cyc-cdh-account/sso-launch',
    billpay: 'cyc-bill-pay/sso-launch',
    claimsubmission: 'cyc-claim-submission/sso-launch',
    rxbenefits: 'rx-benefits/sso-launch',
    claimsandbalances: 'claims-and-balances/sso-launch',
    druginfo: 'drug-info/sso-launch',
    rx: 'order-and-refill/sso-launch',
    homedelivery: 'home-delivery/sso-launch',
    locatepharmacy: 'locate-pharmacy/sso-launch',
    annualcosts: 'annual-rx-cost/sso-launch',
    myrxchoice: 'my-rx-choice/sso-launch',
    pharmacyprofile: 'pharmacy-profile/sso-launch',
    rxhistory: 'rx-history/sso-launch',
    rxpricing: 'rx-pricing/sso-launch',
    referrals: 'member-referrals'
  },
  recoveryEmail: {
    realmSuffix: '/SecureAuth40/Authorized/accountupdate.aspx',
    url: ''
  },
  recoveryPhone: {
    realmSuffix: '/SecureAuth41/Authorized/accountupdate.aspx',
    url: ''
  },
  resetPassword: {
    realmSuffix: '/SecureAuth42/SecureAuth.aspx',
    url: ''
  },
  addPlan: {
    realmSuffix: '/SecureAuth30/Customized/AddAPlan.aspx',
    url: ''
  },
  fileCompression: {
    compressionThreshold: 333,
    compressionRatio: .7
  },
  featureFlags: {
    accountPrivacy: true,
    accountSettings: true,
    benefits: true,
    benefitBooklets: true,
    benefitsDashWidget: true,
    billPay: true,
    chat: true,
    claimsEob: true,
    claimsList: true,
    claimsSubmission: true,
    claimsSubmitDental: true,
    claimsSubmitMedical: true,
    claimsSubmitPrescription: false,
    claimsSubmitVision: true,
    claimsSubmitVisionPartOfMedical: true,
    configService: false,
    editContactDetails: false,
    eobPromoFlag: false,
    experian: true,
    findADoctor: true,
    form1095Advanced: true,
    fundingAccounts: true,
    hra: true,
    identificationCard: true,
    identificationCardOrdering: true,
    login: true,
    medicareSupplementFlag: false,
    multiReg: true,
    multiRegAddPlan: true,
    multiRegPlanSwitch: true,
    paperless1095: true,
    paperlessEob: true,
    primaryCareProvider: true,
    resetPasswordFeatureFlag: true,
    resetRecoveryEmailFeatureFlag: true,
    resetRecoveryPhoneFeatureFlag: true,
    rxCareAlerts: false,
    costEstimate: false,
    costEstimateRequest: false,
    secureChat: true,
    ssoOutboundSapphire: true,
    ssoOutboundEsi: true,
    ssoOutboundRxAdvance: true,
    ssoOutboundCyc: true,
    ssoOutboundSecureInbox: true,
    ssoOutboundTeladocFlag: true,
    tMobileFlag: true,
    wellness: true,
    defaultEobEligibility: true,    
    futureEligibilityFlag: true,    
    pcpWelcomeFlag: true,   
    planSwitchingFlag: true,
    notificationFEFlag : true,
    kinwellAdFlag: true,
    futureHmoBannerFlag: true,
    hmoWelcomePageFlag:  true,
    newVisionClaimFlag : true,
    visionClaimPartTwoEnhancement : false,
    amazonBookletFlag : true,
    newVisionServiceFlag : true,
    visionPCPSearchFlag : true

  }
};