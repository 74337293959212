export enum Site {
  aon = 'aon',
  enroll = 'enroll',
  medicare = 'medicare',
  member = 'member',
  visitor = 'visitor',
  sebb = 'sebb',
  hmo = 'hmo',
  lwwa = 'lwwa'
}

export enum ChildSite {
  none = '',
  aag = 'aag',
  amazon = 'amazon',
  weyerhaeuser = 'weyerhaeuser',
  sb = 'sb',
  sm = 'sm',
  sm_2022 = '2022',
  sm_1 = '1',
  am = 'am',
  tm = 'tm',
  tmo_openenroll = 'tm-oe',
  supplement = 'supplement',
  tmobile = 'tmobile',
  provider = 'provider',
 ['shared-admin'] = 'shared-admin'
}

export class SiteScope {
  site: Site;
  nonSecurePath: string;
  childSiteConfigs: ChildSiteConfig[];
}

export class ChildSiteConfig {
  parentPath: string;
  childSite: ChildSite;
}

export const  ChildSiteScopes: SiteScope[] = [
  {
    site: Site.visitor,
    nonSecurePath: 'visitor',
    childSiteConfigs: [
      {
        parentPath: '/sites/',
        childSite: ChildSite.aag
      },
      {
        parentPath: '/sites/',
        childSite: ChildSite.amazon
      },
      {
        parentPath: '/sites/',
        childSite: ChildSite.weyerhaeuser
      },
      {
        parentPath: '/sites/',
        childSite: ChildSite.tmobile
      },
      {
        parentPath: '/',
        childSite: ChildSite['shared-admin']
      }
    ]
  },
  {
    site: Site.aon,
    nonSecurePath: '',
    childSiteConfigs: [
      {
        parentPath: '/',
        childSite: ChildSite.sm
      },
      {
        parentPath: '/',
        childSite: ChildSite.sm_2022
      },
      {
        parentPath: '/',
        childSite: ChildSite.sm_1
      }
    ]
  },
  {
    site: Site.enroll,
    nonSecurePath: '',
    childSiteConfigs: [
      {
        parentPath: '/',
        childSite: ChildSite.am
      },
      {
        parentPath: '/',
        childSite: ChildSite.tmo_openenroll
      },
      {
        parentPath: '/',
        childSite: ChildSite.tm
      }
    ]
  },
  {
    site: Site.medicare,
    nonSecurePath: '',
    childSiteConfigs: [
      {
        parentPath: '/',
        childSite: ChildSite.supplement
      }
    ]
  },
  {
    site: Site.sebb,
    nonSecurePath: '/',
    childSiteConfigs: []
  },
  {
    site: Site.member,
    nonSecurePath: '',
    childSiteConfigs: []
  },
  {
    site: Site.hmo,
    nonSecurePath: '',
    childSiteConfigs: []
  },
  {
    site: Site.lwwa,
    nonSecurePath: '/',
    childSiteConfigs: [
      {
        parentPath: '/',
        childSite: ChildSite.provider
      }
    ]
  }
];
