import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-sidebar-nav',
  templateUrl: './sidebar-nav.component.html'
})
export class SidebarNavComponent implements ContentComponent, OnChanges {
  @Input() data: ComponentState;
  sections: any;

  constructor(private route: ActivatedRoute) { }

  ngOnChanges(): void {
    if (!this.sections && this.data && this.data.content && this.data.content.sectionMenuGroupBox) {
      this.sections = JSON.parse(this.data.content.sectionMenuGroupBox);
      this.sections.forEach(menuItem => {
        menuItem.sectionMenuGroupBox.linksGroupBox = Array.isArray(menuItem.sectionMenuGroupBox.linksGroupBox)
          ? menuItem.sectionMenuGroupBox.linksGroupBox
          : this.linksToArray(menuItem.sectionMenuGroupBox.linksGroupBox);
        menuItem.sectionMenuGroupBox.linksGroupBox = menuItem.sectionMenuGroupBox.linksGroupBox.filter(link => link.enabled === 'true');
      });
    }
  }

  routerLinkActive(route: string): string {
    if (!this.route || !this.route.snapshot || !this.route.snapshot.routeConfig || !route) {
      return undefined;
    }

    if (this.route.snapshot.routeConfig.path === route.replace(/^\/*(.+[^\/])\/?$/, '$1')) {
      return 'page';
    }

    return undefined;
  }

  private linksToArray(input: object): Array<object> {
    const linksArray = Array<object>();
    linksArray.push(input);
    return linksArray;
  }
}

