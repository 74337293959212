import { BaseMemberRequest, Member } from 'libs/models/member.models';

export enum IdCardSide {
  Front = 'Front',
  Back = 'Back'
}

export class IdCardImageResult {
  content: string;
}

export class IdCardImageResponse {
  isSuccessful: string;
  front: string;
  back: string;
}

export class BaseMemberIdCardRequest extends BaseMemberRequest {
  targetMemberKey: string;
  constructor(requestorMemberKey: string, targetMemberKey: string) {
    super(requestorMemberKey);
    this.targetMemberKey = targetMemberKey;
  }
}

export class MemberIdCardRequest extends BaseMemberIdCardRequest {
  targetMemberKey: string;
  side: string;
  constructor(requestorMemberKey: string, targetMemberKey: string, side: string) {
    super(requestorMemberKey, targetMemberKey);
    this.side = side;
  }
}

export class MemberIdCardImageRequest {
  id: string;
  targetKey: string;
  memberKey: string;
 
  constructor(id: string, memberKey: string, targetKey: string) {
    this.id = id;
    this.memberKey = memberKey;
    this.targetKey = targetKey;
  }
}

export class MemberCardOrderRequest extends BaseMemberIdCardRequest {
  constructor(requestorMemberKey: string, targetMemberKey: string) {
    super(requestorMemberKey, targetMemberKey);
  }
}

export class FallbackIdCardRequest extends BaseMemberIdCardRequest {
  planEffectiveDate: Date;
  constructor(requestorMemberKey: string, targetMemberKey: string, planEffectiveDate: Date) {
    super(requestorMemberKey, targetMemberKey);
    this.planEffectiveDate = planEffectiveDate;
  }
}

export class MemberIdCardOrderResponse {
}

export class SurrogateIdRequest {
}

export class SurrogateIdResponse {
  surrogateId: string;
}

export class FallbackIdCard {
  firstName: string;
  lastName: string;
  cardId: string;
  prefix: string;
  suffix: string;
  medicalNetwork: string;
  dentalNetwork: string;
  prescription: string;
  groupNum: string;
  rxGroup: string;
  emergencyCopay: string;
  rxRetail: string;
  mailOrderCopay: string;
  officeVisitCopay: string;
  bin: string;
  specialistCopay: string;
  urgentCareCopay: string;
  rxPlan: string;
  plans: Plans[];
}

export class Plans{
  etlPlanKey: number;
  productId: string;
  classId: string;
  planId: string;
  type: string;
  name: string;
  eligibilityStart: string;
  eligibilityEnd: string;
  startDate: string;
  termDate: string;
  accumulatedFamilyUsages : AccumulatedUsages[];
  accumulatedIndividualUsages: AccumulatedUsages[];
  individualBenefitUsages: string;
  networks: Networks[];
  isDummy: boolean;
}

export class AccumulatedUsages{
  accumNum: number;
  networkType: string;
  benefitType: string;
  benefitName: string;
  limitUsed: number;
  deductibleUsed: number;
  limitMax: number;
  deductibleMax: number;
  periodType: string;
  isSensitive: boolean;
  benefitTier: string;
}

export class Networks{
  id: string;
  prefix: string;
  participationType: string;
}

export class FamilyMember extends Member {
  // used on ux only
  checked: boolean;
}

export enum AuthenticationType {
  directLogin,
  planSwitch
}

export class HasCreditMonitoringRequest extends BaseMemberRequest {

  constructor(requestorMemberKey: string) {
    super(requestorMemberKey);
  }
}


