<div *ngIf="data?.isVisible" data-id="icon-columns">
  <div [ngStyle]="{'text-align': data?.content.headerStyle}">
    <h2 *ngIf="data?.content.sectionHeader" [innerHTML]="data?.content.sectionHeader | safehtml"
      data-id="icon-columns-section-header"></h2>
    <div *ngIf="data?.content.introduction" [innerHTML]="data?.content.introduction | safehtml"
      data-id="icon-columns-section-intro"></div>
  </div>

  <div class="row no-gutters">
    <ng-container *ngFor="let section of getSections()| groupBy: 'column'; last as isLast">
      <div class="col-12 col-sm" [ngClass]="{'mr-sm-3': !isLast}">
        <div *ngFor="let icon of section.value">
          <div class="row no-gutters mb-3">
            <div class="col-auto pr-3">
              <i *ngIf="data?.content?.useFontIcon && icon?.iconStyle" [class]="icon?.iconStyle" aria-hidden="true"></i>
              <img class="img-icon" *ngIf="!data?.content?.useFontIcon && icon?.iconPath" alt=""
                [src]="icon?.iconPath | urlQualify" aria-hidden="true">
            </div>
            <div class="col">
              <h2 class="h3" *ngIf="icon.iconHeader && !data?.content.sectionHeader" [innerHTML]="icon.iconHeader"
                data-id="icon-columns-h2"></h2>
              <h3 *ngIf="icon.iconHeader && data?.content.sectionHeader" [innerHTML]="icon.iconHeader"
                data-id="icon-columns-h3"></h3>
              <div [innerHTML]="icon.iconText | decodeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>