import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AuthenticationService } from 'libs/services/authentication.service';
import { ContentService } from 'libs/services/content.service';
import { DataComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-footer-logo',
  templateUrl: './footer-logo.component.html',
})
export class FooterLogoComponent implements OnInit, OnDestroy {
  @Input() footerContent: DataComponentState;
  @Input() hideRegionSwitch: boolean;
  @Input() homePageUrl: string;
  @Input() logoImgUrl: string;

  isAuthenticated: boolean;
  isChildSite: boolean;
  componentDestroyed$: Subject<boolean> = new Subject();
  hideRegionSwitchLink: boolean;

  constructor(private readonly authService: AuthenticationService,
    private readonly contentService: ContentService) {
  }

  ngOnInit() {
    this.authService.getIsAuthenticated().pipe(takeUntil(this.componentDestroyed$))
      .subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;
      });

    if (!this.homePageUrl) {
      this.authService.defaultHomePage.pipe(takeUntil(this.componentDestroyed$)).subscribe(link => {
        this.homePageUrl = link;
      });
    }

    if (!this.logoImgUrl) {
      this.contentService.region$.pipe(takeUntil(this.componentDestroyed$)).subscribe(region => {
        this.logoImgUrl = this.contentService.getLogoImageUrl();
      });
    }

    this.hideRegionSwitchLink = (this.hideRegionSwitch === null || this.hideRegionSwitch === undefined) ? true : this.hideRegionSwitch;
  }

  switchLob() {
    this.contentService.switchRegion();
    console.log(`Switch: Switched region to ${this.contentService.region$.value}`);
    const redirectUrl = `${this.homePageUrl}?region=${this.contentService.region$.value}`;
    console.log(`Switch: Redirecting to ${redirectUrl}`);
    window.location.href = redirectUrl;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
