<section>
  <div class="row my-lg-5 py-lg-5 my-3 py-3 justify-content-md-center">
    <div class="col-lg-8 text-center">
      <div class="h1 centered" aria-hidden="true" tabindex="-1">
        {{ strings.data.value?.ERROR_STATE_TITLE_500 || 'We\'re sorry.' }}
      </div>
      <h1 class="sr-only" tabindex="-1">
        {{ strings.data.value?.ERROR_STATE_TITLE_500 || 'We\'re sorry.' }}
      </h1>
      <p>
        {{ strings.data.value?.ERROR_STATE_BODY_500 || 'Something went wrong. Please try again.' }}
      </p>
      <div *ngIf="userSession">
        <p>{{ strings.data.value?.ERROR_STATE_BODY_SESSION_500 || 'Session ID:' }} {{ userSession }}</p>
      </div>
    </div>
  </div>
</section>
