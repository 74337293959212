import { AfterViewInit, Directive, ElementRef, HostBinding, Input } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'img[pbcLazyLoadImg]'
})
export class LazyLoadImgDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = 'assets/image/loader.gif';
  @HostBinding('attr.title') srcTitle = ' ';
  @HostBinding('attr.aria-label') srcAria = ' ';
  @Input() src: string;

  constructor(private readonly el: ElementRef) {}

  ngAfterViewInit() {
    this.canLazyLoad() ? this.lazyLoadImage() : setTimeout(() => { this.loadImage() }, 100);
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage() {
    this.srcAttr = this.src;
  }
}
