<h1 tabindex="-1">Premera sign in</h1>
<div class="cls-nav-tabs">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
    <li title="Member" id="tab-0" [ngbNavItem]="1">
      <a ngbNavLink>Member</a>
      <ng-template ngbNavContent>
        <div class="mx-3">
          <pbc-ngb-accordion activeIds="{{activeAccordionId}}">
            <pbc-ngb-panel title="I have a Premera plan through my employer or another group." id="member-acc-1">
              <ng-template pbcNgbPanelContent>
                <p><a pbcAnalyticsTag (click)="signIn()" href onclick="return false;" class="btn btn-tertiary ml-3">Sign in or create account</a></p>
              </ng-template>
            </pbc-ngb-panel>
            <pbc-ngb-panel title="I have an individual or family plan that I purchased independently." id="member-acc-2">
              <ng-template pbcNgbPanelContent>
                <p *ngIf="location !=='ak'"><a pbcAnalyticsTag href="https://pmwaportal.valence.care/" class="btn btn-tertiary ml-3">Sign in or create account</a></p>
                <p *ngIf="location ==='ak'"><a pbcAnalyticsTag href="https://pmakportal.valence.care/" class="btn btn-tertiary ml-3">Sign in or create account</a></p>
              </ng-template>
            </pbc-ngb-panel>
            <pbc-ngb-panel title="I have a Medicare plan." id="member-acc-3">
              <ng-template pbcNgbPanelContent>
                <p><a pbcAnalyticsTag (click)="signIn()" href onclick="return false;" class="btn btn-tertiary ml-3">Medicare Supplemental</a></p>
                <p *ngIf="location !=='ak'"><a pbcAnalyticsTag href="https://secure.healthx.com/premera.member" class="btn btn-tertiary ml-3">Medicare Advantage</a></p>
              </ng-template>
            </pbc-ngb-panel>
          </pbc-ngb-accordion>
        </div>
      </ng-template>
    </li>
    <li title="Employer" id="tab-1" [ngbNavItem]="2">
      <a ngbNavLink>Employer</a>
      <ng-template ngbNavContent>
        <div class="px-3">
          <p><a pbcAnalyticsTag href="https://www.premera.com/portals/employerPortal/account/login/ak" class="btn btn-tertiary">Alaska employer</a></p>
          <p><a pbcAnalyticsTag href="https://www.premera.com/portals/employerPortal/account/login/wa" class="btn btn-tertiary">Washington employer</a></p>
        </div>
      </ng-template>
    </li>
    <li title="Provider"  id="tab-2" [ngbNavItem]="3">
      <a ngbNavLink>Provider</a>
      <ng-template ngbNavContent>
        <div class="mx-3">
          <pbc-ngb-accordion activeIds="{{activeAccordionId}}">
            <pbc-ngb-panel title="Service for an Individual Plan patient" id="provider-acc-1">
              <ng-template pbcNgbPanelContent>
                <div class="ml-3">
                  <p>Service for Individual Plan patients (their insurance card contains the phrase Individual Plan)
                  </p>                  
                  <div>
                    <p *ngIf="location !=='ak'"><a pbcAnalyticsTag href="https://identity.onehealthport.com/EmpowerIDWebIdPForms/Login/PREMERA_WA_EVOLENT" target="_blank" class="btn btn-tertiary">Sign in to Evolent</a></p>
                    <p *ngIf="location ==='ak'"><a pbcAnalyticsTag href="https://identity.onehealthport.com/EmpowerIDWebIdPForms/Login/PREMERA_AK_EVOLENT" target="_blank" class="btn btn-tertiary">Sign in to Evolent</a></p>
                  </div>
                </div>
              </ng-template>
            </pbc-ngb-panel> 
            <pbc-ngb-panel title="Service for Medicare patients" id="provider-acc-2">
              <ng-template pbcNgbPanelContent>
                <p><a pbcAnalyticsTag href="https://apps.availity.com/web/onboarding/availity-fr-ui/#/login" target="_blank" class="btn btn-tertiary ml-3">Medicare Supplemental</a></p>
                <p *ngIf="location !== 'ak'"><a pbcAnalyticsTag href="https://www.onehealthport.com/sso-payer/premera-blue-cross-medicare-advantage" class="btn btn-tertiary ml-3">Medicare Advantage</a></p>
              </ng-template>
            </pbc-ngb-panel>
            <pbc-ngb-panel title="Service for all other patients" id="provider-acc-3">
              <ng-template pbcNgbPanelContent>
                <div class="ml-3">
                  <p>This website supports members who are insured as part of larger groups, like employees, tribal members, association members and others.</p>
                  <p>This website won’t support services for Individual Plan or Medicare Advantage patients.</p>
                  <p><a pbcAnalyticsTag href="https://apps.availity.com/web/onboarding/availity-fr-ui/#/login" target="_blank" class="btn btn-tertiary">Any other Premera plan patient</a></p>
                </div>
              </ng-template>
            </pbc-ngb-panel>
          </pbc-ngb-accordion>
        </div>
      </ng-template>
    </li>
    <li title="Producer" id="tab-3" [ngbNavItem]="4">
      <a ngbNavLink>Producer</a>
      <ng-template ngbNavContent>
        <div class="px-3">
          <p *ngIf="location !=='ak'"><a pbcAnalyticsTag href="https://www.premera.com/portals/producer/dashboard/index" class="btn btn-tertiary">Manage my Premera business</a></p>
          <p *ngIf="location ==='ak'"><a pbcAnalyticsTag href="https://www.premera.com/portals/producer/ak/dashboard/index" class="btn btn-tertiary">Manage my Premera business</a></p>
        </div>
      </ng-template>
    </li>
  </ul> 
<div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>