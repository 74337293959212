<div class="notify_style" [ngClass]="{'empty-notify' : notificationDetails.length ==0}">
  <div class="popover-header">
    <div class="row hearder-over bg ml-0">
      <div>
        <h5>
          <span class="notify-header">{{ notificationCenterContent?.content?.notificationHeader }}</span>
          <span class="rectangle-text text-msg pl-2 pr-2 pt-1 pb-1">{{message}}</span>
        </h5>
      </div>
      <div>
        <button pbcAnalyticsTag aria-label="close-notification" class="notify-close" type="button" (click)="closePopover()">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div id="impersonationMessage" class="alert alert-warning" *ngIf="blockAction">
    <p class="body-smaller text-left mb-0"><i aria-hidden="true" class="material-icons">warning</i>
      {{notificationCenterContent?.content?.impersonateMsg}}</p>
  </div>
  <div class="notification-scroll" *ngIf="!notifyCheck">
    <div class="card" *ngFor="let notify of notificationDetails; let i = index;">
      <div class="card-body border-dark-notify pl-4 pr-4 "
        [ngClass]="!notify.read ? 'background-info': 'notification-background-normal' ">
        <div class="ml-1" (click)="readNotificationChange(i,notify)">
          <div *ngIf="!notify.read; then readNotification else unreadNotification"></div>
        </div>
      </div>
      <ng-template #readNotification>
        <div class="row">
          <div class="col-4 pl-0">
            <i class="fas fa-circle mr-2 text-accent fs-16"></i>
            <strong class="fs-16"><b>{{notify.dateTime | date:
                'MM/dd/yyyy'}}</b></strong>
          </div>
          <div class="col-5 text-accent notify-name-left fs-16"><strong><b>{{notify.recipient.firstName | uppercase}}
                {{notify.recipient.lastName | uppercase}}</b></strong></div>
          <div class="col-2">
            <button aria-label="delete-notification"   pbcAnalyticsTag data-analytics-title="delete" data-analytics-override-route="notification" class="text-accent btn pt-1 notify-delete" (click)="deleteNotification(i,notify)">{{
              notificationCenterContent?.content?.delete }}</button>
          </div>
        </div>
        <div class="row">
          <div class="auto-message">{{notify.message[0].content}}</div>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'MEMBERPCPCHANGE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewMemberPCPChange(notify)"  aria-label="Visit PCP for more information"><b>
						{{ notificationCenterContent?.content?.viewPCPselections }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'PRIORAUTHCREATE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewPriorAuth(notify)" aria-label="Visit Prior Auth for more information"><b>
						{{ notificationCenterContent?.content?.viewpreapprovals }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'REFERRALCREATE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewReferral(notify)" aria-label="Visit referral for more information"><b>
						{{ notificationCenterContent?.content?.viewreferrals }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'KINWELLCONNECTWELCOMEPPO'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewKinwellConnect(notify)" 
          pbcAnalyticsTag data-analytics-title="ppo-web-cta-kinwellbookappt" 
          data-analytics-override-route="notification" aria-label="Visit kinwell for more information"><b>
						{{ notificationCenterContent?.content?.viewKinwellConnectPpo }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'KINWELLCONNECTWELCOMEHDHP'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewKinwellConnect(notify)" 
          pbcAnalyticsTag data-analytics-title="hdhp-web-cta-kinwellbookappt" 
          data-analytics-override-route="notification" aria-label="Visit kinwell for more information"><b>
						{{ notificationCenterContent?.content?.viewKinwellConnectHdhp }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'HMOENROLLMENTWELCOME'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewHMOWelcome(notify)" pbcAnalyticsTag data-analytics-title="welcome HMO Unread link"  
           data-analytics-override-route="notification" aria-label="Visit HMO for more information"><b>
						{{ notificationCenterContent?.content?.viewWelcomeHMO}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMMEMBERSUBMITTED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPROVIDERSUBMITTED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPENDING'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPROCESSED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
      </ng-template>
      <ng-template #unreadNotification>
        <div class="row">
          <div class="col-4 pl-0 unread-notify-date">{{notify.dateTime | date: 'MM/dd/yyyy'}}</div>
          <div class="col-5 text-accent fs-16 notify-name-left">{{notify.recipient.firstName | uppercase}}
            {{notify.recipient.lastName |
            uppercase}}</div>
          <div class="col-2">
            <button aria-label="delete-notification" pbcAnalyticsTag data-analytics-title="notification-delete" data-analytics-override-route="notification" class="text-accent btn pt-1 notify-delete" (click)="deleteNotification(i,notify)">{{
              notificationCenterContent?.content?.delete }}</button>
          </div>
        </div>
        <div class="row">
          <div class="auto-message-read">{{notify.message[0].content}}</div>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'MEMBERPCPCHANGE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewMemberPCPChange(notify)" aria-label="Visit PCP for more information"><b>
						{{ notificationCenterContent?.content?.viewPCPselections }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'PRIORAUTHCREATE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewPriorAuth(notify)" aria-label="Visit Prior Auth for more information"><b>
						{{ notificationCenterContent?.content?.viewpreapprovals }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'REFERRALCREATE'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewReferral(notify)" aria-label="Visit referral for more information"><b>
						{{ notificationCenterContent?.content?.viewreferrals }}</b></a>
        </div>
				<div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'KINWELLCONNECTWELCOMEPPO'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewKinwellConnect(notify)" 
          pbcAnalyticsTag data-analytics-title="ppo-web-cta-kinwellbookappt"
           data-analytics-override-route="notification" aria-label="Visit kinwell for more information"><b>
						{{ notificationCenterContent?.content?.viewKinwellConnectPpo }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'KINWELLCONNECTWELCOMEHDHP'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewKinwellConnect(notify)" 
          pbcAnalyticsTag data-analytics-title="hdhp-web-cta-kinwellbookappt" 
          data-analytics-override-route="notification" aria-label="Visit kinwell for more information"><b>
						{{ notificationCenterContent?.content?.viewKinwellConnectHdhp }}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'HMOENROLLMENTWELCOME'">
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewHMOWelcome(notify)" pbcAnalyticsTag data-analytics-title="welcome HMO read link" 
          data-analytics-override-route="notification" aria-label="Visit HMO for more information"><b>
						{{ notificationCenterContent?.content?.viewWelcomeHMO}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMMEMBERSUBMITTED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPROVIDERSUBMITTED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPENDING'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
        <div class="row pt-2" *ngIf="(notify.notificationType | uppercase) === 'CLAIMPROCESSED'">
          <label for="claimssubmitted" class="visually-hidden">{{ notificationCenterContent?.content?.viewAllClaims}} </label>
          <a class="btnhyperlink-tertiary" routerLink="" (click)="onViewManageClaims(notify)" aria-label="Visit view all claims for more information"><b>
						{{ notificationCenterContent?.content?.viewAllClaims}}</b></a>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="notifyCheck">
    <div id="notifyNullMessage" class="card notify_nullbg">
      <span class="notify_nullMsg pt-2">{{ notificationCenterContent?.content?.notifyNullMsg }}</span>
    </div>
  </div>
  <div aria-live="assertive" *ngIf="hasError">
    <pbc-error-data-block id="errorBlock" [showSessionId]="true"></pbc-error-data-block>
  </div>
</div>
