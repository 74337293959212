import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InternalServerErrorComponent } from './internal-server-error/internal-server-error.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    InternalServerErrorComponent,
    PageNotFoundComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ErrorsModule { }
