import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { LoggingService } from './logging.service';
import { ContentService } from './content.service';
import { TrapFocusService } from './trap-focus.service';
import { MemberSharedService } from './member-shared.service';
import { HelperService } from './helper.service';
import { AlertService } from './alert.service';
import { LoaderService } from './loader.service';
import { ScrollService } from './scroll.service';
import { StringsService } from './strings.service';
import { UserAgentService } from './user-agent.service';
import { AuthenticationService } from './authentication.service';
import { FeatureFlagGuard } from './feature-flag.service';
import { SurrogateIdService } from './surrogate-id.service';
import { UrlBuilderService } from './url-builder.service';
import { LayoutHelper } from './layout-helper';
import { StateGuard } from './state-guard.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    LoggingService,
    ContentService,
    TrapFocusService,
    ScrollService,
    StringsService,
    AlertService,
    LoaderService,
    UserAgentService,
    HelperService,
    MemberSharedService,
    AuthenticationService,
    FeatureFlagGuard,
    SurrogateIdService,
    UrlBuilderService,
    LayoutHelper,
    StateGuard
  ],
})
export class ServicesModule { }
