import { Component, OnInit, Input, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ComponentItem } from 'libs/models/pbc-models';

export interface TemplateComponentComponent {
  data: any;
}

@Component({
  selector: 'pbc-component-renderer',
  template: '<ng-template></ng-template>'
})
export class ComponentRendererComponent implements OnInit {
  @Input() component: ComponentItem;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, public viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    if (!this.component) {
      return;
    }
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component.component);
    this.viewContainerRef.clear();
    const componentRef = this.viewContainerRef.createComponent(componentFactory);
    ( componentRef.instance as TemplateComponentComponent).data = this.component.data;
  }
}
