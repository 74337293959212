import { CommonModule, CurrencyPipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ClassesModule } from 'libs/classes/classes.module';
import { DirectivesModule } from 'libs/directives/directives.module';
import { PipesModule } from 'libs/pipes/pipes.module';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { StringsService } from 'libs/services/strings.service';
import { AlertComponent } from './alert/alert.component';
import { DashComponent } from './dash/dash.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { ErrorDataBlockComponent } from './error-data-block/error-data-block.component';
import { ExpandableRowComponent } from './expandable-row/expandable-row.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FindADoctorComponent } from './find-care/find-a-doctor.component';
import { FooterBottomComponent } from './footer/footer-bottom/footer-bottom.component';
import { FooterLogoComponent } from './footer/footer-logo/footer-logo.component';
import { FooterSocialMediaComponent } from './footer/footer-social-media/footer-social-media.component';
import { FooterTopComponent } from './footer/footer-top/footer-top.component';
import { FooterTopMobileComponent } from './footer/footer-top/mobile/footer-top-mobile.component';
import { FooterComponent } from './footer/footer.component';
import { AudienceNavComponent } from './header/audience-nav/audience-nav.component';
import { HeaderComponent } from './header/header.component';
import { PrimaryNavComponent } from './header/primary-nav/primary-nav.component';
import { SecondaryNavComponent } from './header/secondary-nav/secondary-nav.component';
import { HtmlBlockComponent } from './html-block/html-block.component';
import { CardComponent } from './card/card.component';
import { CalloutComponent } from './callout/callout.component';
import { CalloutImageComponent } from './callout-image/callout-image.component';
import { IconCardComponent } from './icon-card/icon-card.component';
import { IconColumnsComponent } from './icon-columns/icon-columns.component';
import { ImageHeroComponent } from './image-hero/image-hero.component';
import { LoaderComponent } from './loader/loader.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { PopoverContainerComponent } from './popover-container/popover-container.component';
import { SelectComponent } from './select/select.component';
import { StepperRowComponent } from './stepper-row/stepper-row.component';
import { SvgDonutComponent } from './svg-donut/svg-donut.component';
import { ContentHelperMethods } from './content-helper';
import { AccordionComponent } from './accordion/accordion.component';
import { CtaComponent } from './cta/cta.component';
import { MarketoFormComponent } from 'libs/components/marketo-form/marketo-form.component';
import { MarketoLaunchComponent } from './marketo-launch/marketo-launch.component';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { ModalComponent } from 'libs/components/modal/modal.component';
import { ExternalTargetPipe } from 'libs/pipes/external-target.pipe';
import { SafeExternalTargetPipe } from 'libs/pipes/safe-external-target.pipe';
import { TwoPackComponent } from './two-pack/two-pack.component';
import { PromoComponent } from './promo/promo.component';
import { PullQuoteComponent } from './pull-quote/pull-quote.component';
import { StatementBorderComponent } from './statement-border/statement-border.component';
import { TwoPackVerticalComponent } from './two-pack-vertical/two-pack-vertical.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { CtaPackComponent } from './cta-pack/cta-pack.component';
import { PbcNgbAccordion } from './pbc-ngb-accordion/pbc-accordion';
import { ComponentRendererComponent } from './component-renderer/component-renderer.component';
import { FooterMobileAppsComponent } from './footer/footer-top/footer-mobile-apps/footer-mobile-apps.component';
import { FooterNavHorizComponent } from './footer/footer-nav-horiz/footer-nav-horiz.component';
import { FooterNavColsComponent } from './footer/footer-nav-cols/footer-nav-cols.component';
import { XmlDataTableComponent } from './xml-data-table/xml-data-table.component';
import { PriorAuthDrugsComponent } from './prior-auth-drugs/prior-auth-drugs.component';
import { CardRowComponent } from './card-row/card-row.component';
import { GroupByPipe } from 'libs/pipes/group-by.pipe';
import { FindMedicareRepComponent } from './find-medicare-rep/find-medicare-rep.component';
import { SafeHtmlPipe } from 'libs/pipes/safe-html.pipe';
import { UrlQualifyPipe } from 'libs/pipes/url-qualify.pipe';
import { DecodeHtmlPipe } from 'libs/pipes/decode-html.pipe';
import { FindCareSelectComponent } from './find-care-select/find-care-select.component';
import { CarouselComponent } from './carousel/carousel.component';
import { ImageBlockBannerComponent } from './image-block-banner/image-block-banner.component';
import { ToggleContainerComponent } from './toggle-container/toggle-container.component';
import { NotificationCenterComponent } from 'libs/components/notification-center/notification-center.component';
import { TwoPackPlanComponent } from './two-pack-plan/two-pack-plan.component';
import { TwoPackKinwellComponent } from './two-pack-kinwell/two-pack-kinwell.component';
import { OverlayHeroComponent } from './overlay-hero/overlay-hero.component';
import { OverlayImageComponent } from './overlay-image/overlay-image.component';
import { OverlayImageSecondaryComponent } from './overlay-image-secondary/overlay-image-secondary.component';
import { TabSetComponent } from './tab-set/tab-set.component';
import { CardRowsComponent } from './card-rows/card-rows.component';
import { StepperHorizontalComponent } from './stepper-horizontal/stepper-horizontal.component';
import { PaymentPoliciesSearchComponent } from './payment-policies-search/payment-policies-search.component';
import { MedicalPoliciesSearchComponent} from  'libs/components/medical-policies-search/medical-policies-search.component';
import { MedicalPoliciesRecentUpdatesComponent } from './medical-policies-recent-updates/medical-policies-recent-updates.component';

@NgModule({
  declarations: [
    AccordionComponent,
    AlertComponent,
    AudienceNavComponent,
    CalloutComponent,
    CalloutImageComponent,
    CardComponent,
    CardRowComponent,
    ComponentRendererComponent,
    CtaComponent,
    CtaPackComponent,
    DashComponent,
    DatePickerComponent,
    ErrorDataBlockComponent,
    ExpandableRowComponent,
    FileUploadComponent,
    FindADoctorComponent,
    FooterBottomComponent,
    FooterComponent,
    FooterLogoComponent,
    FooterTopComponent,
    FooterTopMobileComponent,
    FooterSocialMediaComponent,
    HeaderComponent,
    HtmlBlockComponent,
    IconCardComponent,
    IconColumnsComponent,
    ImageHeroComponent,
    LoaderComponent,
    MarketoFormComponent,
    MarketoLaunchComponent,
    ModalComponent,
    PbcNgbAccordion,
    PlaceholderComponent,
    PopoverContainerComponent,
    PrimaryNavComponent,
    PromoComponent,
    PullQuoteComponent,
    SecondaryNavComponent,
    SelectComponent,
    SidebarNavComponent,
    StatementBorderComponent,
    StepperRowComponent,
    SvgDonutComponent,
    TwoPackComponent,
    TwoPackVerticalComponent,
    ImageHeroComponent,
    FooterMobileAppsComponent,
    FooterNavHorizComponent,
    FooterNavColsComponent,
    XmlDataTableComponent,
    PriorAuthDrugsComponent,
    CalloutComponent,
    CalloutImageComponent,
    FindMedicareRepComponent,
    FindCareSelectComponent,
    CarouselComponent,
    ImageBlockBannerComponent,
    ToggleContainerComponent,
    NotificationCenterComponent,
    TwoPackPlanComponent,
    TwoPackKinwellComponent,
    OverlayHeroComponent,
    TabSetComponent,
    OverlayImageComponent,
    OverlayImageSecondaryComponent,
    CardRowsComponent,
    StepperHorizontalComponent,
    MedicalPoliciesSearchComponent,
    PaymentPoliciesSearchComponent,
    MedicalPoliciesRecentUpdatesComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    NgbModule,
    PipesModule,
    DirectivesModule,
    ReactiveFormsModule,
    ClassesModule
  ],
  providers: [
    FeatureFlagGuard,
    EnvironmentService,
    StringsService,
    ContentHelperMethods,
    MemberSharedService,
    GroupByPipe,
    ExternalTargetPipe,
    SafeExternalTargetPipe,
    SafeHtmlPipe,
    DecodeHtmlPipe,
    UrlQualifyPipe,
    CurrencyPipe
  ],
  exports: [
    AccordionComponent,
    AlertComponent,
    AudienceNavComponent,
    CalloutComponent,
    CalloutImageComponent,
    CardComponent,
    CardRowComponent,
    CarouselComponent,
    ComponentRendererComponent,
    CtaComponent,
    DashComponent,
    DatePickerComponent,
    ErrorDataBlockComponent,
    ExpandableRowComponent,
    FileUploadComponent,
    FindADoctorComponent,
    FooterBottomComponent,
    FooterComponent,
    FooterTopComponent,
    FooterTopMobileComponent,
    HeaderComponent,
    HtmlBlockComponent,
    IconCardComponent,
    IconColumnsComponent,
    ImageBlockBannerComponent,
    ImageHeroComponent,
    LoaderComponent,
    MarketoFormComponent,
    MarketoLaunchComponent,
    PbcNgbAccordion,
    PlaceholderComponent,
    PopoverContainerComponent,
    PrimaryNavComponent,
    PriorAuthDrugsComponent,
    PromoComponent,
    PullQuoteComponent,
    SecondaryNavComponent,
    SelectComponent,
    SidebarNavComponent,
    StatementBorderComponent,
    StepperRowComponent,
    SvgDonutComponent,
    TwoPackComponent,
    TwoPackVerticalComponent,
    XmlDataTableComponent,
    FindMedicareRepComponent,
    ToggleContainerComponent,
    TwoPackKinwellComponent,
    OverlayHeroComponent,
    TabSetComponent,
    StepperHorizontalComponent,
    MedicalPoliciesSearchComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    AccordionComponent,
    AlertComponent,
    CardComponent,
    CarouselComponent,
    HtmlBlockComponent,
    IconCardComponent,
    IconColumnsComponent,
    ImageBlockBannerComponent,
    ImageHeroComponent,
    ModalComponent,
    PriorAuthDrugsComponent,
    PromoComponent,
    PullQuoteComponent,
    SidebarNavComponent,
    StatementBorderComponent,
    TwoPackComponent,
    TwoPackVerticalComponent,
    XmlDataTableComponent,
    FindMedicareRepComponent,
    OverlayHeroComponent,
    TabSetComponent
  ]
})
export class ComponentsModule { }
