import { Component, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { LayoutHelper } from 'libs/services/layout-helper';
import { BaseFlexTemplate } from '../base-flex-template';

@Component({
  templateUrl: './flexible.component.html'
})

export class FlexibleTemplateComponent extends BaseFlexTemplate {

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,
    public layoutHelper: LayoutHelper,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(tabTitle, activatedRoute, layoutHelper, componentFactoryResolver);
  }

}
