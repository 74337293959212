import { ComponentState, ContentLabel, ContentLabels } from 'libs/models/pbc-models';
import { DecodeHtmlPipe } from 'libs/pipes/decode-html.pipe';

export interface ContentComponent {
  data: ComponentState;
}

export class ContentHelperMethods {
  public static isAlignedRight(data: ComponentState): boolean {
    if (data && data.content && data.content.alignment) {
      return data.content.alignment.toLowerCase() === 'right';
    }
    if (data && data.content && data.content.imageAlignment) {
      return data.content.imageAlignment.toLowerCase() === 'right';
    }
    return false;
  }

  public static isLarge(data: ComponentState): boolean {
    if (data && data.content && data.content.heroSize) {
      return data.content.heroSize.toLowerCase() === 'large';
    }

    if (data && data.content && data.content.size) {
      return data.content.size.toLowerCase() === 'large';
    }

    return false;
  }

  public static getContentArray(data: string): ContentLabels {
    const decodePipe = new DecodeHtmlPipe();
    const result: ContentLabels = {};
    if (!data) {
      return result;
    }
    const arr = JSON.parse(data);
    if (!Array.isArray(arr)) {
      return result;
    }
    for (const item of arr) {
      if (!!item.dataLabel && !result.hasOwnProperty(item.dataLabel)) {
        result[item.dataLabel] = new ContentLabel(item.dataValue, decodePipe.transform(item.dataValueHtml), item.richText);
      }
    }
    return result;
  }

}
