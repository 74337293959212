import { Meta, Title } from '@angular/platform-browser';
import { OnInit, Injector, OnDestroy, Component, Inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { ComponentState, AlertMessage } from 'libs/models/pbc-models';
import { AlertType } from 'libs/models/pbc-enums';
import { MemberDetails } from 'libs/models/member.models';
import { ContentService } from 'libs/services/content.service';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { AlertService } from 'libs/services/alert.service';
import { StringsService } from 'libs/services/strings.service';
import { DecodeHtmlPipe } from 'libs/pipes/decode-html.pipe';
import { PageTitleInfo } from 'member/shared/models/page.models';

@Component({
  template: ''
})

export class BaseContentPage implements OnInit, OnDestroy {
  static injector: Injector;
  private readonly meta: Meta;
  protected readonly router: Router;
  protected readonly logger: LoggingService;
  public readonly contentService: ContentService;
  private readonly decodePipe: DecodeHtmlPipe;
  private readonly alertService: AlertService;
  public readonly constants: StringsService;

  localAlert: ComponentState;
  popUpAlert: AlertMessage;
  pageTitle = '';
  pageInfo: ComponentState;
  pageInfoObservable: Subject<ComponentState> = new Subject();
  contentCache: { [key: string]: ComponentState } = {};
  pageTitleInfo: PageTitleInfo = new PageTitleInfo();

  memberDetailsObservable: BehaviorSubject<MemberDetails> = new BehaviorSubject(null);
  checkedForBlockedPopUp: BehaviorSubject<boolean> = new BehaviorSubject(false);

  componentDestroyed$: Subject<boolean> = new Subject();

  static isAnyContentVisible(contentStore: any): boolean {
    let result = false;
    for (const key in contentStore) {
      if (contentStore.hasOwnProperty(key) && BaseContentPage.isContentVisible(contentStore[key])) {
        result = true;
        break;
      }
    }
    return result;
  }
  private static isContentVisible(component: ComponentState) {
    return !!component && !!component.content && component.isVisible;
  }
  static isAlertVisible(component: ComponentState) {
    return BaseContentPage.isContentVisible(component) && !!component.content.message;
  }

  /**
   * @param shouldErrorOnEmptyContent Page is heavily dependent upon content, therefore it should error when content is unavailable
   */
  constructor(
    @Inject(String) public shouldErrorOnEmptyContent: boolean,
    protected readonly tabTitle: Title,
    public readonly activatedRoute: ActivatedRoute,
    protected readonly baseMemberService?: MemberSharedService
  ) {
    this.meta = BaseContentPage.injector.get(Meta);
    this.contentService = BaseContentPage.injector.get(ContentService);
    this.decodePipe = BaseContentPage.injector.get(DecodeHtmlPipe);
    this.router = BaseContentPage.injector.get(Router);
    this.logger = BaseContentPage.injector.get(LoggingService);
    this.alertService = BaseContentPage.injector.get(AlertService);
    this.constants = BaseContentPage.injector.get(StringsService);
  }

  ngOnInit() {
    this.getPageContent();
    this.getMemberDetail();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getPageContent(): void {
    const pageData = this.activatedRoute.snapshot.data;
    if (pageData && !!pageData['page']) {
      this.contentService.getPageContent(pageData['page'], pageData['contentGroup'])
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(response => {
          if (!!response.pageContent && Object.keys(response.pageContent).length !== 0) {
            this.contentCache = response.pageContent;
          }
          if (this.shouldErrorOnEmptyContent && !BaseContentPage.isAnyContentVisible(this.contentCache)) {
            console.log(`Exception: Empty content page at ${this.router.url}`);
            this.logger.track('Empty content page', this.router.url, SeverityLevel.Error);
            this.router.navigate(['/internal-server-error'], { skipLocationChange: true });
          }
          if (BaseContentPage.isAlertVisible(this.contentCache['alert-1'])) {
            this.localAlert = this.contentCache['alert-1'];
            this.alertService.setGlobalAlert(new ComponentState(false));
          } else {
            this.contentService.getGlobalAlert(pageData['contentGroup'])
              .subscribe(resp => { this.alertService.setGlobalAlert(resp); });
          }
          this.pageInfo = response.pageInfo;
          this.pageInfoObservable.next(response.pageInfo);
          if (!!response && !!response.pageInfo && !!response.pageInfo.content) {
            this.setPageTitle(response.pageInfo.content.title);
            this.setMetaTags(response.pageInfo.content.MetaTags);
            this.setPageTitleInfo(response.pageInfo.content);
          }
          this.setAnalyticsTags(response.pageInfo);
          this.checkForBlockedPopup();
        },
          error => {
            this.logger.track('Base page, getPageContent() errored out', this.router.url, SeverityLevel.Error);
            this.logger.trackException(error, this.router.url, SeverityLevel.Error);

            if (this.shouldErrorOnEmptyContent) {
              this.router.navigate(['/internal-server-error'], { skipLocationChange: true });
            }
          });
    }
  }

  checkForBlockedPopup() {
    const blocked = localStorage.getItem('pop-up-blocked');
    if (!!blocked) {
      localStorage.removeItem('pop-up-blocked');
      this.popUpAlert = new AlertMessage(true, AlertType.danger, this.getPopUpBlockedMessage(blocked), true);
    }
    this.checkedForBlockedPopUp.next(true);
  }

  protected getPopUpBlockedMessage(blockedComponent: string): string {
      return this.constants.data.value.ERROR_STATE_POPUP_BLOCKED;
  }

  protected getMemberDetail(): void {
    if (this.baseMemberService) {
      this.baseMemberService.loggedInMemberKey.pipe(takeUntil(this.componentDestroyed$)).subscribe(mk => {
        if (!mk) {
          return;
        }
        this.baseMemberService.getLoggedInMemberDetails()
        .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(response => {
            if (response !== this.memberDetailsObservable.value) {
              this.memberDetailsObservable.next(response);
            }
          });
      });
    }
  }

  protected setPageTitle(title: string) {
    if (title) {
      const siteLobTitle = this.contentService.isAkRegion() ? this.constants.data.value.SITE_TITLE_AK : this.contentService.islwwaRegion()? this.constants.data.value.SITE_TITLE_LWWA : this.constants.data.value.SITE_TITLE;
      this.tabTitle.setTitle(this.decodePipe.transform(`${title} | ${siteLobTitle}`));
      this.pageTitle = title;
    }
  }

  protected setPageTitleInfo(content: any) {
    if (content) {
      this.pageTitleInfo.pageTitle = content.title;
      this.pageTitleInfo.pageSubTitle = content.subtitle;
      this.pageTitleInfo.pageTitleImagePath = content.image === undefined ? content.imagesrc : content.image;
    }
  }

  protected setMetaTags(metaTags: string) {
    if (metaTags) {
      JSON.parse(metaTags).forEach(element => {
        if (!!element.metaName && !!element.metaContent) {
          this.meta.updateTag({ name: element.metaName, content: element.metaContent });
        }
      });
    }
  }

  protected setAnalyticsTags(componentState: ComponentState) {
    if (!!componentState) {
      this.meta.updateTag({ name: 'analytics_page_name', content: componentState.analyticsView });
      this.meta.updateTag({ name: 'analytics_site_section', content: componentState.analyticsSiteSection });
    }
  }
}
