import { Component, OnInit, Input } from '@angular/core';

import { DataComponentState } from 'libs/models/pbc-models';
import { FooterContentHelperService } from '../footer-content-helper.service';
import { StringsService } from 'libs/services/strings.service';
import { ChildSite } from 'libs/models/site-config';
import { ContentService } from 'libs/services/content.service';

@Component({
  selector: 'pbc-footer-top',
  templateUrl: './footer-top.component.html'
})
export class FooterTopComponent implements OnInit {
  @Input() footerContent: DataComponentState;
  @Input() hideMobileApps: boolean;
  @Input() hideSocialMedia: boolean;
  @Input() hideRegionSwitch: boolean;
  @Input() homePageUrl: string;
  @Input() horizontalLinks: boolean;
  @Input() logoImgUrl: string;
  @Input() menus: any[];

  contactMenus: any[];
  openMenu: number;
  ncqaDisclaimerText: string;
  weyerhaeuserParentGroupId = '100000045';
  isWeyerhaeuser: boolean;

  constructor(private readonly contentHelper: FooterContentHelperService, private readonly stringsService: StringsService, private readonly contentService: ContentService) { }

  ngOnInit() {
    this.contactMenus = this.contentHelper.parseFooterContent(this.footerContent, 'contactMenuGroupBox') || [];
    this.ncqaDisclaimerText = this.footerContent?.content?.ncqaDisclaimer;
    this.isWeyerhaeuser = (this.contentService.group?.parentGroupId === this.weyerhaeuserParentGroupId);
  }

  toggleSubmenu(index: number) {
    this.openMenu = this.openMenu === index ? -1 : index;
  }

  needsDomain(route: string): boolean {
    return this.stringsService.needsDomain(route);
  }

  addDomain(route: string): string {
    return this.stringsService.addDomainToLink(route);
  }

  dropToken(route: string): string {
    return this.stringsService.dropRootToken(route);
  }

  showNcqaLogo(): boolean {
    return (this.footerContent?.type !== 'medicare-navigation' && this.footerContent?.type !== 'lwwa-navigation' && !window.location.href.includes(ChildSite.weyerhaeuser) && !this.isWeyerhaeuser);    
  }

  showNcqaDarkLogo(): boolean {
    return (this.footerContent?.type === 'lwwa-navigation');
  }
}
