import { FindADoctorComponent } from 'libs/components/find-care/find-a-doctor.component';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { CoveredDrugsComponent } from '../pharmacy/covered-drugs/covered-drugs.component';
import { FlexibleTemplateComponent } from 'libs/templates/flexible/flexible.component';
import { FlexibleTwoColTemplateComponent } from 'libs/templates/flexible-two-col/flexible-two-col.component';

export const AagRoutes = [

  // AAG: HOME
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home-aag', contentGroup: 'member-home-aag' } },

  // AAG: BENEFITS & COVERAGE
  { path: 'consumer-choice-ppo-plan', component: FlexibleTemplateComponent, data: { page: 'consumer-choice-ppo-plan-aag', contentGroup: 'member-consumer-choice-ppo-plan-aag' } },
  { path: 'coverage-while-traveling', component: FlexibleTemplateComponent, data: { page: 'coverage-while-traveling-aag', contentGroup: 'member-coverage-while-traveling-aag' } },
  { path: 'new', component: FlexibleTemplateComponent, data: { page: 'new-aag', contentGroup: 'member-new-aag' } },
  { path: 'personal-funding-accounts', component: FlexibleTemplateComponent, data: { page: 'personal-funding-accounts-aag', contentGroup: 'member-personal-funding-accounts-aag' } },
  { path: 'personal-health-support', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'personal-health-support-aag', contentGroup: 'member-personal-health-support-aag' } },
  { path: 'ppo-plan', component: FlexibleTemplateComponent, data: { page: 'ppo-plan-aag', contentGroup: 'member-ppo-plan-aag' } },
  { path: 'preapproval', component: FlexibleTemplateComponent, data: { page: 'preapproval-aag', contentGroup: 'member-preapproval-aag' } },
  { path: 'welcome', component: FlexibleTemplateComponent, data: { page: 'welcome-aag-v2', pageWidth: 'full', contentGroup: 'member-welcome-aag' } },

  // AAG: CLAIMS
  { path: 'explanation-of-benefits', component: FlexibleTemplateComponent, data: { page: 'explanation-of-benefits-aag', contentGroup: 'member-explanation-of-benefits-aag' } },
  { path: 'appeals-complaints', component: FlexibleTemplateComponent, data: { page: 'appeals-complaints-aag', contentGroup: 'member-appeals-complaints-aag' } },

  // AAG: PRESCRIPTIONS
  { path: 'pharmacy-information', component: FlexibleTemplateComponent, data: { page: 'pharmacy-information-aag', contentGroup: 'member-pharmacy-information-aag' } },
  { path: 'manage-prescriptions', component: FlexibleTemplateComponent, data: { page: 'manage-prescriptions-aag', contentGroup: 'member-manage-prescriptions-aag' } },
  { path: 'prescription-savings', component: FlexibleTemplateComponent, data: { page: 'prescription-savings-aag', contentGroup: 'member-prescription-savings-aag' } },

  // AAG: FIND CARE
  { path: 'find-a-doctor', component: FindADoctorComponent, data: { feature: 'findADoctor', page: 'find-a-doctor-aag', contentGroup: 'member-find-a-doctor-aag' }, canActivate: [FeatureFlagGuard] },
  { path: 'centers-of-excellence', component: FlexibleTemplateComponent, data: { page: 'centers-of-excellence-aag', contentGroup: 'member-centers-of-excellence-aag' } },
  { path: 'centers-of-excellence/bariatric', component: FlexibleTemplateComponent, data: { page: 'bariatric-aag', contentGroup: 'member-bariatric-aag' } },
  { path: 'centers-of-excellence/cardiac', component: FlexibleTemplateComponent, data: { page: 'cardiac-aag', contentGroup: 'member-cardiac-aag' } },
  { path: 'centers-of-excellence/knee-hip-replacement-surgery', component: FlexibleTemplateComponent, data: { page: 'knee-hip-replacement-surgery-aag', contentGroup: 'member-knee-hip-replacement-surgery-aag' } },
  { path: 'centers-of-excellence/spinal-surgery', component: FlexibleTemplateComponent, data: { page: 'spinal-surgery-aag', contentGroup: 'member-spinal-surgery-aag' } },
  { path: 'decision-aids', component: FlexibleTemplateComponent, data: { page: 'decision-aids-aag', contentGroup: 'member-decision-aids-aag' } },
  { path: 'virtual-care', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'virtual-care-aag', contentGroup: 'member-virtual-care-aag' } },

  // AAG: EXPLORE RESOURCES: QUICK HELP
  { path: 'quick-help', component: FlexibleTwoColTemplateComponent, data: { page: 'quick-help-aag', contentGroup: 'member-quick-help-aag' } },
  { path: 'quick-help/claims', component: FlexibleTwoColTemplateComponent, data: { page: 'claims-aag', contentGroup: 'member-claims-aag' } },
  { path: 'quick-help/benefits', component: FlexibleTwoColTemplateComponent, data: { page: 'benefits-aag', contentGroup: 'member-benefits-aag' } },
  { path: 'quick-help/care-costs', component: FlexibleTwoColTemplateComponent, data: { page: 'care-costs-aag', contentGroup: 'member-care-costs-aag' } },
  { path: 'quick-help/accounts-id-cards', component: FlexibleTwoColTemplateComponent, data: { page: 'accounts-id-cards-aag', contentGroup: 'member-accounts-id-cards-aag' } },
  { path: 'quick-help/medications', component: FlexibleTwoColTemplateComponent, data: { page: 'accounts-medications-aag', contentGroup: 'member-medications-cards-aag' } },
  { path: 'quick-help/plan-basics', component: FlexibleTwoColTemplateComponent, data: { page: 'plan-basics-aag', contentGroup: 'member-plan-basics-aag' } },
  { path: 'quick-help/policies-practices', component: FlexibleTwoColTemplateComponent, data: { page: 'policies-practices-aag', contentGroup: 'member-policies-practices-aag' } },

  // AAG: EXPLORE RESOURCES: CARE ESSENTIALS
  { path: 'care-essentials', component: FlexibleTwoColTemplateComponent, data: { page: 'care-essentials-aag', contentGroup: 'member-care-essentials-aag' } },
  { path: 'care-essentials/preventive-care', component: FlexibleTwoColTemplateComponent, data: { page: 'preventive-care-aag', contentGroup: 'member-preventive-care-aag' } },
  { path: 'care-essentials/womens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'womens-health-aag', contentGroup: 'member-womens-health-aag' } },
  { path: 'care-essentials/mens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mens-health-aag', contentGroup: 'member-mens-health-aag' } },
  { path: 'care-essentials/kids-health', component: FlexibleTwoColTemplateComponent, data: { page: 'kids-health-aag', contentGroup: 'member-kids-health-aag' } },
  { path: 'care-essentials/lgbt-health', component: FlexibleTwoColTemplateComponent, data: { page: 'lgbt-health-aag', contentGroup: 'member-lgbt-health-aag' } },
  { path: 'care-essentials/mental-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mental-health-aag', contentGroup: 'member-mental-health-aag' } },
  { path: 'care-essentials/cancer', component: FlexibleTwoColTemplateComponent, data: { page: 'cancer-aag', contentGroup: 'member-cancer-aag' } },
  { path: 'care-essentials/diabetes', component: FlexibleTwoColTemplateComponent, data: { page: 'diabetes-aag', contentGroup: 'member-diabetes-aag' } },
  { path: 'care-essentials/maternity-care', component: FlexibleTwoColTemplateComponent, data: { page: 'maternity-care-aag', contentGroup: 'member-maternity-care-aag' } },
  { path: 'care-essentials/screenings', component: FlexibleTwoColTemplateComponent, data: { page: 'screenings-aag', contentGroup: 'member-screenings-aag' } },
  { path: 'care-essentials/sleep-conditions', component: FlexibleTwoColTemplateComponent, data: { page: 'sleep-conditions-aag', contentGroup: 'member-sleep-conditions-aag' } },
  { path: 'care-essentials/back-pain', component: FlexibleTwoColTemplateComponent, data: { page: 'back-pain-aag', contentGroup: 'member-back-pain-aag' } },

  // AAG: EXPLORE RESOURCES: OTHER
  { path: 'forms', component: FlexibleTemplateComponent, data: { page: 'forms-aag', contentGroup: 'member-forms-aag' } },
  { path: 'glossary', component: FlexibleTemplateComponent, data: { page: 'glossary-aag', contentGroup: 'member-glossary-aag' } },
  { path: 'go-mobile', component: FlexibleTemplateComponent, data: { page: 'go-mobile-aag', contentGroup: 'member-go-mobile-aag' } },
  { path: 'how-health-plans-work', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'how-health-plans-work-aag', contentGroup: 'member-how-health-plans-work-aag' } },

  // AAG: ABOUT US
  { path: 'premera-difference', component: FlexibleTemplateComponent, data: { page: 'premera-difference-aag', contentGroup: 'member-premera-difference-aag' } },
  { path: 'about-premera', component: FlexibleTemplateComponent, data: { page: 'about-premera-aag', contentGroup: 'member-about-premera-aag' } },
  { path: 'executives', component: FlexibleTemplateComponent, data: { page: 'executives-aag', contentGroup: 'member-executives-aag' } },
  { path: 'companies-we-work-with', component: FlexibleTemplateComponent, data: { page: 'companies-we-work-with-aag', contentGroup: 'member-companies-we-work-with-aag' } },
  { path: 'press-releases', component: FlexibleTemplateComponent, data: { page: 'press-releases-aag', contentGroup: 'member-press-releases-aag' } },
  { path: 'community-support', component: FlexibleTemplateComponent, data: { page: 'community-support-aag', contentGroup: 'member-community-support-aag' } },

  // AAG: GUIDANCE
  { path: 'fraud-abuse', component: FlexibleTemplateComponent, data: { page: 'fraud-abuse-aag', contentGroup: 'member-fraud-abuse-aag' } },
  { path: 'privacy-practices', component: FlexibleTemplateComponent, data: { page: 'privacy-practices-aag', contentGroup: 'member-privacy-practices-aag' } },
  { path: 'aviso-practicas-privacidad', component: FlexibleTemplateComponent, data: { page: 'aviso-practicas-privacidad-aag', contentGroup: 'member-aviso-practicas-privacidad-aag' } },
  { path: 'terms-conditions', component: FlexibleTemplateComponent, data: { page: 'terms-conditions-aag', contentGroup: 'member-terms-conditions-aag' } },
  { path: 'data-collection', component: FlexibleTemplateComponent, data: { page: 'data-collection-aag', contentGroup: 'member-data-collection-aag' } },
  { path: 'medical-policies', component: FlexibleTemplateComponent, data: { page: 'medical-policies-aag', contentGroup: 'member-medical-policies-aag' } },

  // AAG: GET CONNECTED
  { path: 'contact-us', component: FlexibleTemplateComponent, data: { page: 'contact-us-aag', contentGroup: 'member-contact-us-aag' } },

  // AAG: PHARMACY FOOTER
  { path: 'pharmacy-benefit-guide', component: FlexibleTemplateComponent, data: { page: 'pharmacy-benefit-guide-aag', contentGroup: 'member-pharmacy-benefit-guide-aag' } },
  { path: 'covered-drugs', component: CoveredDrugsComponent, data: { page: 'covered-drugs-aag', contentGroup: 'member-covered-drugs-aag' } },
  { path: 'specialty-drugs', component: FlexibleTemplateComponent, data: { page: 'specialty-drugs-aag', contentGroup: 'member-specialty-drugs-aag' } },
  { path: 'drugs-requiring-approval', component: FlexibleTemplateComponent, data: { page: 'drugs-requiring-approval-v2-aag', contentGroup: 'member-drugs-requiring-approval-v2-aag' } },

  // AAG: OTHER
  { path: 'outbreaks', component: FlexibleTemplateComponent, data: { page: 'outbreaks-aag', contentGroup: 'member-outbreaks-aag' } },
  { path: 'natural-disasters', component: FlexibleTemplateComponent, data: { page: 'natural-disasters-aag', contentGroup: 'member-natural-disasters-aag' } },
  { path: 'flu', component: FlexibleTemplateComponent, data: { page: 'flu-aag', contentGroup: 'member-flu-aag' } }
];
