import { Component, Input, OnDestroy, OnChanges,OnInit } from '@angular/core';
import { Subscription, combineLatest, Subject, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from 'libs/services/authentication.service';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { VisibilityService } from 'libs/services/visibility.service';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { ContentService } from 'libs/services/content.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { MemberService } from 'member/shared/services/member.service';
import { MemberPermission } from 'libs/models/pbc-enums';

@Component({
  selector: 'pbc-secondary-nav',
  templateUrl: './secondary-nav.component.html'
})
export class SecondaryNavComponent implements OnChanges, OnDestroy,OnInit {
  @Input() content: any;
  @Input() signInUrl: string;
  @Input() signInText: string;
  @Input() homePageUrl: string;
  @Input() hideRegionSwitch: boolean;
  @Input() switchContent: any;
  site:string;
  isAuthenticated = false;
  isAuthenticatedSubscription: Subscription;
  displayName: string;
  permissions: string[];
  accountMenuLinks = new Array();
  toggleMenu: boolean;
  componentDestroyed$: Subject<boolean> = new Subject();
  demoserverHmoFlag :any;
  serverName:string = EnvironmentService.variables.name;
  demoserverFutureHmoFlag :any;
  featureFlags: any;
  isLwwaUser: boolean = false;
  lwwaIcnCls = '';
  constructor(private readonly authenticationService: AuthenticationService,
    public readonly featureFlagService: FeatureFlagGuard,
    private readonly visibilityService: VisibilityService,
    private readonly memberSharedService: MemberSharedService,
    private readonly contentService: ContentService,
    private readonly router: Router,
    private readonly memberService: MemberService) {
    this.featureFlags = EnvironmentService.variables.featureFlags;
    this.site=EnvironmentService.variables.site;
    this.isAuthenticatedSubscription = combineLatest([this.authenticationService.getIsAuthenticated(),
      this.memberSharedService.loggedInMemberKey])
        .pipe(map(([isAuthenticated, loggedInMemberKey]) => {
          this.isAuthenticated = isAuthenticated;
          if (this.isAuthenticated && !!loggedInMemberKey) {
            this.visibilityService.getMemberVisibilityPermissions().subscribe(visibilityPerms => {
              if (!!visibilityPerms) {
                this.permissions = visibilityPerms.permissions;
              }
            });
          } else {
            this.permissions = undefined;
          }
        })).subscribe(() => {});
  }
  ngOnInit() {
    this.demoserverHmoFlag = JSON.parse(sessionStorage.getItem('isHmoUser')) !=undefined ? JSON.parse(sessionStorage.getItem('isHmoUser')): EnvironmentService.variables.hmo;
    this.demoserverFutureHmoFlag = JSON.parse(sessionStorage.getItem('isFutureHmoDemo')) !=undefined ? JSON.parse(sessionStorage.getItem('isFutureHmoDemo')): false;

    this.visibilityService.hasPermission(MemberPermission.HasLwwaPlan).pipe(takeUntil(this.componentDestroyed$))
    .subscribe(hasLwwa => { 
      if (hasLwwa) {
        this.isLwwaUser = hasLwwa;
        this.lwwaIcnCls = 'i-lwwa';
      }
    });
  }
  ngOnChanges() {
    this.getAccountLinks();
  }

  ngOnDestroy() {
    this.isAuthenticatedSubscription.unsubscribe();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
 
  getAccountLinks() {
    if (!!this.accountMenuLinks && this.accountMenuLinks.length > 0) {
      return;
    }

    if (!this.content || !this.content.accountMenuGroupBox) {
      return;
    }

    // json parse response
    const accountMenuJson = JSON.parse(this.content.accountMenuGroupBox);
    // get links from the response
    const accountMenuLinks = (!!accountMenuJson) ? accountMenuJson[0].accountMenuGroupBox.linksGroupBox : '';
    // when only 1 rule is present on account menu link convert to array (newtonsoft to json conversion)
    let index = 0;
    accountMenuLinks.forEach(accountMenuItem => {
      if (!!accountMenuItem.visibilityRules.rule) {
        accountMenuItem.visibilityRules.rule = (Array.isArray(accountMenuItem.visibilityRules.rule)) ?
          accountMenuItem.visibilityRules.rule : accountMenuItem.visibilityRules.rule.split();
      }
      accountMenuItem.index = index++;
    });
    for (const menuLink of accountMenuLinks) {
      this.isLinkVisible(menuLink).pipe(takeUntil(this.componentDestroyed$)).subscribe(isVisible => {
        if (isVisible) {
          this.insertInOrder(menuLink);
        }
      });
    }
  }

  signOut() {
    window.location.href = `${EnvironmentService.variables.baseUrlMember}/sign-out`;
  }

  isLinkVisible(link: any): Observable<boolean> {
    if (!this.isAuthenticated || !link) {
      return of(false);
    }

    if (link.enabled !== 'true') {
      return of(false);
    }

    if(link.linkText.includes('1095') && this.isLwwaUser){
      return of(false);
    }

    if (!!link.visibilityRules && !!this.permissions) {
      for (const rule of link.visibilityRules.rule) {
        if (!!rule && !this.permissions.includes(rule)) {
          return of(false);
        }
      }
    }

    if (!this.featureFlagService.isRouteVisible(link.route)) {
      return of(false);
    }

    return this.visibilityService.isRouteVisible(link.route);
  }

  close(event: MouseEvent): void {
    this.toggleMenu = false;
  }

  private insertInOrder(menuLink: any) {
    for (let i = 0, len = this.accountMenuLinks.length; i < len; i++) {
      if (this.accountMenuLinks[i].index > menuLink.index) {
        this.accountMenuLinks.splice(i, 0, menuLink);
        return;
      }
    }
    this.accountMenuLinks.push(menuLink);
  }

  switchLob() {
    this.contentService.switchRegion();
    console.log(`Switch: Switched region to ${this.contentService.region$.value}`);
    const redirectUrl = `${this.homePageUrl}?region=${this.contentService.region$.value}`;
    console.log(`Switch: Redirecting to ${redirectUrl}`);
    window.location.href = redirectUrl;
  }
  /**
   * This function is  demo environment's specific function
   * it is only used for demo environment for switching between plans instead of selecting through plan drop down
   */
  changeMember(selectedValue:any, SelectedOption : any) {
    if(SelectedOption == 'FHMO') {
      this.demoserverFutureHmoFlag = true;
      sessionStorage.setItem('isFutureHmoDemo', JSON.stringify(true));
    } else if(SelectedOption == 'HMO') {
      this.demoserverFutureHmoFlag = false;
      sessionStorage.setItem('isFutureHmoDemo', JSON.stringify(false));
    } 
    const account = this.memberService.getRegistrationsFromCookie();
    if (!!account && !!account.registrations && account.registrations.length > 0) {
      const memberKeys = account.registrations.map(reg => reg.memberKey);
      let selectedMemberKey;
      let selectedRegistration;
      this.memberService.getRegistrationPlanDetails(memberKeys, this.featureFlags.planSwitchingFlag)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe((registrationResponse) => {
          registrationResponse.map((data)=>{
            if(SelectedOption == 'FHMO' && data.plan=='HMO' && data.planStatus == 'Future') {
              sessionStorage.setItem('selectedPlanSession', JSON.stringify(data));
            } else if(SelectedOption == 'PPO' && data.plan == 'PPO'){
              sessionStorage.setItem('selectedPlanSession', JSON.stringify(data));
            } else if(SelectedOption == 'HMO' && data.plan == 'HMO' && data.planStatus == 'Active'){
              sessionStorage.setItem('selectedPlanSession', JSON.stringify(data));
            }
          });
        });
    }
    sessionStorage.setItem('demoHmoNonHmoValue',selectedValue);
    sessionStorage.setItem('isHmoUser',selectedValue);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
