import { Component, Input, OnChanges } from '@angular/core';

import { ContentComponent } from 'libs/components/content-component';
import { ComponentState, CtaModel } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-cta-pack',
  template: `
    <pbc-cta [model]="ctaOne"></pbc-cta>
    <pbc-cta [model]="ctaTwo"></pbc-cta>
    `
})
export class CtaPackComponent implements ContentComponent, OnChanges {
  @Input() data: ComponentState;
  ctaOne: CtaModel;
  ctaTwo: CtaModel;

  ngOnChanges() {
    if (!!this.data.content) {
      this.ctaOne = new CtaModel(this.data.content.ctaOneUrl, this.data.content.ctaOneText, this.data.content.ctaOneStyle,
        this.data.content.ctaOneExternal, this.data.content.ctaOneAnalyticsTracker, null, this.data.content.ctaOnePreventDefault);

      this.ctaTwo = new CtaModel(this.data.content.ctaTwoUrl, this.data.content.ctaTwoText, this.data.content.ctaTwoStyle,
        this.data.content.ctaTwoExternal, this.data.content.ctaTwoAnalyticsTracker, null, this.data.content.ctaTwoPreventDefault);
    }
  }
}
