import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';

@Injectable()
export class UserAgentService {

  private readonly parser: UAParser;

  constructor() {
    this.parser = new UAParser();
  }

  isMobileDevice(): boolean {
    const device = this.parser.getDevice();
    return !device || !device.type ? false : device.type.toLowerCase() === 'mobile';
  }
}
