import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UrlBuilderService } from './url-builder.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SuccessResponse, BffHealth, ContentHealth } from 'libs/models/pbc-models';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  constructor(private readonly dataService: DataService, private readonly urlBuilderService: UrlBuilderService) {
  }

  getBffHealthInfo(): Observable<BffHealth> {
    return this.dataService
      .get<SuccessResponse<BffHealth>>(this.urlBuilderService.buildBffHealthUrl(), null, false)
      .pipe(map(response => response.result));
  }

  getContentHealthInfo(scope: string = null): Observable<ContentHealth> {
    return this.dataService
      .get<SuccessResponse<ContentHealth>>(this.urlBuilderService.buildContentHealthUrl(scope), null, false)
      .pipe(map(response => response.result));
  }
}
