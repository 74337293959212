import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseContentPage } from './base-content-page';
import { ContentService } from 'libs/services/content.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    ContentService
  ]
})
export class ClassesModule {
  constructor(protected injector: Injector) {
    BaseContentPage.injector = this.injector;
  }
}
