import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { ContentService } from 'libs/services/content.service';

@Component({
  selector: 'pbc-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit, OnDestroy {
  currentPortal = 'member';
  location = 'wa';
  activeId: any = 1;
  activeAccordionId = '';
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(private readonly router: Router,
    private route: ActivatedRoute,
    private readonly contentService: ContentService) { }

  // todo: tech-debt pull content from ektron
  ngOnInit() {
    if (!!this.route.snapshot.queryParams) {
      if (!!this.route.snapshot.queryParams.loc) {
        this.location = this.route.snapshot.queryParams.loc.toLowerCase();
      } else {
        this.contentService.region$.pipe(takeUntil(this.componentDestroyed$)).subscribe(region => {
          if (!!region) {
            this.location = this.contentService.isAkRegion() ? 'ak' : 'wa';
          }
        });
      }
      if (!!this.route.snapshot.queryParams.portal) {
        this.currentPortal = this.route.snapshot.queryParams.portal.toLowerCase();
      }
      if (!!this.route.snapshot.queryParams.acc) {
        this.activeAccordionId = `${this.currentPortal}-acc-${this.route.snapshot.queryParams.acc}`;
      }
    }
    switch (this.currentPortal) {
      case 'member': {
        this.activeId = 1;
        break;
      }
      case 'employer': {
        this.activeId = 2;
        break;
      }
      case 'provider': {
        this.activeId = 3;
        break;
      }
      case 'producer': {
        this.activeId = 4;
        break;
      }
      default: {
        this.activeId = 5;
        break;
      }
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  signIn() {
    this.router.navigate(['/member/sign-in']);
  }
}
