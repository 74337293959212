import { Component, Input } from '@angular/core';

import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';
import { ContentHelperMethods } from '../content-helper';

@Component({
  selector: 'pbc-callout-image',
  templateUrl: './callout-image.component.html'
})
export class CalloutImageComponent implements ContentComponent {
  @Input() data: ComponentState;
  isAlignedRight = ContentHelperMethods.isAlignedRight;
}
