<div class="sidebar-page" data-template="flexible-two-col">
  <div class="row no-gutters">
    <div class="col-12 col-md-3 pt-5">
      <pbc-sidebar-nav [data]="contentCache['sidebar-nav-1']"></pbc-sidebar-nav>
      <ng-container *ngIf="!contentCache['sidebar-nav-1']">
        <div *ngFor="let component of pageComponents">
          <ng-container *ngIf="component.data.content.componentName === 'SidebarNavComponent'">
            <pbc-sidebar-nav [data]="component.data"></pbc-sidebar-nav>
          </ng-container>
        </div>
      </ng-container>
    </div>
    <div class="col">
      <h1 class="ml-5" [ngClass]="{'sr-only': pageInfo?.content?.hideTitle === 'true' }" tabindex="-1" [innerHtml]="pageTitle"
      [attr.aria-label]="pageTitle"></h1>

      <div *ngFor="let component of pageComponents" class="ml-0 ml-md-5" pbcApplyRules [visibilityRules]="component.data.visibilityRules" [rulesOperation]="component.data.visibilityRuleOperation" >
        <div [ngClass]="component.data.content.parentClasses" *ngIf="component.data.content.componentName !== 'SidebarNavComponent'">
          <pbc-component-renderer [component]="component"></pbc-component-renderer>
        </div>
      </div>
    </div>
  </div>
</div>


