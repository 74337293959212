import { Component, ElementRef, Input, OnChanges, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertMessage, CtaModel, DataComponentState } from 'libs/models/pbc-models';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { style } from '@angular/animations';


@Component({
  selector: 'pbc-card',
  templateUrl: './card.component.html'
})
export class CardComponent implements OnChanges, OnDestroy {
  @Input() headerOverride: string;
  @Input() data: DataComponentState;
  @Input() cta: CtaModel;
  @Input() detailCta: CtaModel;
  @Input() hideCta: boolean;
  @Input() hideDetailCta: boolean;
  @Input() hideImg: boolean;
  @Input() error: AlertMessage;
  @Input() isLoading: boolean;
  @Input() blockAction: boolean;
  @Input() blockedMessage: string;
  @Input() notificationSelectedPlan: string = "";
  ctaOne: CtaModel;
  ctaTwo: CtaModel;
  providerName: string;
  displayName: boolean = false;
  featureFlags : any;
  isFromPcpCardComponent: boolean = false;
  styleAdjust: string;
  isHmoUser: any = false;
  componentDestroyed$: Subject<boolean> = new Subject();
  pcpStyle : string;
  minHeight : string;
  borderStyle : string = 'border-Style';
  notificationRedirectPlan: string = "";
  constructor() {
    this.featureFlags = EnvironmentService.variables.featureFlags;
  }

  ngOnChanges(): void {
    if (sessionStorage.length > 0) {
      this.isHmoUser = JSON.parse(sessionStorage.getItem("isHmoUser"));
    }
    this.notificationRedirectPlan = this.notificationSelectedPlan;
    if (this.featureFlags.notificationFEFlag && this.notificationSelectedPlan === "HMO") {
      this.isHmoUser = true;
    }
    else if (this.featureFlags.planSwitchingFlag && this.notificationSelectedPlan === "PPO")
    {
      this.isHmoUser = false;
    }

    if (this.cta != undefined) {
      this.ctaOne = this.cta;
      this.isFromPcpCardComponent = this.cta?.navExtras?.state['isFromPcpCardComponent'];
      if (this.isFromPcpCardComponent) {
        this.pcpStyle = this.cta?.navExtras?.state['gotLocation'] ? 'ml-3 mr-2' : 'mt-3 col-sm-5'
        this.providerName = this.cta?.navExtras?.state['firstName'] + ' ' + this.cta?.navExtras?.state['lastName'];
        this.displayName = true;
      }
      this.styleAdjust = this.cta?.navExtras?.state['gotLocation'] ? 'mt-2 ml-3' : 'mt-3 col-md-12';
      this.borderStyle = this.isHmoUser?(this.isFromPcpCardComponent? 'border-Style responsive-card-height': 'card-main'):'';
    }

    if (!this.cta && this.data && this.data.content) {
      this.ctaOne = new CtaModel(this.data.content.ctaOneUrl, this.data.content.ctaOneText, this.data.content.ctaOneStyle,
        this.data.content.ctaOneExternal, this.data.content.ctaOneAnalyticsTracker, null, this.data.content.ctaOnePreventDefault);
    }

    if (this.detailCta) {
      this.ctaTwo = this.detailCta;
    }

    if (!this.isHmoUser) {
     
      if (!this.detailCta && this.data && this.data.content) {
        this.ctaTwo = new CtaModel(this.data.content.ctaOneUrl, this.data.content.ctaOneText, this.data.content.ctaOneStyle,
          this.data.content.ctaOneExternal, this.data.content.ctaOneAnalyticsTracker, null, this.data.content.ctaOnePreventDefault);
      }
    }
  }

  @ViewChildren('catchheight') pcpCards:QueryList<ElementRef>
  ngAfterViewInit()
  {
    if (this.isFromPcpCardComponent && this.isHmoUser){
      let maxHeight;
      maxHeight = localStorage.getItem("minHeight")? Number(localStorage.getItem("minHeight")) : 0;
      this.pcpCards.forEach(x=>{
        let height=x.nativeElement.getBoundingClientRect().height;
        if (height > maxHeight){
          maxHeight = height
          if(maxHeight < 272){
            maxHeight = 256;
          }
        }
        localStorage.setItem("minHeight",JSON.stringify(maxHeight));
        let heightStyle : string = '.card.icon-card{min-height:' +  Math.ceil(this.convertPXToVW(maxHeight)).toString() + 'rem}';
        const styleElement = document.createElement('style');
        styleElement.appendChild(document.createTextNode(heightStyle));
        x.nativeElement.appendChild(styleElement);
      });
    }
  }

  convertPXToVW(px) {
    return px * 0.0625;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
