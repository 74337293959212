<div *ngFor="let menu of menus; let index = index; let last = last" class="mb-4">          
  <div>
    <div class="footer-heading">{{menu.footerMenuGroupBox.headerTitle}}</div>
    <div class="small">
      <span *ngFor="let link of menu.footerMenuGroupBox.linksGroupBox; let lastLink = last">
        <ng-container *ngIf="needsDomain(link.route); else isRoute">
          <a [href]="addDomain(link.route)" [innerHtml]="link.title" [attr.lang]="link.lang" [attr.target]="link.external? '_blank':'_self'" pbcAnalyticsTag
            data-analytics-override-route="footer"></a>
        </ng-container>
        <ng-template #isRoute>
          <a [routerLink]="dropToken(link.route)" [innerHtml]="link.title" [attr.lang]="link.lang" [attr.target]="link.external? '_blank':'_self'"
            pbcAnalyticsTag data-analytics-override-route="footer"></a>
        </ng-template>
        <span *ngIf="!lastLink"> | </span>
      </span>
    </div>
  </div>
</div>