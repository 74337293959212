declare var require: any;
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ComponentsModule } from 'libs/components/components.module';
import { FooterComponent } from 'libs/components/footer/footer.component';
import { HeaderComponent } from 'libs/components/header/header.component';
import { DirectivesModule } from 'libs/directives/directives.module';
import { ErrorsModule } from 'libs/errors/errors.module';
import { PipesModule } from 'libs/pipes/pipes.module';
import { AnalyticsReportingService } from 'libs/services/analytics-reporting.service';
import { AzureConfigService } from 'libs/services/azure-config.service';
import { DataService } from 'libs/services/data.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { HealthService } from 'libs/services/health.service';
import { LoggingService } from 'libs/services/logging.service';
import { MockUrlBuilderService } from 'libs/services/mock-url-builder.service';
import { PbcCookieService } from 'libs/services/pbc-cookie.service';
import { PbcErrorHandlerService } from 'libs/services/pbc-error-handler.service';
import { ServicesModule } from 'libs/services/services.module';
import { StateGuard } from 'libs/services/state-guard.service';
import { StringsService } from 'libs/services/strings.service';
import { SurrogateIdService } from 'libs/services/surrogate-id.service';
import { UrlBuilderService } from 'libs/services/url-builder.service';
import { TemplatesModule } from 'libs/templates/templates.module';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentModule } from './content/content.module';
import { PlanInfoComponent } from './covid/plan-info/plan-info.component';
import { CoveredDrugsComponent } from './pharmacy/covered-drugs/covered-drugs.component';
import { SingleFormularyTemplateComponent } from './pharmacy/single-formulary-template/single-formulary-template.component';
import { GroupBookletsComponent } from './shop-for-plans/group-booklets/group-booklets.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { TemplateModule } from './template/template.module';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    CoveredDrugsComponent,
    SingleFormularyTemplateComponent,
    PlanInfoComponent,
    GroupBookletsComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ContentModule,
    NgbModule,
    TemplateModule,
    ServicesModule,
    ErrorsModule,
    ComponentsModule,
    TemplatesModule,
    PipesModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule
  ],
  providers: [
    FeatureFlagGuard,
    StateGuard,
    { provide: ErrorHandler, useClass: PbcErrorHandlerService },
    DataService,
    {
      provide: UrlBuilderService,
      useClass: environment.name === 'mock' ? MockUrlBuilderService : UrlBuilderService
    },
    PbcCookieService,
    CookieService,
    HealthService,
    { provide: LoggingService, useFactory: () => {
      LoggingService.appInsightsKey = environment.appInsightsKey;
      return new LoggingService();
    }},
    SurrogateIdService,
    StringsService,
    AnalyticsReportingService,
    {
      provide: APP_INITIALIZER,
      useFactory: (service: StringsService) => () => {
        service.defaultData = require('../assets/l10n/en.json');
        service.use('en');
      },
      deps: [StringsService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (azureConfig: AzureConfigService) => () => { azureConfig.initialize(); },
      deps: [AzureConfigService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    EnvironmentService.variables = environment;
    HeaderComponent.headerContent = require('../assets/fallback/content-header.json');
    HeaderComponent.topNavFallback = require('../assets/fallback/top-nav-primary-nonsecure.json');
    HeaderComponent.topNavSecondaryFallback = require('../assets/fallback/top-nav-secondary.json');
    HeaderComponent.topNavAudienceFallback = require('../assets/fallback/top-nav-audience-nonsecure.json');
    FooterComponent.footerFallbackContent = require('../assets/fallback/footer-content.json');
  }
}
