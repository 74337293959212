import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { AnalyticsInfo } from 'libs/models/pbc-models';
import { PbcCookieService } from './pbc-cookie.service';

/**
 * Make global window property available to typescript type checking
 */
declare global {
  interface Window {
    // ensighten analytics object
    s: any;
    // Adobe analytics object
    AnalyticsObject: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsReportingService {

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly pbcCookieService: PbcCookieService) { }

  public setAnalyticsPageError(errorType: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      analyticsInfo.pageError = errorType;
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public setSurrogateId(surrogateId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      if (!surrogateId) {
        delete analyticsInfo.surrogateId;
      } else {
        analyticsInfo.surrogateId = surrogateId;
      }
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public setImpersonation(isImpersonating: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      analyticsInfo.impersonation = isImpersonating;
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public setSso(isSso: boolean): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      analyticsInfo.sso = isSso;
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public setGroupId(groupId: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      analyticsInfo.groupId = groupId;
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public setGroupName(groupName: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      analyticsInfo.groupName = groupName;
      window.AnalyticsObject = analyticsInfo;
    }
  }

  public getSurrogateId(): string {
    let surrogateId = '';
    if (isPlatformBrowser(this.platformId)) {
      const analyticsInfo = this.getAnalyticsObject();
      surrogateId = analyticsInfo.surrogateId;
    }
    return surrogateId;
  }

  public callAnalyticsForEOBOptIn() {
    this.callAnalytics('Account Settings: Paperless EOB signup', 'Account Settings Link Tracking', ['events'], 'event5');
  }

  public callAnalyticsFor1095OptIn() {
    this.callAnalytics('Account Settings: 1095 signup', 'Account Settings Link Tracking');
  }

  public callAnalyticsForEOBOptOut() {
    this.callAnalytics('Account Settings: Paperless EOB signup Disabled', 'Account Settings Link Tracking');
  }

  public callAnalyticsFor1095OptOut() {
    this.callAnalytics('Account Settings: 1095 signup Disabled', 'Account Settings Link Tracking');
  }

  private callAnalytics(metricName: string, metricCategory: string, additionalProperties: Array<string> = [], eventValue: string = undefined) {
    {
      if (!!window && !!window.s) {
        window.s.linkTrackVars = ['channel,prop1,prop2,prop3,prop4,prop5,prop6,prop7,prop8,prop9,prop12,prop13,prop14,prop15,prop16,prop18,prop35,eVar1,eVar2,eVar3,eVar21,eVar51,eVar52,eVar92', ...additionalProperties].join(',');
        window.s.prop2 = metricName;
        window.s.linkTrackEvents = eventValue || 'None';
        window.s.events = eventValue || '';
        window.s.tl(true, 'o', metricCategory);
        window.s.prop2 = '';
        window.s.events = '';
        window.s.linkTrackEvents = 'None';
      }
    }
  }

  private getAnalyticsObject(): AnalyticsInfo {
    if (!!window.AnalyticsObject) {
      return window.AnalyticsObject;
    }
    const analytics = new AnalyticsInfo();
    const surrIdFromCookie = this.pbcCookieService.get(this.pbcCookieService.surrogateIdCookie);
    if (!!surrIdFromCookie) {
      analytics.surrogateId = surrIdFromCookie;
    }
    return analytics;
  }
}