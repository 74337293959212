import { Component, Input } from '@angular/core';
import { StringsService } from 'libs/services/strings.service';

@Component({
  selector: 'pbc-footer-nav-horiz',
  templateUrl: './footer-nav-horiz.component.html'
})
export class FooterNavHorizComponent {
  @Input() menus: any[];

  constructor(private readonly stringsService: StringsService) { }

  needsDomain(route: string): boolean {
    return this.stringsService.needsDomain(route);
  }

  addDomain(route: string): string {
    return this.stringsService.addDomainToLink(route);
  }

  dropToken(route: string): string {
    return this.stringsService.dropRootToken(route);
  }
}
