<div class="quote" *ngIf="data?.isVisible" [ngClass]="data?.content?.primaryColor === 'true' ? 'background-primary' : ''">
  <p class="h4" *ngIf="data?.content?.miniHeader">{{data?.content?.miniHeader}}</p>
  <h3 class="h1 no-dash" *ngIf="data?.content?.header">{{data?.content?.header}}</h3>
  <ngb-carousel [showNavigationArrows]="quotes?.length > 1"
    [showNavigationIndicators]="quotes?.length > 1">
    <ng-template ngbSlide *ngFor="let quote of quotes">
      <div class="carousel-caption">
        <h4 class="h3" [innerHTML]="quote.quoteAuthor | decodeHtml"></h4>
        <p [innerHTML]="quote.quoteBody | decodeHtml"></p>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
