import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ContentComponent, ContentHelperMethods } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';
import { VisibilityService } from 'libs/services/visibility.service';
import { Subject } from 'rxjs';
import { MemberPermission } from 'libs/models/pbc-enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pbc-two-pack',
  templateUrl: './two-pack.component.html'
})
export class TwoPackComponent implements ContentComponent,OnInit,OnDestroy  {
  @Input() data: ComponentState;
  isAlignedRight = ContentHelperMethods.isAlignedRight;
  isLwwa:boolean = false;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private readonly visibilityService: VisibilityService) {
    
  }

  ngOnInit(): void {
    this.visibilityService.hasPermission(MemberPermission.HasLwwaPlan).pipe(takeUntil(this.componentDestroyed$))
    .subscribe(hasLwwa => {
      if (hasLwwa) {
        this.isLwwa = hasLwwa; 
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
