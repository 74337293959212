<lw-placeholder *ngIf="!data"></lw-placeholder>
<div *ngIf="data?.isVisible" data-id="hero-image-overlay" class="hero-forms hero-image-overlay">
  <div class="hero-image" aria-hidden="true">
    <lw-overlay-image-secondary svgOpacity=".8" svgWidth="200%" svgColor="white"></lw-overlay-image-secondary>
  </div>
  <nav class="breadcrumb" *ngIf="showBreadCrumbs(heroOverlay?.breadcrumbs)" id="bread-crumbs">
    <div *ngFor="let crumb of heroOverlay?.breadcrumbs; last as isLast" class="mr-3"><a [ngClass]="{ 'active': isLast}" href="{{crumb.route}}">{{crumb.displayText}}</a></div>
  </nav>
  <div class="hero-row">
    <div class="hero-text">
      <h1 [innerHtml]="heroOverlay?.heading | safehtml"></h1>
      <div [innerHtml]="heroOverlay?.content | safehtml"></div>
      <a class="btn btn-{{data?.content?.ctaOneStyle}} py-3" *ngIf="data?.content?.ctaOneUrl" [target]="data?.content?.ctaOneExternal | externalTarget" rel="isExternal | safeExternalTarget" [attr.aria-label]="data?.content?.ctaOneText" [attr.href]="data?.content?.ctaOneUrl">{{data?.content?.ctaOneText}}</a>
    </div>
  </div>
</div>
