import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';

import { EnvironmentService } from './environment-variables.service';

@Injectable()
export class FeatureFlagGuard implements CanActivate, CanActivateChild {

  constructor(private readonly router: Router) { }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    const flagToCheck = childRoute.data.feature;
    
    if (flagToCheck && !EnvironmentService.variables.featureFlags[flagToCheck]) {
      console.log('FeatureFlagGuard blocked navigation');
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }

  canActivate(route: ActivatedRouteSnapshot) {
    const flagToCheck = route.data.feature;

    if (flagToCheck && !EnvironmentService.variables.featureFlags[flagToCheck]) {
      console.log('FeatureFlagGuard blocked navigation');
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
  
  public isRouteVisible(route: string): boolean {
    if (!route) {
      return true;
    }

    const routeConfiguration = this.router.config.find(x => x.path === route.substring(route.lastIndexOf('/') + 1));
    const flagToCheck = !!routeConfiguration && !!routeConfiguration.data ? routeConfiguration.data.feature : null;

    if (flagToCheck) {
      return EnvironmentService.variables.featureFlags[flagToCheck];
    }

    return true;
  }
}
