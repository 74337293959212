import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { Parser } from 'xml2js';
import * as FileSaver from 'file-saver';

import { AuthStatusCode, ErrorCodeMap } from 'libs/models/member.models';
import { ProviderSearchResult } from 'member/shared/models/pcp.models';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private readonly currency: CurrencyPipe) { }

  static openPdf(fileData: string, fileName: string) {
    if (!!fileData) {
      const binary = atob(fileData);
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const pdfByteStream = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        pdfByteStream[i] = binary.charCodeAt(i);
      }
      const fileBlob = new Blob([pdfByteStream], { type: 'application/pdf' });
      FileSaver.saveAs(fileBlob, fileName);
    }
  }

  static getFriendlyAccountStatus(accountStatusCode: AuthStatusCode): string {
    if (!accountStatusCode) {
      return '';
    }
    return !!AuthStatusCode[accountStatusCode] && !!ErrorCodeMap[AuthStatusCode[accountStatusCode]]
    ? ErrorCodeMap[AuthStatusCode[accountStatusCode]]
    : accountStatusCode.toString();
  }

  static isSuccessAccountStatus(accountStatusCode: AuthStatusCode): boolean {
    return !accountStatusCode || [0, -1].includes(accountStatusCode);
  }

  compareObjects(obj1: any, obj2: any): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  formatName(name: string): string {
    if (!name) {
      return '';
    }

    name =  name.toLowerCase().replace(/(?:^|\s|-|\'|\’)[^\s-\'\’]/g, firstChar => firstChar.toUpperCase());
    //AOT was not able to identify the above regex only for U. So added logic to convert the first letter to UpperCase
    return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
  }

  makePosessiveForm(noun: string): string {
    if (!noun || noun.length === 0) {
      return noun;
    }

    if (noun[noun.length - 1] === 's') {
      return noun + '\'';
    }

    return noun + '\'s';
  }

  // Method formats string into phone number format
  formatPhoneNumber(phoneNumber: string): string {
    let formattedString = '';
    const dash = '-';
    const space = ' ';
    if (!Number(phoneNumber)) {
      return phoneNumber;
    }
    if (phoneNumber.length <= 10) {
        switch (phoneNumber.length) {
          case 7: // not sure if we allow 7 digit numbers but catching it just in case
            return phoneNumber.substr(0, 3) + dash + phoneNumber.substr(3, 4);
          case 10:
            return phoneNumber.substr(0, 3) + dash + phoneNumber.substr(3, 3) + dash + phoneNumber.substr(6, 4);
          default:
            return phoneNumber;
        }
      } else {
        // catching case where they put in a + the for international format.
        // adding in a plus if the customer didnt.
        if (phoneNumber.charAt(0) !== '+') {
          formattedString += '+';
        }
        return formattedString + phoneNumber.substr(0, phoneNumber.length - 10) + space +
          phoneNumber.substr(phoneNumber.length - 10, 3) + dash +
          phoneNumber.substr(phoneNumber.length - 7, 3) + dash +
          phoneNumber.substr(phoneNumber.length - 4, 4);
      }
  }

  parseXml(xml: string): any {
    const xmlParser = new Parser({explicitArray: false, mergeAttrs : true});
    let parsedXml = {};

    xmlParser.parseString(xml, (err, result) => {
      if (err) {
        console.log(err);
        return {};
      }
      parsedXml = result;
    });

    return parsedXml;
  }

  parseRecentUpdatesXml(xml:string):any{
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xml, 'application/xml');
      return this.xml2json(xmlDoc);
  }

  private xml2json(xml: any): any {
    try {
      let obj = {};
      if (!xml) {
        return obj;
      }
      if (!xml.children || xml.children.length === 0) {
        obj = xml.textContent;
        return obj;
      }
      for (let i = 0; i < xml.children.length; i++) {
        const item = xml.children.item(i);
        const nodeName = item.nodeName;
        if (typeof (obj[nodeName]) === 'undefined') {
          obj[nodeName] = this.xml2json(item);
        } else {
          if (typeof (obj[nodeName].push) === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xml2json(item));
        }
      }
      return obj;
    } catch (e) {
      console.log(e.message);
    }
  }

  convertStringToCurrencyString(stringCurrency: string, withCents: boolean): string {
    const nbrCurrency = parseFloat(stringCurrency);
    if (isNaN(nbrCurrency)) {
      return 'N/A';
    }
    return this.convertNumberToCurrencyString(nbrCurrency, withCents);
  }

  convertNumberToCurrencyString(currency: number, withCents: boolean): string {
    return `${this.currency.transform(currency, 'USD', 'symbol', withCents? '1.2-2' : '1.0-0')}`;
  }

  createHeading(cardHeaderLevel: string, cardStyle: string, cardHeader): string {
    if (!!cardHeaderLevel && !!cardHeader) {
      if (cardStyle) {
        return '<' + cardHeaderLevel + ' class=\'' + cardStyle + '\'>' + cardHeader + '</' + cardHeaderLevel + '>';
      } else {
        return '<' + cardHeaderLevel +  '>' + cardHeader + '</' + cardHeaderLevel + '>';
      }

    } else { return '<!-- Card level and heading are required -->'; }
  }

  MergePCPNamewithDegree(doctor: ProviderSearchResult): string {
    return !!doctor?.degreeTypes?.toString() ? `${doctor?.name}, ${doctor?.degreeTypes?.map(x => x).join(", ")}` : `${doctor?.name}` ;
  }
}
