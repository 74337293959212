import { Component, Input } from '@angular/core';

import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-callout',
  templateUrl: './callout.component.html'
})
export class CalloutComponent implements ContentComponent {
  @Input() data: ComponentState;
}
