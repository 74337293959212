import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ContentModule } from '../content/content.module';
import { Hybrid1TemplateComponent } from './hybrid-1/hybrid-1.component';
import { Hybrid2TemplateComponent } from './hybrid-2/hybrid-2.component';
import { Info2TemplateComponent } from './info-2/info-2.component';
import { Landing3TemplateComponent } from './landing-3/landing-3.component';
import { Landing4TemplateComponent } from './landing-4/landing-4.component';
import { Landing5TemplateComponent } from './landing-5/landing-5.component';
import { Landing6TemplateComponent } from './landing-6/landing-6.component';
import { LayoutHelper } from 'libs/services/layout-helper';
import { ComponentsModule } from 'libs/components/components.module';
import { ClassesModule } from 'libs/classes/classes.module';

@NgModule({
  imports: [
    CommonModule,
    ContentModule,
    RouterModule,
    ComponentsModule,
    ClassesModule,
    ComponentsModule
  ],
  declarations: [
    Info2TemplateComponent,
    Hybrid1TemplateComponent,
    Hybrid2TemplateComponent,
    Landing3TemplateComponent,
    Landing4TemplateComponent,
    Landing5TemplateComponent,
    Landing6TemplateComponent
  ],
  exports: [
    Info2TemplateComponent,
    Hybrid1TemplateComponent,
    Hybrid2TemplateComponent,
    Landing3TemplateComponent,
    Landing4TemplateComponent,
    Landing5TemplateComponent,
    Landing6TemplateComponent
  ],
  providers: [
    LayoutHelper
  ]
})
export class TemplateModule { }
