import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { BaseContentPage } from 'libs/classes/base-content-page';
import { GroupBooklet } from 'libs/models/benefits.models';
import { ComponentState } from 'libs/models/pbc-models';
import { BenefitsSharedService } from 'libs/services/benefits-shared.service';
import { SeverityLevel } from 'libs/services/logging.service';
import { StringsService } from 'libs/services/strings.service';

@Component({
  selector: 'pbc-group-booklets',
  templateUrl: './group-booklets.component.html'
})
export class GroupBookletsComponent extends BaseContentPage implements OnInit {
  groupBookletsContent: ComponentState;
  groupsLoading = true;
  groupNumber = '';
  resultsPerPage = 1000;
  region: string;
  pageNumber = 0;
  groups: GroupBooklet[] = [];
  filteredGroups: GroupBooklet[] = [];
  groupsToDisplay: GroupBooklet[] = [];
  hasError = false;

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,
    constants: StringsService,
    private benefitsSharedService: BenefitsSharedService
  ) {
    super(false, tabTitle, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();

    this.region = this.contentService.isAkRegion() ? 'AK' : 'WA';

    const pageData = this.activatedRoute.snapshot.data;
    if (pageData) {
      this.contentService
        .getComponentState('content-' + pageData['page'], pageData['contentGroup'])
        .pipe(takeUntil(this.componentDestroyed$), catchError(err => this.handleError(err)))
        .subscribe(res => {
          this.groupBookletsContent = res;
        });
    }

    this.benefitsSharedService.getGroupBooklets(this.region)
      .pipe(takeUntil(this.componentDestroyed$), catchError(err => this.handleError(err)))
      .subscribe(res => {
        this.groups = res.plandocument;
        this.filteredGroups = res.plandocument;
        this.groupsToDisplay = this.filteredGroups.slice(0, this.resultsPerPage);
        this.groupsLoading = false;
      });
    }

  getPdfUrl(doc: string) {
    if (!this.groupBookletsContent) {
      return '';
    }
    return `${this.groupBookletsContent.content.pdfUrl}${this.region}/${doc}`;
  }

  filter() {
    this.pageNumber = 0;
    this.filteredGroups = this.groups.filter(group => group.groupnumber.toString().indexOf(this.groupNumber) > -1);
    this.groupsToDisplay = this.filteredGroups.slice(0, this.resultsPerPage);
  }

  reset() {
    this.pageNumber = 0;
    this.groupNumber = '';
    this.filteredGroups = this.groups;
    this.groupsToDisplay = this.filteredGroups.slice(0, this.resultsPerPage);
  }

  getPage(pageNumber: number) {
    this.pageNumber = pageNumber;
    window.scrollTo(0, 0);
    const startIndex = this.pageNumber * this.resultsPerPage;
    const endIndex = startIndex + this.resultsPerPage;
    this.groupsToDisplay = this.filteredGroups.slice(startIndex, endIndex);
  }

  showNextBtn() {
    return this.pageNumber < Math.floor(this.filteredGroups.length / this.resultsPerPage);
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
    return of(null);
  }
}
