import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentState, KeyValue } from 'libs/models/pbc-models';
import { ContentService } from 'libs/services/content.service';
import { HelperService } from 'libs/services/helper.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { StringsService } from 'libs/services/strings.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';

class DrugRequiringApproval {
  drugName: string;
  clinicalCriteria: string;
  quantityLimit: string;
  quantityPerFill: string;
  typeOfRequirement: string;
  chartNotes: string;
  medicalPolicy1: string;
  medicalPolicy2: string;
}

class RawParsedDrugTable {
  druglisttable: {
    druglistitem: {
      drugname: string;
      clinicalcriteria: string;
      quantitylimit: string;
      quantityperfill: string;
      typeofrequirement: string;
      chartnotes: string;
      medicalpolicy1: string;
      medicalpolicy2: string;
    }[]
  };
}

@Component({
  selector: 'pbc-prior-auth-drugs',
  templateUrl: './prior-auth-drugs.component.html'
})
export class PriorAuthDrugsComponent implements OnInit, OnDestroy {
  @Input() data: ComponentState;
  dropdownOptions;
  selectedDrug;
  hasError = false;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private readonly helper: HelperService,
    private readonly constants: StringsService,
    private readonly contentService: ContentService,
    private readonly router: Router,
    private readonly logger: LoggingService) { }

  ngOnInit() {

    if (!!this.data && !!this.data.content && !!this.data.content.dataFilePath) {
      this.contentService.getContentFile(this.data.content.dataFilePath, EnvironmentService.variables.contentDomain)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.dropdownOptions = this.parseXml(result);
        });
    }
  }

  parseXml(xml: string): DrugRequiringApproval[] {
    const result = this.helper.parseXml(xml);
    if (!result || !result.druglisttable) {
      this.handleError(new Error('Empty or invalid xml'));
      return;
    }
    return this.processRawObject(result);
  }

  getFormLink() {
    const values = [new KeyValue('drugName', `${this.selectedDrug.drugName}`), new KeyValue('state', this.getState())];
    const link = this.constants.replaceContentTokens(this.data.content.formLink, values);
    return link;
  }
  getHMOFormLink() {
    const values = [new KeyValue('drugName', `${this.selectedDrug.drugName}`), new KeyValue('state', this.getState())];
    const link = this.constants.replaceContentTokens(this.data.content.HMOFormLink, values);
    return link;
  }

  private getState() {
    return this.contentService.isAkRegion() ? 'AK' : 'WA';
  }

  private processRawObject(rawParsedContent: RawParsedDrugTable): DrugRequiringApproval[] {
    return rawParsedContent.druglisttable.druglistitem.map(drug => {
      return {
        drugName: drug.drugname,
        clinicalCriteria: drug.clinicalcriteria,
        quantityLimit: drug.quantitylimit,
        quantityPerFill: drug.quantityperfill,
        typeOfRequirement: drug.typeofrequirement,
        chartNotes: drug.chartnotes,
        medicalPolicy1: drug.medicalpolicy1,
        medicalPolicy2: drug.medicalpolicy2,
      };
    });
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
