import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { BioComponent } from './bio/bio.component';
import { CalloutColoredBgComponent } from './callout-colored-bg/callout-colored-bg.component';
import { CtaCardComponent } from './cta-card/cta-card.component';
import { ImageBg3ContentBannerComponent } from './image-bg-3-content-banner/image-bg-3-content-banner.component';
import { ImageBgContentBannerComponent } from './image-bg-content-banner/image-bg-content-banner.component';
import { ImageBgContentOverlayOneComponent } from './image-bg-content-overlay-1/image-bg-content-overlay-1.component';
import { ImageBgContentOverlay2Component } from './image-bg-content-overlay-2/image-bg-content-overlay-2.component';
import { ImageBgContentOverlayShadeComponent } from './image-bg-content-overlay-shade/image-bg-content-overlay-shade.component';
import { LargeImageCenterCalloutComponent } from './large-image-center-callout/large-image-center-callout.component';
import { LargeImageMultiCtaComponent } from './large-image-multi-cta/large-image-multi-cta.component';
import { StatementBorderMiniHeaderComponent } from './statement-border-mini-header/statement-border-mini-header.component';
import { StatementComponent } from './statement/statement.component';
import { TwoPackBorderComponent } from './two-pack-border/two-pack-border.component';
import { TwoPackLargeComponent } from './two-pack-large/two-pack-large.component';
import { TwoPackMiniHeaderShadeComponent } from './two-pack-mini-header-shade/two-pack-mini-header-shade.component';
import { TwoPackMiniHeaderComponent } from './two-pack-mini-header/two-pack-mini-header.component';
import { TwoPackVerticalOverlayComponent } from './two-pack-vertical-overlay/two-pack-vertical-overlay.component';
import { PipesModule } from 'libs/pipes/pipes.module';
import { DirectivesModule } from 'libs/directives/directives.module';
import { ComponentsModule } from 'libs/components/components.module';

@NgModule({
    imports: [
        NgbModule,
        CommonModule,
        RouterModule,
        PipesModule,
        DirectivesModule,
        ComponentsModule
    ],
    declarations: [
        LargeImageMultiCtaComponent,
        CtaCardComponent,
        StatementBorderMiniHeaderComponent,
        TwoPackMiniHeaderShadeComponent,
        StatementComponent,
        TwoPackMiniHeaderComponent,
        ImageBgContentOverlayOneComponent,
        TwoPackBorderComponent,
        CalloutColoredBgComponent,
        ImageBg3ContentBannerComponent,
        TwoPackVerticalOverlayComponent,
        ImageBgContentOverlayShadeComponent,
        ImageBgContentOverlay2Component,
        ImageBgContentBannerComponent,
        TwoPackLargeComponent,
        LargeImageCenterCalloutComponent,
        BioComponent
    ],
    exports: [
        LargeImageMultiCtaComponent,
        CtaCardComponent,
        StatementBorderMiniHeaderComponent,
        TwoPackMiniHeaderShadeComponent,
        StatementComponent,
        TwoPackMiniHeaderComponent,
        ImageBgContentOverlayOneComponent,
        TwoPackBorderComponent,
        CalloutColoredBgComponent,
        ImageBg3ContentBannerComponent,
        TwoPackVerticalOverlayComponent,
        ImageBgContentOverlayShadeComponent,
        ImageBgContentOverlay2Component,
        ImageBgContentBannerComponent,
        TwoPackLargeComponent,
        LargeImageCenterCalloutComponent,
        BioComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContentModule { }
