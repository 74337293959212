import { Component, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { LayoutHelper } from 'libs/services/layout-helper';
import { BaseFlexTemplate } from '../base-flex-template';

@Component({
  selector: 'pbc-flex-two-col-1',
  templateUrl: './flexible-two-col.component.html'
})
export class FlexibleTwoColTemplateComponent extends BaseFlexTemplate {

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,
    public layoutHelper: LayoutHelper,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(tabTitle, activatedRoute, layoutHelper, componentFactoryResolver);
  }

}
