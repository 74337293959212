import { FlexibleTemplateComponent } from 'libs/templates/flexible/flexible.component';

export const WeyRoutes = [

  // WEY: HOME
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home-wey', contentGroup: 'member-home-wey' } },

  //  WEY: EXPLORE RESOURCES: OTHER
  { path: 'forms', component: FlexibleTemplateComponent, data: { page: 'forms-wey', contentGroup: 'member-forms-wey' } },

  // WEY: GUIDANCE
  { path: 'data-collection', component: FlexibleTemplateComponent, data: { page: 'data-collection-wey', contentGroup: 'member-data-collection-wey' } },
  { path: 'fraud-abuse', component: FlexibleTemplateComponent, data: { page: 'fraud-abuse-wey', contentGroup: 'member-fraud-abuse-wey' } },
  { path: 'privacy-practices', component: FlexibleTemplateComponent, data: { page: 'privacy-practices-wey', contentGroup: 'member-privacy-practices-wey' } },
  { path: 'aviso-practicas-privacidad', component: FlexibleTemplateComponent, data: { page: 'aviso-practicas-privacidad-wey', contentGroup: 'member-aviso-practicas-privacidad-wey' } },
  { path: 'terms-conditions', component: FlexibleTemplateComponent, data: { page: 'terms-conditions-wey', contentGroup: 'member-terms-conditions-wey' } },
  { path: 'section-1557', component: FlexibleTemplateComponent, data: { page: 'section-1557-wey', contentGroup: 'member-section-1557-wey' } },
  { path: 'medical-policies', component: FlexibleTemplateComponent, data: { page: 'medical-policies-wey', contentGroup: 'member-medical-policies-wey' } },
  // WEY: GET CONNECTED
  { path: 'contact-us', component: FlexibleTemplateComponent, data: { page: 'contact-us-wey', contentGroup: 'member-contact-us-wey' } }
];
