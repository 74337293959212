<div *ngIf="data?.isVisible" data-id="card-row">
  <div *ngIf="data?.content?.introduction" [innerHTML]="data?.content?.introduction"></div>

  <!-- Standard (top image optional)  -->
  <div *ngIf="data?.content?.cardStyle === 'default' || data?.content?.cardStyle === 'defaultImage' " class="row card-row">
    <ng-container *ngFor="let section of getSections(); last as isLast">
      <div class="col-12 col-sm pb-sm-0" [ngClass]="{'pb-3': !isLast}">
        <div class="card">
          <img *ngIf="section?.cardImagePath" alt="" [src]="section?.cardImagePath | urlQualify" class="card-img-top img-fluid" aria-hidden="true">
          <div class="d-flex flex-column h-100">
            <div class="card-body">
              <div *ngIf="section?.cardHeader" [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml"></div>
              <div class="card-text" [innerHTML]="section?.cardBody  | decodeHtml"></div>
            </div>
            <div *ngIf="section?.cardFooter" class="card-footer background-white border-0 mt-auto">
              <div [innerHTML]="section?.cardFooter | decodeHtml"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>


<!-- Icon card border with icon circle top centered -->
  <div class="row card-row" *ngIf="data?.content?.cardStyle === 'iconTop'">
    <ng-container *ngFor="let section of getSections(); index as index; last as isLast">
      <div class="col-12 {{colWidth(getSections().length)}} pb-5" [ngClass]="{'pb-0': !isLast}">
        <div class="card icon-card">
          <i *ngIf="data?.content?.useFontIcon && section?.iconStyle" [class]="section?.iconStyle" aria-hidden="true"></i>
          <img class="img-icon" *ngIf="!data?.content?.useFontIcon && section?.cardImagePath" alt="" [src]="section?.cardImagePath | urlQualify" aria-hidden="true">
          <ng-container  *ngIf="!data?.content?.useFontIcon && !section?.cardImagePath || data?.content?.useFontIcon && !section?.iconStyle">
            <!-- Please check smartform inputs.  There is a setting that is preventing the icon from showing -->
          </ng-container>
          <div class="d-flex flex-column h-100">
            <div class="card-body">
              <div *ngIf="section?.cardHeader" [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml"></div>
              <div class="row no-gutters align-items-center">
                <div class="col px-3 pt-3">
                  <div [innerHTML]="section?.cardBody | decodeHtml" [ngClass]="{'mb-3': section?.cardFooter}"></div>
                </div>
              </div>
            </div>
            <div *ngIf="section?.cardFooter" class="card-footer mt-auto" [innerHTML]="section?.cardFooter | decodeHtml"></div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- Icon card border or background -->
 <div class="row card-row" *ngIf="data?.content?.cardStyle === 'iconBordered' || data?.content?.cardStyle === 'iconBackground'">
    <ng-container *ngFor="let section of getSections(); index as index; last as isLast">
      <div class="col col-lg mb-3 mb-lg-0" [ngClass]="{'pb-3 pb-lg-0': !isLast}">
        <div class="card shadow-none" [ngClass]="data?.content?.cardStyle === 'iconBordered' ? 'border-accent-tertiary' : 'background-lightest border-0'">
          <div class="card-body">
            <div class="row no-gutters h-100">
              <div class="col-auto col-lg-1">
                <i class="secondary-icon-color" *ngIf="data?.content?.useFontIcon && section?.iconStyle" [ngClass]="section?.iconStyle" aria-hidden="true"></i>
                <img class="img-icon" *ngIf="!data?.content?.useFontIcon && section?.cardImagePath" alt="" [src]="section?.cardImagePath | urlQualify" aria-hidden="true">
                <ng-container  *ngIf="!data?.content?.useFontIcon && !section?.cardImagePath || data?.content?.useFontIcon && !section?.iconStyle">
                  <!-- Please check smartform inputs.  There is a setting that is preventing the icon from showing -->
                </ng-container>
              </div>
              <div class="col ml-3">
                <div class="d-flex flex-column h-100">
                  <div *ngIf="section?.cardHeader" [innerHTML]="((createHeading(section?.cardHeaderLevel, section?.cardHeaderStyle, section?.cardHeader)) | decodeHtml) | safehtml"></div>
                  <div [innerHTML]="section?.cardBody | decodeHtml" [ngClass]="{'mb-3': section?.cardFooter}"></div>
                  <div *ngIf="section?.cardFooter" class="border-0 mt-auto" [innerHTML]="section?.cardFooter | decodeHtml"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="data?.content?.AdditionalContent" [innerHTML]="data?.content?.AdditionalContent"></div>

</div>

