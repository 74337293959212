import { Component, OnInit, Input, OnDestroy, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ComponentState } from 'libs/models/pbc-models';
import { ContentService } from 'libs/services/content.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { HelperService } from 'libs/services/helper.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'pbc-xml-data-table',
  templateUrl: './xml-data-table.component.html'
})

export class XmlDataTableComponent implements OnInit, OnDestroy {
  @Input() data: ComponentState;
  xml: any;
  router: Router;
  componentDestroyed$: Subject<boolean> = new Subject();
  tableHeaderText: string;
  columns = [];
  rows = [];

  constructor(
    private readonly contentService: ContentService
    , private readonly helper: HelperService
    , private readonly logger: LoggingService
    , private readonly injector: Injector) {
    this.router = this.injector.get(Router);
     }

  ngOnInit() {
    if (!!this.data && !!this.data.content && !!this.data.content.dataFilePath) {
      this.contentService.getContentFile(this.data.content.dataFilePath, EnvironmentService.variables.contentDomain)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.xml = this.helper.parseXml(result);
          this.tableHeaderText = this.data.content.tableHeader;
          this.getColumns();
          this.getRows(this.findRowList(this.data.content.rowObject, this.xml));
        });
    }
  }

  private getColumns() {
    const cols = JSON.parse(this.data.content.Columns);
    if (!cols) {
      return;
    }
    cols.forEach(column => {
      this.columns.push({ name: column.columnHeaderText, value: column.columnValueField, width: column.columnWidth });
    });
  }

  private findRowList(elementName: string, nodes: any): any[] {
    if (!nodes) {
      return [];
    }
    for (const property in nodes) {
      if (nodes.hasOwnProperty(property)) {
        if (property === elementName) {
          return nodes[property];
        } else if (typeof nodes[property] === 'object') {
          return this.findRowList(elementName, nodes[property]);
        }
      }
    }
  }

  private getRows(xmlElements: any[]): void {
    try {
      if (!xmlElements) {
        return;
      }
      //if the xmlElements is not an array, it means there is only one row
      if (!Array.isArray(xmlElements)) {
        xmlElements = [xmlElements]; // Convert single element to array
      }
      xmlElements.forEach(element => {
        this.processRowObject(element);
      });
    } catch (error) {
      this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    }
  }
  
  private processRowObject(element: any): void {
    const properties = [];
    this.columns.forEach(col => {
      if (!!element[col.value]) {
        properties.push({ name: col.name, width: col.width, value: element[col.value] });
      }
    });
    if (properties.length) {
      this.rows.push(properties);
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
