import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class PbcCookieService {
  readonly memberKeysCookie = 'pbcMemberKeys';
  readonly surrogateIdCookie = 'pbcSurrogateId';
  readonly memberAuthCookie = 'pbcMemberAuth';
  readonly sessionGuidCookie = 'pbcSessionGuid';
  readonly returnUrlCookie = 'pbcReturnUrl';
  readonly regionCookie = 'pbcRegion';
  readonly lwwaRegion = 'lwwaRegion';
  readonly localeCookie = 'pbcLocale';

  constructor(private readonly cookieService: CookieService) { }

  setExpiringCookie(name: string, value: string, expiration: Date, crossDomain = true) {
    this.cookieService.set(name, value, expiration, '/', crossDomain ? this.getCookieDomain() : null, true, 'Lax');
  }

  setSessionCookie(name: string, value: string, crossDomain = true) {
    this.setExpiringCookie(name, value, null, crossDomain);
  }

  get(name: string): string {
    return this.cookieService.get(name);
   }

  delete(name: string): void {
    const today = new Date();
    const futureCookieExpireDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
    this.setExpiringCookie(name, '', futureCookieExpireDate);
    this.cookieService.delete(name);
  }

  private getCookieDomain(): string {
    let domain = 'localhost';
    if (!!window && !!window.location && window.location.hostname.includes('.')) {
      domain = window.location.hostname.substr(window.location.hostname.indexOf('.'));
    }
    return domain;
  }

}
