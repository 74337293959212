import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safehtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private readonly _sanitizer: DomSanitizer) { }

  transform(value: string): SafeHtml {
    if (!value) {
      return value;
    }
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
