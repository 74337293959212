import { Component, Input } from '@angular/core';

import { DataComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-footer-social-media',
  templateUrl: './footer-social-media.component.html'
})
export class FooterSocialMediaComponent {
  @Input() footerContent: DataComponentState;

}
