import { Pipe, PipeTransform } from '@angular/core';
import { EnvironmentService } from 'libs/services/environment-variables.service';

@Pipe({
  name: 'urlQualify'
})
export class UrlQualifyPipe implements PipeTransform {

  transform(url: string, args?: any): any {
    if (!url || url === undefined) {
      return url;
    }
    if (url.startsWith('http')) {
      return url;
    }
    if (url.startsWith('/')) {
      return EnvironmentService.variables.contentDomain + url;
    }
    return `${EnvironmentService.variables.contentDomain}/${url}`;
  }
}
