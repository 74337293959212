import { Injectable } from '@angular/core';
import { PbcCookieService } from './pbc-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class AiSessionService {
  private readonly sessionCookieName = 'ai_session';
  private readonly userCookieName = 'ai_user';
  sessionId: string;
  userId: string;

  constructor(private readonly pbcCookieService: PbcCookieService) {
    this.sessionId = this.getSessionId();
    this.userId = this.getUserId();
  }

  private getSessionId(): string {
    return this.parseValue(this.pbcCookieService.get(this.sessionCookieName));
  }

  private getUserId(): string {
    return this.parseValue(this.pbcCookieService.get(this.userCookieName));
  }

  private parseValue(value: string): string {
    return (!!value) ? value.split('|', 1).toString() : '';
  }

  readSessionId(): string {
    return this.sessionId =  (!!this.sessionId) ? this.sessionId : this.getSessionId();
  }
}
