<div *ngIf="!browserOnline.value" id="site-offline-message" role="alert" class="alert alert-warning" aria-live="polite"
  aria-label="Site-Level Offline Message">
  <div class="d-flex">
    <div class="align-self-stretch"><i aria-hidden="true" class="material-icons pr-1">warning</i></div>
    <div>
      <p>You are currently offline.</p>
    </div>
    <div aria-hidden="true" class="ml-auto close"></div>
  </div>
</div>

<pbc-alert [data]="globalAlert" [level]="'Site'"></pbc-alert>

<header class="main-loading" trackScroll [site]="siteName" [ngClass]="{'main-in': headerContent?.content}">
  <nav aria-labelledby="nav1">
    <div id="nav1">
      <div *ngIf="!isAuthenticated && headerContent?.content?.audienceNavigationVisible === 'true'"
        class="nav-tertiary-container">
        <pbc-audience-nav [content]="audienceNav?.content"></pbc-audience-nav>
      </div>
    </div>
  </nav>

  <nav aria-labelledby="nav2" class="navbar navbar-expand-md">
    <div id="nav2" class="logo">
      <a class="navbar-brand" href="{{homePageUrl}}" pbcAnalyticsTag data-analytics-override-route="header"
        data-analytics-title="header logo">
        <img [src]="headerContent?.content?.primaryLogoUrl" aria-label="Premera home" alt="" aria-hidden="true">
        <span class="sr-only">Premera home</span>
      </a>
      <span *ngIf="!isAuthenticated && headerContent?.content?.coBrandingLogoUrl"><img class="mr-3 ml-sm-n3"
          id="childSiteLogo" [src]="headerContent?.content?.coBrandingLogoUrl" alt="" aria-hidden="true" /></span>
    </div>

    <div class="nav-primary-container d-print-none">
      <button *ngIf="!isCycOnly && !hasEmptyMemberKeys" class="navbar-toggler" type="button" (click)="navbarToggle()"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" pbcAnalyticsTag
        data-analytics-override-route="header" data-analytics-title="hamburger menu">
        <div class="button" [ngClass]="{ 'open': !isNavbarCollapsed }">
          <i aria-hidden="true" class="fas fa-bars icon-default"></i>
          <i aria-hidden="true" class="fas fa-times icon-hover"></i>
        </div>
      </button>

      <div *ngIf="!isCycOnly && !hasEmptyMemberKeys" [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse">
        <pbc-primary-nav [primaryNavMenus]="primaryNavMenus" (menuItemClicked)="navbarCollapse()"
          [isVisible]="!isCycOnly && !hasEmptyMemberKeys"></pbc-primary-nav>
      </div>
    </div>
    <div *ngIf="siteName!=='lwwa' && enableNotificationIcon" class="notify-popover d-print-none">
      <ng-container>
        <div class="notification-mb">
          <button type="button" aria-label="notifications" title="notifications" #popOver="ngbPopover" class="notifications"
            data-placement="bottom" [ngbPopover]="popData" [autoClose]="'outside'" (click)="clearNotifiCnt()" 
            pbcAnalyticsTag data-analytics-title="notification button click" data-analytics-override-route="notification" [attr.aria-label]="notificationCountAria(notificationCount)">
            <i class="fa fa-bell text-accent" aria-hidden="false" style="font-size: 1.8em;"></i>
          <span *ngIf="notificationCount > 0 && notificationCount <= 9999" class="notification_count">{{
            notificationCount }}</span>&nbsp;&nbsp;</button>
          <span *ngIf="notificationCount > 9999" class="notification_count">9999+</span>&nbsp;&nbsp;
        </div>
      </ng-container>
      <ng-template #popData>
        <pbc-notification-center title="notification" [notificationCount]="notificationCount" [notificationDetails]="notificationDetails"
          (closePop)="popOver.close()" (counter)="counterChange($event)" ></pbc-notification-center>
      </ng-template>
    </div>
    <div
      *ngIf="!!headerContent?.content?.signInUrl && (!isAuthenticated || headerContent?.content?.secondaryNavigationVisible === 'true')"
      class="nav-secondary-container d-print-none">
      <span *ngIf="headerContent?.content?.signInHelpText" class="mr-2" data-id="additional-text-signin"
        [innerHtml]="headerContent?.content?.signInHelpText"></span>
      <a data-id="signInButtonNoMenu" *ngIf="headerContent?.content?.secondaryNavigationVisible !== 'true'"
        class="btn btn-secondary mr-sm-0" [href]="signInUrl" pbcAnalyticsTag data-analytics-override-route="header">
        <strong>{{headerContent?.content?.signInText}}</strong></a>
      <div id="isHmoVisible" *ngIf="isHmoUser" hidden="true">{{isHmoUser}}</div>
      <pbc-secondary-nav *ngIf="headerContent?.content?.secondaryNavigationVisible === 'true'"
        [hideRegionSwitch]="hideRegionSwitch" [switchContent]="switchContent" [homePageUrl]="homePageUrl"
        [content]="secondaryNav?.content" [signInUrl]="signInUrl" [signInText]="headerContent?.content?.signInText">
      </pbc-secondary-nav>
    </div>

    <ng-container *ngIf="!!headerContent?.content?.marketoFormId && !!headerContent?.content?.marketoFormButtonText">
      <pbc-marketo-launch [formId]="headerContent?.content?.marketoFormId"
        [title]="headerContent?.content?.marketoFormButtonText"></pbc-marketo-launch>
      <a class="btn btn-primary mr-0" data-analytics-override-route="header"
        [href]="'/form-launch/'+ headerContent?.content?.marketoFormId"
        [innerHtml]="headerContent?.content?.marketoFormButtonText" pbcAnalyticsTag></a>
    </ng-container>

  </nav>
</header>