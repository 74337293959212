import {Directive, ElementRef, Output, EventEmitter, HostListener} from '@angular/core';

@Directive({
    selector: '[pbcCloseDropdown]'
})
export class CloseDropdownDirective {
    constructor(private elementRef: ElementRef) {
    }

    @Output()
    public pbcCloseDropdown = new EventEmitter<MouseEvent>();

    @HostListener('document:click', ['$event', '$event.target'])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }


        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.pbcCloseDropdown.emit(event);
        }

    }

}
