<div class="container m-3">
  <h2 class="text-center">{{data?.content?.title}}</h2>

  <form #zipSearchForm="ngForm" appIdentityRevealed (ngSubmit)="search(zipSearchForm.value.zipCode)">

    <div class="row justify-content-center m-3">
      <div class="col-3">
        <div class="cross-validation"
          [class.cross-validation-error]="zipSearchForm.errors?.identityRevealed && (zipSearchForm.touched || zipSearchForm.dirty)">
          <div class="form-group">
            <label for="zipCode" class="visually-hidden">{{data?.content?.zipCode}}</label>
            <input id="zipCode" name="zipCode" class="form-control d-inline-block mr-3" required maxlength="5"
              pattern=".{0,5}" [(ngModel)]="selectedSalesRepZip"
              [ngClass]="selectedSalesRepZip ? 'hasLength': 'hasNoLength'" #zipCode="ngModel">
            <span class="zoom-label">{{data?.content?.zipCode}}</span>
            
            <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="invalid-feedback d-block">
              <div *ngIf="zipCode.errors.required"><i aria-hidden="true" class="material-icons icon-small">error</i>
                {{data?.content?.zipCodeRequiredErrorMessage}}</div>
              <div *ngIf="zipCode.errors.pattern || zipCode.errors.maxlength"><i aria-hidden="true"
                  class="material-icons icon-small">error</i>{{data?.content?.zipCodeInvalidErrorMessage}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2">
        <div class="form-group">
          <button type="submit" class="form-control btn btn-secondary"
            [disabled]="zipSearchForm.invalid" pbcAnalyticsTag data-analytics-title="medicare-zip-search" 
            [attr.aria-label]="data?.content?.searchBtn">{{data?.content?.searchBtn}}</button>
        </div>
      </div>
    </div>


    <div class="submitted-message" *ngIf="zipSearchForm.submitted">
      <div class="row justify-content-center">
        <div class="col-4 mb-3">
          <div>{{ selectedSalesRepCity }}</div>
          <div>
            <h3>{{selectedSalesRep?.name}}</h3>
          </div>
          <br>
          <p [innerHtml]="selectedSalesRepDetails?.bio"></p>
          <div class="row m-0">
            <ng-container *ngIf="!!data?.content?.contactMeFormId && !!data?.content?.contactMeText">
              <pbc-marketo-launch [title]="data?.content?.contactMeText" [data]="marketoData"></pbc-marketo-launch>
              <a [class]="data?.content?.contactMeStyle | buttonType" data-analytics-override-route="header" [href]="'/form-launch/' + data?.content?.contactMeFormId" [innerHtml]="data?.content?.contactMeText" pbcAnalyticsTag></a>
            </ng-container>
          </div>
          <div class="row m-0 mt-2">
            <pbc-cta [model]="ctaTwo"></pbc-cta>
          </div>
        </div>
        <div class="col-4">
          <img class="mr-3" *ngIf="selectedSalesRepDetails?.photo" src="{{selectedSalesRepDetails?.photo}}" alt="{{data?.content?.imageUrlAltText}}">
        </div>
      </div>
    </div>
  </form>

</div>