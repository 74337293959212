import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { ComponentState, CtaModel, AlertMessage } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-icon-card',
  templateUrl: './icon-card.component.html'
})
export class IconCardComponent implements OnChanges, OnDestroy {
  @Input() headerOverride: string;
  @Input() data: ComponentState;
  @Input() error: AlertMessage;
  @Input() useMediaClass = true;
  @Input() customAriaLabelForScreenReader: string;
  ctaOne: CtaModel;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor() { }

  ngOnChanges(): void {
    if (this.data && this.data.content) {
      this.ctaOne = new CtaModel(this.data.content.ctaOneUrl, this.data.content.ctaOneText, this.data.content.ctaOneStyle,
        this.data.content.ctaOneExternal, this.data.content.ctaOneAnalyticsTracker, null, this.data.content.ctaOnePreventDefault);
    }
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
