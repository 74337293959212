<div class="row no-gutters">
  <div class="col-8">
    <pbc-footer-logo [footerContent]="footerContent" [logoImgUrl]="logoImgUrl" [homePageUrl]="homePageUrl">
    </pbc-footer-logo>
  </div>
</div>

<div *ngIf="horizontalLinks" class="row no-gutters">
  <div class="col" [innerHtml]="footerContent?.content?.htmlBlock"></div>
</div>

<nav class="navbar">
  <ul class="navbar-nav" data-id="getConnectedSection">
    <li class="nav-item dropdown" *ngFor="let contactMenu of contactMenus; let index = index"
      (click)="toggleSubmenu(index)">
      <ng-container *ngIf="contactMenu.contactMenuGroupBox?.headerTitle">

        <a class="nav-link dropdown-toggle" id="ContactMenuLinks{{index}}" data-analytics-tag="contact_menu"
          role="button" data-toggle="collapse" aria-haspopup="true" [attr.aria-expanded]="openMenu === index"
          aria-controls="collapseContactMenus" href onclick="return false;">
          {{ contactMenu.contactMenuGroupBox?.headerTitle }}
        </a>
        <ng-container *ngIf="openMenu === index">
          <div [attr.aria-labelledby]="'ContactMenuLinks'+index" id="collapseContactMenus{{index}}">
            <ng-container *ngFor="let link of contactMenu.contactMenuGroupBox?.linksGroupBox;">
              <ng-container *ngIf="needsDomain(link.route); else isRoute">
                <a class="dropdown-item" [href]="addDomain(link.route)" [attr.lang]="link.lang" pbcAnalyticsTag
                  data-analytics-override-route="footer">{{ link.title }}</a>
              </ng-container>
              <ng-template #isRoute>
                <a class="dropdown-item" [routerLink]="dropToken(link.route)" [attr.lang]="link.lang" pbcAnalyticsTag
                  data-analytics-override-route="footer">{{ link.title }}</a>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </li>

    <ng-container *ngIf="!horizontalLinks">
      <div data-id="footerNavLinks" *ngFor="let menu of menus; let index = index;"
        (click)="toggleSubmenu(index + contactMenus?.length)">
        <div *ngIf="menu.footerMenuGroupBox.headerTitle">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [attr.id]="'ConnectedMenuLinks' + index" role="button"
              data-toggle="collapse" aria-haspopup="true"
              [attr.aria-expanded]="(openMenu - contactMenus?.length) === index" aria-controls="collapseMenus" href
              onclick="return false;" data-analytics-tag="nav-link" data-analytics-override-route="footer">
              {{menu.footerMenuGroupBox?.headerTitle}}
            </a>
            <ng-container *ngIf="(openMenu - contactMenus?.length) === index">
              <div aria-labelledby="ConnectedMenuLinks" id="collapseMenus">
                <ng-container *ngFor="let link of menu.footerMenuGroupBox?.linksGroupBox;">
                  <div *ngIf="link.enabled">
                    <ng-container *ngIf="needsDomain(link.route); else isRoute">
                      <a class="dropdown-item" [href]="addDomain(link.route)" [attr.lang]="link.lang" pbcAnalyticsTag [attr.target]="link.external? '_blank':'_self'" 
                        data-analytics-override-route="footer">{{ link.title }}</a>
                    </ng-container>
                    <ng-template #isRoute>
                      <a class="dropdown-item" [routerLink]="dropToken(link.route)" [attr.lang]="link.lang" [attr.target]="link.external? '_blank':'_self'" 
                        pbcAnalyticsTag data-analytics-override-route="footer">{{ link.title }}</a>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </li>
        </div>
      </div>
    </ng-container>
  </ul>
</nav>
<div class="row no-gutters">
  <div class="col-auto">
    <pbc-footer-social-media *ngIf="!hideSocialMedia" [footerContent]="footerContent"></pbc-footer-social-media>
  </div>

  <pbc-footer-mobile-apps *ngIf="!hideMobileApps" [footerContent]="footerContent"></pbc-footer-mobile-apps>
</div>
<ng-container *ngIf="showNcqaLogo()">
  <img id="NCQA" style="width: 6em" src="assets/image/ncqa-accredited-transparent.png" alt="NCQA Badge" />
</ng-container>
<ng-container *ngIf="showNcqaDarkLogo()"> 
  <a href="/policies-and-procedures" target="_blank">
  <img id="NCQA" style="width: 6em" src="assets/image/NCQA_for_dark_background.png" alt="NCQA Badge" />
</a>
</ng-container>