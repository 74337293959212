<h2 class="h4 mb-3" *ngIf="headerOverride || data?.content?.iconCardHeader"
  [innerHtml]="(headerOverride || data?.content?.iconCardHeader) | safehtml"></h2>
<div class="border-light p-4 h-100">
  <div [class]="useMediaClass ? 'media': ''">
    <img class="mr-3" *ngIf="data?.content?.imageUrl" [src]="(data?.content?.imageUrl | urlQualify)"
      alt="{{data?.content?.imageUrlAltText}}">
    <div class="media-body">
      <h3 class="mt-0">{{data?.content?.title}}</h3>
      <div [innerHtml]="data?.content?.body | safehtml"></div>
      <div class="mb-0" *ngIf="!error?.isVisible">
        <pbc-cta [model]="ctaOne" [customAriaLabelForScreenReader]="customAriaLabelForScreenReader"></pbc-cta>
      </div>
      <div *ngIf="error?.isVisible" class="mt-4">
        <pbc-alert [alert]="error" [level]="'Component'"></pbc-alert>
      </div>
    </div>
  </div>
</div>
