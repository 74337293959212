import { Directive, ElementRef, HostListener, Input } from "@angular/core";

@Directive({
    selector: '[trackScroll]'
})
export class TrackScrollDirective {
    @Input() site: string;
    constructor(private elementRef: ElementRef) {
    }

    @HostListener('window:scroll', ['$event'])
    onScroll(event) {
        if (this.site === 'lwwa') {
            if (window.scrollY !== 0) {
                this.stickyHeader(true);
            } else {
                this.stickyHeader(false);
            }
        }
    }

    stickyHeader(setStickyHeader: boolean) {
        if (setStickyHeader) {
            this.elementRef.nativeElement.setAttribute('style', 'position:fixed;top:0')
        }
        else {
            this.elementRef.nativeElement.setAttribute('style', 'position:relative;top:unset')
        }
    }
}