import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeExternalTarget'
})
export class SafeExternalTargetPipe implements PipeTransform {

  transform(value: any): any {
    if (!value) {
      return ''
    }
    return value.toLowerCase() === 'true' ? 'noopener' : '';
  }

}
