import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalTarget'
})
export class ExternalTargetPipe implements PipeTransform {

  constructor() { }

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.toLowerCase() === 'true' ? '_blank' : '';
  }
}
