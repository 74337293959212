<section *ngIf="data?.isVisible" class="mb-5">
  <div [innerHtml]="data?.content?.introduction"></div>
  <pbc-ngb-accordion #acc="pbcNgbAccordion" activeIds="{{ shouldOpenFirstOnLoad() ? 'acc-panel-0' : '' }}">
    <pbc-ngb-panel *ngFor="let section of getSections(); let index = index" id="acc-panel-{{index}}">
      <ng-template pbcNgbPanelTitle>
        <div [innerHtml]="section?.sectionHeader | decodeHtml">
        </div>
      </ng-template>
      <ng-template pbcNgbPanelContent>
        <div [innerHtml]="section?.sectionBody | decodeHtml" class="p-4"></div>
      </ng-template>
    </pbc-ngb-panel>
  </pbc-ngb-accordion>
  <div [innerHtml]="data?.content?.AdditionalContent"></div>
</section>
