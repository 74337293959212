import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';

import { FindADoctorComponent } from 'libs/components/find-care/find-a-doctor.component';
import { InternalServerErrorComponent } from 'libs/errors/internal-server-error/internal-server-error.component';
import { PageNotFoundComponent } from 'libs/errors/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from 'libs/errors/unauthorized/unauthorized.component';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { Info1TemplateComponent } from 'libs/templates/info-1/info-1.component';
import { environment } from '../environments/environment';
import { CoveredDrugsComponent } from './pharmacy/covered-drugs/covered-drugs.component';
import { SingleFormularyTemplateComponent } from './pharmacy/single-formulary-template/single-formulary-template.component';
import { AagRoutes } from './routes/aag.routing';
import { AmazonRoutes } from './routes/amazon.routing';
import { WeyRoutes } from './routes/wey.routing';
import { GroupBookletsComponent } from './shop-for-plans/group-booklets/group-booklets.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { FlexibleTemplateComponent } from 'libs/templates/flexible/flexible.component';
import { FlexibleTwoColTemplateComponent } from 'libs/templates/flexible-two-col/flexible-two-col.component';
import { SharedAdminRoutes } from './routes/shared-admin.routing';
import { PressReleaseRoutes } from './routes/press-releases.routing';
import { TMobileRoutes } from './routes/tmobile.routing';
import { NotificationCenterComponent } from 'libs/components/notification-center/notification-center.component';

const visitorRoutes: Routes = [

  // CHOOSE PLAN
  { path: 'individual-family-plans', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'individual-family-plans-v2', contentGroup: 'member-individual-family-plans' } },
  { path: 'individual-family-plans/health-plans-by-area', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'health-plans-by-area', contentGroup: 'member-health-plans-by-area' } },
  { path: 'individual-family-plans/health-plans-by-area-previous-year', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'health-plans-by-area-previous-year', contentGroup: 'member-health-plans-by-area-previous-year' } },
  { path: 'individual-family-plans/renew', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'renew', contentGroup: 'member-renew' } },
  { path: 'get-ready-to-enroll', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'get-ready-to-enroll', contentGroup: 'member-get-ready-to-enroll' } },
  { path: 'business-plans', component: FlexibleTemplateComponent, data: { page: 'business-plans', contentGroup: 'member-business-plans' } },
  { path: 'special-enrollment', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'special-enrollment', contentGroup: 'member-special-enrollment' } },
  { path: 'medicare-advantage', component: Info1TemplateComponent, data: { page: 'medicare-advantage', contentGroup: 'member-medicare-advantage' } },
  { path: 'medicare-supplement', component: Info1TemplateComponent, data: { page: 'medicare-supplement', contentGroup: 'member-medicare-supplement' } },
  { path: 'dental-plans', component: Info1TemplateComponent, data: { page: 'dental-plans', contentGroup: 'member-dental-plans' } },
  { path: 'group-booklets', component: GroupBookletsComponent, data: { page: 'group-booklets', contentGroup: 'member-group-booklets' } },

  // FIND CARE
  { path: 'find-a-doctor', component: FlexibleTemplateComponent, data: { pageWidth: 'full', feature: 'findADoctor', page: 'find-a-doctor', contentGroup: 'member-find-a-doctor' }, canActivate: [FeatureFlagGuard] },
  { path: '24-hour-care', redirectTo: 'virtual-care' },
  { path: 'decision-aids', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'decision-aids', contentGroup: 'member-decision-aids' } },
  { path: 'virtual-care', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'virtual-care', contentGroup: 'member-virtual-care' } },

  // EXPLORE RESOURCES: QUICK HELP
  { path: 'quick-help', component: FlexibleTwoColTemplateComponent, data: { page: 'quick-help', contentGroup: 'member-quick-help' } },
  { path: 'quick-help/claims', component: FlexibleTwoColTemplateComponent, data: { page: 'claims', contentGroup: 'member-claims' } },
  { path: 'quick-help/benefits', component: FlexibleTwoColTemplateComponent, data: { page: 'benefits', contentGroup: 'member-benefits' } },
  { path: 'quick-help/care-costs', component: FlexibleTwoColTemplateComponent, data: { page: 'care-costs', contentGroup: 'member-care-costs' } },
  { path: 'quick-help/accounts-id-cards', component: FlexibleTwoColTemplateComponent, data: { page: 'accounts-id-cards', contentGroup: 'member-accounts-id-cards' } },
  { path: 'quick-help/plan-basics', component: FlexibleTwoColTemplateComponent, data: { page: 'plan-basics', contentGroup: 'member-plan-basics' } },
  { path: 'quick-help/policies-practices', component: FlexibleTwoColTemplateComponent, data: { page: 'policies-practices', contentGroup: 'member-policies-practices' } },

  // EXPLORE RESOURCES: OTHER
  { path: 'how-health-plans-work', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'how-health-plans-work-v2', contentGroup: 'member-how-health-plans-work' } },
  { path: 'provider-networks', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'provider-networks', contentGroup: 'member-provider-networks' } },
  { path: 'glossary', component: Info1TemplateComponent, data: { page: 'glossary', contentGroup: 'member-glossary' } },
  { path: 'medical-expense-accounts/health-savings-accounts', component: FlexibleTemplateComponent, data: { page: 'health-savings-accounts', contentGroup: 'member-health-savings-accounts' } },
  { path: 'personal-health-support', component: FlexibleTemplateComponent, data: { page: 'personal-health-support', contentGroup: 'member-personal-health-support' } },
  { path: 'medical-expense-accounts', component: FlexibleTemplateComponent, data: { page: 'medical-expense-accounts', contentGroup: 'member-medical-expense-accounts' } },
  { path: 'coverage-while-traveling', component: FlexibleTemplateComponent, data: { page: 'coverage-while-traveling', contentGroup: 'member-coverage-while-traveling' } },
  { path: 'explanation-of-benefits', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'explanation-of-benefits', contentGroup: 'member-explanation-of-benefits' } },
  { path: 'go-mobile', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'go-mobile', contentGroup: 'member-go-mobile' } },
  { path: 'pay-monthly-bill', component: FlexibleTemplateComponent, data: { page: 'pay-monthly-bill', contentGroup: 'member-pay-monthly-bill' } },
  { path: 'get-started', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'get-started-v2', contentGroup: 'member-get-started' } },
  { path: 'weight-wellness', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'weight-wellness', contentGroup: 'member-weight-wellness' } },

  // EXPLORE RESOURCES: CARE ESSENTIALS.
  { path: 'care-essentials/preventive-care', component: FlexibleTwoColTemplateComponent, data: { page: 'preventive-care', contentGroup: 'member-preventive-care' } },
  { path: 'care-essentials/surgery', component: FlexibleTwoColTemplateComponent, data: { page: 'surgery', contentGroup: 'member-surgery' } },
  { path: 'care-essentials/womens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'womens-health', contentGroup: 'member-womens-health' } },
  { path: 'care-essentials/mens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mens-health', contentGroup: 'member-mens-health' } },
  { path: 'care-essentials/sexual-health-family-planning', component: FlexibleTwoColTemplateComponent, data: { page: 'sexual-health-family-planning', contentGroup: 'member-sexual-health-family-planning' } },
  { path: 'care-essentials/kids-health', component: FlexibleTwoColTemplateComponent, data: { page: 'kids-health', contentGroup: 'member-kids-health' } },
  { path: 'care-essentials/lgbt-health', component: FlexibleTwoColTemplateComponent, data: { page: 'lgbt-health', contentGroup: 'member-lgbt-health' } },
  { path: 'care-essentials/dental-health', component: FlexibleTwoColTemplateComponent, data: { page: 'dental-health', contentGroup: 'member-dental-health' } },
  { path: 'care-essentials/screenings', component: FlexibleTwoColTemplateComponent, data: { page: 'screenings', contentGroup: 'member-screenings' } },
  { path: 'care-essentials/pregnancy', component: FlexibleTwoColTemplateComponent, data: { page: 'pregnancy', contentGroup: 'member-pregnancy' } },
  { path: 'care-essentials/cancer', component: FlexibleTwoColTemplateComponent, data: { page: 'cancer', contentGroup: 'member-cancer' } },
  { path: 'care-essentials/diabetes', component: FlexibleTwoColTemplateComponent, data: { page: 'diabetes', contentGroup: 'member-diabetes' } },
  { path: 'care-essentials/hypertension', component: FlexibleTwoColTemplateComponent, data: { page: 'hypertension', contentGroup: 'member-hypertension' } },
  { path: 'care-essentials/mental-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mental-health', contentGroup: 'member-mental-health' } },
  { path: 'care-essentials/sleep-conditions', component: FlexibleTwoColTemplateComponent, data: { page: 'sleep-conditions', contentGroup: 'member-sleep-conditions' } },
  { path: 'care-essentials/back-pain', component: FlexibleTwoColTemplateComponent, data: { page: 'back-pain', contentGroup: 'member-back-pain' } },
  { path: 'care-essentials', component: FlexibleTwoColTemplateComponent, data: { page: 'care-essentials', contentGroup: 'member-care-essentials' } },
  
  // HMO 
  { path: 'hmo', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'hmo', contentGroup: 'member-hmo' } },
  { path: 'hmo-care', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'hmo-care', contentGroup: 'member-hmo-care' } },
  { path: 'hmo-welcome', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'hmo-welcome', contentGroup: 'member-hmo-welcome' } },

  // ABOUT US
  { path: 'about-premera', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'about-premera', contentGroup: 'member-about-premera' } },
  { path: 'about-premera/affiliates', component: FlexibleTemplateComponent, data: { page: 'affiliates', contentGroup: 'member-affiliates' } },
  { path: 'about-premera/health-equity', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'health-equity', contentGroup: 'member-health-equity' } },
  { path: 'careers', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'careers', contentGroup: 'member-careers' } },
  { path: 'careers/culture', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'culture', contentGroup: 'member-culture' } },
  { path: 'campus', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'campus', contentGroup: 'member-campus' } },
  { path: 'careers/employee-benefits', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'employee-benefits', contentGroup: 'member-employee-benefits' } },
  { path: 'careers/pharmacy-residency', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'pharmacy-residency', contentGroup: 'member-pharmacy-residency' } },
  { path: 'careers/social-impact', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'social-impact', contentGroup: 'member-social-impact' } },
  { path: 'community-support', component: Info1TemplateComponent, data: { page: 'community-support', contentGroup: 'member-community-support' } },
  { path: 'community-support/grants-sponsorships', component: Info1TemplateComponent, data: { page: 'grants-sponsorships', contentGroup: 'member-grants-sponsorships' } },
  { path: 'contact-broker', component: FlexibleTemplateComponent, data: { page: 'contact-broker', contentGroup: 'member-contact-broker' } },
  { path: 'contact-us', component: FlexibleTemplateComponent, data: { page: 'contact-us', contentGroup: 'member-contact-us' } },
  { path: 'ethics-integrity', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'ethics-integrity', contentGroup: 'member-ethics-integrity' } },
  { path: 'ethics-integrity/code-of-conduct', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'code-of-conduct', contentGroup: 'member-code-of-conduct' } },
  { path: 'ethics-integrity/why-it-matters', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'why-it-matters', contentGroup: 'member-why-it-matters' } },
  { path: 'ethics-integrity/purpose-values', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'purpose-values', contentGroup: 'member-purpose-values' } },
  { path: 'ethics-integrity/your-responsibilities', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'your-responsibilities', contentGroup: 'member-your-responsibilities' } },
  { path: 'ethics-integrity/compliance-with-law', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'compliance-with-law', contentGroup: 'member-compliance-with-law' } },
  { path: 'ethics-integrity/protecting-data', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'protecting-data', contentGroup: 'member-protecting-data' } },
  { path: 'ethics-integrity/conflict-of-interest', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'conflict-of-interest', contentGroup: 'member-conflict-of-interest' } },
  { path: 'ethics-integrity/workplace-conduct', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'workplace-conduct', contentGroup: 'member-workplace-conduct' } },
  { path: 'ethics-integrity/reporting-suspected-noncompliance', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'reporting-suspected-noncompliance', contentGroup: 'member-reporting-suspected-noncompliance' } },
  { path: 'executives', component: FlexibleTemplateComponent, data: { page: 'executives', contentGroup: 'member-executives' } },
  { path: 'partners-vendors', component: FlexibleTemplateComponent, data: { page: 'partners-vendors', contentGroup: 'member-partners-vendors' } },
  { path: 'premera-difference', component: FlexibleTemplateComponent, data: { page: 'premera-difference', contentGroup: 'member-premera-difference' } },
  { path: 'community-support/social-impact-report', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'social-impact-report', contentGroup: 'member-social-impact-report' } },

  // PHARMACY
  { path: 'pharmacy-benefit-guide', component: FlexibleTemplateComponent, data: { page: 'pharmacy-benefit-guide', contentGroup: 'member-pharmacy-benefit-guide' } },
  { path: 'covered-drugs', component: CoveredDrugsComponent, data: { page: 'covered-drugs', contentGroup: 'member-covered-drugs' } },
  { path: 'm1-drug-list', component: SingleFormularyTemplateComponent, data: { page: 'm1-drug-list', contentGroup: 'member-m1-drug-list' } },
  { path: 'm2-drug-list', component: SingleFormularyTemplateComponent, data: { page: 'm2-drug-list', contentGroup: 'member-m2-drug-list' } },
  { path: 'm4-drug-list', component: SingleFormularyTemplateComponent, data: { page: 'm4-drug-list', contentGroup: 'member-m4-drug-list' } },
  { path: 'drugs-requiring-approval', component: FlexibleTemplateComponent, data: { page: 'drugs-requiring-approval-v2', contentGroup: 'member-drugs-requiring-approval-v2' } },
  { path: 'drug-list-changes', component: FlexibleTemplateComponent, data: { page: 'drug-list-changes', contentGroup: 'member-drug-list-changes' } },  
  { path: 'drug-list-changes-provider', component: FlexibleTemplateComponent, data: { page: 'drug-list-changes-provider', contentGroup: 'member-drug-list-changes-provider' } },
  { path: 'emergency-drug-fills', component: FlexibleTemplateComponent, data: { page: 'emergency-drug-fills', contentGroup: 'member-emergency-drug-fills' } },
  { path: 'find-a-pharmacy', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'find-a-pharmacy', contentGroup: 'member-find-a-pharmacy' } },
  { path: 'prescription-savings', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'prescription-savings', contentGroup: 'member-prescription-savings' } },
  { path: 'specialty-drugs', component: FlexibleTemplateComponent, data: { page: 'specialty-drugs', contentGroup: 'member-specialty-drugs' } },
  { path: 'pharmacy-discounts', component: Info1TemplateComponent, data: { page: 'pharmacy-discounts', contentGroup: 'member-pharmacy-discounts' } },
  { path: 'medication-safety', component: FlexibleTemplateComponent, data: { page: 'medication-safety', contentGroup: 'member-medication-safety' } },
  { path: 'saveonsp', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'saveonsp', contentGroup: 'member-saveonsp' } },

  // GUIDANCE
  { path: 'forms', component: FlexibleTemplateComponent, data: { page: 'forms', contentGroup: 'member-forms' } },
  { path: 'summary-benefits-coverage', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'summary-benefits-coverage', contentGroup: 'member-summary-benefits-coverage' } },
  { path: 'summary-benefits-coverage/individual', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'individual', contentGroup: 'member-individual' } },
  { path: 'summary-benefits-coverage/individual-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'individual-prior-year', contentGroup: 'member-individual-prior-year' } },
  { path: 'summary-benefits-coverage/individual-next-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'individual-next-year', contentGroup: 'member-individual-next-year' } },
  { path: 'summary-benefits-coverage/small-business', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'small-business', contentGroup: 'member-small-business' } },
  { path: 'summary-benefits-coverage/small-business-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'small-business-prior-year', contentGroup: 'member-small-business-prior-year' } },
  { path: 'summary-benefits-coverage/small-business-hmo', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'small-business-hmo', contentGroup: 'member-small-business-hmo' } },
  { path: 'summary-benefits-coverage/small-business-hmo-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'small-business-hmo-prior-year', contentGroup: 'member-small-business-hmo-prior-year' } },
  { path: 'summary-benefits-coverage/large-business', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'large-business', contentGroup: 'member-large-business' } },
  { path: 'summary-benefits-coverage/large-business-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'large-business-prior-year', contentGroup: 'member-large-business-prior-year' } },
  { path: 'summary-benefits-coverage/large-business-hmo', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'large-business-hmo', contentGroup: 'member-large-business-hmo' } },
  { path: 'summary-benefits-coverage/large-business-hmo-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'large-business-hmo-prior-year', contentGroup: 'member-large-business-hmo-prior-year' } },
  { path: 'summary-benefits-coverage/preferred-choice', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'preferred-choice', contentGroup: 'member-preferred-choice' } },
  { path: 'summary-benefits-coverage/preferred-choice-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'preferred-choice-prior-year', contentGroup: 'member-preferred-choice-prior-year' } },
  { path: 'summary-benefits-coverage/sbc-hmo', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'sbc-hmo', contentGroup: 'member-sbc-hmo' } },
  { path: 'summary-benefits-coverage/hmo-prior-year', component: FlexibleTwoColTemplateComponent, data: { pageWidth: 'full', page: 'hmo-prior-year', contentGroup: 'member-hmo-prior-year' } },
  { path: 'group-booklets', component: Info1TemplateComponent, data: { page: 'group-booklets', contentGroup: 'member-group-booklets' } },
  { path: 'fraud-abuse', component: FlexibleTemplateComponent, data: { page: 'fraud-abuse', contentGroup: 'member-fraud-abuse' } },
  { path: 'privacy-practices', component: FlexibleTemplateComponent, data: { page: 'privacy-practices', contentGroup: 'member-privacy-practices' } },
  { path: 'aviso-practicas-privacidad', component: FlexibleTemplateComponent, data: { page: 'aviso-practicas-privacidad', contentGroup: 'member-aviso-practicas-privacidad' } },
  { path: 'terms-conditions', component: FlexibleTemplateComponent, data: { page: 'terms-conditions', contentGroup: 'member-terms-conditions' } },
  { path: 'data-collection', component: FlexibleTemplateComponent, data: { page: 'data-collection', contentGroup: 'member-data-collection' } },
  { path: 'transparency-coverage', component: FlexibleTemplateComponent, data: { page: 'transparency-coverage', contentGroup: 'member-transparency-coverage' } },
  { path: 'medical-policies', component: FlexibleTemplateComponent, data: { page: 'medical-policies', contentGroup: 'member-medical-policies' } },
  { path: 'aca-rate-review', component: FlexibleTemplateComponent, data: { page: 'aca-rate-review', contentGroup: 'member-aca-rate-review' } },
  { path: 'developers', component: FlexibleTemplateComponent, data: { page: 'developers', contentGroup: 'member-developers' } },
  { path: 'payer-data-exchange', component: FlexibleTemplateComponent, data: { page: 'payer-data-exchange', contentGroup: 'member-payer-data-exchange' } },

  // HEALTH DATA
  { path: 'health-data', component: FlexibleTwoColTemplateComponent, data: { page: 'health-data', contentGroup: 'member-health-data' } },
  { path: 'health-data/access', component: FlexibleTwoColTemplateComponent, data: { page: 'access', contentGroup: 'member-access' } },
  { path: 'health-data/things-to-consider', component: FlexibleTwoColTemplateComponent, data: { page: 'things-to-consider', contentGroup: 'member-things-to-consider' } },
  { path: 'health-data/privacy', component: FlexibleTwoColTemplateComponent, data: { page: 'privacy', contentGroup: 'member-privacy' } },
  { path: 'health-data/faq', component: FlexibleTwoColTemplateComponent, data: { page: 'faq', contentGroup: 'member-faq' } },
  { path: 'health-data/payer', component: FlexibleTwoColTemplateComponent, data: { page: 'payer', contentGroup: 'member-payer' } },

  // OTHER
  { path: 'bluehpn', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'bluehpn', contentGroup: 'member-bluehpn' } },
  { path: 'demo', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'demo', contentGroup: 'member-demo' } },
  { path: 'demo1', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'demo1', contentGroup: 'member-demo1' } },
  { path: 'demo2', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'demo2', contentGroup: 'member-demo2' } },
  { path: 'demo3', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'demo3', contentGroup: 'member-demo3' } },
  { path: 'digital-health-messages', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'digital-health-messages', contentGroup: 'member-digital-health-messages' } },
  { path: 'flex-advantage', component: FlexibleTemplateComponent, data: { page: 'flex-advantage', contentGroup: 'member-flex-advantage' } },
  { path: 'flex-advantage/find-care', component: FlexibleTemplateComponent, data: { page: 'find-care', contentGroup: 'member-find-care' } },
  { path: 'flex-advantage/sample-costs', component: FlexibleTemplateComponent, data: { page: 'sample-costs', contentGroup: 'member-sample-costs' } },
  { path: 'help', component: FlexibleTemplateComponent, data: { page: 'help', contentGroup: 'member-help' } },
  { path: 'mentalhealth', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'mentalhealth', contentGroup: 'member-mentalhealth' } },
  { path: 'mycare', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'mycare', contentGroup: 'member-mycare' } },
  { path: 'natural-disasters', component: FlexibleTemplateComponent, data: { page: 'natural-disasters', contentGroup: 'member-natural-disasters' } },
  { path: 'navigator', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'navigator', contentGroup: 'member-navigator' } },
  { path: 'new', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'new', contentGroup: 'member-new' } },
  { path: 'news', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'news', contentGroup: 'member-news' } },
  { path: 'news1', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'news1', contentGroup: 'member-news1' } },
  { path: 'news2', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'news2', contentGroup: 'member-news2' } },
  { path: 'outage', component: FlexibleTemplateComponent, data: { page: 'outage', contentGroup: 'member-outage' } },
  { path: 'outbreaks', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'outbreaks-v2', contentGroup: 'member-outbreaks' } },
  { path: 'pathfinder-welcome', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'pathfinder-welcome', contentGroup: 'member-pathfinder-welcome' } },
  { path: 'patternlibrary', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'patternlibrary', contentGroup: 'member-patternlibrary' } },
  { path: 'payments-anon', component: FlexibleTemplateComponent, data: { page: 'payments-anon', contentGroup: 'member-payments-anon' } },
  { path: 'payments-anon-ak', component: FlexibleTemplateComponent, data: { page: 'payments-anon-ak', contentGroup: 'member-payments-anon-ak' } },
  { path: 'payments-sep', component: FlexibleTemplateComponent, data: { page: 'payments-sep', contentGroup: 'member-payments-sep' } },
  { path: 'preapproval', component: FlexibleTemplateComponent, data: {  pageWidth: 'full', page: 'preapproval', contentGroup: 'member-preapproval' } },
  { path: 'premier-providers', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'premier-providers', contentGroup: 'member-premier-providers' } },
  { path: 'senior-discounts', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'senior-discounts', contentGroup: 'member-senior-discounts' } },
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home', contentGroup: 'member-home' } },
  { path: 'settlement', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'settlement', contentGroup: 'member-settlement' } }
];

// Child sites under "sites"
const childSiteRoutes: Routes = [
  { path: 'aag', children: AagRoutes },
  { path: 'amazon', children: AmazonRoutes },
  { path: 'weyerhaeuser', children: WeyRoutes },
  { path: 'tmobile', children: TMobileRoutes, data: { feature: 'tMobileFlag' }, canActivateChild: [FeatureFlagGuard] }
];

const rootRoutes: Routes = [
  // redirect any link that starts with "member" to the corresponding member site
  { path: 'member/:path', resolve: { url: 'memberUrlRedirectResolver' }, component: FlexibleTemplateComponent },
  { path: 'member/:seg1/:seg2', resolve: { url: 'memberUrlRedirectResolver' }, component: FlexibleTemplateComponent },
  { path: 'member/:seg1/:seg2/:seg3', resolve: { url: 'memberUrlRedirectResolver' }, component: FlexibleTemplateComponent },

  { path: 'home', redirectTo: '/' },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-in/wa/employer', redirectTo: '/sign-in?portal=employer&loc=wa' },
  { path: 'sign-in/wa/member', redirectTo: '/sign-in?portal=member&loc=wa' },
  { path: 'sign-in/wa/producer', redirectTo: '/sign-in?portal=producer&loc=wa' },
  { path: 'sign-in/wa/provider', redirectTo: '/sign-in?portal=provider&loc=wa' },
  { path: 'sign-in/ak/employer', redirectTo: '/sign-in?portal=employer&loc=ak' },
  { path: 'sign-in/ak/member', redirectTo: '/sign-in?portal=member&loc=ak' },
  { path: 'sign-in/ak/producer', redirectTo: '/sign-in?portal=producer&loc=ak' },
  { path: 'sign-in/ak/provider', redirectTo: '/sign-in?portal=provider&loc=ak' },

  { path: 'shared-admin', children: SharedAdminRoutes },
  { path: 'visitor/shared-admin/:path', redirectTo: '/shared-admin/:path' },
  { path: 'visitor/shared-admin', redirectTo: '/shared-admin' },
  { path: 'sites/shared-admin/:path', redirectTo: '/shared-admin/:path' },
  { path: 'sites/shared-admin', redirectTo: '/shared-admin' },

  { path: 'press-releases', children: PressReleaseRoutes },
  { path: 'visitor/press-releases/:path', redirectTo: '/press-releases/:path' },
  { path: 'visitor/press-releases', redirectTo: '/press-releases' },

  { path: 'visitor', children: visitorRoutes },
  { path: 'sites', children: childSiteRoutes },

  { path: 'notification-center', component: NotificationCenterComponent },
  { path: 'preapprovals', redirectTo: 'member/preapprovals' },
  { path: 'manage-referrals', redirectTo: 'member/manage-referrals' },
  { path: 'primary-care-provider', redirectTo: 'member/primary-care-provider' },
 
  // for visibility checking
  { path: 'tax-form-1095', redirectTo: 'member/tax-form-1095', data: { visibilityFunction: 'canDisplayForm1095Page' } },
  // empty root path
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home', contentGroup: 'member-home' } },
  // ERRORS
  { path: 'internal-server-error', component: InternalServerErrorComponent },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  // NOTE : 404 redirect has to be the last entry in routing table
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(rootRoutes, { initialNavigation: 'disabled', relativeLinkResolution: 'legacy' })],
  providers: [
    {
      provide: 'memberUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot) => {
        let redirect = '';
        for (let index = 1; index < route.url.length; index++) {
          redirect += `/${route.url[index]}`;
        }
        window.location.href = `${environment.baseUrlMember}${redirect}`;
      }
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
