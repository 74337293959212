import { Type } from '@angular/core';
import { NavigationExtras } from '@angular/router';

import { AlertType } from './pbc-enums';

export class BffApiResponse {
  message: string;
  statusCode: number;
}

export class SuccessResponse<T> {
  result: T;
  responseInfo: BffApiResponse;
}

export class FailedResponse {
  responseInfo: BffApiResponse;
  errors: string[];
}

export class Address {
  name: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zipCode: string;
}

export class AddressExtended extends Address {
  addressType: string;
  county: string;
  preferredAddress: boolean;
  phoneNumber: string;
  emailAddress: string;
}

export class FamilyMembersRequest {
  memberKey: string;
  constructor(memberKey) {
    this.memberKey = memberKey;
  }
}

export class ComponentState {
  type: string;
  instanceName: string;
  locale: string;
  lineOfBusiness: string;
  parentGroupId?: string;
  groupId?: string;
  isVisible: boolean;
  requiresAuthentication: boolean;
  analyticsView?: string;
  analyticsSiteSection?: string;
  content: any;

  public constructor(isVisible: boolean) {
    this.isVisible = isVisible;
  }
}

export class ComponentItem {
  constructor(public component: Type<any>, public data: any) {}
}

export class PageContent {
  pageInfo: ComponentState;
  pageContent: { [key: string]: ComponentState };
}

export class DataComponentState extends ComponentState {
  failoverBehavior: string = null;
  failoverContent: string = null;
}

export class ContentLabel {
  value: string;
  isHtml: boolean;
  constructor(plainValue: string, htmlValue: string, richText: string) {
    this.isHtml = !!richText && richText.toLowerCase() === 'true';
    this.value = this.isHtml ? htmlValue : plainValue;
  }
}

export class ContentLabels {
  [key: string]: ContentLabel;
}

export class KeyValue {
  id: string;
  value: string;

  constructor(id: string, value: string) {
    this.id = id;
    this.value = value;
  }
}

export class CtaModel {
  href = '';
  title = '';
  style = '';
  target = '_self';
  analytics = '';
  navExtras = null;
  preventDefault = false;

  public constructor(href: string, title: string, style: string, target: string, analytics: string, navExtras?: NavigationExtras, preventDefault?: string) {
    this.href = !!href ? href.split('&amp;').join('&') : href;
    this.title = title;
    this.style = style;
    this.target = target;
    this.analytics = analytics;
    this.navExtras = navExtras;
    this.preventDefault = preventDefault === 'true';
  }
}

export class AlertModel {
  style = '';
  message = '';
  isVisible = false;
  isDismissible = false;

  public constructor(style: string, message: string, isVisible: boolean, isDismissible = false) {
    this.style = style;
    this.message = message;
    this.isVisible = isVisible;
    this.isDismissible = isDismissible;
  }
}

export class Tier {
  name: string;
  value: string;
}

export class BffHealth {
  buildNumber: number;
  pspBaseUrl: string;
  activeEnvironment: string;
}

export class ContentHealth {
  contentTableName: string;
  contentLastUpdated: string;
}

export class AnalyticsInfo {
  pageError: string;
  surrogateId: string;
  impersonation: boolean;
  sso: boolean;
  groupId: string;
  groupName: string;
}

export class GroupContext {
  groupKey: number;
  groupId: string;
  groupName: string;
  parentGroupId: string;

  public constructor(groupKey = 0, groupId = '', groupName = '', parentGroupId = '') {
    this.groupKey = groupKey;
    this.groupId = groupId ? groupId : '';
    this.groupName = groupName ? groupName : '';
    this.parentGroupId = parentGroupId ? parentGroupId : '';
  }
}

export class Group extends GroupContext {
  effectiveDate: Date;
  terminationDate: Date;
  reinstatementDate: Date;
  parentGroupName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  state: string;
  zipCode: string;
  groupType: string;
}

export class GroupRequest {
  groupId: string;
  groupKey: string;
  targetMemberKey: string;
  constructor(groupId: string, groupKey?: string,targetMemberKey?: string) {
    this.groupId = groupId;
    if (!!groupKey) {
      this.groupKey = groupKey;
      this.targetMemberKey = targetMemberKey;
    }
  }
}

export class OutboundSsoRequest {
  ssoDestination: string;
  targetMemberKey: string;
  settings: {}; // Additional settings. Keys needs to be setup in the admin console in attribute configuration of Outbound SSO Configuration
  planEffectiveDate : Date;
  constructor(ssoDestination, targetMemberKey, settings, planEffectiveDate) {
    this.ssoDestination = ssoDestination;
    this.targetMemberKey = targetMemberKey;
    if (settings) {
      this.settings = settings;
    }
    this.planEffectiveDate = planEffectiveDate;
  }
}

export class OutboundSsoResponse {
  accessToken: string;
  outboundSso: {
    jwtToken: string;
    destinationUrl: string;
    statusMessage: string;
    guid: string;
  };
}

export class OutboundSsoDemoResponse {
  relayState: string;
  samlResponse: string;
  destinationUrl: string;
  statusMessage: string;
}

export class AlertMessage {
  isVisible: boolean;
  type: AlertType;
  message: string;
  isDismissible: boolean;

  constructor(isVisible: boolean, type: AlertType, message: string, isDismissible: boolean) {
    this.isVisible = isVisible;
    this.type = type;
    this.message = message;
    this.isDismissible = isDismissible;
  }
}

export class WelcomeCarouselResponse{
  isVisible : boolean;
  content : WelcomeCarouselContent;
  analyticsView: string;

  constructor(isVisible: boolean, content: WelcomeCarouselContent, analyticsView: string){
    this.isVisible = isVisible;
    this.content = content;
    this.analyticsView = analyticsView;
  }
}

export class WelcomeCarouselContent{
  parentClasses : string;
  heading : string;
  headingClass : string;
  introduction : string;
  showNavigationArrows : boolean;
  showNavigationIndicators : boolean;
  sections : CarouselSection[];

  constructor(sections: CarouselSection[]){
    this.sections = sections;
  }
}

export class CarouselSection{
  sectionImage : string;
  sectionHeader : string;
  sectionSubHeader : string;
  sectionLink : string;
  sectionImagePath : string;
  sectionHeaderStyle : string;
  sectionHeaderLevel : string;
  sectionBody : string;

  constructor(sectionImage: string, sectionHeader: string, sectionHeaderStyle: string, sectionHeaderLevel: string, sectionBody: string, sectionSubHeader: string, sectionLink: string, sectionImagePath:string){
    this.sectionImage = sectionImage ;
    this.sectionHeader = sectionHeader;
    this.sectionImage = sectionHeaderStyle;
    this.sectionHeaderLevel = sectionHeaderLevel;
    this.sectionBody = sectionBody;
    this.sectionSubHeader = sectionSubHeader;
    this.sectionLink = sectionLink;
    this.sectionImagePath = sectionImagePath
  }
}
