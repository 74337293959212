import { AfterViewInit, Directive, ElementRef, Renderer2, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { VisibilityService } from 'libs/services/visibility.service';
import { MemberSharedService } from 'libs/services/member-shared.service';

@Directive({
  selector: '[pbcApplyRules]'
})
export class ApplyRulesDirective implements AfterViewInit, OnDestroy {
  @Input() visibilityRules: any;
  @Input() rulesOperation: any;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly visibilityService: VisibilityService,
    private readonly memberSharedService: MemberSharedService,
    private readonly host: ElementRef,
    private readonly renderer: Renderer2) { }

  ngAfterViewInit() {
    if (!this.visibilityRules) {
      return;
    }

    // reevaluate each time logged in member key changes
    this.memberSharedService.loggedInMemberKey.pipe(takeUntil(this.componentDestroyed$)).subscribe(memberKey => {
      this.hideElement();
      if (!memberKey) {
        return;
      }
      const evaluatedIsVisible = this.getSavedEvaluation(memberKey);
      if (evaluatedIsVisible !== undefined) {
        evaluatedIsVisible ? this.showElement() : this.hideElement();
        return;
      }
      const rulesToEvaluate = Array.isArray(this.visibilityRules) ? this.visibilityRules : this.linksToArray(this.visibilityRules);
      this.visibilityService.evaluateVisibilityRules(rulesToEvaluate, this.rulesOperation)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(isVisible => {
          if (!isVisible) {
            this.hideElement();
          } else { // was previously hidden until evaluation complete
            this.showElement();
          }
          this.setEvaluated(isVisible, memberKey);
        }, error => {
          this.hideElement();
          return;
        });
    }, error => {
      this.hideElement();
      return;
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  private linksToArray(input: object): Array<object> {
    const linksArray = Array<object>();
    linksArray.push(input);
    return linksArray;
  }

  private hideElement() {
    this.renderer.addClass(this.host.nativeElement, 'd-none');
  }

  private showElement() {
    this.renderer.removeClass(this.host.nativeElement, 'd-none');
  }

  private getSavedEvaluation(memberKey: string): boolean {
    return this.renderer.data[this.getDataKey(memberKey)];
  }

  private setEvaluated(isVisible: boolean, memberKey: string) {
    this.renderer.data[this.getDataKey(memberKey)] = isVisible;
  }

  private getDataKey(memberKey: string) {
    return `data-eval-${memberKey}-${JSON.stringify(this.visibilityRules)}`;
  }

}
