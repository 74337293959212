import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Info1TemplateComponent } from './info-1/info-1.component';
import { TwoCol1TemplateComponent } from './two-col-1/two-col-1.component';
import { TwoCol2TemplateComponent } from './two-col-2/two-col-2.component';
import { ComponentsModule } from 'libs/components/components.module';
import { FlexibleTemplateComponent } from './flexible/flexible.component';
import { FlexibleTwoColTemplateComponent } from './flexible-two-col/flexible-two-col.component';
import { DirectivesModule } from 'libs/directives/directives.module';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    Info1TemplateComponent,
    TwoCol1TemplateComponent,
    TwoCol2TemplateComponent,
    FlexibleTemplateComponent,
    FlexibleTwoColTemplateComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    DirectivesModule
  ],
  exports: [
    Info1TemplateComponent,
    TwoCol1TemplateComponent,
    TwoCol2TemplateComponent
  ],
  providers: [NgbActiveModal]
})
export class TemplatesModule { }
