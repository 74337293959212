<div (pbcCloseDropdown)="close($event)" *ngIf="isVisible">
  <ul id="primary-nav" class="navbar-nav nav-primary" aria-label="Primary Navigation">
    <ng-container *ngFor="let menu of primaryNavMenus; let index = index;">
      <ng-container *ngIf="menu.levelTwoMenuGroupBox?.headerTitle && !menu.levelTwoMenuGroupBox?.headerRoute?.length">
        <li (click)="toggleSubmenu(index)" class="nav-item dropdown" [ngClass]="{'show': openMenu === index }">
          <a class="nav-link dropdown-toggle" aria-haspopup="true" [attr.aria-expanded]="openMenu === index"
            tabindex="0" id="{{menu.levelTwoMenuGroupBox.id}}" href onclick="return false;" pbcAnalyticsTag
            data-analytics-override-route="header" [attr.aria-label]="menu.levelTwoMenuGroupBox.headerTitle"
            pbcApplyRules [visibilityRules]="menu.levelTwoMenuGroupBox.visibilityRules"
            [rulesOperation]="menu.levelTwoMenuGroupBox.rulesOperation">
            {{ menu.levelTwoMenuGroupBox.headerTitle }}
          </a>
          <ng-container *ngIf="openMenu === index">
            <ul [attr.aria-label]="menu.levelTwoMenuGroupBox.headerTitle" class="nav-primary-dropdown-menu">
              <ng-container *ngFor="let submenu of menu.levelTwoMenuGroupBox.linksGroupBox">
                <ng-container *ngIf="submenu.linkText">
                  <li data-id="listItem" (click)="dropdownItemClicked(menu)">
                    <ng-container *ngIf="needsDomain(submenu.route) && !isHmoWelcome(submenu.linkText); else isRoute">
                      <a class="dropdown-item" [href]="addDomain(submenu.route)" routerLinkActive="active"
                        pbcAnalyticsTag data-analytics-override-route="header" pbcApplyRules
                        [visibilityRules]="submenu.visibilityRules" [rulesOperation]="submenu.rulesOperation"
                        [target]="submenu.isExternal | externalTarget" rel="submenu.isExternal | safeExternalTarget">
                        {{ submenu.linkText }}
                      </a>
                    </ng-container>
                    <ng-template #isRoute>
                      <a class="dropdown-item" [routerLink]="dropToken(submenu.route, submenu.linkText)"
                        routerLinkActive="active"  pbcAnalyticsTag
                        data-analytics-override-route="header" pbcApplyRules [visibilityRules]="submenu.visibilityRules"
                        [rulesOperation]="submenu.rulesOperation" [target]="submenu.isExternal | externalTarget"
                        rel="submenu.isExternal | safeExternalTarget">
                        {{ getLinkText(submenu.linkText )}}
                      </a>
                    </ng-template>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
          </ng-container>
        </li>
      </ng-container>
      <ng-container
        *ngIf="menu.levelTwoMenuGroupBox?.headerTitle && menu.levelTwoMenuGroupBox?.headerRoute?.length > 0">
        <!--No dropdown, make headertitle link to levelTwoMenuGroupBox.headerRoute-->
        <li class="nav-item dropdown">
          <ng-container *ngIf="needsDomain(menu.levelTwoMenuGroupBox.headerRoute); else isRoute">
            <a class="nav-link" aria-haspopup="false" pbcAnalyticsTag data-analytics-override-route="header"
              id="{{menu.levelTwoMenuGroupBox?.id}}" pbcApplyRules
              [visibilityRules]="menu.levelTwoMenuGroupBox.visibilityRules"
              [rulesOperation]="menu.levelTwoMenuGroupBox.rulesOperation"
              [href]="addDomain(menu.levelTwoMenuGroupBox.headerRoute)" routerLinkActive="active"
              [target]="menu.levelTwoMenuGroupBox.isExternal | externalTarget"
              rel="menu.levelTwoMenuGroupBox.isExternal | safeExternalTarget">
              {{ menu.levelTwoMenuGroupBox.headerTitle }}
            </a>
          </ng-container>
          <ng-template #isRoute>
            <a class="nav-link" aria-haspopup="false" pbcAnalyticsTag data-analytics-override-route="header"
              id="{{menu.levelTwoMenuGroupBox?.id}}" pbcApplyRules
              [visibilityRules]="menu.levelTwoMenuGroupBox.visibilityRules"
              [rulesOperation]="menu.levelTwoMenuGroupBox.rulesOperation"
              [routerLink]="dropToken(menu.levelTwoMenuGroupBox.headerRoute)" routerLinkActive="active"
              [target]="menu.levelTwoMenuGroupBox.isExternal | externalTarget"
              rel="menu.levelTwoMenuGroupBox.isExternal | safeExternalTarget">
              {{ menu.levelTwoMenuGroupBox.headerTitle }}
            </a>
          </ng-template>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>