import { Component } from '@angular/core';

import { StringsService } from 'libs/services/strings.service';
import { AiSessionService } from 'libs/services/ai-session.service';
import { AnalyticsReportingService } from 'libs/services/analytics-reporting.service';
import { AuthenticationService } from 'libs/services/authentication.service';

@Component({
  selector: 'pbc-internal-server-error',
  templateUrl: './internal-server-error.component.html'
})

export class InternalServerErrorComponent {
  userSession: string;
  userId: string;

  constructor(readonly strings: StringsService,
    private readonly aiSession: AiSessionService,
    private readonly analyticsReporting: AnalyticsReportingService,
    private readonly authService: AuthenticationService
    ) {
    this.userSession = aiSession.sessionId;
    this.userId = aiSession.userId;
    this.authService.logPageView('ServerErrorPage');
    this.analyticsReporting.setAnalyticsPageError('500');
  }
}

