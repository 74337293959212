import { Component, OnInit, Input } from '@angular/core';

import { DataComponentState, BffHealth, ContentHealth } from 'libs/models/pbc-models';
import { AiSessionService } from 'libs/services/ai-session.service';
import { HealthService } from 'libs/services/health.service';
import { FooterContentHelperService } from '../footer-content-helper.service';
import { isValid, format } from 'date-fns';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { Site } from 'libs/models/site-config';

@Component({
  selector: 'pbc-footer-bottom',
  templateUrl: './footer-bottom.component.html'
})
export class FooterBottomComponent implements OnInit {
  @Input() footerContent: DataComponentState;
  @Input() menus: any[];
  @Input() horizontalLinks: boolean;
  @Input() site: Site;

  copyrightText: string;
  bffHealth: BffHealth;
  contentHealth: ContentHealth;
  languages: any[];

  readonly environment = EnvironmentService.variables;

    constructor(
    private readonly aiSession: AiSessionService,
    private readonly healthService: HealthService,
    private readonly contentHelper: FooterContentHelperService
  ) { }

  ngOnInit() {
    if (this.footerContent && this.footerContent.content) {
      this.copyrightText = `&copy; ${new Date().getFullYear()}  ${this.footerContent.content.legalCopyright}`;
    }
    this.languages = this.contentHelper.parseFooterContent(this.footerContent, 'languages') || [];

    this.healthService.getBffHealthInfo().subscribe(result => this.bffHealth = result);
    this.healthService.getContentHealthInfo(!this.site ? 'member' : this.site).subscribe(result => {
      this.contentHealth = result;
      if (!!this.contentHealth && !!this.contentHealth.contentLastUpdated) {
        this.contentHealth.contentLastUpdated = this.localizeDate(this.contentHealth.contentLastUpdated);
      }
    });
  }

  getSessionId(): string {
    return this.aiSession.readSessionId();
  }

  private localizeDate(updated: string): string {
    if (!!updated && isValid(new Date(updated))) {
      const offset = new Date().getTimezoneOffset();
      const localOffset = this.isDaylightSavings(offset) ? offset : offset - 60;
      return format(new Date(new Date(updated).getTime() - localOffset * 60000), 'M/DD/YYYY h:mm A');
    } else {
      return updated;
    }
  }

  private isDaylightSavings(localOffset): boolean {
      const winterStandardOffset = new Date(new Date().getFullYear(), 1, 1).getTimezoneOffset();
      return localOffset !== winterStandardOffset;
  }
}
