<div class="call-out" *ngIf="data?.isVisible" data-id="call-out-image">
  <div class="row align-items-center">
    <div class="col-sm-6 col-md-4" *ngIf="!isAlignedRight(data)">
      <img alt="" aria-hidden="true" [src]="(data?.content?.imageUrl | urlQualify)" class="img-fluid mb-3 mb-sm-0">
    </div>
    <div class="col-sm-6 col-md-8 p-3">
      <h2 [innerHTML]="data?.content?.title"></h2>
      <div *ngIf="data?.content?.description && data?.content?.description.length" [innerHTML]="data?.content?.description | decodeHtml"></div>
      <a pbcAnalyticsTag class="btn py-3" [ngClass]="data?.content.ctaStyle" *ngIf="data?.content?.ctaUrl" [target]="data?.content?.ctaExternal | externalTarget" rel="isExternal | safeExternalTarget" [attr.aria-label]="data?.content?.ctaText" [attr.href]="data?.content?.ctaUrl">{{data?.content?.ctaText}}</a>
    </div>
    <div class="col-sm-6 col-md-4" *ngIf="isAlignedRight(data)">
      <img alt="" aria-hidden="true" [src]="(data?.content?.imageUrl | urlQualify)" class="img-fluid mb-3 mb-sm-0">
    </div>
  </div>
</div>

