import { Injectable } from '@angular/core';
import { Observable, combineLatest, BehaviorSubject, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { DataService } from './data.service';
import { SuccessResponse } from 'libs/models/pbc-models';
import { MemberDetails, MemberDetailsRequest, FamilyEligibilityRequest, MemberWithEligibility, Impersonation, Sso, MemberRegistrationResponse } from 'libs/models/member.models';
import { ProductCategory } from 'libs/enums/eligibilities.enums';
import { AnalyticsReportingService } from './analytics-reporting.service';
import { EnvironmentService } from './environment-variables.service';
import { PbcCookieService } from './pbc-cookie.service';
import { UrlBuilderService } from './url-builder.service';
import { RegistrationPlanDetailsCommon } from 'libs/models/registrationCommon.models';
import { LoggingService, SeverityLevel } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class MemberSharedService {
  private memberKeyQueue = new BehaviorSubject<string>(null);
  selectedRegistration : RegistrationPlanDetailsCommon;

  constructor(protected readonly dataService: DataService, protected readonly pbcCookieService: PbcCookieService,
    protected readonly analyticsService: AnalyticsReportingService, protected readonly urlBuilderService: UrlBuilderService, private readonly logger: LoggingService) { }

  get loggedInMemberKey(): Observable<string> {
    this.refreshLoggedInMemberKey();
    return this.memberKeyQueue.asObservable();
  }

  refreshLoggedInMemberKey() {
    let selectedMemberKey = this.getSelectedMemberKey();
    if (!selectedMemberKey) {
      selectedMemberKey = null;
    }
    if (this.memberKeyQueue.value !== selectedMemberKey) {
      this.memberKeyQueue.next(selectedMemberKey);
    }
  }

  hasValidMemberKey(): Observable<boolean> {
    return this.loggedInMemberKey.pipe(map(loggedInMemKey => !!loggedInMemKey));
  }

  getRegistrationsFromCookie(): MemberRegistrationResponse {
    const pbcMemberKeys = this.pbcCookieService.get(this.pbcCookieService.memberKeysCookie);
    return (!!pbcMemberKeys ? JSON.parse(pbcMemberKeys) : {}) as MemberRegistrationResponse;
  }

  getSelectedMemberKey(): string {
    const account = this.getRegistrationsFromCookie();
    if (!!account && !!account.registrations && account.registrations.length > 0) {
      let selectedRegFromCookie = account.registrations.find(reg => reg.isSelected);
      if (!selectedRegFromCookie) {
        selectedRegFromCookie = account.registrations[0];
      }
      return selectedRegFromCookie.memberKey;
    }
    return null;
  }

  getFamilyMembersWithEligibility(asOfDate: Date, privacyScope: string, appendDob?: boolean): Observable<MemberWithEligibility[]> {
    return this.getFamilyWithEligibility<MemberWithEligibility[]>(asOfDate, privacyScope, appendDob);
  }

  getLoggedInMemberDetails(): Observable<MemberDetails> {

    // If selected Plan session information is not available .. Just continue without disturbing any flow 
    try {
      if (sessionStorage.length > 0 && sessionStorage.getItem('selectedPlanSession') !== null && sessionStorage.getItem("selectedPlanSession") !== undefined && !!sessionStorage.getItem("selectedPlanSession")) {
        this.selectedRegistration = JSON.parse(sessionStorage.getItem("selectedPlanSession"));
      } 
    } catch(error){
      this.logger.track(`Failed to get the selected Plan session`, {}, SeverityLevel.Error);
    }
   
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new MemberDetails());
      }
      return this.dataService.post<SuccessResponse<MemberDetails[]>>(
        this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberDetails),
        new MemberDetailsRequest(loggedInMemKey, loggedInMemKey, '',this.selectedRegistration?.planStatus?.toLowerCase() === 'future' ? this.selectedRegistration?.keyDate : new Date()), {}
      ).pipe(map(response => !!response && !!response.result && response.result.length > 0 ? response.result[0] : null));
    }),catchError(() => {
      this.logger.track(`Failed to get logged in Member details: ${sessionStorage.getItem('selectedPlanSession')}`, {}, SeverityLevel.Error);
      return of(undefined);
    }));
  }

  getMemberDetails(targetMemberKey: string, privacyScope: string): Observable<MemberDetails> {

    if (sessionStorage.length > 0  && sessionStorage.getItem('selectedPlanSession') !== null && sessionStorage.getItem("selectedPlanSession") !== undefined && !!sessionStorage.getItem("selectedPlanSession")) {
      try {
        this.selectedRegistration = JSON.parse(sessionStorage.getItem("selectedPlanSession"));
      }catch(error) {
        this.logger.track(`Failed to get the selected Plan session in member details`, {}, SeverityLevel.Error);
      }
     
    } 
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!!loggedInMemKey && !!targetMemberKey) {
        return this.dataService.post<SuccessResponse<MemberDetails[]>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberDetails),
          new MemberDetailsRequest(loggedInMemKey, targetMemberKey, privacyScope,this.selectedRegistration?.planStatus?.toLowerCase() === 'future' ? this.selectedRegistration?.keyDate : new Date()), {}
        ).pipe(map(response => !!response && !!response.result && response.result.length > 0 ? response.result[0] : null));
      }
      return of(new MemberDetails());
    }));
  }
  getMemberAdditionalDetails() {  
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!!loggedInMemKey) {
        return this.dataService.post<SuccessResponse<MemberDetails[]>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberDetails),
          new MemberDetailsRequest(loggedInMemKey, loggedInMemKey, "", new Date()), {}
        ).pipe(map(response => !!response && !!response.result && response.result.length > 0 ? response.result[0] : null));
      }
      return of(new MemberDetails());
    }));
  }

  getImpersonation(): Observable<Impersonation> {
    return this.dataService
      .get<SuccessResponse<Impersonation>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberImpersonationRequestUrl), null, true)
      .pipe(map(response => {
        if (!!response && !!response.result) {
          this.analyticsService.setImpersonation(response.result.isImpersonating);
          return response.result;
        }
        return null;
      }));
  }


  getSso(): Observable<Sso> {
    return this.dataService
      .get<SuccessResponse<Sso>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberSsoRequestUrl), null, true)
      .pipe(map(response => {
        if (!!response && !!response.result) {
          this.analyticsService.setSso(response.result.isSso);
          return response.result;
        }
        return null;
      }));
  }

  isMemberEligible(memberKey: string, familyMembers: MemberWithEligibility[], productCategories: ProductCategory[] =
    [ProductCategory.Medical, ProductCategory.Dental, ProductCategory.Vision, ProductCategory.Prescription]): boolean {
    const eligibleFamilyMembers = familyMembers.filter(m => m.eligibleProductCategories.some(cat => productCategories.includes(cat.productCategory)));
    if (!!eligibleFamilyMembers && eligibleFamilyMembers.length > 0 && !!memberKey) {
      const memberWithEligibility = eligibleFamilyMembers.find(member =>
        member.memberKey === memberKey, memberKey);
      return (!!memberWithEligibility);
    } else {
      return false;
    }
  }

  isLoggedInMemberActiveAndEligible(asOfDate: Date, privacyScope: string, products: ProductCategory[] = [ProductCategory.Medical, ProductCategory.Dental, ProductCategory.Vision, ProductCategory.Prescription]): Observable<boolean> {
    return combineLatest([this.loggedInMemberKey, this.getFamilyMembersWithEligibility(asOfDate, privacyScope)])
      .pipe(map(([loggedInMemberKey, members]) => {
        return this.isMemberEligible(loggedInMemberKey, members, products);
      }));
  }

  protected getFamilyWithEligibility<T>(asOfDate: Date, privacyScope: string, appendDob?: boolean): Observable<T> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(null);
      }
      return this.dataService
        .post<SuccessResponse<T>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.familyMembersEligibilityUrl),
          new FamilyEligibilityRequest(loggedInMemKey, asOfDate, privacyScope), {})
        .pipe(map(response => {
          if (!response) {
            return null;
          }
          if (appendDob) {
            const members = (response.result as unknown) as any[];
            this.resolveAppendDob(members);
          }
          return response.result;
        }));
    }));
  }

  protected resolveAppendDob(members: any[]): void {
    members.forEach((member, index) => {
      const filteredMembers = members.filter(x => x.firstName === member.firstName && x.lastName === member.lastName);
      if (filteredMembers.length > 1) {
        members[index].appendDob = true;
      }
    });
  }
}
