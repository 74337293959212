import { Component, Input, OnInit } from '@angular/core';
import { ComponentState } from 'libs/models/pbc-models';
import { HelperService } from 'libs/services/helper.service';
import { StringsService } from 'libs/services/strings.service';

@Component({
  selector: 'pbc-card-rows',
  templateUrl: './card-rows.component.html',
  styles: [
  ]
})
export class CardRowsComponent implements OnInit {
  @Input() data: ComponentState;
  states: string[];
  sections: any;

  constructor(private readonly constants: StringsService, private helperService: HelperService) {}

  ngOnInit() {
    this.states = this.constants.data.value.stateOptions;
  }

  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    if (!this.sections) {
      this.sections = JSON.parse(this.data.content.sections);
    }
    return this.sections;
  }

  colWidth(numberOfCards: number): string {
    let cols =  'col-sm';
    if (numberOfCards === 3 || numberOfCards === 4) {
      cols = 'col-md';
    } else if (numberOfCards > 4) {
      cols = 'col-lg';
    }
    return cols;
  }

  createHeading(cardHeaderLevel: string, cardStyle: string, cardHeader): string {
    return this.helperService.createHeading(cardHeaderLevel, cardStyle, cardHeader);
  }

  cardIsStandard(cardStyle): boolean {
    if (cardStyle === 'primary' || cardStyle === 'secondary') {
      return true;
    }
    return false;
  }

  cardStandardFooterStyle(cardStyle): string {
    if (cardStyle === 'primary') {
      return 'background-white border-0';
    }
    return 'border-0 background-accent';
  }

  cardIsHorizontal(cardStyle): boolean {
    return cardStyle.startsWith('horizontal');
  }

  isRightAligned(align): boolean {
    if (align.includes('right')) {
      return true;
    }
    return false;
  }

  isSecondary(cardStyle): boolean {
    if (!cardStyle.indexOf('secondary')) {
      return true;
    }
    return false;
  }

  isIcon(cardStyle): boolean {
    if (!cardStyle.indexOf('icon')) {
      return true;
    }
    return false;
  }

}
