<pbc-placeholder></pbc-placeholder>
<div *ngIf="data?.isVisible">
  <div  *ngIf="isHMO; else ppoKinwellAd" data-id="two-pack-kinwell" class="hero row b-shadow">
    <div class="col-sm-4 p-0" *ngIf="!isAlignedRight(data)">
      <img pbcLazyLoadImg class="img-fluid kinwell-height" [alt]="data?.content?.imageAltText"
        [attr.aria-hidden]="!data?.content?.imageAltText" [src]="(data?.content?.imageSmallUrl)">
    </div>
    <ng-container *ngTemplateOutlet="heroText"></ng-container>
    <div class="col-sm-4 p-0" *ngIf="isAlignedRight(data)">
      <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageAltText"
        [attr.aria-hidden]="!data?.content?.imageAltText" [src]="(data?.content?.imageUrl)">
    </div>
  </div>
</div>
<ng-template #heroText>
  <div class="col-sm-8 pt-4 px-4">
    <h2>{{data?.content?.title}}</h2>
    <div *ngIf="data?.content?.body" [innerHTML]="data?.content?.body"></div>
    <div *ngIf="data?.content?.description" [innerHTML]="data?.content?.description"></div>
    <pbc-cta-pack [data]="data" class="my-5"></pbc-cta-pack>
  </div>
</ng-template>
<ng-template #ppoKinwellAd>
  <div data-id="two-pack-kinwell" class="row">
    <div class="col-sm-4 p-0" *ngIf="isSmallSize">
      <img pbcLazyLoadImg class="img-fluid kinwell-smallheight-ppo" [alt]="data?.content?.imageAltText"
        [attr.aria-hidden]="!data?.content?.imageAltText" [src]="(data?.content?.imageSmallUrl)">
    </div>
    <ng-container *ngTemplateOutlet="heroText"></ng-container>
    <div class="col-sm-4 p-0" *ngIf="!isSmallSize">
      <img pbcLazyLoadImg class="img-fluid kinwell-height-ppo" [alt]="data?.content?.imageAltText"
        [attr.aria-hidden]="!data?.content?.imageAltText" [src]="(data?.content?.imageUrl)">
    </div>
  </div>
</ng-template>