import { Component, HostListener, Input, OnInit } from '@angular/core';

import { ContentComponent, ContentHelperMethods } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

declare const gtag:Function;

@Component({
  selector: 'pbc-two-pack-kinwell',
  templateUrl: './two-pack-kinwell.component.html'
})
export class TwoPackKinwellComponent implements ContentComponent , OnInit {
  @Input() data: ComponentState;
  @Input() isHMO: boolean=false;
  isAlignedRight = ContentHelperMethods.isAlignedRight;
  isSmallSize: boolean = false;
 

  constructor(router:Router){

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
       gtag('event', 'view_banner', {
        'banner_name': 'kinwell-primary_care',
        'banner_cta': 'find_kinwell_primary_care',
        'banner_audience': 'washington_state'
       })
      })
    
  }

  ngOnInit() {
    this.isSmallSize = window.screen.width < 768 ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallSize = window.screen.width < 768 ? true : false;
  }
}
