<pbc-placeholder></pbc-placeholder>
<div *ngIf="data?.isVisible" data-id="hero-background-img" class="hero-background-img" [style.background-image]="'url('+ (data?.content?.imageUrl | urlQualify) +')'">
  <img [src]="!!data?.content?.imageMobileUrl ? data.content.imageMobileUrl : data?.content?.imageUrl" class="d-md-none" alt="" aria-hidden="true">
  <div [ngClass]="isTextAlignedRight(data) ? 'hero-text-right': 'hero-text-left'">
    <div class="hero-background-img-text" [ngClass]="isLarge(data) ? 'lrg': 'sm'">
      <h1 *ngIf="isLarge(data)" class="mt-0">{{data?.content?.title}}</h1>
      <h2 *ngIf="!isLarge(data)" class="h1 mt-0">{{data?.content?.title}}</h2>
      <div *ngIf="data?.content?.body" [innerHTML]="data?.content?.body"></div>
      <div *ngIf="data?.content?.description"  [innerHTML]="data?.content?.description"></div>
      <pbc-cta-pack [data]="data" class="my-5"></pbc-cta-pack>
    </div>
  </div>
</div>
