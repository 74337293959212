import { Component, ElementRef, OnDestroy, OnInit, ViewChild, AfterViewChecked } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { AzureConfigService } from 'libs/services/azure-config.service';
import { ContentService } from 'libs/services/content.service';
import { LoaderService } from 'libs/services/loader.service';
import { LoggingService } from 'libs/services/logging.service';
import { environment } from '../environments/environment';
import { EnvironmentService } from 'libs/services/environment-variables.service';

@Component({
  selector: 'pbc-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('main', { static: true }) main: ElementRef;
  loadingData = false;
  headerFocused = false;
  site = EnvironmentService.variables.site;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private readonly contentService: ContentService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly loader: LoaderService,
    private readonly logger: LoggingService,
    private readonly title: Title,
    private readonly azureConfigService: AzureConfigService
  ) {
    this.checkRegionParam();
    this.addAdobeAnalytics();
  }

  ngAfterViewChecked() {
    if (!this.headerFocused) {
      const h1 = document.querySelectorAll('h1, .h1');
      if (h1.length > 0) {
        (h1[0] as HTMLElement).focus();
        this.headerFocused = true;
      }
    }
  }

  ngOnInit() {
    this.azureConfigService.doneLoading.asObservable().pipe(takeUntil(this.componentDestroyed$)).subscribe((doneLoading) => {
      if (doneLoading) {
        this.router.initialNavigation();
      }
    });
    this.loader.loaderState.pipe(debounceTime(100)).pipe(takeUntil(this.componentDestroyed$)).subscribe((state) => {
      this.loadingData = state;
    });

    this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.contentService.refreshChildSite(window.location.pathname, EnvironmentService.variables.site);
        this.headerFocused = false;
        this.logger.trackPageView(this.title.getTitle(), this.router.url.indexOf('#') > 0 ? this.router.url.substr(0, this.router.url.indexOf('#')) : this.router.url);
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  getComponentName(outlet): string {
    if (!outlet.activated) {
      return '';
    }
    return outlet.activatedRoute.component.name;
  }

  getPageWidth(outlet): string {
    if (!outlet.activated) {
      return '';
    }
    return outlet.activatedRoute.snapshot.data['pageWidth'];
  }

  getPageClass(outlet): string {
    if (!outlet.activated || !outlet.activatedRoute.snapshot.data['pageClass']) {
      return '';
    }
    return outlet.activatedRoute.snapshot.data['pageClass'];
  }

  skipLink() {
    this.main.nativeElement.focus();
  }

  checkRegionParam() {
    this.route.queryParams.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      const paramCopy = Object.assign({}, params);
      const region = params['region'];
      if (region) {
        this.contentService.setRegion(region);
        console.log(`App: Switched region to ${this.contentService.region$.value}`);
        delete paramCopy['region'];
        console.log(`App: Redirecting to ${this.route} (?${JSON.stringify(paramCopy)})`);
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: paramCopy,
          replaceUrl: true
        });
      }
    });
  }

  private addAdobeAnalytics() {
    // <script id="Ensighten_bootstrap" src="//nexus.ensighten.com/premera/dev|prod/Bootstrap.js" type="text/javascript"></script>
    const ensightenTagId = 'Ensighten_bootstrap';
    // if (!document.getElementById(ensightenTagId)) {
    //   const node = document.createElement('script');
    //   node.id = ensightenTagId;
    //   node.type = 'text/javascript';
    //   node.src = `//nexus.ensighten.com/premera/${environment.production ? 'prod' : 'dev'}/Bootstrap.js`;
    //   document.getElementsByTagName('head').item(0).appendChild(node);
    // }
  }
}
