export class GroupBookletRequest {
    lineOfBusiness: string;

    constructor(lineOfBusiness: string) {
        this.lineOfBusiness = lineOfBusiness;
    }
}

export class PlanDocument {
    plandocument: GroupBooklet[];
}

export class GroupBooklet {
    groupnumber: number;
    plandescription: string;
    effectivedate: string;
    sbcdocument: string;
    bookletdocument: string;
}
