import { Injectable } from '@angular/core';
import { ComponentState } from  '../../libs/models/pbc-models';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AlertService {
  private readonly globalAlert = new BehaviorSubject<ComponentState>(new ComponentState(false));

  getGlobalAlert(): BehaviorSubject<ComponentState> {
    return this.globalAlert;
  }

  setGlobalAlert(alert: ComponentState)  {
    this.globalAlert.next(alert);
  }
}
