import { Component, OnInit, AfterViewChecked, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MarketoFormComponent } from '../marketo-form/marketo-form.component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-marketo-launch',
  template: ''
})
export class MarketoLaunchComponent implements OnInit, AfterViewChecked {
  @Input() data: ComponentState;
  @Input() title: string;
  @Input() formId: number;
  private readonly launchUrlBase = '/form-launch/';
  constructor(private readonly modalService: NgbModal) { }

  ngOnInit(): void {
    this.addMarketoScript();
    if (!this.formId && !!this.data && !!this.data.content && !!this.data.content.formId) {
      this.formId = +this.data.content.formId;
    }
  }

  private addMarketoScript() {
    const marketoTagId = 'marketo_script';
    if (!document.getElementById(marketoTagId)) {
      const node = document.createElement('script');
      node.id = marketoTagId;
      node.type = 'text/javascript';
      node.src = 'https://app-sj06.marketo.com/js/forms2/js/forms2.min.js';
      document.getElementsByTagName('head').item(0).appendChild(node);
    }
  }

  ngAfterViewChecked() {
    this.configureMarketoLinks();
  }

  openModal() {
    const openModal = this.modalService.open(MarketoFormComponent, { keyboard: true, centered: true });
      openModal.componentInstance.formId = this.formId;
      openModal.componentInstance.title = !!this.data && !!this.data.content && this.data.content.formTitle ? this.data.content.formTitle : this.title;
      openModal.componentInstance.done$.subscribe(done => {
        if (!done) {
          return;
        }
        if (!!this.data && !!this.data.content && !!this.data.content.redirectUrl) {
          const newWindow = window.open(this.data.content.redirectUrl, '_blank');
          if (!!newWindow) {
            newWindow.focus();
            localStorage.removeItem('pop-up-blocked');
          } else {
            localStorage.setItem('pop-up-blocked', this.data.content.redirectUrl);
          }
        }
        this.modalService.dismissAll();
      });
  }

  configureMarketoLinks() {
    const emailLinkElements = document.querySelectorAll(`a[href="${this.launchUrlBase}${this.formId}"]`);
    if (!emailLinkElements || !emailLinkElements.length) {
      return;
    }
    const emailLinkArray = Array.prototype.slice.call(emailLinkElements);

    emailLinkArray.forEach(link => {
      if (link._clickHandlerAdded) {
        return;
      }
      link._clickHandlerAdded = true;
      link.addEventListener('click', event => {
        if (event.stopPropagation) {
          event.stopPropagation();
        }
        if (event.preventDefault) {
          event.preventDefault();
        }
        this.openModal();
        return false;
      });
    });
  }

}
