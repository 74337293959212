import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonParserService {
  safeParse(obj: any): any {
    if (obj && this.isMaybeStringifiedObject(obj)) {
      try {
        return JSON.parse(obj);
      } catch (err) { /* ok */ }
    }
    return obj;
  }

  private isMaybeStringifiedObject(obj: unknown): boolean {
    return (typeof obj === 'string') && (obj.startsWith('[') || obj.startsWith('{'));
  }
}
