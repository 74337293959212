import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import { DataService } from './data.service';
import { SuccessResponse } from 'libs/models/pbc-models';
import { AnalyticsReportingService } from './analytics-reporting.service';
import { LoggingService, SeverityLevel } from './logging.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { PbcCookieService } from './pbc-cookie.service';
import { BaseMemberRequest } from 'libs/models/member.models';
import { MemberSharedService } from './member-shared.service';

@Injectable({
  providedIn: 'root'
})
export class SurrogateIdService  {
  private readonly defaultSurrogateId = 'Unknown';

  constructor(private readonly dataService: DataService,
              private readonly analyticsReporting: AnalyticsReportingService,
              private readonly memberSharedService: MemberSharedService,
              private readonly pbcCookieService: PbcCookieService,
              private readonly logger: LoggingService,
              private readonly router: Router) { }

  populateSurrogateId(): Observable<boolean> {
    if (this.hasSurrogateId()) {
      return of(true);
    }
    return this.retrieveSurrogateId().pipe(map(surrId => {
      return this.setSurrogateId(surrId);
    }), catchError(err => this.handleError(err)));
  }

  clearSurrogateId() {
    this.analyticsReporting.setSurrogateId(null);
  }

  resetSurrogateId(): Observable<boolean> {
    this.clearSurrogateId();
    return this.populateSurrogateId().pipe(map(resp => resp));
  }

  private retrieveSurrogateId(): Observable<string> {
    return this.memberSharedService.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of('');
      }
      return this.dataService.post<SuccessResponse<string>>(`${EnvironmentService.variables.dataLocation.bffBaseUrl}${EnvironmentService.variables.dataLocation.memberSurrogateIdRequestUrl}`,
      new BaseMemberRequest(loggedInMemKey), {})
        .pipe(map(response => response.result),
          catchError(error => {
            this.logger.trackException(error, this.router.url, SeverityLevel.Error);
            return of(this.defaultSurrogateId);
          })
        );
    }));
  }

  private setSurrogateId(surrogateId: string): boolean {
    if (!surrogateId) {
      surrogateId = this.defaultSurrogateId;
    }
    this.analyticsReporting.setSurrogateId(surrogateId);
    this.pbcCookieService.setSessionCookie(this.pbcCookieService.surrogateIdCookie, surrogateId);
    return true;
  }

  private setToDefaultSurrogateId(): void {
    if (!this.hasSurrogateId()) {
      this.setSurrogateId(null);
    }
  }

  private hasSurrogateId(): boolean {
    const cookieSurrogateId = this.pbcCookieService.get(this.pbcCookieService.surrogateIdCookie);
    const analyticsSurrogateId = this.analyticsReporting.getSurrogateId();
    return this.isValid(cookieSurrogateId) && this.isValid(analyticsSurrogateId);
  }

  private isValid(surrogateId: string): boolean {
    return !!surrogateId && surrogateId !== this.defaultSurrogateId;
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.setToDefaultSurrogateId();
    return of(null);
  }
}
