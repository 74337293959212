import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { GroupBookletRequest, PlanDocument } from 'libs/models/benefits.models';
import { SuccessResponse } from 'libs/models/pbc-models';
import { DataService } from './data.service';
import { EnvironmentService } from './environment-variables.service';
import { UrlBuilderService } from './url-builder.service';

@Injectable({
  providedIn: 'root'
})
export class BenefitsSharedService {

  constructor(protected readonly dataService: DataService, protected readonly urlBuilderService: UrlBuilderService) { }

  getGroupBooklets(lineOfBusiness: string): Observable<PlanDocument> {
    return this.dataService
    .post<SuccessResponse<PlanDocument>>(
      this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.benefitGroupBooklets), new GroupBookletRequest(lineOfBusiness)
    ).pipe(map(response => response.result));
  }
}
