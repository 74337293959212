/* pulled from https://github.com/abhazelton/animate-on-scroll, as the module breaks AOT build as of 1.0.8 */
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ScrollService implements OnDestroy {
  scrollObs: Observable<any>;
  resizeObs: Observable<any>;
  pos: number;
  private readonly ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor() {
    // set initial value
    this.manageScrollPos();

    // create observable that we can subscribe to from component or directive
    this.scrollObs = fromEvent(window, 'scroll');

    // initiate subscription to update values
    this.scrollObs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.manageScrollPos());

    // create observable for changes in screen size
    this.resizeObs = fromEvent(window, 'resize');

    // initiate subscription to update values
    this.resizeObs
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.manageScrollPos());
  }

  private manageScrollPos(): void {
    // update service property
    this.pos = window.pageYOffset;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
