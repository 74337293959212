<div class="row mb-5">
  <div class="col-sm-4 pr-3">
    <pbc-footer-logo [footerContent]="footerContent" [logoImgUrl]="logoImgUrl" [homePageUrl]="homePageUrl" [hideRegionSwitch]="hideRegionSwitch"></pbc-footer-logo>

    <div data-id="getConnectedSection" *ngFor="let contactMenu of contactMenus">
      <div class="footer-heading">{{ contactMenu.contactMenuGroupBox.headerTitle }}</div>
      <ul [attr.aria-label]="contactMenu.contactMenuGroupBox.headerTitle">
        <li *ngFor="let contactLink of contactMenu.contactMenuGroupBox.linksGroupBox">
          <ng-container *ngIf="needsDomain(contactLink.route); else isRoute">
            <a [href]="addDomain(contactLink.route)" [innerHtml]="contactLink.title" [attr.lang]="contactLink.lang"
              pbcAnalyticsTag data-analytics-override-route="footer"></a>
          </ng-container>
          <ng-template #isRoute>
            <a [routerLink]="dropToken(contactLink.route)" [innerHtml]="contactLink.title"
              [attr.lang]="contactLink.lang" pbcAnalyticsTag data-analytics-override-route="footer"></a>
          </ng-template>
        </li>
      </ul>
    </div>

    <pbc-footer-social-media *ngIf="!hideSocialMedia" [footerContent]="footerContent"></pbc-footer-social-media>
    <pbc-footer-mobile-apps *ngIf="!hideMobileApps" [footerContent]="footerContent"></pbc-footer-mobile-apps>
    <br>
    <ng-container *ngIf="this.showNcqaLogo()"> 
      <img id="NCQA" style="width: 8em; padding-top: 1em;" src="assets/image/ncqa-accredited-transparent.png" alt="NCQA Badge" />
      <p id="ncaqDisclaimer" class="copyright pt-2" [innerHtml]="ncqaDisclaimerText"></p>
    </ng-container>
    <ng-container *ngIf="showNcqaDarkLogo()">
      <a href="/policies-and-procedures" target="_blank">
      <img id="NCQA" style="width: 8em" src="assets/image/NCQA_for_dark_background.png" alt="NCQA Badge" />
      </a>
      <p id="ncaqDisclaimer" class="copyright pt-2" [innerHtml]="ncqaDisclaimerText"></p>
    </ng-container>
  </div>

  <div *ngIf="horizontalLinks" class="col-sm-8" [innerHtml]="footerContent?.content?.htmlBlock"></div>

  <div *ngIf="!horizontalLinks" class="col-sm-8">
    <pbc-footer-nav-cols [menus]="menus"></pbc-footer-nav-cols>
  </div>

</div>
