<div [attr.data-id]="site">
    <a id="skippy" class="sr-only sr-only-focusable" href onclick="return false;" (click)="skipLink()" pbcAnalyticsTag data-analytics-override-route="header"
    data-analytics-tag="skippy">
      <div class="container">
        <span class="skiplink-text">Skip to main content</span>
      </div>
    </a>
    <div #headerWrapper>
      <pbc-header [site]="site" [hideRegionSwitch]="false"></pbc-header>
      </div>
    <main [ngClass]="getPageClass(route)">
      <div [ngClass]="getPageWidth(route) === 'full' ? '' : 'container'" #main id="mainContent" role="main" tabindex="-1">
        <router-outlet #route="outlet"></router-outlet>
      </div>
    </main>
    <pbc-footer class="d-print-none" [hideRegionSwitch]="false"></pbc-footer>
  </div>
