import { BehaviorSubject } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { IMarketoFormObject, IMarketoForms2 } from './marketo.models';
export declare const MktoForms2: IMarketoForms2; // global var added by Marketo library

@Component({
  templateUrl: './marketo-form.component.html',
})
export class MarketoFormComponent implements OnInit, OnDestroy {
  private _marketoForm: IMarketoFormObject;

  readonly baseUrl = 'https://app-sj06.marketo.com';
  @Input() hiddenFields: object;
  munchkinId = '857-YGR-659';
  formId: number;
  title: string;
  done$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  get marketoFormId() {
    return `mktoForm_${this.formId}`;
  }

  constructor(private activeModal: NgbActiveModal) { }
  
  ngOnInit() {
    if (!!this.formId && typeof(this.formId) !== typeof(Number)) {
      this.formId = +this.formId;
    }
    if (!MktoForms2) {
      // TODO: Handle gracefully; e.g., try to load the lib once more before failing.
      throw new Error('Failed to load Marketo library!');
    }
    MktoForms2.loadForm(this.baseUrl, this.munchkinId, this.formId, (form: IMarketoFormObject) => {
      this.initializeMarketoForm(form);
    });

    MktoForms2.whenReady((form: IMarketoFormObject) => {
      form.addHiddenFields(this.hiddenFields);
      form.onSuccess((e) => {
        if (e.hasOwnProperty('formid') && +e['formid'] === this.formId) {
          this.done$.next(true);
        }
      });
    });

  }

  closeModal(): void {
    this.activeModal.close('dismiss');
  }

  ngOnDestroy() {
    this._marketoForm = null;
  }

  private initializeMarketoForm(form: IMarketoFormObject) {
    this._marketoForm = form;
    this._marketoForm.onSuccess((_form: IMarketoFormObject) => {
      this._marketoForm.getFormElem().hide();
      return false;
    });
  }
}
