import { RegistrationPlan, BaseMemberRequest } from 'libs/models/member.models';

/* Class definitions */
export class RegistrationPlanDetails extends RegistrationPlan {
  groupName: string;
  subscriberId: string;
  keyDate: Date;
  planStatus: RegistrationPlanStatus;
  planStatusDescription: string;
  plan: string = '';
}

export enum RegistrationPlanStatus {
    Unknown = 'Unknown',
    Active = 'Active',
    Termed = 'Termed',
    Future = 'Future'
}

export class RegistrationPlansRequest extends BaseMemberRequest {
  memberKeys: string[];

  constructor(requestorMemberKey: string, memberKeys: string[]) {
    super(requestorMemberKey);
    this.memberKeys = memberKeys;
  }
}
