<div class="form-group select">
  <label [attr.for]="id" class="visually-hidden">{{ label }}</label>
  <select data-analytics-tag="generic-select" [formControl]="control" [attr.aria-label]="label" (focus)="focus.emit()" tabindex="0"
  (blur)="blur.emit()" (ngModelChange)="propogateChange($event)" class="form-control" [attr.id]="'select' + id" #select [attr.name]="id"
    [compareWith]="compareFunction" [ngClass]="value ? 'hasLength' : 'hasNoLength'" [attr.disabled]="disabled ? 'true' : null" pbcAnalyticsTag data-analytics-title="form_group_select">
    <option *ngIf="emptyDefaultValue" value=""></option>
    <option *ngFor="let option of options" [ngValue]="option">
      {{ optionLabelFunction(option) }}
    </option>
  </select>
  <span class="zoom-label">{{ label }}</span>
</div>
