import { Component, Input } from '@angular/core';

import { StringsService } from 'libs/services/strings.service';
import { AiSessionService } from 'libs/services/ai-session.service';

@Component({
  selector: 'pbc-error-data-block',
  templateUrl: './error-data-block.component.html',
  styles: ['i.material-icons { font-size: 1.2rem } div.errorMessage.left { margin-left: 1.4rem }']
})
export class ErrorDataBlockComponent {

  @Input() errorMessage;
  @Input() alignment: string;
  @Input() showSessionId = false;
  @Input() icon = 'warning';

  timestamp: Date;
  sessionId: string;
  constructor(readonly strings: StringsService, readonly sessionService: AiSessionService) {
    this.sessionId = sessionService.readSessionId();
    this.timestamp = new Date();
  }
}
