<ng-container *ngIf="!!footerContent">
  <footer class="d-none d-md-block" [ngClass]="{'main-in': footerContent, 'mb-5': getIsImpersonating()}">
    <pbc-footer-top [footerContent]="footerContent" [logoImgUrl]="logoImgUrl" [homePageUrl]="homePageUrl" [hideRegionSwitch]="hideRegionSwitch" [hideSocialMedia]="hideSocialMedia" [hideMobileApps]="hideMobileApps" [horizontalLinks]="horizontalLinks" [menus]="menus"></pbc-footer-top>

    <pbc-footer-bottom [site]="site" [footerContent]="footerContent" [menus]="menus" [horizontalLinks]="horizontalLinks"></pbc-footer-bottom>
  </footer>

  <footer class="mobile d-block d-md-none" [ngClass]="{'mb-5':getIsImpersonating()}">
    <pbc-footer-top-mobile [footerContent]="footerContent" [logoImgUrl]="logoImgUrl" [homePageUrl]="homePageUrl" [hideRegionSwitch]="hideRegionSwitch" [hideSocialMedia]="hideSocialMedia" [hideMobileApps]="hideMobileApps" [horizontalLinks]="horizontalLinks" [menus]="menus"></pbc-footer-top-mobile>

    <pbc-footer-bottom [site]="site" [footerContent]="footerContent" [menus]="menus" [horizontalLinks]="horizontalLinks"></pbc-footer-bottom>
  </footer>

  <div *ngIf="getIsImpersonating()" class="alert alert-warning fixed-bottom mb-0">
    <div class="container d-flex">
      <div class="align-self-stretch"><i class="material-icons pr-1" aria-hidden="true">warning</i></div>
      <div>{{ impersonationMessage }}</div>
    </div>
  </div>
</ng-container>