// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env:prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

export const baseenvironment = {
  site: '',
  name: 'dev',
  appId: '',
  production: false,
  mockDemoSSO: false,
  version: '',
  contentDomain: '',
  egainDomain: '',
  appInsightsKey: '',
  cssCdnLink: 'https://pbclibrary.azureedge.net/library/pbc.css',
  configServiceUrl: '',
  baseUrlVisitor: '',
  baseUrlMember: '',
  baseUrlEmployer: '',
  baseUrlProducer: '',
  baseUrlProvider: '',
  oHPAvailityUrl: '',
  oHPEvolentUrl:'',
  notification : {
    kinwellURL:'https://kinwellhealth.com/appointments/'
  },
  bingMap: {
    bingMapKey: 'AnlwazdZDZwnLI8zFyDe1203DY5D2SNgREbnb-sl5mvit3linGVfWXdC2rZtPDCo',
    bingMapLocationUrl: 'https://dev.virtualearth.net/REST/v1/Locations',
    bingMapControlUrl: 'https://www.bing.com/api/maps/mapcontrol'
  },
  hmo: false,
  medicareSignInDomain: '',
  dataLocation: {
    searchBaseUrl: '',
    bffBaseUrl: '',
    contentBaseUrl: '',    
    healthBffUrl: 'v1.0/environment/information',
    healthContentUrl: 'v1.0/environment/information',
    loginUrl: 'Auth/login?api-version=1',
    validateUrl: 'v1.0/Auth/validate',
    signOutUrl: 'v1.0/Auth/signout',
    ssoOutboundUrl: 'v1.0/Auth/outboundsso',
    encryptedTokenUrl: 'v1.0/Auth/token',

    claimsSearchUrl: 'v1.0/claims/',
    claimsProvidersSearchUrl: 'v2.0/claims/providers',
    claimIdSearchUrl: 'v1.0/claims/id',
    claimsExportUrl: 'v1.0/claims/export',
    detailsUrl: 'v2.0/claims/details',
    claimsSubmissionUrl: 'v1.0/claims/submission',
    contactDetailsGetUrl: 'v1.0/contactdetails/contactdetails',
    contactDetailsUpdateUrl: 'v1.0/contactdetails/contactdetails/update',
    eobUrl: 'v1.0/claims/eob',
    eobVisibilityUrl: 'v1.0/claims/eob/isvisible',
    referralDetailsUrl: 'v2.0/referrals/details',
    referralsSummaryUrl: 'v1.0/referral/referrals',
    preapprovalsListUrl: 'v1.0/PriorAuth/PriorAuthReqDetails',
    memberRegistrationsUrl: 'v1.0/members/orderedregistrations',
    memberRegistrationDetailsUrl: 'v1.0/members/registrationdetails',
    memberRegistrationDetailsUrlV2: 'v2.0/Member/registrationdetails',
    memberDetails: 'v1.0/members/member',
    familyMembersUrl: 'v1.0/members/family',
    familyMembersEligibilityUrl: 'v1.0/members/eligiblefamilymembers',
    eligibleKeysUrl: 'v1.0/members/eligiblefamilymemberkeys',
    memberNameAndDobUrl: 'v1.0/members/membernamedob',
    memberVisibilityPermissions: 'v1.0/members/visibility',
    memberVisibilityPermissionsHmo: 'v1.0/members/visibility',
    evaluateAllRules: 'v1.0/members/evaluateallrules',
    evaluateAnyRules: 'v1.0/members/evaluateanyrules',
    memberImpersonationRequestUrl: 'v1.0/members/impersonation',
    memberSsoRequestUrl: 'v1.0/members/sso',
    memberAccountEmailRequestUrl: 'v1.0/members/accountemail',
    memberGroup: 'v1.0/members/group',
    memberTemporaryCardRequestUrl: 'v1.0/members/temporarycards',
    memberTemporaryCardRequestHMOUrl: 'v1.0/members/member/idcardimages',
    memberFallbackCardRequestUrl: 'v1.0/members/fallbackcard',
    memberSetSelectedPlanUrl: 'v1.0/members/selectedmemberkey/set',
    memberGetSelectedPlanUrl: 'v1.0/members/selectedmemberkey/get',
    memberExperianBenefitUrl: 'v1.0/members/hascreditmonitoring',
    memberDataGetUrl: 'v1.0/members/memberdata/get',
    memberDataSetUrl: 'v1.0/members/memberdata/set',
    notificationGetUrl: 'v1.0/notifications/notifications',
    notificationsUpdateUrl: 'v1.0/notifications/Updatenotifications',

    memberPaperlessEobPreferencesUrl: 'v1.0/memberaccount/getEobAndEmail',
    memberSetPaperlessEobPreferencesUrl: 'v1.0/memberaccount/setEobAndEmail',
    memberSetDefaultPaperlessEobPreferencesUrl: 'v1.0/memberaccount/setdefaulteobandemail',
    memberPaperless1095PreferencesUrl: 'v1.0/form1095/get1095optinstatus',
    memberSetPaperless1095PreferencesUrl: 'v1.0/form1095/set1095optinstatus',
    memberPhysicalCardRequestUrl: 'v1.0/memberaccount/orderidcard',
    memberSurrogateIdRequestUrl: 'v1.0/memberaccount/surrogateid',

    memberLoggingAddEntry: 'v1.0/memberlogging/logentry',

    benefitsAccumulatorsUrl: 'v1.0/benefits/accumulators',
    benefitsCategoriesUrl: 'v1.0/benefits/categories',
    benefitsServicesUrl: 'v2.0/benefits/services',
    benefitBookletsUrl: 'v1.0/benefits/benefitbooklets',
    benefitBookletUrl: 'v1.0/benefits/benefitbooklet',
    benefitGroupBooklets: 'v1.0/benefits/booklets',
    benefitIsCoInsuranceUrl: 'v1.0/visibility/iscoinsurance',
    benefitIsVisionPartOfMedical: 'v1.0/benefits/isvisionpartofmedical',

    form1095ListUrl: 'v1.0/form1095/get1095taxforms',
    form1095DetailsUrl: 'v1.0/form1095/get1095taxformdetail',
    form1095EligibilityUrl: 'v1.0/form1095/getform1095eligibility',

    hraPlanYearsUrl: 'v1.0/claims/hraplanyears',
    hraBalanceUrl: 'v1.0/claims/hrabalance',
    hraClaimsUrl: 'v1.0/claims/hraclaims',

    contentComponentUrl: 'v1.0/content/',
    componentStateUrl: 'v1.0/content/componentstate',
    pageStateUrl: 'v1.0/content/pagestate',
    alertUrl: 'v1.0/content/alerts',

    privacyAuthorizationsForMemberUrl: 'v1.0/memberauth/orginatormemberauths',
    privacyAuthorizationHistoryForMemberUrl: 'v1.0/memberauth/orginatormemberauths',
    privacyAuthorizationsToMemberUrl: 'v1.0/memberauth/targetmemberauths',
    removePrivacyAuthorization: 'v1.0/memberauth/removetargetmemberAuths',
    authSubmissionUrl: 'v1.0/memberauth/addmemberauth',
    authorizationDateRestrictionsUrl: 'v1.0/memberauth/authorizationdatevisibility',

    pcpSelectedProviderUrl: 'v1.0/pcp/pcpdoctor',
    pcpProviderInfoUrl: 'v1.0/pcp/provider/providerinfo',
    pcpSpecialtiesUrl: 'v1.0/pcp/specialtylist',
    pcpLocationHintsUrl: 'v1.0/pcp/locationhints',
    pcpEMSSearchByNameUrl: 'v3.0/pcp/provider/search/name',
    pcpDetailUrl: 'v1.0/pcp/guidedetail',
    pcpNameHintsUrl: 'v1.0/pcp/PCPNamehints',
    pcpSearchByNameUrl: 'v1.0/pcp/provider/search/name',
    pcpSearchBySpecialtyUrl: 'v1.0/pcp/provider/search/specialty',
    pcpSetUrl: 'v2.0/pcp/setpcp',
    pcpGetCurrentPcpUrl: 'v3.0/pcp/currentpcp',
    pcpGetFamilyUrl: 'v3.0/pcp/family',


    drugsRequiringApprovalXml: 'data/pharmacy/drugs-requiring-approval.xml',

    virtualAssistantUrl: 'wss://vasws.dev.premera.net/chat',
    newpcpSpecialtiesUrl: 'v3.0/pcp/specialtylist',
    newpcpLocationHintsUrl: 'v3.0/pcp/locationhints',
    newpcpSearchByNameUrl: 'v3.0/pcp/provider/search/NameWithOtherLocation',
    newpcpSearchBySpecialtyUrl: 'v3.0/pcp/provider/search/SpecialtyWithOtherLocation',
    newpcpSetUrl: 'v3.0/pcp/setpcp',
    visionClaimSubmissionUrl: 'v1.0/claims/vision/submission',
    visionClaimCreateDraftUrl :'v1.0/claims/vision/createDraft',
    visionClaimAttachmentUploadUrl : 'v1.0/claims/vision/attachments',
    visionClaimAttachmentPDFUploadUrl : 'v1.0/Claims/vision/pdfAttachments',
    visionClaimUpdateDraftUrl : 'v1.0/claims/vision/updateDraft',
    visionClaimAttachmentDeleteUrl :'v1.0/Claims/vision/DeleteClaimAttachment',
    medicalPoliciesRecentUpdatesUrl: '/medicalpolicies-group/RecentUpdatesDataXML.xml',    
  },
  sso: {
    findCareLaunch: 'sapphire',
    cycLaunch: 'ConnectYourCare',
    expressScriptLaunch: 'ExpressScripts',
    secureInboxLaunch: 'eGain',
    teladocLaunch: 'Teladoc',
    pcpLaunch: 'Olympus',
    rxAdvanceLaunch: 'RXAdvance',
    billpayLaunch: 'Alacriti',
    medikeeperLaunch: 'MediKeeper'
  },
  authentication: {
    clientId: '',
    callbackUrl: '',
    responseType: 'id_token+token',
    scope: 'openid+phone+email',
    state: '69b42ab13f27410fb490be8f80a61064',
    nonce: 'ebc75c3b6e6b46b5a7a0351b7c93fd06',
    responseMode: 'fragment',
    authSuffix: '',
    registerUrl: '',
    authorization: '',
    restart: '',
    defaultReturnUrl: '/dashboard'
  },
  relayStateRoutingTable: {
    claims: 'manage-claims',
    claimsearch: 'manage-claims',
    orderidcard: 'dashboard',
    providerdirectory: 'find-a-doctor/sso-launch',
    fundingaccount: 'connect-your-care/sso-launch',
    fundingaccountfsa: 'connect-your-care/sso-launch',
    fundingaccounthsa: 'connect-your-care/sso-launch',
    reimburse: 'connect-your-care/sso-launch',
    cdhaccount: 'cyc-cdh-account/sso-launch',
    billpay: 'cyc-bill-pay/sso-launch',
    claimsubmission: 'cyc-claim-submission/sso-launch',
    rxbenefits: 'rx-benefits/sso-launch',
    claimsandbalances: 'claims-and-balances/sso-launch',
    druginfo: 'drug-info/sso-launch',
    rx: 'order-and-refill/sso-launch',
    homedelivery: 'home-delivery/sso-launch',
    locatepharmacy: 'locate-pharmacy/sso-launch',
    annualcosts: 'annual-rx-cost/sso-launch',
    myrxchoice: 'my-rx-choice/sso-launch',
    pharmacyprofile: 'pharmacy-profile/sso-launch',
    rxhistory: 'rx-history/sso-launch',
    rxpricing: 'rx-pricing/sso-launch',
    referrals: 'member-referrals'
  },
  recoveryEmail: {
    realmSuffix: '',
    url: ''
  },
  recoveryPhone: {
    realmSuffix: '',
    url: ''
  },
  resetPassword: {
    realmSuffix: '',
    url: ''
  },

  addPlan: {
    realmSuffix: '',
    url: ''
  },

  fileCompression: {
    compressionThreshold: 333,
    compressionRatio: .7
  },
  featureFlags: {
    accountPrivacy: true,
    accountSettings: true,
    benefits: true,
    benefitBooklets: true,
    benefitsDashWidget: true,
    billPay: true,
    chat: false,
    claimsEob: true,
    claimsList: true,
    claimsSubmission: true,
    claimsSubmitDental: true,
    claimsSubmitMedical: true,
    claimsSubmitPrescription: false,
    claimsSubmitVision: true,
    claimsSubmitVisionPartOfMedical: true,
    configService: true,
    editContactDetails: true,
    eobPromoFlag: true,
    experian: true,
    findADoctor: true,
    form1095Advanced: false,
    fundingAccounts: false,
    hra: true,
    identificationCard: true,
    identificationCardOrdering: true,
    login: true,
    medicareSupplementFlag: true,
    multiReg: true,
    multiRegAddPlan: true,
    multiRegPlanSwitch: true,
    paperless1095: true,
    paperlessEob: true,
    primaryCareProvider: true,
    resetPasswordFeatureFlag: true,
    resetRecoveryEmailFeatureFlag: true,
    resetRecoveryPhoneFeatureFlag: true,
    rxCareAlerts: true,
    costEstimate: true,
    costEstimateRequest: true,
    secureChat: true,
    ssoOutboundSapphire: true,
    ssoOutboundEsi: true,
    ssoOutboundRxAdvance: true,
    ssoOutboundCyc: true,
    ssoOutboundSecureInbox: true,
    ssoOutboundTeladocFlag: true,
    tMobileFlag: true,
    wellness: true,
    futureEligibilityFlag: true,
    pcpWelcomeFlag: true,
    defaultEobEligibility: true,
    planSwitchingFlag: true,
    notificationFEFlag : true,
    kinwellAdFlag: true,
    futureHmoBannerFlag: true,
    hmoWelcomePageFlag: true,
    newVisionClaimFlag: true,
    visionClaimPartTwoEnhancement : false,
    amazonBookletFlag : true,
    newVisionServiceFlag : true,
    visionPCPSearchFlag : false
  }
};
