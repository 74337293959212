import { Component, Input } from '@angular/core';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-html-block',
  templateUrl: './html-block.component.html'
})

export class HtmlBlockComponent {
  @Input() data: ComponentState;
}
