import { Component, Input, OnInit } from '@angular/core';

import { ComponentState } from 'libs/models/pbc-models';
import { ContentComponent } from '../content-component';
import { StringsService } from 'libs/services/strings.service';
import { HelperService } from 'libs/services/helper.service';
import { Subject } from 'rxjs';
import { VisibilityService } from 'libs/services/visibility.service';
import { MemberPermission } from 'libs/models/pbc-enums';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pbc-card-row',
  templateUrl: './card-row.component.html'
})
export class CardRowComponent implements ContentComponent, OnInit {
  @Input() data: ComponentState;
  states: string[];
  sections: any;
  isLwwa:boolean = false;
  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private readonly constants: StringsService, private helperService: HelperService ,private readonly visibilityService: VisibilityService){}

  ngOnInit() {
    this.states = this.constants.data.value.stateOptions;
    this.visibilityService.hasPermission(MemberPermission.HasLwwaPlan).pipe(takeUntil(this.componentDestroyed$))
    .subscribe(hasLwwa => {
      if (hasLwwa) {
        this.isLwwa = hasLwwa; 
      }
    });
  }

  createHeading(cardHeaderLevel: string, cardStyle: string, cardHeader): string {
    return this.helperService.createHeading(cardHeaderLevel, cardStyle, cardHeader);
  }

  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    if (!this.sections) {
      this.sections = JSON.parse(this.data.content.sections);
    }
    return this.sections;
  }

  cardIsStandard(cardStyle): boolean {
    if(cardStyle === "primary" || cardStyle === "secondary"){
      return true;
    }
    return false;
  }

  cardStandardFooterStyle(cardStyle, bkgStyle): string {
    if(cardStyle !== "accent" && bkgStyle === "white") {
      return 'background-white border-0'
    }
    return 'border-0 background-accent';
  }

  cardIsHorizontal(cardStyle): boolean {
    return cardStyle.startsWith("horizontal")
  }

  isRightAligned(align): boolean {
    if(align.includes("right")){
      return true;
    }
    return false;
  }

  isSecondary(cardStyle): boolean {
    if(!cardStyle.indexOf("secondary")){
      return true;
    }
    return false;
  }

  isIcon(cardStyle): boolean {
    if(!cardStyle.indexOf("icon")){
      return true;
    }
    return false;
  }

}
