import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { Observable, of } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';

import { SuccessResponse, BffApiResponse } from 'libs/models/pbc-models';
import { Member, MemberDetailsRequest, BaseMemberRequest, BaseMemberHMORequest, SelectedPlanresponse } from 'libs/models/member.models';
import {
  IdCardImageResult, MemberIdCardRequest, IdCardSide,
  FallbackIdCardRequest, FallbackIdCard, MemberIdCardOrderResponse, MemberCardOrderRequest, HasCreditMonitoringRequest, MemberIdCardImageRequest, IdCardImageResponse
} from '../models/member.models';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { RegistrationPlansRequest, RegistrationPlanDetails } from 'member/shared/models/registration.models';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { PaperlessPreferenceResponse, PaperlessPreferenceRequest, SetPaperlessPreferenceRequest } from '../models/paperless.models';
import { PaperlessEobPromoResponse, PaperlessEobPromoRequest, SetPaperlessEobPromoData } from '../models/eob.models';
import { Form1095PreferenceResponse } from '../models/form-1095.models';

@Injectable({
  providedIn: 'root'
})
export class MemberService extends MemberSharedService {

  getRegistrationPlanDetails(memberKeys: string[], futurePlanAccess: boolean = false): Observable<RegistrationPlanDetails[]> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of([]);
      }
      return this.dataService.post<SuccessResponse<RegistrationPlanDetails[]>>(
        this.urlBuilderService.buildBffUrl(futurePlanAccess ? EnvironmentService.variables.dataLocation.memberRegistrationDetailsUrlV2 : EnvironmentService.variables.dataLocation.memberRegistrationDetailsUrl),
        new RegistrationPlansRequest(loggedInMemKey, memberKeys), {}).pipe(map(response => !!response ? response.result : null));
    }));
  }

  getFamilyMembers(targetMemberKey: string, privacyScope: string, appendDob?: boolean): Observable<Member[]> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of([]);
      }
      return this.dataService
        .post<SuccessResponse<Member[]>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.familyMembersUrl),
          new MemberDetailsRequest(loggedInMemKey, targetMemberKey, privacyScope,new Date()), {}
        )
        .pipe(map(response => {
          if (!response) {
            return null;
          }
          if (appendDob) {
            this.resolveAppendDob(response.result);
          }
          return response.result;
        }));
    }));
  }

  getFamilyEligibility(asOfDate: Date, privacyScope: string): Observable<Member[]> {
    return this.getFamilyWithEligibility<Member[]>(asOfDate, privacyScope);
  }

  getFamilyMemberFirstNameWithDob(familyMember: Member): string {
    return `${familyMember.firstName}${familyMember.appendDob ? ` (${format(familyMember.dateOfBirth, 'MM/DD/YYYY')})` : ''}`;
  }

  getFamilyMemberFirstNameLastNameWithDob(familyMember: Member): string {
    return `${familyMember.firstName + " " + familyMember.lastName}${familyMember.appendDob ? ` (${format(familyMember.dateOfBirth, 'MM/DD/YYYY')})` : ''}`;
  }
  getFamilyMemberFirstNameWithDashDob(familyMember: Member): string {
    return `${familyMember.firstName}${familyMember.appendDob ? ` - ${format(familyMember.dateOfBirth, 'MM/DD/YYYY')}` : ''}`;
  }

  getMemberIdCardSide(targetMemberKey: string, cardSide = IdCardSide.Front): Observable<IdCardImageResult> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new IdCardImageResult());
      }
      return this.dataService
        .post<SuccessResponse<IdCardImageResult>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberTemporaryCardRequestUrl),
          new MemberIdCardRequest(loggedInMemKey, targetMemberKey, cardSide), {}).pipe(map(response => response.result));
    }));
  }

  getMemberIdCardImages(id: string, targetMemberKey: string): Observable<IdCardImageResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new IdCardImageResponse());
      }
      return this.dataService
        .post<SuccessResponse<IdCardImageResponse>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberTemporaryCardRequestHMOUrl),
          new MemberIdCardImageRequest(id, loggedInMemKey, targetMemberKey), {}).pipe(map(
            response => {
              if (!!response && !!response.result) {
                return response.result;
              }
              return new IdCardImageResponse();
            }
          ));
    }));
  }

  getMemberFallbackIdCard(memberKey: string, planEffectiveDate:Date): Observable<FallbackIdCard> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new FallbackIdCard());
      }
      return this.dataService
        .post<SuccessResponse<FallbackIdCard>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberFallbackCardRequestUrl),
          new FallbackIdCardRequest(loggedInMemKey, memberKey, planEffectiveDate), {}).pipe(map(response => response.result));
    }));
  }

  orderMemberIdCard(memberKey: string): Observable<MemberIdCardOrderResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new MemberIdCardOrderResponse());
      }
      return this.dataService
        .post<SuccessResponse<MemberIdCardOrderResponse>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberPhysicalCardRequestUrl),
          new MemberCardOrderRequest(loggedInMemKey, memberKey), {}).pipe(map(response => response.result));
    }));
  }

  getAccountEmail(): Observable<string> {
    return this.dataService
      .get<SuccessResponse<string>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberAccountEmailRequestUrl))
      .pipe(map(response => {
        return response.result;
      }));
  }

  setSelectedPlan(initialHMOLoginDate: Date, initialWelcomePageDate : Date = null): Observable<boolean> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey || (initialHMOLoginDate == null && initialWelcomePageDate ==null)){
        return of(false);
      }
      return this.dataService
        .post<BffApiResponse>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberSetSelectedPlanUrl),
          new BaseMemberHMORequest(loggedInMemKey, initialHMOLoginDate,initialWelcomePageDate), {}).pipe(map(response => response.statusCode === 200), catchError(err => of(false)));
    }));
  }

  getSelectedPlan(memberKey: string): Observable<SelectedPlanresponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new SelectedPlanresponse());
      }
      return this.dataService
        .get<SuccessResponse<SelectedPlanresponse>>(this.urlBuilderService.buildBffUrl(`${EnvironmentService.variables.dataLocation.memberGetSelectedPlanUrl}?requestorMemberKey=${memberKey}`)).pipe(map(response => {
            return response.result;}));
    }));
  }

  hasCreditMonitoring(): Observable<boolean> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(false);
      }
      return this.dataService
        .post<SuccessResponse<any>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberExperianBenefitUrl),
          new HasCreditMonitoringRequest(loggedInMemKey)
        ).pipe(map(response => response.result));
    }));
  }

  getPaperlessEobStatusAndEmail(): Observable<PaperlessPreferenceResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new PaperlessPreferenceResponse());
      }
      return this.dataService
        .post<SuccessResponse<PaperlessPreferenceResponse>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberPaperlessEobPreferencesUrl),
          new PaperlessPreferenceRequest(loggedInMemKey, loggedInMemKey)
        ).pipe(map(response => response.result));
    }));
  }

  setPaperlessEobStatusAndEmail(optInStatus: boolean, email: string): Observable<void> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of();
      }
      return this.dataService
        .post<any>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberSetPaperlessEobPreferencesUrl),
          new SetPaperlessPreferenceRequest(loggedInMemKey, loggedInMemKey, optInStatus, email)
        );
    }));
  }

  setDefaultPaperlessEobStatusAndEmail(optInStatus: boolean): Observable<PaperlessPreferenceResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new PaperlessPreferenceResponse());
      }
      return this.dataService
        .post<SuccessResponse<PaperlessPreferenceResponse>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberSetDefaultPaperlessEobPreferencesUrl),
          new SetPaperlessPreferenceRequest(loggedInMemKey, loggedInMemKey, optInStatus, null, null)
        ).pipe(map(response => response.result));
    }));
  }

  getMemberData(type: string): Observable<PaperlessEobPromoResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new PaperlessEobPromoResponse());
      }
      return this.dataService
        .post<SuccessResponse<PaperlessEobPromoResponse>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberDataGetUrl),
          new PaperlessEobPromoRequest(loggedInMemKey, loggedInMemKey, type)
        ).pipe(map(response => response.result));
    }));
  }

  setMemberData(type: string, actionType: string, dateOfAction: Date): Observable<void> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of();
      }
      return this.dataService
        .post<any>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberDataSetUrl),
          new SetPaperlessEobPromoData(loggedInMemKey, loggedInMemKey, type, actionType, dateOfAction)
        ).pipe(map(response => response.result));
    }));
  }

  getPaperless1095StatusAndEmail(): Observable<PaperlessPreferenceResponse> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new PaperlessPreferenceResponse());
      }
      return this.dataService
        .post<SuccessResponse<Form1095PreferenceResponse>>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberPaperless1095PreferencesUrl),
          new PaperlessPreferenceRequest(loggedInMemKey, loggedInMemKey)
        ).pipe(map(response => {
          return response.result;
        }));
    }));
  }

  setPaperless1095StatusAndEmail(optInStatus: boolean, email: string): Observable<void> {
    return this.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of();
      }
      return this.dataService
        .post<any>(
          this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.memberSetPaperless1095PreferencesUrl),
          new SetPaperlessPreferenceRequest(loggedInMemKey, loggedInMemKey, optInStatus, email)
        );
    }));
  }
}
