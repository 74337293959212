import { Component, Input } from '@angular/core';
import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';


export interface IHeroOverlayBreadcrumb {
  displayText: string;
  route: string;
  index?: string | number;
}

export interface IHeroOverlay {
  breadcrumbs?: IHeroOverlayBreadcrumb[];
  heading?: string;
  content?: string;
}

export interface IOverlayHeroContent {
  heroOverlays: IHeroOverlay[];
}

@Component({
  selector: 'pbc-overlay-hero',
  templateUrl: './overlay-hero.component.html'
})
export class OverlayHeroComponent implements ContentComponent {
  private _heroOverlay: IHeroOverlay;
  @Input() data: ComponentState;

  get heroOverlay(): IHeroOverlay {
    if (!this._heroOverlay && this.data && !!this.data.content) {
      // local format - deprecating
      if (!!this.data.content.heroOverlays && Array.isArray(this.data.content.heroOverlays)) {
        const content = this.data.content;
        this._heroOverlay = content && content.heroOverlays[0];
      } else { // remote format
        this.data.content.breadcrumbs = this.parseBreadcrumbs();
        this._heroOverlay = this.data.content;
      }
    }
    return this._heroOverlay;
  }

  parseBreadcrumbs(): any[] {
    if (!this.data.content || !this.data.content.breadcrumbs) {
      return [];
    }
    if (typeof (this.data.content.breadcrumbs) === 'string') {
      return JSON.parse(this.data.content.breadcrumbs);
    }
  }

  showBreadCrumbs(crumbs): boolean {
    for (let crumb in crumbs) {
      if (crumbs[crumb].displayText && crumbs[crumb].route) {
        return true;
      }
    }
    return false;
  }
}
