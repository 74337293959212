import { Injectable } from '@angular/core';

import { DataComponentState } from 'libs/models/pbc-models';

@Injectable({
  providedIn: 'root'
})
export class FooterContentHelperService {

  constructor() { }

  parseFooterContent(footerContent: DataComponentState, contentName: string): any {
    if (!footerContent || !footerContent.content || !footerContent.content[contentName]) {
      return undefined;
    }

    const jsonFooter = JSON.parse(footerContent.content[contentName]);
    jsonFooter.forEach(element => {
      if (!element[contentName] || !element[contentName].linksGroupBox) {
        return;
      }
      if (!Array.isArray(element[contentName].linksGroupBox)) {
        const arr = Array<object>();
        arr.push(element[contentName].linksGroupBox);
        element[contentName].linksGroupBox = arr;
      }
      if (!!element[contentName].linksGroupBox.length) {
        element[contentName].linksGroupBox = element[contentName].linksGroupBox.filter(item => item.enabled === 'true');
      }
    });

    return jsonFooter;
  }
}
