import { Component, ComponentFactory, ComponentFactoryResolver, OnInit, Type } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { BaseContentPage } from 'libs/classes/base-content-page';
import { AccordionComponent } from 'libs/components/accordion/accordion.component';
import { AlertComponent } from 'libs/components/alert/alert.component';
import { CalloutComponent } from 'libs/components/callout/callout.component';
import { CalloutImageComponent } from 'libs/components/callout-image/callout-image.component';
import { CardComponent } from 'libs/components/card/card.component';
import { CardRowComponent } from 'libs/components/card-row/card-row.component';
import { CarouselComponent } from 'libs/components/carousel/carousel.component';
import { ComponentItem } from 'libs/models/pbc-models';
import { HtmlBlockComponent } from 'libs/components/html-block/html-block.component';
import { IconCardComponent } from 'libs/components/icon-card/icon-card.component';
import { IconColumnsComponent } from 'libs/components/icon-columns/icon-columns.component';
import { ImageBlockBannerComponent } from 'libs/components/image-block-banner/image-block-banner.component';
import { ImageHeroComponent } from 'libs/components/image-hero/image-hero.component';
import { LayoutHelper } from 'libs/services/layout-helper';
import { PriorAuthDrugsComponent } from 'libs/components/prior-auth-drugs/prior-auth-drugs.component';
import { PromoComponent } from 'libs/components/promo/promo.component';
import { PullQuoteComponent } from 'libs/components/pull-quote/pull-quote.component';
import { SidebarNavComponent } from 'libs/components/sidebar-nav/sidebar-nav.component';
import { StatementBorderComponent } from 'libs/components/statement-border/statement-border.component';
import { TwoPackComponent } from 'libs/components/two-pack/two-pack.component';
import { TwoPackVerticalComponent } from 'libs/components/two-pack-vertical/two-pack-vertical.component';
import { XmlDataTableComponent } from 'libs/components/xml-data-table/xml-data-table.component';
import { FindMedicareRepComponent } from 'libs/components/find-medicare-rep/find-medicare-rep.component';
import { MarketoLaunchComponent } from 'libs/components/marketo-launch/marketo-launch.component';
import { FindCareSelectComponent } from 'libs/components/find-care-select/find-care-select.component';
import { OverlayHeroComponent } from 'libs/components/overlay-hero/overlay-hero.component';
import { TabSetComponent } from 'libs/components/tab-set/tab-set.component';
import { CardRowsComponent } from 'libs/components/card-rows/card-rows.component';

@Component({
  template: ''
})

export class BaseFlexTemplate extends BaseContentPage implements OnInit {
  pageComponents = [];
  index = 0;
  pageWidth: string;
  componentMap = {
    AccordionComponent,
    AlertComponent,
    CalloutComponent,
    CalloutImageComponent,
    CardComponent,
    CardRowComponent,
    CardRowsComponent,
    CarouselComponent,
    HtmlBlockComponent,
    IconCardComponent,
    IconColumnsComponent,
    ImageBlockBannerComponent,
    ImageHeroComponent,
    MarketoLaunchComponent,
    PriorAuthDrugsComponent,
    PromoComponent,
    PullQuoteComponent,
    SidebarNavComponent,
    StatementBorderComponent,
    TwoPackComponent,
    TwoPackVerticalComponent,
    XmlDataTableComponent,
    FindMedicareRepComponent,
    FindCareSelectComponent,
    OverlayHeroComponent,
    TabSetComponent
  };

  componentKey = [ // until content moves over pages
    {instanceName: 'accordion-', componentName: 'AccordionComponent'},
    {instanceName: 'html-block-', componentName: 'HtmlBlockComponent'},
    {instanceName: 'promo-', componentName: 'HtmlBlockComponent'},
    {instanceName: 'statement-', componentName: 'StatementBorderComponent'},
    {instanceName: 'two-pack-shade-', componentName: 'TwoPackComponent'},
    {instanceName: 'two-pack-', componentName: 'TwoPackComponent'},
    {instanceName: 'overlay-hero-', componentName: 'OverlayHeroComponent'},
    {instanceName: 'tabset-', componentName: 'TabSetComponent'}
  ];

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,
    public layoutHelper: LayoutHelper,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(true, tabTitle, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();
    this.pageWidth = this.getPageWidth();
    this.pageInfoObservable.pipe(takeUntil(this.componentDestroyed$)).subscribe((observedPageInfo) => {
      if (!observedPageInfo || !!this.pageComponents.length) {
        return;
      }
      for (const comp in this.contentCache) {
        if (this.contentCache.hasOwnProperty(comp)) {
          const componentItem = this.getComponentItem(this.contentCache[comp]);
          if (!!componentItem) {
            this.pageComponents.push(componentItem);
          }
        }
      }
    });
  }

  createDynamicComponent(typeName: string): ComponentFactory<unknown> {
    if (!this.componentMap.hasOwnProperty(typeName)) {
      console.log(`ERROR >> Referenced component ${typeName} doesn't exist.`);
      return null;
    }
    return this.componentFactoryResolver.resolveComponentFactory(this.componentMap[typeName].prototype.constructor);
  }

  getComponentItem(componentData: any): ComponentItem {
    if (!componentData.content.componentName) {
      componentData.content.componentName = this.getComponentByInstance(componentData.instanceName);
    }
    if (!componentData || !componentData.content || !componentData.content.componentName) {
      return null;
    }
    const factory = this.createDynamicComponent(componentData.content.componentName);
    if (!factory) {
      return null;
    }
    const comp: Type<any> = factory.componentType;
    return new ComponentItem(comp, componentData);
  }

  private getPageWidth(): string {
    if (!this.activatedRoute.snapshot.data) {
      return '';
    }
    return this.layoutHelper.getPageWidth(this.activatedRoute.snapshot.data);
  }

  private getComponentByInstance (instanceName): string {
    for (const inst of this.componentKey) {
      if(instanceName.indexOf(inst.instanceName) > -1) {
        return inst.componentName;
      }
    }
    return null;
  }
}
