import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textAlignmentPipe'
})
export class TextAlignmentPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
     return 'text-left';
    }
    switch (value.toLowerCase()) {
      case 'center' : return 'text-center';
      case 'right' : return 'text-right';
      default: return 'text-left';
    }
  }

}
