import { Component, OnInit, OnDestroy, Inject, Renderer2, ViewEncapsulation, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription, fromEvent, BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { EnvironmentService } from 'libs/services/environment-variables.service';
import { ComponentState, KeyValue } from 'libs/models/pbc-models';
import { AlertService } from 'libs/services/alert.service';
import { ContentService } from 'libs/services/content.service';
import { AuthenticationService } from 'libs/services/authentication.service';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { VisibilityService } from 'libs/services/visibility.service';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { MemberPermission } from 'libs/models/pbc-enums';
import { StringsService } from 'libs/services/strings.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { ChildSite, Site } from 'libs/models/site-config';
import { MemberService } from 'member/shared/services/member.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotificationResponseList, NotificationsResponse } from 'libs/models/notifications.model';
import { NotificationsService } from 'libs/services/notifications.service';
import { RegistrationPlanDetails, RegistrationPlanStatus } from 'member/shared/models/registration.models';

@Component({
  selector: 'pbc-header',
  templateUrl: './header.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: ['nav img { max-width: 8rem; }']
})
export class HeaderComponent implements OnDestroy, OnInit {
  static headerContent = new ComponentState(false);
  static topNavFallback = new ComponentState(false);
  static topNavSecondaryFallback = new ComponentState(false);
  static topNavAudienceFallback = new ComponentState(false);
  static switchContentFallback: ComponentState;
  @Input() site: Site;
  @Input() hideRegionSwitch: boolean;
  headerContent: ComponentState;
  primaryNav: ComponentState;
  secondaryNav: ComponentState;
  audienceNav: ComponentState;
  switchContent: ComponentState;
  primaryNavMenus: any;
  isNavbarCollapsed = true;
  featureFlags: any;
  isAuthenticated = false;
  browserOnlineSubscription: Subscription;
  browserOfflineSubscription: Subscription;
  instanceType: string;
  headerContentInstance = 'content-header';
  securePrimaryNavInstance = 'top-nav-primary-secure-v2';
  nonsecurePrimaryNavInstance = 'top-nav-primary-nonsecure';
  secondaryNavInstance = 'top-nav-secondary';
  audienceNavInstance = 'top-nav-audience-nonsecure';
  globalAlert: ComponentState;
  browserOnline: BehaviorSubject<boolean> = new BehaviorSubject(true);
  homePageUrl: string;
  childSiteLogoUrl = '';
  signInUrl = '';
  notificationDetails: NotificationsResponse[];
  mockNotificationDetail: NotificationsResponse;
  mockNotificationDetails: NotificationsResponse[];
  notificationCount: number = 0;
  isHmoUser: any = false;
  registrations: RegistrationPlanDetails[] = [];
  selectedRegistration: RegistrationPlanDetails;

  componentDestroyed$: Subject<boolean> = new Subject();
  isCycOnly = false;
  hasEmptyMemberKeys = false;
  enableNotificationIcon = false;
  siteName:string;

  constructor(
    @Inject(DOCUMENT) protected readonly document: Document,
    protected readonly renderer: Renderer2,
    protected readonly contentService: ContentService,
    protected readonly authService: AuthenticationService,
    protected readonly alertService: AlertService,
    protected readonly visibilityService: VisibilityService,
    protected readonly featureFlagService: FeatureFlagGuard,
    protected readonly memberSharedService: MemberSharedService,
    protected readonly stringsService: StringsService,
    protected readonly logger: LoggingService,
    private readonly memberService: MemberService,
    private readonly notificationService: NotificationsService,
    config: NgbPopoverConfig) {
    this.featureFlags = EnvironmentService.variables.featureFlags;
    this.siteName=EnvironmentService.variables.site;
    config.placement = 'bottom';
    config.triggers = 'click';
    config.autoClose = false;
  }

  ngOnInit() {

    this.instanceType = this.site === Site.visitor ? 'member-navigation' : `${this.site}-navigation`;
    // listen for changes to authentication, member key, and region
    combineLatest([this.authService.getIsAuthenticated(), this.memberSharedService.loggedInMemberKey, this.authService.defaultHomePage, this.contentService.region$, this.contentService.childSite$])
      .pipe(takeUntil(this.componentDestroyed$)).subscribe(([isUserAuthenticated, loggedInMemberKey, defaultHomeUrl, region, childSite]) => {
        this.isAuthenticated = isUserAuthenticated;
        this.homePageUrl = defaultHomeUrl;
        if (!!childSite) {
          this.homePageUrl = this.contentService.getChildSiteHomeUrl(childSite, EnvironmentService.variables.site);
        }     
        
        if (this.isAuthenticated) {
          this.featureFlags.planSwitchingFlag ? this.validateSelectedRegPlanforHMO() : this.getvisibilityDetailsIsHMO();
        }

        this.getNavigation(isUserAuthenticated, childSite);
        this.hasEmptyMemberKeys = !loggedInMemberKey && isUserAuthenticated;      
        this.alertService.getGlobalAlert().pipe(takeUntil(this.componentDestroyed$)).subscribe(data => { this.globalAlert = data; });
        if (isUserAuthenticated && !!loggedInMemberKey) {
          this.visibilityService.hasPermission(MemberPermission.HasCycFsaOnly).pipe(takeUntil(this.componentDestroyed$)).subscribe(hasCycOnly => {
            this.isCycOnly = hasCycOnly;
          }); 
        }
        else {
          this.isCycOnly = false;
        }
      });
      
    this.browserOnlineSubscription = fromEvent(window, 'online').subscribe(e => {
      this.browserOnline.next(window.navigator.onLine);
    });
    this.browserOfflineSubscription = fromEvent(window, 'offline').subscribe(e => {
      this.browserOnline.next(window.navigator.onLine);
    });


  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    if (this.browserOnlineSubscription) {
      this.browserOnlineSubscription.unsubscribe();
    }
    if (this.browserOfflineSubscription) {
      this.browserOfflineSubscription.unsubscribe();
    }
  }

  getNotificationDetails() {
    this.notificationDetails = [];
    this.notificationCount = 0;
    this.notificationService.getNotification().pipe(takeUntil(this.componentDestroyed$),
      catchError(err => {
        console.log(err);
        this.logger.trackException(err, 'NotificationLoadingError in Header', SeverityLevel.Error);
        return of(null);
      })).subscribe((response) => {
        if (response != null && !!response && response?.length > 0) {
          this.notificationDetails = response;
          this.notificationDetails = this.notificationDetails?.filter(x => x.hidden == false && x.message?.length > 0);
          if (this.notificationDetails?.length > 0)
            this.notificationCount = this.notificationDetails?.filter(x => x.read == false)?.length;
        }
      });
  }

  updateNotification(updatedNotificationDetails: NotificationsResponse[]) {
    this.notificationService.updateNotification(updatedNotificationDetails).pipe(takeUntil(this.componentDestroyed$),
      catchError(err => {
        this.logger.trackException(err, 'NotificationLoadingError in Header', SeverityLevel.Error);
        return of(null);
      })).subscribe((response) => {
        if (!!response) {
          this.logger.track("Notification updated", null, SeverityLevel.Information);
        }
      });
  }
  navbarExpand() {
    this.isNavbarCollapsed = false;
    this.renderer.addClass(this.document.body, 'fixed');
  }

  navbarCollapse() {
    this.isNavbarCollapsed = true;
    this.renderer.removeClass(this.document.body, 'fixed');
  }

  navbarToggle() {
    if (this.isNavbarCollapsed) {
      this.navbarExpand();
    } else {
      this.navbarCollapse();
    }
  }

  private getNavigation(isSecureNav: boolean, childSite: ChildSite): void {
    const primaryNavInstance = this.appendChildSiteToInstanceName(isSecureNav ? this.securePrimaryNavInstance : this.nonsecurePrimaryNavInstance, childSite);
    this.contentService.getComponentState(primaryNavInstance, this.instanceType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(response => {
        if (!!response) {
          this.primaryNav = response;
        } else {
          this.logger.track(`Header:: Retreived blank primary nav (${primaryNavInstance}). Using fallback.`, {}, SeverityLevel.Warning);
          this.primaryNav = (HeaderComponent.topNavFallback as any).result;
        }
        this.parsePrimaryNav();
      }, error => {
        // if secure nav not found, try looking for nonsecure
        if (isSecureNav && !!error && !!error.status && error.status === 404) {
          const nonsecInstance = this.appendChildSiteToInstanceName(this.nonsecurePrimaryNavInstance, childSite);
          this.contentService.getComponentState(nonsecInstance, this.instanceType)
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(response => {
              if (!!response) {
                this.primaryNav = response;
              } else {
                this.logger.track(`Header:: Retreived blank primary nav (${nonsecInstance}). Using fallback.`, {}, SeverityLevel.Warning);
                this.primaryNav = (HeaderComponent.topNavFallback as any).result;
              }
              this.parsePrimaryNav();
            }, error2nd => {
              this.logger.trackException(error2nd, `Header:: Second try on header primary nav (${nonsecInstance}) failed. Using fallback.`, SeverityLevel.Error);
              this.primaryNav = (HeaderComponent.topNavFallback as any).result;
              this.parsePrimaryNav();
            });
        } else {
          this.logger.trackException(error, `Header:: Retrieving primary nav (${primaryNavInstance}) failed. Using fallback.`, SeverityLevel.Error);
          this.primaryNav = (HeaderComponent.topNavFallback as any).result;
          this.parsePrimaryNav();
        }
      });

    const headerContentInstance = this.appendChildSiteToInstanceName(this.headerContentInstance, childSite);
    this.contentService.getComponentState(headerContentInstance, this.instanceType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(headerContentResponse => {
        if (!!headerContentResponse && !!headerContentResponse.content) {
          this.headerContent = headerContentResponse;
        } else {
          this.logger.track(`Header:: Retreived blank headerContent (${headerContentInstance}). Using fallback.`, {}, SeverityLevel.Warning);
          this.headerContent = (HeaderComponent.headerContent as any).result;
        }
        this.getAudienceAndSecondaryNav(childSite);
      }, error => {
        this.logger.track(`Header:: Retreiving headerContent (${headerContentInstance}) failed. Using fallback.`, {}, SeverityLevel.Warning);
        this.headerContent = (HeaderComponent.headerContent as any).result;
        this.getAudienceAndSecondaryNav(childSite);
      });
  }

  private getAudienceAndSecondaryNav(childSite: ChildSite) {
    if (!!this.headerContent && !!this.headerContent?.content && !!this.headerContent?.content?.signInUrl) {
      const kvp = [new KeyValue('member', EnvironmentService.variables.baseUrlMember), new KeyValue('visitor', EnvironmentService.variables.baseUrlVisitor), new KeyValue('medicare', EnvironmentService.variables.medicareSignInDomain)];
      this.signInUrl = this.stringsService.replaceContentTokens(this.headerContent.content.signInUrl, kvp);
    }
    if (this.headerContent?.content?.audienceNavigationVisible === 'true') {
      this.getAudienceNav(this.appendChildSiteToInstanceName(this.audienceNavInstance, childSite));
    }
    if (this.headerContent?.content?.secondaryNavigationVisible === 'true') {
      this.getSecondaryNav(this.appendChildSiteToInstanceName(this.secondaryNavInstance, childSite));
    }
    if (!this.switchContent) {
      let instanceName = 'footer-content';
      this.contentService.getComponentState(instanceName, `${!this.site || this.site == Site.visitor ? 'member' : this.site}-navigation`)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(response => {
          this.switchContent = response || this.getSwitchContentFallback();
        }, error => {
          this.switchContent = this.getSwitchContentFallback();
        });
    }
  }

  private appendChildSiteToInstanceName(baseInstanceName: string, childSite: ChildSite): string {
    return childSite === ChildSite.none || !childSite || this.isAuthenticated ? baseInstanceName : `${baseInstanceName}-${childSite}`;
  }

  private getAudienceNav(instanceName: string) {
    this.contentService.getComponentState(instanceName, this.instanceType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(audienceNavResponse => {
        if (!!audienceNavResponse) {
          this.audienceNav = audienceNavResponse;
        } else {
          this.logger.track(`Header:: Retreived blank audience nav (${instanceName}). Using fallback.`, {}, SeverityLevel.Warning);
          this.audienceNav = (HeaderComponent.topNavAudienceFallback as any).result;
        }
      }, error => {
        this.logger.track(`Header:: Retreiving audience nav (${instanceName}) failed. Using fallback.`, {}, SeverityLevel.Warning);
        this.audienceNav = (HeaderComponent.topNavAudienceFallback as any).result;
      });
  }

  private getSecondaryNav(instanceName: string) {
    this.contentService.getComponentState(instanceName, this.instanceType)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(secondaryNavResponse => {
        if (!!secondaryNavResponse) {
          this.secondaryNav = secondaryNavResponse;
        } else {
          this.logger.track(`Header:: Retreived blank secondary nav (${instanceName}). Using fallback.`, {}, SeverityLevel.Warning);
          this.secondaryNav = (HeaderComponent.topNavSecondaryFallback as any).result;
        }
      }, error => {
        this.logger.track(`Header:: Retreiving secondary nav (${instanceName}) failed. Using fallback.`, {}, SeverityLevel.Warning);
        this.secondaryNav = (HeaderComponent.topNavSecondaryFallback as any).result;
      });
  }

  private parsePrimaryNav() {
    if (this.primaryNav && this.primaryNav.content && this.primaryNav.content.levelTwoMenuGroupBox) {
      this.primaryNavMenus = JSON.parse(this.primaryNav.content.levelTwoMenuGroupBox);
      this.primaryNavMenus.forEach(menuItem => {
        if (this.selectedRegistration?.planStatus?.toLowerCase() === 'future' && menuItem.levelTwoMenuGroupBox?.headerTitle?.toLowerCase() === "prescriptions") {
          menuItem.levelTwoMenuGroupBox = null;
        }
        else {
          // when only 1 link is present in links group (newtonsoft to json conversion)
          menuItem.levelTwoMenuGroupBox.linksGroupBox = (Array.isArray(menuItem.levelTwoMenuGroupBox.linksGroupBox)) ?
            menuItem.levelTwoMenuGroupBox.linksGroupBox : this.linksToArray(menuItem.levelTwoMenuGroupBox.linksGroupBox);

          menuItem.levelTwoMenuGroupBox.linksGroupBox = this.checkVisibility(menuItem.levelTwoMenuGroupBox.linksGroupBox);
        }
      });
    }
  }

  private linksToArray(input: object): Array<object> {
    const linksArray = Array<object>();
    linksArray.push(input);

    return linksArray;
  }

  private checkVisibility(linksGroupBox: any[]): any[] {
    const links = [];
    for (const link of linksGroupBox) {
      if (this.featureFlagService.isRouteVisible(link?.route) && link?.enabled === 'true') {
        links.push(link);
      }
    }
    return links;
  }

  private getSwitchContentFallback(): ComponentState {
    if (!HeaderComponent.switchContentFallback) {
      return undefined;
    }

    return (HeaderComponent.switchContentFallback as any).result as ComponentState;
  }

   clearNotifiCnt() {
    let updatedNotificationDetails: NotificationsResponse[];
    updatedNotificationDetails = JSON.parse(sessionStorage.getItem("updatedNotificationDetails"));
    if (!!updatedNotificationDetails) {
      this.updateNotification(updatedNotificationDetails);
      sessionStorage.setItem('updatedNotificationDetails', JSON.stringify([]));
    }
    if(sessionStorage.getItem('notificationPlanSwitching') != undefined && sessionStorage.getItem('notificationPlanSwitching') != null) {
      sessionStorage.removeItem('notificationPlanSwitching');
    }
  }
  counterChange(notificationChangeCounter: number) {
    this.notificationCount = notificationChangeCounter;
  }
  closePopup() {
    sessionStorage.setItem('updatedNotificationDetails', JSON.stringify([]));
  }

  getvisibilityDetailsIsHMO() {
    this.isHmoUser = false;
    this.visibilityService.hasPermission(MemberPermission.HasHmoPlan).pipe(takeUntil(this.componentDestroyed$)).subscribe(res => {
      if (!!res) {
        this.isHmoUser = res;
          this.getNotificationDetails();
          this.enableNotificationIcon = true;
      }
      else {
        this.isHmoUser = false;
      }
    }, error => {
      this.isHmoUser = false;
    }); 
  }

  validateSelectedRegPlanforHMO() {
    if (sessionStorage.getItem('selectedPlanSession') === null || sessionStorage.getItem('selectedPlanSession') === "undefined") {
      const account = this.memberService.getRegistrationsFromCookie();
      if (!!account && !!account.registrations && account.registrations.length > 0) {
        const memberKeys = account.registrations.map(reg => reg.memberKey);
        this.memberService.getRegistrationPlanDetails(memberKeys, this.featureFlags.planSwitchingFlag)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((registrationResponse) => {
            this.isHmoUser = false;
            if (!!registrationResponse && registrationResponse.length > 0) {
              this.registrations = registrationResponse.filter(s => !!s.subscriberId);
              if (!!this.registrations && this.registrations.length > 0) {
                const selectedMemberKey = this.memberService.getSelectedMemberKey();
                if (!!selectedMemberKey) {
                  this.selectedRegistration = !!this.registrations.filter(x => x.planStatus === RegistrationPlanStatus.Active).find(r => r.memberKey === selectedMemberKey) ? this.registrations.filter(x => x.planStatus === RegistrationPlanStatus.Active).find(r => r.memberKey === selectedMemberKey) : this.registrations.find(r => r.memberKey === selectedMemberKey);  
                }
                sessionStorage.setItem('selectedPlanSession', JSON.stringify(this.selectedRegistration));
                if (this.selectedRegistration?.plan?.toUpperCase() === "HMO") {
                  sessionStorage.setItem('isHmoUser', JSON.stringify(true));
                  this.isHmoUser = true;                
                }
                else { sessionStorage.setItem('isHmoUser', JSON.stringify(false)); }

                if (this.featureFlags.notificationFEFlag) {
                  if (this.selectedRegistration?.planStatus?.toLowerCase() !== "termed" || (this.isHmoUser && this.selectedRegistration?.planStatus?.toLowerCase() === "termed")) {
                    this.getNotificationDetails();
                    this.enableNotificationIcon = true;
                  }
                }
              }
            }
          }, error => {
            this.isHmoUser = false;
            this.logger.track(`Failed to initialize plan slug: ${JSON.stringify(error)}`, {}, SeverityLevel.Error);
          });
      }
    }
    else {
      this.selectedRegistration = JSON.parse(sessionStorage.getItem('selectedPlanSession'));
      this.isHmoUser = sessionStorage.getItem('isHmoUser') == "true" ? true : false;  
      if (this.featureFlags.notificationFEFlag) {
        if (this.selectedRegistration?.planStatus?.toLowerCase() !== "termed" || (this.isHmoUser && this.selectedRegistration?.planStatus?.toLowerCase() === "termed")) {
          this.getNotificationDetails();
          this.enableNotificationIcon = true;
        }
      } 
    }
  }


  public notificationCountAria(notificationCount: string): string {
    return `Notification button - ${notificationCount} notifications are available`
  }
}
