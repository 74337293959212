<pbc-placeholder></pbc-placeholder>
<div *ngIf="data?.isVisible" data-id="two-pack-plan" class="hero b-shadow">
  <div *ngIf="isKinwellAd; else nonKinwellAd" class="hero">
    <div *ngIf="!isAlignedRight(data)">
        <img pbcLazyLoadImg class="img-fluid kinwell-height" [alt]="data?.content?.imageOneAltText" [attr.aria-hidden]="!data?.content?.imageOneAltText"
        [src]="(data?.content?.imageOneSmallUrl)">
    </div>
    <div class="hero-text hero-hmo pt-0 pb-0">
      <div class="pb-2 pt-2">
        <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageTwoAltText" [attr.aria-hidden]="!data?.content?.imageTwoAltText"
         [src]="(data?.content?.imageTwoUrl)">  
      </div>
      <h2 *ngIf="data?.content?.title">{{data?.content?.title}}</h2>
      <div *ngIf="data?.content?.bodyOne" [innerHTML]="data?.content?.bodyOne"></div>
      <pbc-cta-pack [data]="data" class="my-5 hero-hmo-cta"></pbc-cta-pack>
      <div class="hero-hmo-btn" class="mt-2 pt-1" *ngIf="data?.content?.bodyTwo && !isFuture; else futureLink" [innerHTML]="data?.content?.bodyTwo"></div>
    </div>
    <div class="hero-img" *ngIf="isAlignedRight(data)">
      <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageOneAltText" [attr.aria-hidden]="!data?.content?.imageOneAltText"
        [src]="(data?.content?.imageOneUrl)">
    </div>
  </div>
</div>

<ng-template #nonKinwellAd>
  <div class="hero-img" *ngIf="!isAlignedRight(data)">
    <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageOneAltText" [attr.aria-hidden]="!data?.content?.imageOneAltText"
      [src]="(data?.content?.imageOneUrl)">
  </div>
  <div class="hero-text hero-hmo pt-0 pb-0">
    <div class="pb-5 pt-3">
      <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageTwoAltText" [attr.aria-hidden]="!data?.content?.imageTwoAltText"
       [src]="(data?.content?.imageTwoUrl)">  
    </div>
    <h2 *ngIf="data?.content?.title">{{data?.content?.title}}</h2>
    <div *ngIf="data?.content?.bodyOne" [innerHTML]="data?.content?.bodyOne"></div>
    <pbc-cta-pack [data]="data" class="my-5 hero-hmo-cta"></pbc-cta-pack>
    <div class="hero-hmo-btn" class ="mt-5" *ngIf="data?.content?.bodyTwo && !isFuture; else futureLink"  [innerHTML]="data?.content?.bodyTwo"></div>
  </div>
  <div class="hero-img" *ngIf="isAlignedRight(data)">
    <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageOneAltText" [attr.aria-hidden]="!data?.content?.imageOneAltText"
      [src]="(data?.content?.imageOneUrl)">
  </div>
</ng-template>


<ng-template #futureLink>
  <div class="hero-hmo-btn" [ngClass]="isKinwellAd ? 'mt-3' : 'mt-5'" *ngIf="data?.content?.bodyFuture"  [innerHTML]="data?.content?.bodyFuture"></div>
</ng-template>
