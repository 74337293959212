<ul id="audience-nav" aria-label="Audience Navigation" class="list-inline nav-tertiary">
  <li class="list-inline-item" *ngIf="content?.employerEnabled"><a href="{{ employerLink }}" pbcAnalyticsTag
      data-analytics-override-route="header" data-analytics-title="for employer">
      {{content?.employerText}}</a></li>
  <li class="list-inline-item" *ngIf="content?.producerEnabled"><a href="{{ producerLink }}" pbcAnalyticsTag
    data-analytics-override-route="header" data-analytics-title="for producer">
    {{content?.producerText}}</a></li>
  <li class="list-inline-item" *ngIf="content?.providerEnabled"><a href="{{ providerLink }}" pbcAnalyticsTag
      data-analytics-override-route="header" data-analytics-title="for provider">
      {{content?.providerText}}</a></li>
</ul>