<div class="row" data-id="footerNavLinks">
  <div *ngFor="let menu of menus; let index = index; let last = last" >
    <div pbcApplyRules [visibilityRules]="menu?.footerMenuGroupBox.visibilityRules"
    [rulesOperation]="menu.footerMenuGroupBox?.rulesOperation">
      <div class="col-md" [ngClass]="last ? 'col-12' : 'col-6'">
        <div *ngIf="menu.footerMenuGroupBox.headerTitle" class="footer-heading">{{menu.footerMenuGroupBox.headerTitle}}</div>
        <ul [attr.aria-label]="menu.footerMenuGroupBox.headerTitle">
          <li *ngFor="let link of menu.footerMenuGroupBox.linksGroupBox;">
            <ng-container *ngIf="needsDomain(link.route); else isRoute">
              <a [href]="addDomain(link.route)" [innerHtml]="link.title" [attr.lang]="link.lang" [attr.target]="link.external? '_blank':'_self'" pbcAnalyticsTag
                data-analytics-override-route="footer"></a>
            </ng-container>
            <ng-template #isRoute>
              <a [routerLink]="dropToken(link.route)" [innerHtml]="link.title" [attr.lang]="link.lang" [attr.target]="link.external? '_blank':'_self'" 
                pbcAnalyticsTag data-analytics-override-route="footer"></a>
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>