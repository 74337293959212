import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from 'libs/services/content.service';

@Directive({
  selector: '[pbcAnalyticsTag]',
})
export class AnalyticsTagDirective implements AfterViewInit {
  private elementTitle: string;
  private elementRouteOverride: string;
  private elementComponent: string;
  private region: string;

  constructor(private readonly host: ElementRef,
              private readonly renderer: Renderer2,
              private readonly router: Router,
              private readonly contentService: ContentService
  ) {
    this.contentService.region$.subscribe(region => {
      this.region = region;
    });
  }

  ngAfterViewInit(): void {
    this.elementComponent = (!!this.host) ? this.host.nativeElement.getAttribute('data-analytics-component') : null;
    this.elementRouteOverride = (!!this.host) ? this.host.nativeElement.getAttribute('data-analytics-override-route') : null;
    this.elementTitle = (!!this.host) ? this.host.nativeElement.getAttribute('data-analytics-title') : null;
    this.setAnalyticsTag();
  }

  private setAnalyticsTag(): void {
    this.renderer.setAttribute(this.host.nativeElement, 'data-analytics-tag', this.getAnalyticsTag());
  }

  private getAnalyticsTag(): string {
    const page = (!!this.elementRouteOverride) ? this.elementRouteOverride : this.sanitizePath(this.router.url);
    const component = this.getComponent(this.elementComponent);
    const elementId = this.getElementTitle(this.host.nativeElement.Id, this.host.nativeElement.text, this.elementTitle);
    return this.formatId(`${page} ${component} ${elementId} ${this.region}`);
  }

  private sanitizePath(path: string): string {
    // get the last segment from the route url
    return (!!path) ? path.substr(path.lastIndexOf('/') + 1).trim().toLowerCase() : '';
  }

  private getComponent(component: string): string {
    return (!!component) ? `${component.trim().toLowerCase()}` : '';
  }

  private getElementTitle(id: string, title: string, dataId: string): string {
    let tagId: string;
    if (!!dataId) {
      tagId = dataId;
    } else if (!!id) {
      tagId = id;
    } else if (!!title) {
      tagId = title;
    } else {
      tagId = 'unknown';
    }
    return (!!tagId) ? `${tagId.trim().toLowerCase()}` : '';
  }

  public formatId(input: string): string {
    return (!!input) ? input.trim().replace(/ +/g, '_') : '';
  }
}
