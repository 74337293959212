<div *ngIf="data?.isVisible" class="card promo">
  <div class="card-body">
    <div class="h4 card-title">{{data?.content?.title}}</div>
    <div [innerHtml]="data?.content?.body | safehtml"></div>
    <div class="mb-0">
        <pbc-cta [model]="ctaOne"></pbc-cta>
      </div>
      <div class="mb-0">
        <pbc-cta [model]="ctaTwo"></pbc-cta>
      </div>
      <div class="mb-0">
        <pbc-cta [model]="ctaThree"></pbc-cta>
      </div>
  </div>
</div>
