import { Component, OnInit, Input, OnDestroy, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ContentService } from 'libs/services/content.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { HelperService } from 'libs/services/helper.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { ComponentState } from 'libs/models/pbc-models';

export class State {
  name: string;
  ci: string;
  counties: County[];
}

export class County {
  name: string;
  ci: string;
}

export class RawParsedState {
  states: {
    state: {
      name: string;
      ci: string;
      county: {
        name: string;
        ci: string;
      }[]
    }[]
  };
}

@Component({
  selector: 'pbc-find-care-select',
  templateUrl: './find-care-select.component.html',
})
@Injectable()
export class FindCareSelectComponent implements OnInit, OnDestroy {
  @Input() data: ComponentState;

  componentDestroyed$: Subject<boolean> = new Subject();
  states: State[];
  counties: County[];
  selectedState: string;
  selectedCi: string;
  hasError = false;

  stateCallback: (state: State) => string;
  countyCallback: (county: County) => string;

  constructor(
    private readonly contentService: ContentService,
    private readonly helper: HelperService,
    private readonly router: Router,
    private readonly logger: LoggingService) { }

  ngOnInit(): void {
    if (!!this.data && !!this.data.content && !!this.data.content.dataFilePath) {
      this.contentService.getContentFile(this.data.content.dataFilePath, EnvironmentService.variables.contentDomain)
        .pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
          this.states = this.parseXml(result);
        });
      }

    this.stateCallback = this.getStateName.bind(this);
    this.countyCallback = this.getCountyName.bind(this);
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  onStateChange(state: State) {
    this.selectedState = '';
    this.counties = null;
    this.selectedCi = null;

    if (!!state) {
      this.selectedState = state.name;
      if (!!state.counties) {
        this.counties = state.counties;
      } else {
        this.selectedCi = state.ci;
      }
    }
  }

  onCountyChange(county: County) {
    this.selectedCi = county.ci;
  }

  getStateName(state: State): string {
    return this.states.find(s => s.name === state.name).name;
  }

  getCountyName(county: County): string {
    return this.counties.find(c => c.name === county.name).name;
  }

  parseXml(xml: string): State[] {
    const result = this.helper.parseXml(xml);
    if (!result || !result.states) {
      this.handleError(new Error('Empty or invalid xml'));
      return;
    }
    return this.processRawObject(result);
  }

  private processRawObject(rawParsedContent: RawParsedState): State[] {
    return rawParsedContent.states.state.map(s => {
      return {
        name: s.name,
        ci: s.ci,
        counties: s.county
      };
    });
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
  }
}
