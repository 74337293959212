import { AddressExtended } from './pbc-models';
import { ProductCategory } from 'libs/enums/eligibilities.enums';

export class BaseMemberRequest {
  requestorMemberKey: string;
  constructor(requestorMemberKey: string) {
    this.requestorMemberKey = requestorMemberKey;
  }
}

export class BaseMemberHMORequest {
  requestorMemberKey: string;
  initialHMOLoginDate: Date
  initialWelcomePageDate : Date;
  constructor(requestorMemberKey: string, initialHMOLoginDate: Date, initialWelcomePageDate : Date) {
    this.requestorMemberKey = requestorMemberKey;
    this.initialHMOLoginDate = initialHMOLoginDate;
    this.initialWelcomePageDate = initialWelcomePageDate;
  }
}

export class SelectedPlanresponse {
  application: string;
  defaultMemberId: number;
  selectedMemberId: number;
  username: string;
  initialHMOLoginDate: Date;
  initialWelcomePageDate : Date;
}

export class Impersonation {
  isImpersonating: boolean;
  userId: string;
  isImpersonationValidationOverrides = {};

  constructor(isImpersonating, userId, isImpersonationValidationOverrides) {
    this.isImpersonating = isImpersonating;
    this.userId = userId;
    this.isImpersonationValidationOverrides = isImpersonationValidationOverrides || null;
  }
}

export class Sso {
  isSso: boolean;
}

export class MemberDetailsRequest extends BaseMemberRequest {
  targetMemberKey: string;
  privacyScope: string;
  planEffectiveDate: Date;
  constructor(requestorMemberKey: string, targetMemberKey: string, privacyScope: string, planEffectiveDate: Date) {
    super(requestorMemberKey);
    this.targetMemberKey = targetMemberKey;
    this.privacyScope = privacyScope;
    this.planEffectiveDate = planEffectiveDate;
  }
}

export class ImpersonationValidationOverrides {
  key: string;
  value: boolean;
}

export class Member {
  memberKey: string;
  subscriberId: string;
  suffix: number;
  firstName: string;
  lastName: string;
  middleInitial: string;
  dateOfBirth: Date;
  appendDob: boolean;
  memberRelation: string;
  addresses: AddressExtended[];
}

export class EligibleKeysResponse {
  eligibleMemberKeys: string[];
}

export class MemberWithEligibility extends Member {
  eligibleProductCategories: MemberProductEligibility[];
}

export class MemberProductEligibility {
  productCategory: ProductCategory;
  productCategoryName: string;
  effectiveDate: Date;
  terminationDate: Date;
  acceptedMonths: number;
  acceptedMonthsIndicator: string;
}

export class FamilyEligibilityRequest extends BaseMemberRequest {
  asOfDate: Date;
  privacyScope: string;
  constructor(requestorMemberKey: string, asOfDate: Date, privacyScope: string) {
    super(requestorMemberKey);
    this.asOfDate = asOfDate;
    this.privacyScope = privacyScope;
  }
}

export class MemberDetails {
  memberKey: string;
  firstName: string;
  lastName: string;
  middleInitial: string;
  dateOfBirth: Date;
  userId: string;
  subscriberId: string;
  suffix: number;
  employeeId: string;
  age: number;
  dependentId: string;
  memberRelation: string;
  maritalStatus: string;
  groupId: string;
  subGroupId: string;
  gender: string;
  lineOfBusiness: string;
  eobEmailAddress: string;
  addresses: AddressExtended[];
  personalFundingAccountTypes: PersonalFundingAccountTypes;
}

export class PersonalFundingAccountTypes {
  cycAccountTypes: string[];
}

export class MemberVisibilityPermissions {
  subscriberId: string;
  suffix: string;
  dateOfBirth: Date;
  age: number;
  gender: string;
  groupId: string;
  groupType: string;
  parentGroupId: string;
  lob: string;
  hmoEligibilityDate:string;
  permissions: string[];
}

export class EvaluateRulesRequest extends BaseMemberRequest {
  rules: string[];
  constructor(requestorMemberKey: string, rules: string[]) {
    super(requestorMemberKey);
    this.rules = rules;
  }
}

export class MemberRegistrationResponse {
  registrations: RegistrationPlan[];
  statusCode?: AuthStatusCode;
}

export class RegistrationPlan {
  memberKey: string;
  groupKey: string;
  lineOfBusiness: string;
  isSelected: boolean;
}

export class Form1095EligibilityResponse {
  is1095Eligible: boolean;
  is1095EligibleCurrentYear: boolean;
}

export class Form1095EligibilityRequest extends BaseMemberRequest {
  memberKey: string;
  targetMemberKey: string;

  constructor(requestorMemberKey: string, targetMemberKey: string) {
    super(requestorMemberKey);
    this.memberKey = requestorMemberKey;
    this.targetMemberKey = targetMemberKey;
  }
}

export enum AuthStatusCode {
  Success = 0,
  UnknownError = -7000,
  DependencyError = -7001,
  EmptyNullResponse = -7002,
  InvalidRequest = -7003,
  GroupBlocked = -7004,
  GroupNotAllowed = -7005,
  DivorcedSpouse = -7006,
  MultiRegMismatchOnFname = -7201,
  MultiRegMismatchOnSsn = -7202,
  MultiRegMismatchOnDob = -7203
}

export const ErrorCodeMap = {
  UnknownError: 'E7000', // begins with E for Error
  DependencyError: 'E7001',
  EmptyNullResponse: 'E7002',
  InvalidRequest: 'E7003',
  GroupBlocked: 'F7004', // begins with F for Filtered
  GroupNotAllowed: 'F7005',
  DivorcedSpouse: 'F7006',
  MultiRegMismatchOnFname: 'A135', // begins with A for Account Issue
  MultiRegMismatchOnSsn: 'A137',
  MultiRegMismatchOnDob: 'A139'
}
