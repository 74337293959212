<div data-id="mobileAppsSection" class="row mt-4 no-gutters">
  <div *ngIf ="!!footerContent?.content?.appleStoreAppUrl" class="col col-sm-4 social">
    <a href="{{ footerContent?.content?.appleStoreAppUrl }}" target="_blank" rel="noopener noreferrer"
      pbcAnalyticsTag data-analytics-override-route="footer" data-analytics-title="apple store">
      <img class="img-fluid mr-sm-2" aria-hidden="false" alt="{{ footerContent?.content?.appleStoreAltImage }}"
        src="assets/image/apple-store-badge.svg">
    </a>
  </div>
  <div *ngIf ="!!footerContent?.content?.googlePlayAppUrl" class="col col-sm-4 social">
    <a href="{{ footerContent?.content?.googlePlayAppUrl }}" target="_blank" rel="noopener noreferrer"
      pbcAnalyticsTag data-analytics-override-route="footer" data-analytics-title="google play">
      <img class="img-fluid ml-sm-2" aria-hidden="false" alt="{{ footerContent?.content?.googlePlayAltImage }}"
        src="assets/image/google-play-badge.svg">
    </a>
  </div>
</div>