import { Component, Input } from '@angular/core';
import { DataComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-footer-mobile-apps',
  templateUrl: './footer-mobile-apps.component.html'
})
export class FooterMobileAppsComponent {
  @Input() footerContent: DataComponentState;

  constructor() { }

}
