import { Injectable } from '@angular/core';
import { BehaviorSubject,Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseenvironment } from 'libs/environments/environment-base';

@Injectable({
  providedIn: 'root'
})
export class BingMapsService {
  private readonly key: string = baseenvironment.bingMap.bingMapKey;
  private readonly locationUrl: string = baseenvironment.bingMap.bingMapLocationUrl;
  private readonly mapControlUrl: string = baseenvironment.bingMap.bingMapControlUrl;
  private readonly callbackName: string = 'onBingMapLoad';  
  isMapLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); 
  
  constructor(private readonly http: HttpClient) {   
    window[this.callbackName] = () => {
      this.isMapLoaded.next(true);
    };
    const script = document.createElement('script');
    script.src = `${this.mapControlUrl}?callback=${this.callbackName}&key=${this.key}`;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    document.getElementsByTagName('head')[0].appendChild(script);   
  } 

  getStateName(latitude: number, longitude: number){
    const url = `${this.locationUrl}/${latitude},${longitude}?key=${this.key}`;
      return this.http.get(url);
  }
}
