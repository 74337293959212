import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MemberPermission } from 'libs/models/pbc-enums';
import { ComponentState } from 'libs/models/pbc-models';
import { VisibilityService } from 'libs/services/visibility.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pbc-image-block-banner',
  templateUrl: './image-block-banner.component.html',
  styles: [
  ]
})
export class ImageBlockBannerComponent implements OnInit,OnDestroy {
  @Input() data: ComponentState;
  isLwwa:boolean = false;
  componentDestroyed$: Subject<boolean> = new Subject();
  constructor(private readonly visibilityService: VisibilityService) {
    
  }

  ngOnInit(): void {
    this.visibilityService.hasPermission(MemberPermission.HasLwwaPlan).pipe(takeUntil(this.componentDestroyed$))
    .subscribe(hasLwwa => {
      if (hasLwwa) {
        this.isLwwa = hasLwwa; 
      }
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

   isImageAlignedRight(data: ComponentState): boolean {
     if (!!data && !!data.content && data.content.imageAlignment === 'right') {
       return true;
     }
     return false;
   }
 }

