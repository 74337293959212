import { Injectable } from '@angular/core';

import { ComponentState } from 'libs/models/pbc-models';

@Injectable()
export class LayoutHelper {
    calculateEightOrTenColumns(comp1: ComponentState, comp2: ComponentState, comp3: ComponentState): string {
        return (this.isVisible(comp1) && this.isVisible(comp2) && this.isVisible(comp3))
        ? 'col-lg-10'
        : 'col-lg-8';
    }

    isVisible(comp: ComponentState): boolean {
      return !!comp && comp.isVisible;
    }

    getPageWidth(pageData: any): string {
      if (!pageData || !pageData['pageWidth']) {
        return '';
      }
      return pageData['pageWidth'];
    }
}
