import { Injectable } from '@angular/core';

// This class addresses the problem where a component is
// escapable via keyboard tabs when it should not be

@Injectable()
export class TrapFocusService {
  private readonly KEYCODE_TAB = 9;
  private readonly KEYWORD_TAB = 'Tab';

  constructor() { }

  adjustFocus(e: KeyboardEvent, firstFocusableElement: HTMLElement, lastFocusableElement: HTMLElement) {
    const isTabPressed = (e.key === this.KEYWORD_TAB || e.keyCode === this.KEYCODE_TAB);

    if (!isTabPressed || !firstFocusableElement || !lastFocusableElement) {
      return;
    }

    this.lockFocus(e, firstFocusableElement, lastFocusableElement);
  }

  private lockFocus(e: KeyboardEvent, firstFocusableElement: HTMLElement, lastFocusableElement: HTMLElement) {
    if (e.shiftKey) /* shift + tab */ {
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else /* tab */ {
      if (document.activeElement === lastFocusableElement) {
        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  }
}
