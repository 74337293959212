import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';

import { SuccessResponse } from 'libs/models/pbc-models';
import { DataService } from 'libs/services/data.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { Form1095EligibilityRequest, Form1095EligibilityResponse } from 'libs/models/member.models';
import { UrlBuilderService } from './url-builder.service';

@Injectable({
  providedIn: 'root'
})
export class Form1095SharedService {

  constructor(protected readonly dataService: DataService, protected readonly memberSharedService: MemberSharedService, protected readonly urlBuilderService: UrlBuilderService) { }

  getForm1095Eligibility(): Observable<Form1095EligibilityResponse> {
    return this.memberSharedService.loggedInMemberKey.pipe(mergeMap(loggedInMemKey => {
      if (!loggedInMemKey) {
        return of(new Form1095EligibilityResponse());
      }
      return this.dataService
        .post<SuccessResponse<Form1095EligibilityResponse>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.form1095EligibilityUrl),
          new Form1095EligibilityRequest(loggedInMemKey, loggedInMemKey))
        .pipe(map(response => {
          if (!!response && !!response.result) {
            return response.result;
          }
          return new Form1095EligibilityResponse();
        }));
    }));
  }
}
