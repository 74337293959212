<div class="row" data-template="info-1">
  <div class="col-lg-8 mx-auto">
    <h1 tabindex="-1" [innerHtml]="pageTitle" [attr.aria-label]="pageTitle"></h1>
    <pbc-alert [data]="localAlert" [alert]="popUpAlert"></pbc-alert>
    <pbc-html-block [data]="contentCache['html-block-1']"></pbc-html-block>
    <pbc-two-pack [data]="contentCache['two-pack-1']"></pbc-two-pack>
    <div *ngIf="layoutHelper.isVisible(contentCache['two-pack-shade-1'])" class="mb-5">
      <pbc-two-pack [data]="contentCache['two-pack-shade-1']"></pbc-two-pack>
    </div>
    <pbc-html-block [data]="contentCache['html-block-2']"></pbc-html-block>
    <div class="row">
      <div class="col-sm-6 mx-auto">
        <pbc-statement-border [data]="contentCache['statement-border-1']"></pbc-statement-border>
      </div>
    </div>
    <pbc-pull-quote [data]="contentCache['pull-quote-1']"></pbc-pull-quote>
    <pbc-html-block [data]="contentCache['html-block-3']"></pbc-html-block>
  </div>
</div>
<div class="row">
  <div class="mx-auto {{layoutHelper.calculateEightOrTenColumns(contentCache['two-pack-vertical-1'], contentCache['two-pack-vertical-2'], contentCache['two-pack-vertical-3'])}}">
    <div class="row">
      <div *ngIf="layoutHelper.isVisible(contentCache['two-pack-vertical-1'])" class="col">
        <pbc-two-pack-vertical [data]="contentCache['two-pack-vertical-1']"></pbc-two-pack-vertical>
      </div>
      <div *ngIf="layoutHelper.isVisible(contentCache['two-pack-vertical-2'])" class="col">
        <pbc-two-pack-vertical [data]="contentCache['two-pack-vertical-2']"></pbc-two-pack-vertical>
      </div>
      <div *ngIf="layoutHelper.isVisible(contentCache['two-pack-vertical-3'])" class="col">
        <pbc-two-pack-vertical [data]="contentCache['two-pack-vertical-3']"></pbc-two-pack-vertical>
    </div>
  </div>
</div>
</div>
<div class="row">
  <div class="col-lg-8 mx-auto">
    <pbc-html-block [data]="contentCache['html-block-4']"></pbc-html-block>
  </div>
</div>
<div class="row">
  <div class="mx-auto {{layoutHelper.calculateEightOrTenColumns(contentCache['promo-1'], contentCache['promo-2'], contentCache['promo-3'])}}">
    <div class="row card-row">
      <div *ngIf="layoutHelper.isVisible(contentCache['promo-1'])" class="col mb-4">
        <pbc-promo [data]="contentCache['promo-1']"></pbc-promo>
      </div>
      <div *ngIf="layoutHelper.isVisible(contentCache['promo-2'])" class="col mb-4">
        <pbc-promo [data]="contentCache['promo-2']"></pbc-promo>
      </div>
      <div *ngIf="layoutHelper.isVisible(contentCache['promo-3'])" class="col mb-4">
        <pbc-promo [data]="contentCache['promo-3']"></pbc-promo>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-8 mx-auto">
    <pbc-accordion [data]="contentCache['accordion-1']"></pbc-accordion>
    <pbc-accordion [data]="contentCache['accordion-2']"></pbc-accordion>
    <pbc-html-block [data]="contentCache['html-block-5']"></pbc-html-block>
    <pbc-html-block [data]="contentCache['html-block-6']"></pbc-html-block>
  </div>
</div>
