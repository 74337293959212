import { Component, Input, OnInit } from '@angular/core';
import { ContentComponent } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';
import { JsonParserService } from 'libs/services/json-parser.service';


@Component({
  selector: 'pbc-tab-set',
  templateUrl: './tab-set.component.html'
})
export class TabSetComponent  implements ContentComponent {
  @Input() data: ComponentState;
  sections = [];
  active;

  constructor(private readonly jsonParserService: JsonParserService) {}

  ngOnInit() {
    this.sections = this.getSections();
  }

  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    return this.jsonParserService.safeParse(this.data.content.sections);
  }
}
