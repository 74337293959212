import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Parser } from 'xml2js';

import { BaseContentPage } from 'libs/classes/base-content-page';
import { ComponentState } from 'libs/models/pbc-models';
import { SeverityLevel } from 'libs/services/logging.service';

class DrugList {
  name: string;
  siteCode: string;
}

@Component({
  selector: 'pbc-covered-drugs',
  templateUrl: './covered-drugs.component.html'
})
export class CoveredDrugsComponent extends BaseContentPage implements OnInit {
  coveredDrugsContent: ComponentState;
  selectedDrugList: DrugList;
  drugLists: DrugList[] = [];
  hasError = false;

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute
  ) {
    super(false, tabTitle, activatedRoute);
  }

  ngOnInit() {
    super.ngOnInit();

    const pageData = this.activatedRoute.snapshot.data;
    if (pageData) {
      this.contentService
        .getComponentState('content-' + pageData['page'], pageData['contentGroup'])
        .pipe(takeUntil(this.componentDestroyed$), catchError(err => this.handleError(err)))
        .subscribe(res => {
          this.coveredDrugsContent = res;
          this.drugLists = this.parseXml(this.coveredDrugsContent.content.drugListsXml);
          this.selectedDrugList = this.drugLists[0];
        });
    }
  }

  getIframeSrc() {
    return `${this.coveredDrugsContent.content.iframeSrc}${this.selectedDrugList.siteCode}`;
  }

  parseXml(xml: string): DrugList[] {
    const xmlParser = new Parser({explicitArray: false, mergeAttrs : true});
    let parsedXml;

    xmlParser.parseString(xml, (err, result) => {
      if (err) {
        this.handleError(err);
        return;
      }

      const druglist = result.druglistitems.druglist;

      result.druglistitems.druglist = Array.isArray(druglist) ? druglist : [druglist];
      parsedXml = result;
    });

    return parsedXml && this.processRawObject(parsedXml);
  }

  processRawObject(rawObject): DrugList[] {
    return rawObject.druglistitems.druglist.map(list => ({name: list.name, siteCode: list.sitecode}));
  }

  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
    return of(null);
  }
}
