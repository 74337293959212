<nav ngbDropdown class="d-block sidebar" *ngIf="!!data">
  <button pbcAnalyticsTag class="btn" id="dropdownSideBarNav" ngbDropdownToggle>{{data?.content?.mobileOpenMenuText}}</button>
  <div ngbDropdownMenu aria-labelledby="dropdownSideBarNav">
    <ul class="dd-section list-unstyled" *ngFor="let section of sections">
      <li><a ngbDropdownItem class="sidebar-section-title" [attr.aria-current]="routerLinkActive(section?.sectionMenuGroupBox?.sectionHeaderRoute)"
          routerLink="{{section?.sectionMenuGroupBox?.sectionHeaderRoute}}" routerLinkActive="active"
          pbcAnalyticsTag>{{section?.sectionMenuGroupBox?.sectionHeaderText}}</a></li>
      <li *ngFor="let link of section?.sectionMenuGroupBox?.linksGroupBox">
        <a ngbDropdownItem routerLink="{{link?.route}}" routerLinkActive="active"
          [attr.aria-current]="routerLinkActive(link?.route)" pbcAnalyticsTag>{{ link?.linkText }}</a>
      </li>
    </ul>
  </div>
</nav>
