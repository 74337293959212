import { Component, OnInit, Input } from '@angular/core';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-icon-columns',
  templateUrl: './icon-columns.component.html',
  styles: [
  ]
})
export class IconColumnsComponent {
  @Input() data: ComponentState;
  sections: any;

  getSections(): any[] {
    if (!this.data || !this.data.content || !this.data.content.sections) {
      return [];
    }
    if (!this.sections) {
      this.sections = JSON.parse(this.data.content.sections);
    }

    return this.sections;
  }
}
