import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { VisibilityService } from 'libs/services/visibility.service';
import { StringsService } from 'libs/services/strings.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { Subject } from 'rxjs';

import { MemberPermission } from 'libs/models/pbc-enums';
import { takeUntil } from 'rxjs/operators';
import { link } from 'fs';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';

@Component({
  selector: 'pbc-primary-nav',
  templateUrl: './primary-nav.component.html'
})

export class PrimaryNavComponent implements OnDestroy {
  @Input() primaryNavMenus: any;
  @Input() isVisible: boolean;
  openMenu: number;
  featureFlags: any;
  hmoWelcomeLink: string = "hmo-welcome";
  hmoWelcomeLinkText: string = 'welcome';
  isHmoPlan: boolean;
  planStatus: string;
  componentDestroyed$: Subject<boolean> = new Subject();

  @Output()
  menuItemClicked: EventEmitter<any> = new EventEmitter();


  constructor(private readonly stringsService: StringsService, private readonly environments: EnvironmentService, private readonly router: Router, private readonly visibilityService: VisibilityService, private readonly logger: LoggingService) {
    this.featureFlags = EnvironmentService.variables.featureFlags;
    if (this.featureFlags.planSwitchingFlag) {
      this.isHmoPlan = sessionStorage.getItem('isHmoUser') == "true" ? true : false;
    } else {
      this.visibilityService.hasPermission(MemberPermission.HasHmoPlan).pipe(takeUntil(this.componentDestroyed$)).subscribe(hasHmoPlan => {
        this.isHmoPlan = hasHmoPlan;        
      }, error => {
        this.isHmoPlan = false;
      });
    }
  }

  dropdownItemClicked(clickedMenu: any) {
    this.menuItemClicked.emit(clickedMenu);
  }

  toggleSubmenu(index: number) {
    this.openMenu = this.openMenu === index ? -1 : index;
    /** Check if the user has only future plan and remove the submit claims menu */    
    if (sessionStorage.length > 0 && !!sessionStorage.getItem('selectedPlanSession') && sessionStorage.getItem('selectedPlanSession') !== undefined && sessionStorage.getItem('selectedPlanSession') !== null) { 
      try {
        this.planStatus = JSON.parse(sessionStorage.getItem("selectedPlanSession"))?.planStatus;
      }catch(error){
        this.logger.track(`Selected Plan registration not available: ${sessionStorage.getItem('selectedPlanSession')}`, {}, SeverityLevel.Error);
      }
    }
    if(this.planStatus?.toLowerCase() === 'future') {
      if(this.primaryNavMenus[index].levelTwoMenuGroupBox.headerTitle =='Claims') {
        this.primaryNavMenus[index].levelTwoMenuGroupBox.linksGroupBox.map((data,menuindex)=> {
          if(data.linkText == 'Submit Claims') {
            this.primaryNavMenus[index].levelTwoMenuGroupBox.linksGroupBox.splice(menuindex, 1);
            return;
          }
        })
      }
    }
  }

  needsDomain(route: string): boolean {
    return this.stringsService.needsDomain(route);
  }

  addDomain(route: string): string {
    return this.stringsService.addDomainToLink(route);
  }

  dropToken(route: string, linkTree?: string): string {
    if (this.isHmoPlan && typeof linkTree != "undefined" && linkTree && this.isHmoWelcome(linkTree)) {
      return this.hmoWelcomeLink;
    }
    return this.stringsService.dropRootToken(route);
  }

  isHmoWelcome(linkTree?: string): boolean {
    console.log('in isHmoWelcome, linkTree: {0} , isHmoPlan: {1}', linkTree, this.isHmoPlan);
    if (linkTree?.length > 6 && linkTree?.substring(0, 7).trim().toLowerCase() === this.hmoWelcomeLinkText && this.isHmoPlan) {
      return true;
    }

    return false;
  }

  getLinkText(linkTree?: string): string {
    if (this.isHmoPlan && typeof linkTree != "undefined" && linkTree && (linkTree.length > 6 && linkTree.substring(0, 7).trim().toLowerCase() === this.hmoWelcomeLinkText)) {
      return "Welcome to HMO Benefits";
    }
    return linkTree;
  }

  close(event: MouseEvent): void {
    this.openMenu = -1;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  routeTo(route: string) {
    this.router.navigate([route]);
  }
  
}
