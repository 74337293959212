<div class="row">
  <div [ngClass]="isLwwaUser?'col-lg-12 mx-auto':'col-lg-8 mx-auto'" data-template="find-care">
    <h1 [ngClass]="{'h1 mb-4':isLwwaUser}" tabindex="-1" [innerHtml]="pageTitle" title="pageTitle"></h1>
    <pbc-alert [data]="localAlert" [alert]="popUpAlert"></pbc-alert>
  </div>
</div>

<div *ngIf="isLoadingContent" class="text-center pt-5 pb-5">
  <pbc-loader [alwaysShow]="true"></pbc-loader>
</div>
<div class="row" [ngClass]="{'d-none': isLoadingContent }" style="margin-bottom: 3rem;">
  <div [ngClass]="isLwwaUser?'col-lg-12 mx-auto ':'col-lg-8 mx-auto'">   
    <ng-container *ngFor="let templateName of componentNameList">
    <ng-container [ngTemplateOutlet]="getTemplate(templateName)"></ng-container>                 
  </ng-container>
  </div>
</div>
<pbc-html-block *ngIf="isHmoUser || futureEligibility"
  [data]="getComponentItem(contentCache, 'html-block-3')"></pbc-html-block>

<ng-template #twoPack>
  <pbc-two-pack *ngIf="!isKinwellAd" [data]="getComponentItem(contentCache, 'two-pack-shade-1')"></pbc-two-pack>
  <pbc-two-pack-kinwell *ngIf="isKinwellAd" [isHMO]="isHmoUser"
    [data]="getComponentItem(contentCache, 'two-pack-shade-1')"></pbc-two-pack-kinwell>
</ng-template>
<ng-template #nonKinwellAd>
  <div class="col-sm-12" [ngClass]="{'mt-5 mb-5 pl-0 pr-0':isLwwaUser}">
    <pbc-two-pack-plan *ngIf="isHmoUser || futureEligibility; else twoPack" [isFuture]="futureEligibility"
      [isKinwellAd]="isKinwellAd" [data]="getComponentItem(contentCache, 'two-pack-plan-1')"></pbc-two-pack-plan>
  </div>
</ng-template>
<ng-template #ppoKinwellAd>
  <div class="col-sm-12">
    <pbc-two-pack-kinwell [data]="getComponentItem(contentCache, 'two-pack-kinwell')" [isHMO]="isHmoUser"></pbc-two-pack-kinwell>
  </div>
</ng-template>
<ng-template #htmlBlock1>
  <pbc-html-block [data]="getComponentItem(contentCache, 'html-block-1')"></pbc-html-block>
</ng-template>
<ng-template #twoPackKW>
  <div *ngIf="isKinwellAd; else nonKinwellAd" class="row ml-0">
    <div *ngIf="isHmoUser || futureEligibility; else ppoKinwellAd" class="row ml-0">
      <div class="col-sm-6">
        <pbc-two-pack-plan *ngIf="isHmoUser || futureEligibility; else twoPack" [isFuture]="futureEligibility"
          [isKinwellAd]="isKinwellAd" [data]="getComponentItem(contentCache, 'two-pack-plan-1')"></pbc-two-pack-plan>
      </div>
      <div class="col-sm-6 kinwellText">
        <pbc-two-pack-kinwell [data]="getComponentItem(contentCache, 'two-pack-kinwell')"  [isHMO]="isHmoUser"></pbc-two-pack-kinwell>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #cardRow>
  <div *ngIf="isLwwaUser">
    <div class="mb-4">
      <pbc-card-row [data]="getComponentItem(contentCache, 'card-row-1')"></pbc-card-row>
    </div>
    <div class="mb-4">
      <pbc-card-row [data]="getComponentItem(contentCache, 'card-row-2')"></pbc-card-row>
    </div>
  </div>
</ng-template>
<ng-template #htmlBlock2>
  <pbc-html-block [data]="getComponentItem(contentCache, 'html-block-2')"></pbc-html-block>
</ng-template>