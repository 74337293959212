import { Component, Input, OnChanges } from '@angular/core';

import { ComponentState, CtaModel } from 'libs/models/pbc-models';
import { ContentComponent } from '../content-component';

@Component({
  selector: 'pbc-promo',
  templateUrl: './promo.component.html'
})
export class PromoComponent implements ContentComponent, OnChanges {
  @Input() data: ComponentState;
  ctaOne: CtaModel;
  ctaTwo: CtaModel;
  ctaThree: CtaModel;

  ngOnChanges(): void {
    if (this.data && this.data.content) {
      this.ctaOne = new CtaModel(this.data.content.ctaOneUrl, this.data.content.ctaOneText, this.data.content.ctaOneStyle,
        this.data.content.ctaOneExternal, this.data.content.ctaOneAnalyticsTracker, null, this.data.content.ctaOnePreventDefault);

      this.ctaTwo = new CtaModel(this.data.content.ctaTwoUrl, this.data.content.ctaTwoText, this.data.content.ctaTwoStyle,
        this.data.content.ctaTwoExternal, this.data.content.ctaTwoAnalyticsTracker, null, this.data.content.ctaTwoPreventDefault);

      this.ctaThree = new CtaModel(this.data.content.ctaThreeUrl, this.data.content.ctaThreeText, this.data.content.ctaThreeStyle,
          this.data.content.ctaThreeExternal, this.data.content.ctaThreeAnalyticsTracker, null, this.data.content.ctaThreePreventDefault);
    }
  }
}
