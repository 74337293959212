<div class="row">
  <div class="col-lg-8 mx-auto">
    <h1 tabindex="-1" [innerHtml]="pageTitle"></h1>
    <pbc-alert [data]="localAlert" [alert]="popUpAlert"></pbc-alert>
  </div>
</div>
<div *ngIf="!hasError" class="row">
  <div class="col-lg-8 mx-auto">
    <pbc-html-block [data]="contentCache['html-block-1']"></pbc-html-block>
    <pbc-accordion [data]="contentCache['accordion-1']"></pbc-accordion>
  </div>
</div>
<div aria-live="assertive" *ngIf="hasError">
  <pbc-error-data-block id="errorBlock" [showSessionId]="true"></pbc-error-data-block>
</div>
