import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decodeHtml'
})
export class DecodeHtmlPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const el = document.createElement('textarea');
    el.innerHTML = value;
    return el.value;
  }

}
