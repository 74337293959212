<pbc-placeholder></pbc-placeholder>
<div *ngIf="data?.isVisible" data-id="two-pack" class="hero">
  <div class="hero-img" *ngIf="!isAlignedRight(data)">
    <img [ngClass]="isLwwa?'hero-img-lwwa':'img-fluid'" pbcLazyLoadImg [alt]="data?.content?.imageAltText" [attr.aria-hidden]="!data?.content?.imageAltText"
      [src]="(data?.content?.imageUrl)">
  </div>
  <div [ngClass]="{'hero-text-lwwa':isLwwa}" class="hero-text">
    <h2 [ngClass]="{'hero-heading-lwwa':isLwwa}" >{{data?.content?.title}}</h2>
    <div *ngIf="data?.content?.body" [innerHTML]="data?.content?.body"></div>
    <div *ngIf="data?.content?.description"  [innerHTML]="data?.content?.description"></div>
    <pbc-cta-pack [data]="data" class="my-5"></pbc-cta-pack>
  </div>
  <div class="hero-img" *ngIf="isAlignedRight(data)">
    <img pbcLazyLoadImg class="img-fluid" [alt]="data?.content?.imageAltText" [attr.aria-hidden]="!data?.content?.imageAltText"
      [src]="(data?.content?.imageUrl)">
  </div>
</div>
