import { ComponentState } from 'libs/models/pbc-models';
import { Injectable } from "@angular/core";

@Injectable()
export class ContentHelperMethods {
    public static isAlignedRight(data: ComponentState): boolean {
      if (data && data.content && data.content.alignment) {
        return data.content.alignment.toLowerCase() === 'right';
      }
      if (data && data.content && data.content.imageAlignment) {
        return data.content.imageAlignment.toLowerCase() === 'right';
      }
      return false;
    }
  }
