
import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { map, takeUntil } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import 'bingmaps';
import { AuthenticationService } from 'libs/services/authentication.service';
import { MemberWithEligibility } from 'libs/models/member.models';
import { BaseContentPage } from 'libs/classes/base-content-page';
import { MemberSharedService } from 'libs/services/member-shared.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { VisibilityService } from 'libs/services/visibility.service';
import { MemberPermission } from 'libs/models/pbc-enums';
import { HttpClient } from '@angular/common/http';
import { BingMapsService } from 'libs/services/bing-maps.service';
import { SeverityLevel } from 'libs/services/logging.service';


@Component({
  selector: 'pbc-find-a-doctor',
  templateUrl: './find-a-doctor.component.html'
})
export class FindADoctorComponent extends BaseContentPage implements OnInit, OnDestroy {
  @ViewChild('bingMap', { static: true }) bingMap: ElementRef;
  @ViewChild('htmlBlock1') htmlBlock1:TemplateRef<any>;
  @ViewChild('htmlBlock2') htmlBlock2:TemplateRef<any>;
  @ViewChild('cardRow') cardRow:TemplateRef<any>;
  @ViewChild('twoPackKW') twoPackKW:TemplateRef<any>;
  eligibleFamilyMembers: MemberWithEligibility[];
  isLoadingContent = true;
  privacyScope = ''; // according to Komala, empty string suffices
  isHmoUser: any = false;
  isLwwaUser: any = false;
  futureEligibility: boolean = false;
  latitude: any;
  longitude: any;
  stateName: string;
  isKinwellAd: boolean = false;
  planStatus: string;
  featureFlags: any;
  keyDate: Date;
  componentNameList:string[]=[];
  constructor(
    tabTitle: Title, activatedRoute: ActivatedRoute, private readonly httpClient: HttpClient, private readonly authenticationService: AuthenticationService,
    private readonly memberSharedService: MemberSharedService, private readonly visibilityService: VisibilityService, private bingMapsService: BingMapsService) {
    super(false, tabTitle, activatedRoute, memberSharedService);
    this.featureFlags = EnvironmentService.variables.featureFlags;
  }
  ngOnInit() {
    super.ngOnInit();
    if (sessionStorage.length > 0  && !!sessionStorage.getItem('selectedPlanSession') && sessionStorage.getItem('selectedPlanSession') !== undefined && sessionStorage.getItem('selectedPlanSession') !== null) {
      try{
        this.planStatus = JSON.parse(sessionStorage.getItem("selectedPlanSession"))?.planStatus;
        this.keyDate = JSON.parse(sessionStorage.getItem("selectedPlanSession"))?.keyDate;
      }catch(error){
        this.logger.track(`Selected Plan registration not available: ${sessionStorage.getItem('selectedPlanSession')}`, {}, SeverityLevel.Error);
      }
    }  
      
    if(this.planStatus?.toLowerCase() === 'future'){
      this.futureEligibility = true;
    }
    if(this.featureFlags.kinwellAdFlag){
      this.handlePermission();
      if(this.stateName?.toLowerCase() === 'wa'){
        this.isKinwellAd = true;
      }
    } 
    else{
      this.isKinwellAd = false;
    }   
    this.pageInfoObservable.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => { // wait until the base init finishes then process
      combineLatest([this.authenticationService.getIsAuthenticated(), this.memberSharedService.loggedInMemberKey])
      .pipe(map(([isAuth, loggedInUser]) => {
        if (isAuth && !!loggedInUser) {
          combineLatest([this.visibilityService.hasPermission(MemberPermission.IsMedSuppGroup),
          this.memberSharedService.isLoggedInMemberActiveAndEligible(this.planStatus?.toLowerCase() === 'future' ? this.keyDate : new Date(), this.privacyScope),
          this.visibilityService.hasPermission(MemberPermission.HasHmoPlan),
          this.visibilityService.hasPermission(MemberPermission.HasLwwaPlan)
          ]).pipe(map(([isMedSuppGroup, isEligibleAndActive, hasHmoPlan, hasLwwaPlan]) => {
            this.isHmoUser = hasHmoPlan;
            this.isLwwaUser = hasLwwaPlan;
            if (isMedSuppGroup && !hasHmoPlan) {
              this.getMedsupPageContent();
            } else if((hasHmoPlan || this.futureEligibility) && isEligibleAndActive){
              this.getEligibleHmoPageContent();
            } else if (isEligibleAndActive && !hasHmoPlan) {
              this.getEligiblePageContent();
            } else {
              this.getInactivePageContent();
            }
          }), takeUntil(this.componentDestroyed$)).subscribe(() => { }, err => {
            this.getNonsecurePageContent();
          });
        } else {
          this.getNonsecurePageContent();
        }
      }), takeUntil(this.componentDestroyed$)).subscribe(() => { }, err => {
        this.getNonsecurePageContent();
      });
    });
  }
  isAlignedRight(data: any): boolean {
    if (data && data.content && data.content.alignment) {
      return data.content.alignment.toLowerCase() === 'right';
    }
    if (data && data.content && data.content.imageAlignment) {
      return data.content.imageAlignment.toLowerCase() === 'right';
    }
    return false;
  }
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
  private getNonsecurePageContent() {
    this.getContentWithSsoLink('-nonsecure-v2');
  }
  private getEligiblePageContent() {
    this.getContentWithSsoLink('-secure');
  }
  private getEligibleHmoPageContent() {
    this.getContentWithSsoLink('-secure-hmo');
  }
  private getContentWithSsoLink(alias: string) {
    if (EnvironmentService.variables.featureFlags.ssoOutboundSapphire) {
      this.getContextualPageContent(alias);
    } else {
      this.getSsoDisabledPageContent();
    }
  }
  private getMedsupPageContent() {
    this.getContextualPageContent('-medsupp');
  }
  private getInactivePageContent() {
    this.getContextualPageContent('-inactive');
  }
  private getSsoDisabledPageContent() {
    this.getContextualPageContent('-ssodisabled');
  }
  private getContextualPageContent(suffix: string): void {
    const pageData = this.activatedRoute.snapshot.data;
    if (pageData) {
      this.contentService
        .getPageContent(pageData['page'] + suffix, pageData['contentGroup'] + suffix)
        .pipe(takeUntil(this.componentDestroyed$), map(response => {
          this.contentCache = response.pageContent;
          this.pageTitle = response.pageInfo.content.title;
          this.isLoadingContent = false;
          for(const comp in this.contentCache){
            let instanceName=this.contentCache[comp]?.instanceName?.toLowerCase();
            let compTemplateName=instanceName==='html-block-1'
            ?'htmlBlock1':
              instanceName.includes('two-pack')
            ?'twoPack':
              instanceName.includes('card-row')
            ?'cardRow':
              instanceName==='html-block-2'
            ?'htmlBlock2':'';
            if(this.componentNameList.indexOf(compTemplateName)==-1 && compTemplateName!== ""){
              this.componentNameList.push(compTemplateName)
            }
          }        
        })).subscribe();
    }
  }
  getComponentItem(componentData: any, instanceName: string): any {
    for (const comp in componentData) {
      if (componentData.hasOwnProperty(comp)) {       
        if(componentData[comp]?.instanceName?.toLowerCase() === instanceName?.toLowerCase()){
          return componentData[comp];
        }
        else{
          if(instanceName?.toLowerCase() === 'two-pack-shade-1'){
            if(componentData[comp]?.instanceName?.toLowerCase() === 'two-pack-1'){
              return componentData[comp];
            }
          }
        }
      }    
  }
}
handlePermission() {
  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    if (result.state === "granted") {
      this.report(result.state);
      this.getUserLocation();
    } else if (result.state === "prompt") {
      this.report(result.state);     
      this.getUserLocation();
    } else if (result.state === "denied") {
      this.report(result.state);      
    }
    result.addEventListener("change", () => {
      this.report(result.state);
      if (result.state === "granted") {
        this.report(result.state);
        this.getUserLocation();
      }
    });
  });
}
report(state) {
  console.log(`Permission ${state}`);
}
getUserLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position: any) => {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.getbingMapState(this.latitude,this.longitude);
    });
  }
}
getbingMapState(latitude,longitude){
  this.bingMapsService.getStateName(latitude, longitude).subscribe((response: any) => {
    if(!!response){
      const resourceSets = response.resourceSets;  
      if(resourceSets && resourceSets.length>0){
        const resources = resourceSets[0].resources;
        if(resources && resources.length>0){
          const address = resources[0].address;
          if(address && address.adminDistrict){
            this.stateName =  address.adminDistrict;
            if(this.stateName?.toLowerCase() === 'wa'){
              this.isKinwellAd = true;
            }
          }
        }
      }
    }     
  });    
}
getTemplate(item: string): TemplateRef<any> {
  let dynItem=ViewChild(item);
  switch(item){
    case "htmlBlock1":
      return this.htmlBlock1
      break;
    case "htmlBlock2":
      return this.htmlBlock2
      break;
    case "cardRow":
      return this.cardRow
      break;
    case "twoPack":
      return this.twoPackKW
      break;
    default:
      return dynItem;
  }   
}
}
