import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonTypePipe } from './button-type.pipe';
import { ColumnOrderPipe } from './column-order.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { DebouncePipe } from './debounce.pipe';
import { DecodeHtmlPipe } from './decode-html.pipe';
import { ExternalTargetPipe } from './external-target.pipe';
import { FileSizePipe } from './file-size.pipe';
import { JustifyContentPipe } from './justify-content.pipe';
import { PhonePipe } from './phone.pipe';
import { SafeExternalTargetPipe } from './safe-external-target.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TextAlignmentPipe } from './text-alignment.pipe';
import { UnicodeHexDecodePipe } from './unicode-hex-decode.pipe';
import { UrlQualifySafeResourcePipe } from './url-qualify-safe-resource.pipe';
import { UrlQualifySafeStylePipe } from './url-qualify-safe-style.pipe';
import { UrlQualifyPipe } from './url-qualify.pipe';
import { GroupByPipe } from './group-by.pipe';

@NgModule({
  declarations: [
    FileSizePipe,
    PhonePipe,
    ExternalTargetPipe,
    TextAlignmentPipe,
    ButtonTypePipe,
    ColumnOrderPipe,
    SafeHtmlPipe,
    UrlQualifyPipe,
    UrlQualifySafeStylePipe,
    JustifyContentPipe,
    DateFormatPipe,
    DecodeHtmlPipe,
    DebouncePipe,
    SafeExternalTargetPipe,
    UnicodeHexDecodePipe,
    UrlQualifySafeResourcePipe,
    GroupByPipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    DecodeHtmlPipe,
    DateFormatPipe
  ],
  exports: [
    FileSizePipe,
    PhonePipe,
    ExternalTargetPipe,
    GroupByPipe,
    TextAlignmentPipe,
    ButtonTypePipe,
    ColumnOrderPipe,
    SafeHtmlPipe,
    UrlQualifyPipe,
    UrlQualifySafeStylePipe,
    JustifyContentPipe,
    DateFormatPipe,
    DecodeHtmlPipe,
    DebouncePipe,
    SafeExternalTargetPipe,
    UnicodeHexDecodePipe,
    UrlQualifySafeResourcePipe
  ]
})
export class PipesModule { }
