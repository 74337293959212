import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, take, map } from 'rxjs/operators';

import { DataService } from 'libs/services/data.service';
import { LoggingService, SeverityLevel } from 'libs/services/logging.service';
import { SurrogateIdService } from 'libs/services/surrogate-id.service';
import { UrlBuilderService } from 'libs/services/url-builder.service';
import { StatusCode } from '../models/pbc-enums';
import { EnvironmentService } from './environment-variables.service';
import { MemberSharedService } from './member-shared.service';
import { PbcCookieService } from './pbc-cookie.service';
import { SuccessResponse } from 'libs/models/pbc-models';
import { ChildSiteScopes } from 'libs/models/site-config';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  protected isMockDemo = false;
  protected isAuthenticated = new BehaviorSubject<boolean>(null); // used for tracking history of cookie
  private readonly nonsecureHome = `${EnvironmentService.variables.baseUrlVisitor}/${ChildSiteScopes.find(scope => scope.site === EnvironmentService.variables.site)?.nonSecurePath ?? 'visitor'}`;
  private readonly secureHome = `${EnvironmentService.variables.baseUrlMember}/dashboard`;
  public readonly defaultHomePage = new BehaviorSubject<string>(this.nonsecureHome);

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(protected readonly dataService: DataService,
    protected readonly pbcCookieService: PbcCookieService,
    protected readonly urlBuilderService: UrlBuilderService,
    protected readonly router: Router,
    protected readonly logger: LoggingService,
    protected readonly surrogateIdService: SurrogateIdService,
    protected readonly memberSharedService: MemberSharedService,) { }

  // just checks for authentication, not memberkeys
  getIsAuthenticated(): Observable<boolean> {
    this.checkAuthAndUpdate();
    return this.isAuthenticated.asObservable();
  }

  hasBeenSignedIn(): boolean {
    return this.isAuthenticated.value !== null;
  }

  sendToSignIn(returnUrl = EnvironmentService.variables.authentication.defaultReturnUrl): void {
    this.resetAuthentication();
    console.log(`Sign In trace [${new Date().toISOString()}]: ?. authSvc.sendToSignIn - returnUrl=${returnUrl}`);
    this.setReturnUrl(returnUrl);
    const redirectUrl = this.urlBuilderService.buildSecureAuthUrl(EnvironmentService.variables.authentication.callbackUrl);
    window.location.href = redirectUrl;
  }

  signOut(): void {
    this.resetAuthentication();  
    this.dataService.get<any>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.signOutUrl))
      .pipe(finalize(() => {
        console.log(`Sign In trace [${new Date().toISOString()}]: ?. authSvc.signOut`);
        this.resetAuthentication();
        this.setReturnUrl('/');
        if (!this.isMockDemo) {
          window.location.href = `${EnvironmentService.variables.authentication.restart}`;
        }
      }), take(1)).subscribe();
  }

  getEncryptedToken(): Observable<string> {
    return this.dataService
      .get<SuccessResponse<string>>(this.urlBuilderService.buildBffUrl(EnvironmentService.variables.dataLocation.encryptedTokenUrl))
      .pipe(map(response => {
        return response.result;
      }));
  }

  logPageView(pageName: string): void {
    const authSessionGuid = this.pbcCookieService.get(this.pbcCookieService.sessionGuidCookie);
    const memberKey = this.memberSharedService.getSelectedMemberKey();
    this.logger.track(`Page view::${pageName}:: ${authSessionGuid}, MemberKey: ${memberKey}`, {}, SeverityLevel.Information);
  }

  protected resetAuthentication(): void {
    this.pbcCookieService.delete(this.pbcCookieService.memberKeysCookie);
    this.pbcCookieService.delete(this.pbcCookieService.surrogateIdCookie);
    this.pbcCookieService.delete(this.pbcCookieService.memberAuthCookie);
    this.pbcCookieService.delete(this.pbcCookieService.sessionGuidCookie);
    try {
      localStorage.removeItem('familyPCPResult');
      localStorage.removeItem('MemberAddress');
      localStorage.removeItem('targetMemberKey');
      sessionStorage.removeItem("isHmoUser");
      sessionStorage.removeItem("selectedPlanSession");
    }
    catch (e) {
      this.logger.track(`Session clear out error on resetAuthentication method`, {}, SeverityLevel.Information);
    }
    this.surrogateIdService.clearSurrogateId();
    this.memberSharedService.refreshLoggedInMemberKey();
    this.checkAuthAndUpdate();
  }

  protected checkAuthAndUpdate(): boolean {
    const authCookie = this.pbcCookieService.get(this.pbcCookieService.memberAuthCookie);
    const authSessionGuid = this.pbcCookieService.get(this.pbcCookieService.sessionGuidCookie);
    if (!authCookie) {
      return this.pushState(false);
    }
    // split the authCookie
    const authCookieValues = authCookie.split(';');
    if (authCookieValues.length < 2) {
      this.logger.track(`Sign In Error ${new Date().toISOString()}:: ${authSessionGuid} authSvc.checkAuthAndUpdate auth Cookie missing timestamp offset`, {}, SeverityLevel.Error);
      return this.pushState(false);
    }
    const authTokenExpiration = authCookieValues[0];
    const timeStampOffset = Number(authCookieValues[1]);

    const correctMachineTime = new Date().getTime() + (timeStampOffset);

    const isAuth = new Date(correctMachineTime) < new Date(authTokenExpiration);

    return this.pushState(isAuth);
  }

  protected pushState(state: boolean): boolean {
    if (this.isAuthenticated.getValue() !== state) {
      this.isAuthenticated.next(state);
      this.toggleDefaultLandingPage();
    }
    return state;
  }

  protected setReturnUrl(url: string): void {
    this.pbcCookieService.setSessionCookie(this.pbcCookieService.returnUrlCookie, url, true);
  }

  protected toggleDefaultLandingPage() {
    if (this.isAuthenticated.getValue()) {
      this.defaultHomePage.next(this.secureHome);
    } else {
      this.defaultHomePage.next(this.nonsecureHome);
    }
  }

  protected httpErrorResponse(error: HttpErrorResponse): Observable<boolean> {
    if (error.status === StatusCode.Forbidden) {
      this.router.navigate(['/unauthorized'], { skipLocationChange: true });
    } else if (this.isAuthenticated.value) { // if they were previously signed in and token expired
      this.signOut();
    }
    return of(this.checkAuthAndUpdate());
  }
}
