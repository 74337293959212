import { Component, Input } from '@angular/core';

import { ContentComponent, ContentHelperMethods } from '../content-component';
import { ComponentState } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-image-hero',
  templateUrl: './image-hero.component.html'
})
export class ImageHeroComponent implements ContentComponent {
  @Input() data: ComponentState;
  isTextAlignedRight = ContentHelperMethods.isAlignedRight;
  isLarge = ContentHelperMethods.isLarge;
}
