<a [href]="homePageUrl" pbcAnalyticsTag data-analytics-override-route="footer"
data-analytics-title="footer logo">
  <img id="footer-logo" class='mb-1' [src]="logoImgUrl" aria-label="Premera home" [attr.aria-hidden]="false" alt="footer logo">
</a>
<ul>
  <li>
    <a *ngIf="!hideRegionSwitchLink" pbcAnalyticsTag data-analytics-override-route="footer" data-analytics-title="footer lob" tabindex="0" href onclick="return false" (click)="switchLob()">
      <span id="footer-lob">{{ footerContent?.content?.primarySwitchStateButton }}</span>
    </a>
  </li>
</ul>
