import { Component } from '@angular/core';
import { FooterNavHorizComponent } from '../footer-nav-horiz/footer-nav-horiz.component';

@Component({
  selector: 'pbc-footer-nav-cols',
  templateUrl: './footer-nav-cols.component.html'
})
export class FooterNavColsComponent extends FooterNavHorizComponent {

}
