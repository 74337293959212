import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'libs/services/authentication.service';
import { StringsService } from 'libs/services/strings.service';

@Component({
  selector: 'pbc-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {

  constructor(readonly strings: StringsService, private readonly authService: AuthenticationService) { }

  ngOnInit() {
    this.authService.logPageView('UnauthorizedPage');
  }

}
