<section *ngIf="data?.isVisible" class="py-3">
  <div *ngIf="data?.content?.imageUrl" class="text-center p-0 pb-3">
    <img [alt]="data?.content?.imageAltText" [attr.aria-hidden]="!data?.content?.imageAltText" [src]="data?.content?.imageUrl | urlQualify">
  </div>
  <div [class]="data?.content?.alignment | textAlignmentPipe">
    <div *ngIf="data?.content?.title" class="h2 mb-3">{{data?.content?.title}}</div>
    <div class="promo-body" [innerHtml]="data?.content?.body"></div>
    <pbc-cta-pack [data]="data"></pbc-cta-pack>
  </div>
</section>
