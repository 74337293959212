<section *ngIf="data?.isVisible" class="{{data?.content?.alignment | textAlignmentPipe}}">
  <div class="row">
    <div class="col-11 col-md-8 col-xl-6 mx-auto">
      <div class="card my-5">
        <div class="card-body">
          <div data-id="statementBorderHeader" *ngIf="data?.content?.title" class="h2 mb-3 px-5">{{data?.content?.title}}</div>
          <div [innerHTML]="data?.content?.description" class="mt-1"></div>
          <pbc-cta-pack [data]="data"></pbc-cta-pack>
        </div>
      </div>
    </div>
  </div>
</section>
