<section *ngIf="data?.isVisible" class="mb-5">
    <div [innerHtml]="data?.content?.introduction"></div>
    <!-- <ngb-tabset>
      <ngb-tab  *ngFor="let section of getSections(); let index = index" id="ts-panel-{{index}}">
        <ng-template ngbTabTitle><span [innerHtml]="section?.sectionHeader | decodeHtml"></span></ng-template>
        <ng-template ngbTabContent>
          <div [innerHtml]="section?.sectionBody | decodeHtml"></div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset> -->
    <ul ngbNav #nav="ngbNav"  activeid="active" class="nav cls-nav-tabs nav-tabs justify-content-start">        
        <li *ngFor="let section of sections; let index = index" [ngbNavItem]="index" [title]="">
            <a ngbNavLink><span [innerHtml]="section?.sectionHeader | decodeHtml"></span></a>
            <ng-template ngbNavContent>
                <div [innerHtml]="section?.sectionBody | decodeHtml"></div>
            </ng-template>
        </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </section>