import { FlexibleTemplateComponent } from 'libs/templates/flexible/flexible.component';

export const PressReleaseRoutes = [
  { path: '', component: FlexibleTemplateComponent, data: { page: 'press-releases', contentGroup: 'member-press-releases' } },

  // 2023  
  { path: '2023-11-08', component: FlexibleTemplateComponent, data: { page: '2023-11-08', contentGroup: 'member-2023-11-08' } },
  { path: '2023-10-10', component: FlexibleTemplateComponent, data: { page: '2023-10-10', contentGroup: 'member-2023-10-10' } },
  { path: '2023-09-05', component: FlexibleTemplateComponent, data: { page: '2023-09-05', contentGroup: 'member-2023-09-05' } },
  { path: '2023-08-10', component: FlexibleTemplateComponent, data: { page: '2023-08-10', contentGroup: 'member-2023-08-10' } },
  { path: '2023-06-09', component: FlexibleTemplateComponent, data: { page: '2023-06-09', contentGroup: 'member-2023-06-09' } },
  { path: '2023-03-29', component: FlexibleTemplateComponent, data: { page: '2023-03-29', contentGroup: 'member-2023-03-29' } },
  
  // 2022
  { path: '2022-12-13', component: FlexibleTemplateComponent, data: { page: '2022-12-13', contentGroup: 'member-2022-12-13' } },
  { path: '2022-12-08', component: FlexibleTemplateComponent, data: { page: '2022-12-08', contentGroup: 'member-2022-12-08' } },
  { path: '2022-10-25', component: FlexibleTemplateComponent, data: { page: '2022-10-25', contentGroup: 'member-2022-10-25' } },
  { path: '2022-08-31', component: FlexibleTemplateComponent, data: { page: '2022-08-31', contentGroup: 'member-2022-08-31' } },
  { path: '2022-08-15', component: FlexibleTemplateComponent, data: { page: '2022-08-15', contentGroup: 'member-2022-08-15' } },
  { path: '2022-07-07', component: FlexibleTemplateComponent, data: { page: '2022-07-07', contentGroup: 'member-2022-07-07' } },
  { path: '2022-04-19', component: FlexibleTemplateComponent, data: { page: '2022-04-19', contentGroup: 'member-2022-04-19' } },
  { path: '2022-04-05', component: FlexibleTemplateComponent, data: { page: '2022-04-05', contentGroup: 'member-2022-04-05' } },
  { path: '2022-03-03', component: FlexibleTemplateComponent, data: { page: '2022-03-03', contentGroup: 'member-2022-03-03' } },
  { path: '2022-01-18', component: FlexibleTemplateComponent, data: { page: '2022-01-18', contentGroup: 'member-2022-01-18' } },
  { path: '2022-01-13', component: FlexibleTemplateComponent, data: { page: '2022-01-13', contentGroup: 'member-2022-01-13' } },

  // 2021
  { path: '2021-12-02', component: FlexibleTemplateComponent, data: { page: '2021-12-02', contentGroup: 'member-2021-12-02' } },
  { path: '2021-11-29', component: FlexibleTemplateComponent, data: { page: '2021-11-29', contentGroup: 'member-2021-11-29' } },
  { path: '2021-11-18', component: FlexibleTemplateComponent, data: { page: '2021-11-18', contentGroup: 'member-2021-11-18' } },
  { path: '2021-11-16', component: FlexibleTemplateComponent, data: { page: '2021-11-16', contentGroup: 'member-2021-11-16' } },
  { path: '2021-10-26', component: FlexibleTemplateComponent, data: { page: '2021-10-26', contentGroup: 'member-2021-10-26' } },
  { path: '2021-09-17', component: FlexibleTemplateComponent, data: { page: '2021-09-17', contentGroup: 'member-2021-09-17' } },
  { path: '2021-08-20', component: FlexibleTemplateComponent, data: { page: '2021-08-20', contentGroup: 'member-2021-08-20' } },
  { path: '2021-07-20', component: FlexibleTemplateComponent, data: { page: '2021-07-20', contentGroup: 'member-2021-07-20' } },
  { path: '2021-02-08', component: FlexibleTemplateComponent, data: { page: '2021-02-08', contentGroup: 'member-2021-02-08' } },

  // 2020
  { path: '2020-11-04', component: FlexibleTemplateComponent, data: { page: '2020-11-04', contentGroup: 'member-2020-11-04' } },
  { path: '2020-10-01', component: FlexibleTemplateComponent, data: { page: '2020-10-01', contentGroup: 'member-2020-10-01' } },
  { path: '2020-09-17', component: FlexibleTemplateComponent, data: { page: '2020-09-17', contentGroup: 'member-2020-09-17' } },
  { path: '2020-05-14', component: FlexibleTemplateComponent, data: { page: '2020-05-14', contentGroup: 'member-2020-05-14' } },
  { path: '2020-05-13', component: FlexibleTemplateComponent, data: { page: '2020-05-13', contentGroup: 'member-2020-05-13' } },
  { path: '2020-04-21', component: FlexibleTemplateComponent, data: { page: '2020-04-21', contentGroup: 'member-2020-04-21' } },
  { path: '2020-04-08', component: FlexibleTemplateComponent, data: { page: '2020-04-08', contentGroup: 'member-2020-04-08' } },
  { path: '2020-04-02', component: FlexibleTemplateComponent, data: { page: '2020-04-02', contentGroup: 'member-2020-04-02' } },
  { path: '2020-03-31', component: FlexibleTemplateComponent, data: { page: '2020-03-31', contentGroup: 'member-2020-03-31' } },
  { path: '2020-03-24', component: FlexibleTemplateComponent, data: { page: '2020-03-24', contentGroup: 'member-2020-03-24' } },
  { path: '2020-03-03', component: FlexibleTemplateComponent, data: { page: '2020-03-03', contentGroup: 'member-2020-03-03' } },
  { path: '2020-02-06', component: FlexibleTemplateComponent, data: { page: '2020-02-06', contentGroup: 'member-2020-02-06' } },

  // 2019
  { path: '2019-01-30', component: FlexibleTemplateComponent, data: { page: '2019-01-30', contentGroup: 'member-2019-01-30' } },
  { path: '2019-02-06', component: FlexibleTemplateComponent, data: { page: '2019-02-06', contentGroup: 'member-2019-02-06' } },
  { path: '2019-03-21', component: FlexibleTemplateComponent, data: { page: '2019-03-21', contentGroup: 'member-2019-03-21' } },
  { path: '2019-05-01', component: FlexibleTemplateComponent, data: { page: '2019-02-06', contentGroup: 'member-2019-02-06' } },
  { path: '2019-02-06', component: FlexibleTemplateComponent, data: { page: '2019-05-01', contentGroup: 'member-2019-05-01' } },
  { path: '2019-05-02', component: FlexibleTemplateComponent, data: { page: '2019-05-02', contentGroup: 'member-2019-05-02' } },
  { path: '2019-05-15', component: FlexibleTemplateComponent, data: { page: '2019-05-15', contentGroup: 'member-2019-05-15' } },
  { path: '2019-05-16', component: FlexibleTemplateComponent, data: { page: '2019-05-16', contentGroup: 'member-2019-05-16' } },
  { path: '2019-06-19', component: FlexibleTemplateComponent, data: { page: '2019-06-19', contentGroup: 'member-2019-06-19' } },
  { path: '2019-07-31', component: FlexibleTemplateComponent, data: { page: '2019-07-31', contentGroup: 'member-2019-07-31' } },
  { path: '2019-08-06', component: FlexibleTemplateComponent, data: { page: '2019-08-06', contentGroup: 'member-2019-08-06' } },
  { path: '2019-08-14', component: FlexibleTemplateComponent, data: { page: '2019-08-14', contentGroup: 'member-2019-08-14' } },
  { path: '2019-09-24', component: FlexibleTemplateComponent, data: { page: '2019-09-24', contentGroup: 'member-2019-09-24' } },
  { path: '2019-10-29', component: FlexibleTemplateComponent, data: { page: '2019-10-29', contentGroup: 'member-2019-10-29' } },

  // 2018
  { path: '2018-01-31', component: FlexibleTemplateComponent, data: { page: '2018-01-31', contentGroup: 'member-2018-01-31' } },
  { path: '2018-02-22', component: FlexibleTemplateComponent, data: { page: '2018-02-22', contentGroup: 'member-2018-02-22' } },
  { path: '2018-02-27', component: FlexibleTemplateComponent, data: { page: '2018-02-27', contentGroup: 'member-2018-02-27' } },
  { path: '2018-03-12', component: FlexibleTemplateComponent, data: { page: '2018-03-12', contentGroup: 'member-2018-03-12' } },
  { path: '2018-03-12', component: FlexibleTemplateComponent, data: { page: '2018-03-12', contentGroup: 'member-2018-03-12' } },
  { path: '2018-04-18', component: FlexibleTemplateComponent, data: { page: '2018-04-18', contentGroup: 'member-2018-04-18' } },
  { path: '2018-05-10', component: FlexibleTemplateComponent, data: { page: '2018-05-10', contentGroup: 'member-2018-05-10' } },
  { path: '2018-06-12', component: FlexibleTemplateComponent, data: { page: '2018-06-12', contentGroup: 'member-2018-06-12' } },
  { path: '2018-06-25', component: FlexibleTemplateComponent, data: { page: '2018-06-25', contentGroup: 'member-2018-06-25' } },
  { path: '2018-06-26', component: FlexibleTemplateComponent, data: { page: '2018-06-26', contentGroup: 'member-2018-06-26' } },
  { path: '2018-07-10', component: FlexibleTemplateComponent, data: { page: '2018-07-10', contentGroup: 'member-2018-07-10' } },
  { path: '2018-07-19', component: FlexibleTemplateComponent, data: { page: '2018-07-19', contentGroup: 'member-2018-07-19' } },
  { path: '2018-08-01', component: FlexibleTemplateComponent, data: { page: '2018-08-01', contentGroup: 'member-2018-08-01' } },
  { path: '2018-09-10', component: FlexibleTemplateComponent, data: { page: '2018-09-10', contentGroup: 'member-2018-09-10' } },
  { path: '2018-09-12', component: FlexibleTemplateComponent, data: { page: '2018-09-12', contentGroup: 'member-2018-09-12' } },
  { path: '2018-10-10', component: FlexibleTemplateComponent, data: { page: '2018-10-10', contentGroup: 'member-2018-10-10' } },
  { path: '2018-10-22', component: FlexibleTemplateComponent, data: { page: '2018-10-22', contentGroup: 'member-2018-10-22' } },
  { path: '2018-12-13', component: FlexibleTemplateComponent, data: { page: '2018-12-13', contentGroup: 'member-2018-12-13' } },
  { path: '2018-12-17', component: FlexibleTemplateComponent, data: { page: '2018-12-17', contentGroup: 'member-2018-12-17' } },

  // 2017
  { path: '2017-04-20', component: FlexibleTemplateComponent, data: { page: '2017-04-20', contentGroup: 'member-2017-04-20' } },
  { path: '2017-06-28', component: FlexibleTemplateComponent, data: { page: '2017-06-28', contentGroup: 'member-2017-06-28' } },
  { path: '2017-09-19', component: FlexibleTemplateComponent, data: { page: '2017-09-19', contentGroup: 'member-2017-09-19' } },
  { path: '2017-12-01', component: FlexibleTemplateComponent, data: { page: '2017-12-01', contentGroup: 'member-2017-12-01' } },
  { path: '2017-12-14', component: FlexibleTemplateComponent, data: { page: '2017-12-14', contentGroup: 'member-2017-12-14' } }
];
