import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment-variables.service';

@Injectable()

export class MockUrlBuilderService {
  readonly jsonExtension: string = '.json';
  private readonly bffBaseUrl: string = '';
  private readonly contentUrl: string = '';
  private readonly defaultMockMemberKey = '-200004501';

  constructor() {
    this.bffBaseUrl = EnvironmentService.variables.dataLocation.bffBaseUrl;
    this.contentUrl = EnvironmentService.variables.dataLocation.contentBaseUrl;
  }

  buildContentUrl(instanceName: string, region?: string): string {
    if (region !== 'pbcak') {
      region = '';
    }
    const url = EnvironmentService.variables.dataLocation.componentStateUrl.replace('{region}', !!region ? region + '/' : '' );
    return `${this.contentUrl}${url}${instanceName}${this.jsonExtension}`;
  }

  buildPageStateUrl(pageName: string): string {
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.pageStateUrl}${pageName}${this.jsonExtension}`;
  }

  buildAlertUrl(type: string): string {
    let prefix = '';
    if (!!type) {
      prefix = type.substring(0, type.indexOf('-'));
    }
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.alertUrl}alert-${prefix}${this.jsonExtension}`;
  }

  buildBffUrl(endpoint: string, memberKey?: string) {
    if (!memberKey) {
      memberKey = this.defaultMockMemberKey;
    }
    endpoint = endpoint.replace('{memberKey}/', `${memberKey}/`);
    return `${this.bffBaseUrl}${endpoint}`;
  }

  buildBffHealthUrl(): string {
    return `${this.bffBaseUrl}${EnvironmentService.variables.dataLocation.healthBffUrl}`;
  }

  buildContentHealthUrl(): string {
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.healthContentUrl}`;
  }

  buildSecureAuthUrl(redirectUrl: string): string {
    return `${EnvironmentService.variables.authentication.authorization}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}`;
  }

  buildSecureAuthRecoveryUrl(recoveryBaseUrl: string, redirectUrl: string): string {
    return `${recoveryBaseUrl}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}&` +
      `response_mode=${EnvironmentService.variables.authentication.responseMode}&` +
      `refresh=1`;
  }

  buildSecureAuthChangePlanUrl(recoveryBaseUrl: string, redirectUrl: string): string {
    return `${recoveryBaseUrl}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}&` +
      `response_mode=${EnvironmentService.variables.authentication.responseMode}`;
  }

}
