import { Component, Input, OnChanges } from '@angular/core';
import { ContentComponent, ContentHelperMethods } from '../content-component';
import { ComponentState, CtaModel } from 'libs/models/pbc-models';

@Component({
  selector: 'pbc-two-pack-plan',
  templateUrl: './two-pack-plan.component.html'
})
export class TwoPackPlanComponent implements ContentComponent {
  @Input() data: ComponentState;
  @Input() isFuture: boolean;
  @Input() isKinwellAd : boolean = false;
  isAlignedRight = ContentHelperMethods.isAlignedRight;
}
