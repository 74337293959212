<div class="position-relative">
    <div class="d-flex mb-3"
        [ngClass]="{'justify-content-start': alignment === 'left', 'justify-content-center': alignment !== 'left'}">
        <i aria-hidden="true" class="material-icons body pr-2" [ngClass]="{'position-absolute': alignment === 'left'}"
            [ngClass]="'text-' + icon">{{icon}}</i>
        <div class="d-inline errorMessage">
            <span *ngIf="!!errorMessage; else stringsServiceMessage" class="cls-futureHmo">{{errorMessage}}</span>
            <ng-template #stringsServiceMessage>
                {{ strings.data.value?.DATA_CANNOT_BE_RETRIEVED || 'This information is currently unavailable. Please try again later.'}}
            </ng-template>
        </div>
    </div>
    <div *ngIf="showSessionId" class="d-flex body-small"
        [ngClass]="{'justify-content-start': alignment === 'left', 'justify-content-center': alignment !== 'left'}">
        Session ID: {{sessionId}} | {{timestamp | date:'short'}}</div>
</div>