import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment-variables.service';

@Injectable()

// The function of this class is to consolidate where we are building URLs and referencing baseUrl
export class UrlBuilderService {
  private readonly bffBaseUrl: string = '';
  private readonly contentUrl: string = '';
  private readonly defaultMockMemberKey = '-200004501';

  constructor() {
    this.bffBaseUrl = EnvironmentService.variables.dataLocation.bffBaseUrl;
    this.contentUrl = EnvironmentService.variables.dataLocation.contentBaseUrl;
  }

  buildContentUrl(instanceName: string, region?: string): string {
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.componentStateUrl}`;
  }

  buildPageStateUrl(pageName: string): string {
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.pageStateUrl}`;
  }

  buildAlertUrl(type: string): string {
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.alertUrl}`;
  }

  buildBffUrl(endpoint: string, memberKey?: string) {
    if (['demo', 'localdemo'].includes(EnvironmentService.variables.name)) {
      if (!memberKey) {
        memberKey = this.defaultMockMemberKey;
      }
      endpoint = endpoint.replace('{memberKey}/', `${memberKey}/`);
    }
    return endpoint.startsWith('http') ? endpoint : `${this.bffBaseUrl}${endpoint}`;
  }

  buildBffHealthUrl(): string {
    return `${this.bffBaseUrl}${EnvironmentService.variables.dataLocation.healthBffUrl}`;
  }

  buildContentHealthUrl(scope: string): string {
    let scopeFilter = '';
    if (!!scope) {
      scopeFilter = `?scope=${scope}`;
    }
    return `${this.contentUrl}${EnvironmentService.variables.dataLocation.healthContentUrl}${scopeFilter}`;
  }

  buildSecureAuthUrl(redirectUrl: string): string {
    return `${EnvironmentService.variables.authentication.authorization}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}&` +
      `response_mode=${EnvironmentService.variables.authentication.responseMode}`;
  }

  buildSecureAuthRecoveryUrl(recoveryBaseUrl: string, redirectUrl: string): string {
    return `${recoveryBaseUrl}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}&` +
      `response_mode=${EnvironmentService.variables.authentication.responseMode}&` +
      `refresh=1`;
  }

  buildSecureAuthChangePlanUrl(recoveryBaseUrl: string, redirectUrl: string): string {
    return `${recoveryBaseUrl}?` +
      `client_id=${EnvironmentService.variables.authentication.clientId}&` +
      `redirect_uri=${redirectUrl}&` +
      `response_type=${EnvironmentService.variables.authentication.responseType}&` +
      `scope=${EnvironmentService.variables.authentication.scope}&` +
      `state=${EnvironmentService.variables.authentication.state}&` +
      `nonce=${EnvironmentService.variables.authentication.nonce}&` +
      `response_mode=${EnvironmentService.variables.authentication.responseMode}`;
  }
}
