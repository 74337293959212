export enum StatusCode {
  Ok = 200,
  Created = 201,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500
}

export enum AlertType {
  warning = 'warning',
  danger = 'danger',
  info = 'info',
  success = 'success'
}

export enum MemberPermission {
  HasAmazonEsi = 'HasAmazonEsi',
  HasAmazonRxAdvance = 'HasAmazonRxAdvance',
  HasBillPayAlacriti = 'HasBillPayAlacriti',
  HasCyc = 'HasCyc',
  HasCycFsaOnly = 'HasCycFsaOnly',
  HasItronID = 'HasItronID',
  HasLiveChat = 'HasLiveChat',
  HasPcpPlan = 'HasPcpPlan',
  HasTeleHealthProgram = 'HasTeleHealthProgram',
  HasWellnessBenefit = 'HasWellnessBenefit',
  HasWellnessExtras = 'HasWellnessExtras',
  HasWellnessFitbit = 'HasWellnessFitbit',
  IsActive = 'IsActive',
  IsAmazonPcp = 'IsAmazonPcp',
  IsHraEligible = 'IsHraEligible',
  IsHraEligibleCurrentYear = 'IsHraEligibleCurrentYear',
  IsIndividualGroup = 'IsIndividualGroup',
  IsMedSuppGroup = 'IsMedSuppGroup',
  IsMicrosoftPcp = 'IsMicrosoftPcp',
  IsSelfFundedGroup = 'IsSelfFundedGroup',
  IsSubscriber = 'IsSubscriber',
  IsTermed = 'IsTermed',
  NotMedSuppGroup = 'NotMedSuppGroup',
  IsAmazonGroup = 'IsAmazonGroup',
  HasHmoPlan = "HasHmoPlan", 
  HasPaperlessEob = "HasPaperlessEob",
  HasFutureHmoPlan = "HasFutureHmoPlan",
  HmoEligibilityDate = "HmoEligibilityDate",
  HasLwwaPlan = "HasLwwaPlan"
}
