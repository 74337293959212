import { Component, OnInit, Input } from '@angular/core';

import { ComponentState } from 'libs/models/pbc-models';
import { ContentComponent } from '../content-component';

@Component({
  selector: 'pbc-two-pack-vertical',
  templateUrl: './two-pack-vertical.component.html'
})
export class TwoPackVerticalComponent implements ContentComponent {

  @Input() data: ComponentState;

  constructor() { }
}
