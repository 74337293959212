import { Component, Input, ViewEncapsulation, OnChanges } from '@angular/core';

import { ComponentState, AlertMessage } from 'libs/models/pbc-models';
import { AlertType } from 'libs/models/pbc-enums';

@Component({
  selector: 'pbc-alert',
  templateUrl: './alert.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent implements OnChanges {

  @Input() data: ComponentState;
  // or
  @Input() alert: AlertMessage;
  @Input() level: string;
  @Input() isFutureEligiblity: boolean = false;

  icon = '';
  friendlyAlertAnnouncement = '';
  ngOnChanges(): void {
    // convert ComponentState to AlertMessage
    if (!this.alert && !!this.data && !!this.data.content) {
      this.alert = new AlertMessage(this.data.isVisible, this.data.content.type as AlertType, this.data.content.message, this.data.content.isDismissible === 'true');
    }

    if (!this.alert || !this.level) {
      return;
    }

    switch (this.alert.type) {
      case AlertType.success: {
        this.icon = 'check_circle';
        this.friendlyAlertAnnouncement = `${this.level}-Level Success Message`;
        break;
      }
      case AlertType.info: {
        this.icon = 'info';
        this.friendlyAlertAnnouncement = `${this.level}-Level Informational Message`;
        break;
      }
      case AlertType.warning: {
        this.icon = 'warning';
        this.friendlyAlertAnnouncement = `${this.level}-Level Warning Message`;
        break;
      }
      case AlertType.danger: {
        this.icon = 'error';
        this.friendlyAlertAnnouncement = `${this.level}-Level Error Message`;
        break;
      }
    }
  }

  public closeAlert() {
    this.data = undefined;
    this.alert = undefined;
  }
}
